import APIHelper from "helpers/APIHelper";
import { CADOperationTypeEnum } from "../enums";
import { ICADOperationLibraryItem } from "../interfaces";

export default class CADOperationsAPI{

  private static baseURL = `api/cad/operations`;

    static async GetOperations(operationType: CADOperationTypeEnum): Promise<ICADOperationLibraryItem[]> {
      const params = {
        operationType
      }

      return (await APIHelper.GetAsync(`${this.baseURL}/`, params)).Result as ICADOperationLibraryItem[];
    }
  
}