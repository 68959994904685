import * as React from "react";
import { Grid } from "@mui/material";
import { ICADOperationEditor, ICADOperationProperties, ICADParameterEdit } from "../../interfaces";
import OperationParameterComponentSelector from "./OperationParameterComponentSelector";

interface IOperationParametersProps {
    operationEdit?: ICADOperationProperties,
    loading: boolean,
    PostParameterAsync: (opParam: ICADParameterEdit) => Promise<ICADOperationEditor>,
    GetParameterFromLabel: (newEditor: ICADOperationEditor, label: string) => ICADParameterEdit
}

const OperationParameters: React.FC<IOperationParametersProps> = (props: IOperationParametersProps) => {

    const params = props.operationEdit?.parameters;

    if (!params) {
        return <></>;
    }

    return <Grid container direction="column" spacing={1} >
        {params.map((p) => {
            return <Grid item key={`cad-param-${p.name}`}>
                <OperationParameterComponentSelector opParam={p} PostParameterAsync={props.PostParameterAsync} GetParameterFromLabel={props.GetParameterFromLabel} loading={props.loading} />
            </Grid>
        })}
    </Grid>;

}

export default OperationParameters;