import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import React from 'react';

const EdgeElbowIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {


    return <SvgIcon {...props}>
        <path d="m 10 19 l 0 1 h 11 v 1 h -11 l 0 1 l -2 -1.5 z m -2 -17 l 3 0 l 0 0 l -4 9 l 0 11 l -3 0 l 0 -11 l 4 -9 l 0 0 m 2 14 l -2 -1.5 l 2 -1.5 l 0 1 l 11 0 l 0 1 l -11 0 l 0 0" />
    </SvgIcon>
}

export default EdgeElbowIcon;