import { Autocomplete, InputBase } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import ErrorAdornment from "components/Common/ErrorAdornment";
import { IPartCallSizePresets } from "helpers/interfaces";
import { isNullOrWhiteSpace } from "helpers/objects";
import React, { useCallback } from "react";

interface IPasteSpecialEditCallSizeColumnProps {
    align?: "left" | "right" | "center",
    error?: string,
    maxLength?: number,
    callSizes?: IPartCallSizePresets
}

const PasteSpecialEditCallSizeColumn: React.FC<GridRenderEditCellParams & IPasteSpecialEditCallSizeColumnProps> = (props) => {
    const { id, value, field, hasFocus, align, error, maxLength, callSizes } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLInputElement | null>(null);
  
    React.useLayoutEffect(() => {
        if (hasFocus && ref.current) {
            ref.current.focus();
            ref.current.select();
        }
    }, [hasFocus, ref]);
  
    const handleValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value; // The new value entered by the user
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    }, [apiRef, field, id]);
  
    const handleValueSelectChange = useCallback((event: any, data: string | null) => {
        apiRef.current.setEditCellValue({ id, field, value: data ?? "" });
    }, [apiRef, field, id]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
        if (ref.current && event.key === "Tab") {
            ref.current.blur();
        }
    }, [ref]);

    return callSizes && callSizes.callSizes.length > 0 ?
        <Autocomplete
            autoComplete
            disableClearable={callSizes.locked}
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            clearOnEscape
            onKeyDown={handleKeyDown}
            disabled={false /*TODO: Read from shortcut data */}
            options={callSizes.callSizes.map((cs) => cs.callSize)}
            style={{ width: "100%" }}
            renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return <InputBase
                    {...InputProps}
                    {...rest}
                    inputRef={ref}
                    inputProps={{
                        ...params.inputProps,
                        style:{padding: "0 16px", height: "100%", border: 0, fontSize: "14px", textAlign: align}
                    }}
                    endAdornment={
                        <React.Fragment>
                          {!isNullOrWhiteSpace(error) ? <ErrorAdornment validationError={error!} /> : null}
                          {InputProps.endAdornment}
                        </React.Fragment>
                    }
                />
            }}
            onChange={handleValueSelectChange}
            value={value === "" ? null : value}
        />
        :
        <InputBase inputRef={ref} type="text" fullWidth value={value} onChange={handleValueChange} error={!isNullOrWhiteSpace(error)}
            endAdornment={!isNullOrWhiteSpace(error) ? <ErrorAdornment validationError={error!} /> : null}
            inputProps={{style:{padding: "0 16px", height: "100%", border: 0, fontSize: "14px", textAlign: align}, maxLength: maxLength}} />
    
}

export default PasteSpecialEditCallSizeColumn;