import React, { useCallback } from 'react';
import AdjustmentSelection from "./AdjustmentSelection";
import { useTranslations } from "@fenetech/translations";
import { Grid, Typography, FormControl, InputLabel } from "@mui/material";
import ErrorAdornment from "components/Common/ErrorAdornment";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import { IAdjustmentIdentifier, IEdgeRake } from "../../interfaces";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { EdgeAdjustment } from "./AddEdgeAdjustment";
import { EdgeAdjustmentsEnum, EdgePlacement, ShiftDirection } from "../../enums";
import { CADHelpers } from "../../CADHelpers";

interface IEdgeRakeComponentProps {
    edgeName: string;
    existingRake: IEdgeRake | null;
    handleCanApply: (value: boolean, item: EdgeAdjustment | null) => void;
    errorMessage: string;
}


const EdgeRakeComponent: React.FC<IEdgeRakeComponentProps> = ({ edgeName, existingRake, handleCanApply, errorMessage }) => {

    const tm = useTranslations();
    const description = tm.Get("Rake Edge");
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const [shiftOffset, setShiftOffset] = React.useState<string>(lnf.Format(Math.abs(existingRake?.offset ?? 0)));
    const [shiftDirection, setShiftDirection] = React.useState<ShiftDirection>((existingRake?.offset ?? 0) > 0 ? "Out" : "In");
    const [rakePosition, setRakePosition] = React.useState<EdgePlacement>(existingRake?.rakeStart ? "Start" : "End");

    const handleValueChange = useCallback((shiftOffset: string, shiftDirection: ShiftDirection, rakePosition: EdgePlacement) => {

        let parsedOffset: number = CADHelpers.ParseNumericValue(shiftOffset, shiftDirection, lnf);

        const canApply = parsedOffset !== 0;
        const rake: IEdgeRake & IAdjustmentIdentifier = { adjustmentType: EdgeAdjustmentsEnum.Rake, edgeName: edgeName, rakeStart: rakePosition === "Start", offset: parsedOffset }
        handleCanApply(canApply, rake);

    }, [lnf, handleCanApply, edgeName]);


    const handleSetShiftOffset = useCallback((value: string) => {
        setShiftOffset(value);
        handleValueChange(value, shiftDirection, rakePosition);
    }, [handleValueChange, rakePosition, shiftDirection]);

    const handleSetShiftDirection = useCallback((value: ShiftDirection) => {
        setShiftDirection(value);
        handleValueChange(shiftOffset, value, rakePosition);
    }, [handleValueChange, rakePosition, shiftOffset]);

    const handleSetRakePosition = useCallback((value: EdgePlacement) => {
        setRakePosition(value);
        handleValueChange(shiftOffset, shiftDirection, value);
    }, [handleValueChange, shiftDirection, shiftOffset]);


    return <Grid xs item container spacing={1} alignItems={"center"}  >

        <Grid item xs={2}>
            <Grid item >
                <Typography>{description}:</Typography>
            </Grid>
        </Grid>

        <Grid item xs={2}>
            {/* {editing ? */}
            <FormControl fullWidth size="small">
                <InputLabel id="rake-position-label">{tm.Get("Rake from")}</InputLabel>
                <Select
                    labelId="rake-position-label"
                    label={tm.Get("Rake from")}
                    size="small"
                    fullWidth
                    value={rakePosition}
                    onChange={(event) => handleSetRakePosition(event.target.value as "Start" | "End")}
                >
                    <MenuItem value={"Start"}>{tm.Get("Start")}</MenuItem>
                    <MenuItem value={"End"}>{tm.Get("End")}</MenuItem>
                </Select>
            </FormControl>


        </Grid>

        <Grid item xs>
            <AdjustmentSelection
                shiftOffset={shiftOffset}
                shiftDirection={shiftDirection}
                editing={true}
                existingAdjustment={existingRake}
                setShiftOffset={handleSetShiftOffset}
                setShiftDirection={handleSetShiftDirection}
            />
        </Grid>

        <Grid item>
            {errorMessage ? <ErrorAdornment validationError={errorMessage} /> : null}
        </Grid>

    </Grid>
}

export default EdgeRakeComponent;
