import { Grid, useTheme } from "@mui/material";
import React from 'react';
import CADImage from "../CADImage";
import CADOperationTabsMobile from "./CADOperationTabsMobile";
import useCADAvailableOperations from "../../hooks/useCADAvailableOperations";
import { useCADState } from "../../Context/CADContext";
import { CADOperationTypeEnum, EdgeAdjustmentsEnum } from "../../enums";
import EdgeAdjustmentsComponent from "../EdgeTools/EdgeAdjustmentsComponent";
import CADOperationComponent from "../CADOperationComponent";
import { ICADLayer } from "../../interfaces";
import CADUISettings from "../CADUISettings";

interface ICADMainMobileProps {

}

const CADMainMobile: React.FC<ICADMainMobileProps> = (props: ICADMainMobileProps) => {

    const cadState = useCADState();
    const availableOperationTypes = useCADAvailableOperations();
    const theme = useTheme();

    if (!cadState.model || cadState.model.layers.length === 0) return null;
    const layer = cadState.model.layers[0];

    return <Grid container direction="column" p={1} spacing={1}>

        <Grid container item justifyContent={"center"} pb={.5}  >
            <CADImage />
            <CADUISettings style={{marginTop:theme.spacing(1)}}/>
        </Grid>

        <Grid item >
            <CADOperationTabsMobile currentTab={cadState.currentTab} availableOperationTypes={availableOperationTypes} />
        </Grid>

        <Grid item >
            <CADOperationTypeSelector currentTab={cadState.currentTab} layer={layer} />
        </Grid>

    </Grid>;

}

interface ICADOperationTypeSelectorProps {
    currentTab: string;
    layer: ICADLayer;
}

const CADOperationTypeSelector: React.FC<ICADOperationTypeSelectorProps> = (props: ICADOperationTypeSelectorProps) => {

    const opType = parseInt(props.currentTab) as CADOperationTypeEnum;
    const enabledTools = props.layer.edgeAdjustmentTools ?? EdgeAdjustmentsEnum.None;

    switch (props.currentTab) {
        case "EdgeAdjustments":
            return <EdgeAdjustmentsComponent enabledAdjustments={enabledTools} layer={props.layer} />;
        default:
            return <CADOperationComponent operationType={opType} />
    }

};

export default CADMainMobile;