import React, { useCallback } from 'react';
import { ICADOperationLibraryItem } from "../interfaces";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import APIHelper from "helpers/APIHelper";
import { useCADImageBackgroundColor } from "../hooks/useCADImageBackgroundColor";

interface CADOperationLibraryItemDisplayProps {
    operation: ICADOperationLibraryItem
    operationClickedCallback: ((operation: ICADOperationLibraryItem) => void) | undefined;
}

const CADOperationLibraryItemDisplay: React.FC<CADOperationLibraryItemDisplayProps> = (props: CADOperationLibraryItemDisplayProps) => {
    
    const imageSource: string = props.operation.imageExists ? APIHelper.GetBaseURL() + `api/cad/operations/${props.operation.operationID}/image`: "";
    const [errorLoadingImage, setErrorLoadingImage] = React.useState(false);

    const imageBackground = useCADImageBackgroundColor();

    const handleOperationClick = useCallback(() => {
        if (props.operationClickedCallback) {
            props.operationClickedCallback(props.operation);
        }
    },[props]);

    return (
        <Button sx={{width:203, height:300, textTransform:"none"}} onClick={handleOperationClick} >
            <Grid item  border={1} borderColor={"lightgray"} height={"100%"}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"space-between"} >
                    <Box width={195} height={195} marginTop={.25} marginBottom={1} style={{backgroundColor:imageBackground}}>
                        {errorLoadingImage === true ?
                        <div></div>
                        :
                        <img src={imageSource} alt={props.operation.label} onError={e => setErrorLoadingImage(true)} width={193} height={193} />
                        }
                    </Box>
                    <Divider variant="middle" flexItem sx={{bgcolor:"black", opacity:.25}}/>
                    <Typography variant={"body2"} align="center">
                        {props.operation.label}
                    </Typography>
                    <Divider/>
                    <Typography variant={"body2"} align="center">
                        {props.operation.label === props.operation.description ?  "" : props.operation.description}
                    </Typography>
                </Grid>
            </Grid>
        </Button>

    );
};

export default CADOperationLibraryItemDisplay;