import * as React from "react";
import { Grid, IconButton } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FlipIcon from '@mui/icons-material/Flip';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { useTranslations } from "@fenetech/translations";

import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";
import { ICADOperationProperties } from "../../interfaces";
import { ThemeColorEnum } from "helpers/enums";
import { useCADActions } from "../../Context/CADContext";

interface IFlipRotateProps {
    operationEdit: ICADOperationProperties,
    onRotationChange: (rotationDelta: number) => void
}

const FlipRotateTools: React.FC<IFlipRotateProps> = (props: IFlipRotateProps) => {

    const op = props.operationEdit;
    const cadActions = useCADActions();

    const tm = useTranslations();

    const getFlipArray = React.useCallback((op: ICADOperationProperties) => {
        const arr = [] as string[];
        if (op?.flipHorizontal) {
            arr.push('flipH');
        }
        if (op?.flipVertical) {
            arr.push('flipV');
        }
        return arr;
    }, []);

    const flipArray = React.useMemo(() => {
        return getFlipArray(op);
    }, [getFlipArray, op]);

    const [flips, setFlips] = React.useState<string[]>(flipArray);

    const handleFlipValueChange = React.useCallback(async (event: React.MouseEvent<HTMLElement>, newFlips: string[]) => {
        const flipH = newFlips.includes('flipH');
        const flipV = newFlips.includes('flipV');

        const newEditor = await CADOperationEditorAPI.PostPropertiesAsync({ ...op, flipHorizontal: flipH, flipVertical: flipV });
        cadActions.setOperationEditor(newEditor);
        setFlips(getFlipArray(newEditor.operation));
    }, [cadActions, getFlipArray, op]);

    const handleRotate90CwClick = React.useCallback((event: any) => {
        props.onRotationChange(-90);
    }, [props]);

    const handleRotate90CCwClick = React.useCallback((event: any) => {
        props.onRotationChange(90);
    }, [props]);

    const handleRotate180Click = React.useCallback((event: any) => {
        props.onRotationChange(180);
    }, [props]);

    const multiFlipToolsVisible = React.useMemo(() => {
        return op?.flipHorizontalVisible && op?.flipVerticalVisible;
    }, [op]);

    return <Grid container direction="row" spacing={1} alignItems={"center"}>

        {(op?.flipVerticalVisible && !multiFlipToolsVisible) &&
            <Grid item>
                <ToggleButtonGroup
                    value={flips}
                    onChange={handleFlipValueChange}
                    aria-label="flip controls"
                >
                    <ToggleButton value="flipV" aria-label="flip" title={tm.Get("Flip")}>
                        <FlipIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        }

        {multiFlipToolsVisible &&
            <Grid item>
                <ToggleButtonGroup
                    value={flips}
                    onChange={handleFlipValueChange}
                    aria-label="flip controls"
                >
                    <ToggleButton value="flipH" aria-label="flip horizontal" title={tm.Get("Flip Horizontal")}>
                        <FlipIcon />
                    </ToggleButton>
                    <ToggleButton value="flipV" aria-label="flip vertical" title={tm.Get("Flip Vertical")}>
                        <FlipIcon sx={{ padding: 0, transform: 'rotate(90deg)' }} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        }

        {op?.rotationAngleVisible &&
            <>
                <Grid item>
                    <IconButton color={ThemeColorEnum.Primary} onClick={handleRotate90CwClick} title={tm.Get("Rotate right 90°")}>
                        <Rotate90DegreesCwIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton color={ThemeColorEnum.Primary} onClick={handleRotate90CCwClick} title={tm.Get("Rotate left 90°")}>
                        <Rotate90DegreesCcwIcon />
                    </IconButton>
                </Grid>
                <Grid item>

                    <IconButton color={ThemeColorEnum.Primary} onClick={handleRotate180Click} title={tm.Get("Rotate 180°")}>
                        <RotateRightIcon />
                    </IconButton>
                </Grid>
            </>
        }
    </Grid>;
}

export default FlipRotateTools;