import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";
import { CADOperationTypeEnum, ShiftDirection } from "./enums";
import Format from "helpers/fv.format";

export class CADHelpers {

    public static OperationTypeDescriptionFromEnum(opType: CADOperationTypeEnum) :string {

        switch (opType) {
            case CADOperationTypeEnum.EdgeProcessing:
                return "Edgework";
            case CADOperationTypeEnum.EdgeCutout:
                return "Edge Cutouts";
            case CADOperationTypeEnum.CornerCutout:
                return "Corner Cutouts";
            case CADOperationTypeEnum.DrillHole:
                return "Drill Holes";
            case CADOperationTypeEnum.InternalCutout:
                return "Internal Cutouts";
            default:
                throw new Error("Unknown operation type");
        }
    }

    public static ParseNumericValue = (stringValue: string, shiftDirection: ShiftDirection | null, lnf: ILocaleNumberFormatter) => {
        let parsedOffset: number = lnf.Parse(stringValue);
    
        if (isNaN(parsedOffset)) {
            const decimalOffset = Format.fracToDec(stringValue);
            if (decimalOffset !== null) {
                parsedOffset = decimalOffset;
            } else {
                parsedOffset = 0;
            }
        }
    
        if (shiftDirection !== null) {
            if (shiftDirection === "In") {
                parsedOffset *= -1;
            }
        }
    
        if (isNaN(parsedOffset))
            parsedOffset = 0;
    
        return parsedOffset;
    }

}
