import Wizard from "components/OptionsWizard/Wizard";
import useQuoteData from "components/Quotes/useQuoteData";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useWizardStateActions } from "./WebDesigner/useWizardState";
import useItemPropertiesActions from "./ItemProperties/useItemPropertiesActions";
import { CADContextProvider } from "./CAD/Context/CADContext";

const WizardEntry: React.FC<any> = (props) => {

    const { quote } = useQuoteData();
    const wizardActions = useWizardStateActions();
    const itemPropertiesActions = useItemPropertiesActions();
    const oKey = quote?.oKey;

    const [query] = useSearchParams();
    const odKeyString = query.get("odKey") ?? undefined;
    const odKey = odKeyString ? parseInt(odKeyString) : 0;

    useEffect(() => {
        return (() => {
            wizardActions.ClearState();
            itemPropertiesActions.ClearState();
        });
    }, [wizardActions, itemPropertiesActions]);

    if (!oKey || isNaN(odKey)) {
        return null;
    }

    return <CADContextProvider>
        <Wizard oKey={oKey} odKey={odKey} />
    </CADContextProvider>
};

export default WizardEntry;