import APIHelper from "helpers/APIHelper";
import React from 'react';
import { useCADState } from "../Context/CADContext";
import useCADHash from "../Context/useCADHash";
import AutoScaleToWidthDiv from "components/OptionsWizard/GenericComponents/AutoScaleToWidthDiv";
import CADDrawing from "./CADDrawing";
import LoadingOverlay from "components/OptionsWizard/GenericComponents/LoadingOverlay";
import { IPoint } from "../interfaces";
import useImageLoader from "helpers/hooks/useImageLoader";
import CADItemAPI from "../API/CADItemAPI";

interface ICADImageProps {
    imageStyle?: React.CSSProperties;
}

const CADImage: React.FC<ICADImageProps> = (props: ICADImageProps) => {

    const cad = useCADState();
    const cadHash = useCADHash(cad.model);

    const uiSelections = cad.model?.uiSelections;

    let imageUrl: string =`api/cad/item/drawing?hash=${cadHash}&includeDimensions=${uiSelections?.showDimensions??true}&includeEdgeNumbers=${uiSelections?.showEdgeNumbers??true}` 

    const url: string = APIHelper.PrependBaseURL(imageUrl);

    const [loading, loadedImageUrl] = useImageLoader(url);
    const [scale, setScale] = React.useState<number>(1);
    const [offset, setOffset] = React.useState<IPoint>({ x: 0, y: 0 });

    React.useEffect(() => {
        if (loadedImageUrl && cadHash) {
            CADItemAPI.GetItemDrawingInfoAsync().then((info) => {
                setScale(info.scalingFactor);
                setOffset(info.offset);
            });
        }
    }, [cadHash, loadedImageUrl]);

    if (cadHash) {

        return <>
            <AutoScaleToWidthDiv>

                <LoadingOverlay loading={loading}>
                    {loadedImageUrl ?
                        <CADDrawing
                            loadedImageUrl={loadedImageUrl}
                            imageScale={scale}
                            imageOffset={offset}
                        />
                        :
                        <div style={props.imageStyle} />
                    }
                </LoadingOverlay>

            </AutoScaleToWidthDiv>
        </>

    } else {
        return <></>;
    }

}

export default CADImage;