import { IPingTest} from "../../helpers/interfaces";
import React, { useState } from 'react';
import API from "helpers/API";
import { CardContent, Container, Button, Box, Stack } from "@mui/material";
import CustomCardHeader from "components/Common/CustomCardHeader";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import { ThemeColorEnum } from "helpers/enums";
import CardSlim  from "components/Common/CardSlim"
import { useTranslations } from '@fenetech/translations';
import ApiStatusItem from "components/ApiStatus/ApiStatusItem";

  const ApiStatus: React.FC<any> = () => {
     
    const [result, setResult] = useState<IPingTest>({webApiOnline: false, sessionApiOnline: false, roeApiOnline: false, apiDbTest: false, sessionApiDbTest: false, roeApiDbTest: false });
    const reset = ({webApiOnline: false, sessionApiOnline: false, roeApiOnline: false, apiDbTest: false, sessionApiDbTest: false, roeApiDbTest: false });
    const [put, setPut] = useState<boolean>(false);
    const [del, setDelete] = useState<boolean>(false);

    const [loading, setloading] = useState<boolean>(true);
    const tm = useTranslations();
    const cardTitle = tm.Get("API Status");
    useEffectOnLoad(() => {
      API.PingTest().then(r =>{
        setResult(r) 
        setloading(false)
      });
      API.PutTest().then(p =>{
        setPut(p) 
      });
      API.DeleteTest().then(d =>{
        setDelete(d)
      });

    })
    
    const rePing = () => {
      setloading(true); 
      setResult(reset);
      setPut(false);
      setDelete(false);
      API.PingTest().then(r =>{
        setResult(r) 
        setloading(false)
      }); 
      API.PutTest().then(p =>{
        setPut(p) 
      });
      API.DeleteTest().then(d =>{
        setDelete(d)
      });
      
    }
    

    
      return (
        <Container>
          <CardSlim sx={{marginY: 2}}>
            <CustomCardHeader title={cardTitle} />
              <CardContent>
                <Box sx={{ flexGrow: 5 }}>
                  <Stack spacing={2}>
                    <ApiStatusItem loading={loading} condition={result.webApiOnline} text={"API Online"}/>
                    <ApiStatusItem loading={loading} condition={result.apiDbTest} text={"API Database Test"}/>
                    <ApiStatusItem loading={loading} condition={result.sessionApiOnline} text={"Session API Online"}/>
                    <ApiStatusItem loading={loading} condition={result.sessionApiDbTest} text={"Session API Database Test"}/>
                    <ApiStatusItem loading={loading} condition={result.roeApiOnline} text={"ROE API Online"}/>
                    <ApiStatusItem loading={loading} condition={result.roeApiDbTest} text={"ROE Database Test"}/>
                    <ApiStatusItem loading={loading} condition={put && del} text={"Put/Delete Test"}/>
                  </Stack>
                </Box>
              </CardContent>
            </CardSlim>
          <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center',}}>
            <Button color={ThemeColorEnum.Secondary} variant="contained" onClick={rePing}>{tm.Get("Retry")}</Button>
          </div>
        </Container>
        )
    }
    
export default ApiStatus

