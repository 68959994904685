import React, { useCallback } from 'react';
import { CADOperationTypeEnum } from "../enums";
import { Autocomplete, Grid, Typography } from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { ICADOperationLibraryItem } from "../interfaces";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import CADOperationsAPI from "../API/CADOperationsAPI";
import CADOperationLibraryItemDisplay from "./CADOperationLibraryItemDisplay";
import { useTranslations } from "@fenetech/translations";
import { useCADActions, useCADState } from "../Context/CADContext";

interface IOperationLibraryProps {
    operationType: CADOperationTypeEnum;
    addOperationCallback: ((operation: ICADOperationLibraryItem) => void) | undefined;
}

const CADOperationLibrary: React.FC<IOperationLibraryProps> = (props: IOperationLibraryProps) => {

    const tm = useTranslations();
    const cadActions = useCADActions();
    const cadState = useCADState();


    const [searchString, setSearchString] = React.useState<string>("");
    const [straightSidesFilter, setStraightSidesFilter] = React.useState<string>("");
    const [curvedSidesFilter, setCurvedSidesFilter] = React.useState<string>("");
    const [libaryOptions, setLibraryOptions] = React.useState<{ libraryID: number, description: string }[]>([]);
    const [selectedLibrary, setSelectedLibrary] = React.useState<{ libraryID: number, description: string }>({ libraryID: 0, description: tm.Get("All") });

    const operations = cadState.operationLibraries[props.operationType];

    useEffectOnLoad(() => {
        if (operations) {
            setLibraryOptionValues(operations);
        }
        else {
            CADOperationsAPI.GetOperations(props.operationType).then((response) => {
                setLibraryOptionValues(response);
                cadActions.addOperationLibrary(props.operationType, response);
            });
        }
    });

    const filteredOperations = React.useMemo(() => {
        if (!operations) {
            return [];
        }
        return operations.filter((operation) => {
            return (
                (operation.label.toLowerCase().includes(searchString.toLowerCase()) ||
                    operation.description.toLowerCase().includes(searchString.toLocaleLowerCase())
                ) &&
                (straightSidesFilter === "" || operation.straightSides === parseInt(straightSidesFilter)) &&
                (curvedSidesFilter === "" || operation.curvedSides === parseInt(curvedSidesFilter)) &&
                (selectedLibrary.libraryID === 0 || operation.libraryID === selectedLibrary.libraryID)
            );
        });
    }, [operations, searchString, straightSidesFilter, curvedSidesFilter, selectedLibrary]);


    const setLibraryOptionValues = useCallback((operations: ICADOperationLibraryItem[]) => {
        const options: { libraryID: number, description: string }[] = [];
        options.push({ libraryID: 0, description: tm.Get("All") });
        operations.forEach((operation) => {
            if (operation.libraryID) {
                let option = options.find((option) => option.libraryID === operation.libraryID);
                if (!option) {
                    options.push({ libraryID: operation.libraryID, description: operation.libraryDescription });
                }
            }
        });
        setLibraryOptions(options);
    }, [tm]);

    const handleOperationClickCallback = useCallback((operation: ICADOperationLibraryItem) => {
        if (props.addOperationCallback) {
            props.addOperationCallback(operation);
        }
    }, [props]);

    return <Grid container direction={"column"} alignItems={"center"}>
        <Grid item container xs spacing={1} >
            <Grid item xs={2}>
                <Autocomplete
                    id="operation-library"
                    options={libaryOptions}
                    value={selectedLibrary}
                    size="small"
                    fullWidth
                    getOptionLabel={(option) => option.description}
                    onChange={(e, newValue) => setSelectedLibrary(newValue!)}
                    renderInput={(params: AutocompleteRenderInputParams) => {
                        return <TextField {...params} label={tm.Get("Library")} />
                    }}
                ></Autocomplete>
            </Grid>

            <Grid item xs>
                <TextField size="small" label={tm.Get("Search")} fullWidth value={searchString} onChange={e => setSearchString(e.target.value)} />
            </Grid>

            <Grid item xs={2}>
                <TextField size="small" label={tm.Get("Straight Sides")} fullWidth value={straightSidesFilter} onChange={e => setStraightSidesFilter(e.target.value)} />
            </Grid>

            <Grid item xs={2}>
                <TextField size="small" label={tm.Get("Curved Sides")} fullWidth value={curvedSidesFilter} onChange={e => setCurvedSidesFilter(e.target.value)} />
            </Grid>
        </Grid>

        <Grid item container xs marginTop={1}>
            <Box flexWrap={"wrap"} display={"inline-flex"} justifyContent={"flex-start"}>
                {filteredOperations.map((operation, index) => {
                    return <CADOperationLibraryItemDisplay
                        operation={operation}
                        operationClickedCallback={handleOperationClickCallback}
                        key={operation.operationID}
                    />
                })}
                {filteredOperations.length === 0 && <Typography fontWeight={"bold"}>{tm.Get("No results were found.")}</Typography>}
            </Box>
        </Grid>
    </Grid>;
}

export default CADOperationLibrary;
