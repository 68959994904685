import APIHelper from "helpers/APIHelper";
import { IBaseContent } from "helpers/interfaces";
import React from "react";

interface FileDownloadProps {
    preDownload: (() => void) | null;
    postDownload: (() => void) | null;
    onError: (baseContent: IBaseContent) => void;
}

export default function useFileDownloadWithFeedback({ preDownload, postDownload, onError }: FileDownloadProps) {

    const ref = React.useRef<HTMLAnchorElement | null>(null);

    const download = React.useCallback(async (downloadURL: string, params: any) => {
        const unknownError: IBaseContent = { message: "An unknown error has occurred.", params: []}
        if (preDownload !== null) {
            preDownload();
        }
        APIHelper.GetAsync(downloadURL, params, undefined, false).then(res => {
            if (!res.IsOk()) {
                let baseContent: IBaseContent = (res.Message as any) as IBaseContent ?? null
                if (baseContent) {
                    onError(baseContent);
                } else {
                    onError(unknownError);
                }
            } else {
                let payload: Blob = (res.Result as Blob) ?? null;
                if (payload) {
                    const url = window.URL.createObjectURL(payload);
                    if (ref.current) {
                        ref.current.href = url;
                        ref.current.download = res.FileName;
                        ref.current.click();
                    }
                    URL.revokeObjectURL(url);
                } else {
                    onError(unknownError);
                }
            }
            if (postDownload !== null) {
                postDownload();
            }
        })
    }, [ref, onError]);

    return { download, ref };

};