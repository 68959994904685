import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container, Box, Stack, TextField, useTheme, Snackbar, Alert, AlertColor, Tooltip, IconButton } from "@mui/material";
import { GridCellEditCommitParams, GridRowIdGetter, GridRowModel, MuiEvent, gridVisibleSortedRowIdsSelector, useGridApiRef } from "@mui/x-data-grid-pro";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useTranslations } from "@fenetech/translations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useQuoteData from "components/Quotes/useQuoteData";
import useWait from "helpers/context/Page/useWait";
import { ThemeColorEnum } from "helpers/enums";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { IActionButton } from "helpers/context/Page/PageContext";
import useIsMobile from "helpers/hooks/useIsMobile";
import Format, { ICallsizeArgs, ImperialFormatModeEnum } from "helpers/fv.format";
import Constants from "helpers/constants";
import { PartDefaults, usePartDefaultsRepo } from "helpers/context/Parts/usePartDefaults";
import { usePartCallSizesRepo } from "helpers/context/Parts/usePartCallSizes";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { IPartCallSizePresets, IPasteSpecialLine, ILineItem, IPasteSpecialLineAPIParams } from "helpers/interfaces";
import { CaseInsensitiveCompare, isNullOrWhiteSpace } from "helpers/objects";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import useQuoteActions from "components/Quotes/useQuoteActions";

import { callSizeColumn, commentColumn, customerRefColumn, heightColumn, itemGroupColumn, qtyColumn, shapeParamColumn, thicknessColumn, widthColumn } from "./PasteSpecialColumns";
import PasteSpecialAPI from "./PasteSpecialAPI";

const PasteSpecial: React.FC<any> = () => {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter();
    const { quote, lineItems } = useQuoteData();
    const wait = useWait();
    const isMobile = useIsMobile();
    const theme = useTheme();
    const apiRef = useGridApiRef();
    const actionButtons = useActionButtons();
    const formatMethods = useFormatHelper();
    const quoteActions = useQuoteActions();
    const navigate = useNavigate();

    const [lineItem, setLineItem] = useState<ILineItem | null>(null);
    const [rows, setRows] = useState<string>("25");
    const [lines, setLines] = useState<IPasteSpecialLine[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [partCallSizes, setPartCallSizes] = useState<IPartCallSizePresets>();
    const [alert, setAlert] = useState<{ text: string, alertType: AlertColor, visible: boolean }>({ text: "", alertType: "info", visible: false });

    const [qtyColumnVisible, setQtyColumnVisible] = useState<boolean>(true);
    const [callSizeColumnVisible, setCallSizeColumnVisible] = useState<boolean>(true);
    const [widthColumnVisible, setWidthColumnVisible] = useState<boolean>(true);
    const [heightColumnVisible, setHeightColumnVisible] = useState<boolean>(true);
    const [thicknessColumnVisible, setThicknessColumnVisible] = useState<boolean>(true);
    const [customerRefColumnVisible, setCustomerRefColumnVisible] = useState<boolean>(true);
    const [itemGroupColumnVisible, setItemGroupColumnVisible] = useState<boolean>(true);
    const [visibilityEvaluated, setVisibilityEvaluated] = useState<boolean>(false);
    const [overflowRowsAfterPaste, setOverflowRowsAfterPaste] = useState<boolean>(false);

    const [shapeParamColumns, setShapeParamColumns] = useState<string[]>([]);

    const [query] = useSearchParams();
    const oKeyString = query.get("oKey") ?? undefined;
    const odKeyString = query.get("odKey") ?? undefined;
    const oKey = oKeyString ? parseInt(oKeyString) : 0;
    const odKey = odKeyString ? parseInt(odKeyString) : 0;

    const callSizeRepo = usePartCallSizesRepo();
    const partDefaultsRepo = usePartDefaultsRepo();

    const title = useMemo(() => {
        if (quote && lineItem) {
            return `${tm.Get("Paste Special")} - ${Format.FormatPartDescription(lineItem.partNo, lineItem.partNoSuffix)} - #${quote.orderNumber ?? ""}-${lineItem.lineItemNumber}`;
        }
        return "";
    }, [quote, lineItem, tm]);

    const clipboardAccessible = useMemo(() => {
        if (window.location.protocol !== "https:") {
            if (!window.location.host.startsWith("localhost")) {
                return false;
            }
        }
        return true;
    }, []);

    useWindowTitle(title);

    const unitSetID = useMemo(() => {
        return quote?.engineeringUnitSetID;
    }, [quote]);

    const generator = useMemo(() => {
        const columns = new DataGridColumnGenerator(tm, lines, theme, isMobile);
        if (qtyColumnVisible) {
            columns.AddColumn(qtyColumn(tm, isMobile, !overflowRowsAfterPaste));
        }
        if (callSizeColumnVisible) {
            columns.AddColumn(callSizeColumn(partCallSizes, tm, isMobile, !overflowRowsAfterPaste));
        }
        if (widthColumnVisible) {
            columns.AddColumn(widthColumn(tm, isMobile, !overflowRowsAfterPaste));
        }
        if (heightColumnVisible) {
            columns.AddColumn(heightColumn(tm, isMobile, !overflowRowsAfterPaste));
        }
        if (thicknessColumnVisible) {
            columns.AddColumn(thicknessColumn(tm, isMobile, !overflowRowsAfterPaste));
        }

        columns.AddColumn(commentColumn(tm, isMobile, !overflowRowsAfterPaste));

        if (customerRefColumnVisible) {
            columns.AddColumn(customerRefColumn(tm, isMobile, !overflowRowsAfterPaste));
        }
        if (itemGroupColumnVisible) {
            columns.AddColumn(itemGroupColumn(tm, isMobile, !overflowRowsAfterPaste));
        }

        shapeParamColumns.forEach((spc) => columns.AddColumn(shapeParamColumn(tm, isMobile, spc, !overflowRowsAfterPaste)));
        return columns;
    }, [partCallSizes, tm, lines, theme, isMobile, qtyColumnVisible, callSizeColumnVisible, widthColumnVisible, heightColumnVisible, thicknessColumnVisible, 
        customerRefColumnVisible, itemGroupColumnVisible, shapeParamColumns, overflowRowsAfterPaste]);

    const columnVisibleCount = useMemo(() => {
        return generator.GetColumns().length;
    }, [generator]);

    const callSizeRequired = useMemo(() => {
        if (partCallSizes) {
            return partCallSizes.locked && partCallSizes.callSizes.length > 0
        }
    }, [partCallSizes]);

    const resizeGrid = useCallback((currentGrid: IPasteSpecialLine[], newRows: number) => {
        if (newRows < currentGrid.length) {
            return currentGrid.slice(0, newRows);
        } else if (newRows > currentGrid.length) {
            let newLines = currentGrid.slice();
            for (let i = currentGrid.length; i < newRows; i++) {
                newLines.push({
                    id: i,
                    qty: "",
                    callSize: "",
                    width: "",
                    height: "",
                    thickness: "",
                    comment: "",
                    customerRef: "",
                    itemGroup: "",
                    shapeParams: {},
                    shapeParamErrors: {}
                });
            }
            return newLines;
        } else {
            return currentGrid;
        }
    }, []);

    const handleRowsChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRows(e.target.value);
    }, []);

    const handleRowsBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newRows: number = parseInt(e.target.value);
        if (Number.isNaN(newRows) || newRows < Constants.Min.PasteSpecialRows) {
            newRows = Constants.Min.PasteSpecialRows;
        } else if (newRows > Constants.Max.PasteSpecialRowsSoft && !overflowRowsAfterPaste) {
            newRows = Constants.Max.PasteSpecialRowsSoft;
        } else if (newRows > Constants.Max.PasteSpecialRows && overflowRowsAfterPaste) {
            newRows = Constants.Max.PasteSpecialRows;
        } else if (newRows <= Constants.Max.PasteSpecialRowsSoft && overflowRowsAfterPaste) {
            setOverflowRowsAfterPaste(false);
        }
        setRows(newRows.toString());
        setLines(resizeGrid(lines, newRows));
    }, [lines, overflowRowsAfterPaste, resizeGrid]);

    const excelRowIsHeaderRow = useCallback((data: string[]) => {
        const headerRows = [
            tm.Get("Quantity"),
            tm.Get("Call Size"),
            tm.Get("Width"),
            tm.Get("Height"),
            tm.Get("Thickness"),
            tm.Get("Comment"),
            tm.Get("Customer Ref"),
            tm.Get("Group"),
            ...shapeParamColumns
        ];
        return data.findIndex((d) => headerRows.findIndex((hr) => CaseInsensitiveCompare(hr, d)) !== -1) !== -1;
    }, [shapeParamColumns, tm]);

    const headerColumnIsShapeParam = useCallback((column: string) => {
        return shapeParamColumns.findIndex((spc) => CaseInsensitiveCompare(column, spc)) !== -1;
    }, [shapeParamColumns]);

    const getHeaderMap = useCallback((data: string[]): [string[], string] => {
        let headerMap: string[] = [];
        let error = "";
        data.forEach((d) => {
            if (error === "") {
                if (qtyColumnVisible && CaseInsensitiveCompare(tm.Get("Quantity"), d)) {
                    if (headerMap.findIndex((hm) => hm === "qty") === -1) {
                        headerMap.push("qty");
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else if (callSizeColumnVisible && CaseInsensitiveCompare(tm.Get("Call Size"), d)) {
                    if (headerMap.findIndex((hm) => hm === "callSize") === -1) {
                        headerMap.push("callSize");
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else if (widthColumnVisible && CaseInsensitiveCompare(tm.Get("Width"), d)) {
                    if (headerMap.findIndex((hm) => hm === "width") === -1) {
                        headerMap.push("width");
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else if (heightColumnVisible && CaseInsensitiveCompare(tm.Get("Height"), d)) {
                    if (headerMap.findIndex((hm) => hm === "height") === -1) {
                        headerMap.push("height");
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else if (thicknessColumnVisible && CaseInsensitiveCompare(tm.Get("Thickness"), d)) {
                    if (headerMap.findIndex((hm) => hm === "thickness") === -1) {
                        headerMap.push("thickness");
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else if (CaseInsensitiveCompare(tm.Get("Comment"), d)) {
                    if (headerMap.findIndex((hm) => hm === "comment") === -1) {
                        headerMap.push("comment");
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else if (customerRefColumnVisible && CaseInsensitiveCompare(tm.Get("Customer Ref"), d)) {
                    if (headerMap.findIndex((hm) => hm === "customerRef") === -1) {
                        headerMap.push("customerRef");
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else if (itemGroupColumnVisible && CaseInsensitiveCompare(tm.Get("Group"), d)) {
                    if (headerMap.findIndex((hm) => hm === "itemGroup") === -1) {
                        headerMap.push("itemGroup");
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else if (headerColumnIsShapeParam(d)) {
                    if (headerMap.findIndex((hm) => hm === d.toLowerCase()) === -1) {
                        headerMap.push(d.toLowerCase());
                    } else {
                        error = tm.Get("Duplicate column included in import:") + " " + d;
                    }
                } else {
                    error = tm.Get("Unexpected column included in import:") + " " + d;
                }
            }
        });
        return [headerMap, error];
    }, [tm, qtyColumnVisible, callSizeColumnVisible, widthColumnVisible, heightColumnVisible, 
        thicknessColumnVisible, customerRefColumnVisible, itemGroupColumnVisible, headerColumnIsShapeParam]);

    const rowIsEmpty = useCallback((row: IPasteSpecialLine) => {
        return (row.qty === "" && row.callSize === "" && row.width === "" && row.height === "" && row.thickness === "" && 
                row.comment === "" && row.customerRef === "" && row.itemGroup === "" && Object.values(row.shapeParams).findIndex((s) => s !== "") === -1);
    }, []);

    const formatDimensionStringToNumber = useCallback((value: string) => {
        let numericValue = lnf.Parse(value);
        if (isNaN(numericValue)) {
            numericValue = Format.fracToDec(value) ?? 0;
        }
        return numericValue;

    }, [lnf]);

    const parseCallSizeSelection = useCallback((newCallSize: string, originalWidth: string, originalHeight: string): [string, string, string] => {

        let newWidth = originalWidth;
        let newHeight = originalHeight;
        let error = "";

        if (newCallSize) {
            if (partCallSizes) {
                const selectedCallSize = partCallSizes.callSizes.filter(cs => cs.callSize === newCallSize);
                if (selectedCallSize.length > 0) {
                    newWidth = formatMethods.formatDimensionText(selectedCallSize[0].width, unitSetID!, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
                    newHeight = formatMethods.formatDimensionText(selectedCallSize[0].height, unitSetID!, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
                } else {
                    newWidth = "0";
                    newHeight = "0";
                    error = tm.Get("Invalid value");
                }
            }
        } else {
            newWidth = "0";
            newHeight = "0";
        }

        return [newWidth, newHeight, error];
    }, [partCallSizes, formatMethods, unitSetID, tm]);

    const parseCallSizeText = useCallback((newCallSize: string, originalWidth: string, originalHeight: string): [string, string, string] => {
        let callsizeArgs: ICallsizeArgs = {
            callSize: newCallSize,
            width: -1,
            height: -1,
        };

        if (Format.formatCallSize(callsizeArgs)) {
            //valid
            return [formatMethods.formatDimensionText(callsizeArgs.width, unitSetID!, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false),
                    formatMethods.formatDimensionText(callsizeArgs.height, unitSetID!, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false),
                    ""];
        } else {
            if (newCallSize === "") {
                return ["0", "0", ""];
            } else {
                //Invalid
                return [
                    originalWidth,
                    originalHeight,
                    tm.Get("Invalid Value")
                ];
            }
        }

    }, [tm, formatMethods, unitSetID]);

    const formatDimensionValue = useCallback((value: string): [string, string] => {
        let numericValue = formatDimensionStringToNumber(value);
        const error = (numericValue < 0 ? tm.GetWithParams("The value must be greater than or equal to {0}", "0") : "");

        return [formatMethods.formatDimensionText(numericValue, unitSetID!, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false), error];

    }, [formatDimensionStringToNumber, tm, formatMethods, unitSetID]);

    const performFullValidation = useCallback((linesToValidate: IPasteSpecialLine[]): [IPasteSpecialLine[], boolean] => {
        // Main purpose here is to make sure partially-filled rows are validated.
        let hasError = false;
        let value: string, error: string;
        const newLines = [...linesToValidate];
        newLines.forEach((l) => {
            if (rowIsEmpty(l)) {
                // Remove all errors
                l.qtyError = "";
                l.callSizeError = "";
                l.widthError = "";
                l.heightError = "";
                l.thicknessError = "";
                l.commentError = "";
                l.customerRefError = "";
                l.itemGroupError = "";
                l.shapeParamErrors = {};
            } else {
                if (qtyColumnVisible && l.qty === "") {
                    l.qtyError = tm.Get("Invalid value");
                } else if (qtyColumnVisible) {
                    const qtyNumber = lnf.Parse(l.qty);
                    if (qtyNumber >= Constants.Min.Quantity && qtyNumber <= Constants.Max.Quantity) {
                        l.qty = Math.trunc(qtyNumber).toString();
                    } else {
                        l.qtyError = tm.GetWithParams("The value must be between {0} and {1}.", Constants.Min.Quantity.toString(), Constants.Max.Quantity.toString());
                    }
                }

                if (callSizeColumnVisible && l.callSize === "" && callSizeRequired) {
                    l.callSizeError = tm.Get("Invalid Value");
                } else if (callSizeColumnVisible && l.callSize !== "") {
                    let width: string, height: string, error: string;
                    if (partCallSizes && partCallSizes.callSizes.length > 0) {
                        [width, height, error] = parseCallSizeSelection(l.callSize, l.width, l.height);
                    } else {
                        [width, height, error] = parseCallSizeText(l.callSize, l.width, l.height);
                    }

                    if (error !== "") {
                        l.callSizeError = error;
                    } else if (width !== l.width) {
                        l.callSizeError = tm.Get("Call size does not match fixed width. Please re-enter.");
                    } else if (height !== l.height) {
                        l.callSizeError = tm.Get("Call size does not match fixed height. Please re-enter.");
                    }
                }

                if (widthColumnVisible && l.width === "") {
                    l.widthError = tm.Get("Invalid value");
                } else if (widthColumnVisible) {
                    [value, error] = formatDimensionValue(l.width);
                    l.width = value;
                    l.widthError = error;
                }

                if (heightColumnVisible && l.height === "") {
                    l.heightError = tm.Get("Invalid value");
                } else if (heightColumnVisible) {
                    [value, error] = formatDimensionValue(l.height);
                    l.height = value;
                    l.heightError = error;
                }

                if (thicknessColumnVisible) {
                    [value, error] = formatDimensionValue(l.thickness);
                    l.thickness = value;
                    l.thicknessError = error;
                }

                shapeParamColumns.forEach((spc) => {
                    if (l.shapeParams[spc] === undefined || l.shapeParams[spc] === "") {
                        l.shapeParams[spc] = "";
                        l.shapeParamErrors[spc] = tm.Get("Invalid value");
                    } else {
                        [value, error] = formatDimensionValue(l.shapeParams[spc]);
                        l.shapeParams[spc] = value;
                        l.shapeParamErrors[spc] = error;
                    }
                });

                hasError = (hasError || 
                            !isNullOrWhiteSpace(l.qtyError) || 
                            !isNullOrWhiteSpace(l.callSizeError) ||
                            !isNullOrWhiteSpace(l.widthError)||
                            !isNullOrWhiteSpace(l.heightError) ||
                            !isNullOrWhiteSpace(l.thicknessError) ||
                            !isNullOrWhiteSpace(l.commentError) ||
                            !isNullOrWhiteSpace(l.customerRefError) ||
                            !isNullOrWhiteSpace(l.itemGroupError) ||
                            (Object.values(l.shapeParamErrors).findIndex((e) => !isNullOrWhiteSpace(e)) !== -1))
            }
        });
        return [newLines, !hasError];
    }, [tm, lnf, partCallSizes, callSizeRequired, qtyColumnVisible, callSizeColumnVisible, widthColumnVisible, 
        heightColumnVisible, thicknessColumnVisible, shapeParamColumns, rowIsEmpty, parseCallSizeSelection, parseCallSizeText, formatDimensionValue]);

    const copyToClipboard = useCallback(() => {
        if (!navigator.clipboard) {
            setAlert({text: tm.Get("Unable to copy to clipboard."), alertType: "error", visible: true});
            return;
        }

        let headerLine = "";
        if (qtyColumnVisible) {
            headerLine += tm.Get("Quantity") + "\t";
        }
        if (callSizeColumnVisible) {
            headerLine += tm.Get("Call Size") + "\t";
        }
        if (widthColumnVisible) {
            headerLine += tm.Get("Width") + "\t";
        }
        if (heightColumnVisible) {
            headerLine += tm.Get("Height") + "\t";
        }
        if (thicknessColumnVisible) {
            headerLine += tm.Get("Thickness") + "\t";
        }
        headerLine += tm.Get("Comment") + "\t";
        if (customerRefColumnVisible) {
            headerLine += tm.Get("Customer Ref") + "\t";
        }
        if (itemGroupColumnVisible) {
            headerLine += tm.Get("Group") + "\t";
        }
        Object.values(shapeParamColumns).forEach((spc) => headerLine += spc.toUpperCase() + "\t");

        let clipboardStr = headerLine.substring(0, headerLine.length - 1) + "\r\n" + lines.map((l) => {
            let lineStr = "";
            if (qtyColumnVisible) {
                lineStr += l.qty + "\t";
            }
            if (callSizeColumnVisible) {
                lineStr += l.callSize + "\t";
            }
            if (widthColumnVisible) {
                lineStr += l.width + "\t";
            }
            if (heightColumnVisible) {
                lineStr += l.height + "\t";
            }
            if (thicknessColumnVisible) {
                lineStr += l.thickness + "\t";
            }
            if (l.comment.indexOf("\n") !== -1) {
                lineStr += ('"' + l.comment.replaceAll('"', '""') + '"\t');
            } else {
                lineStr += l.comment + "\t";
            }
            if (customerRefColumnVisible) {
                if (l.customerRef.indexOf("\n") !== -1) {
                    lineStr += '"' + l.customerRef.replaceAll('"', '""') + '"\t';
                } else {
                    lineStr += l.customerRef + "\t";
                }
            }
            if (itemGroupColumnVisible) {
                if (l.itemGroup.indexOf("\n") !== -1) {
                    lineStr += '"' + l.itemGroup.replaceAll('"', '""') + '"\t';
                } else {
                    lineStr += l.itemGroup + "\t";
                }
            }
            Object.values(l.shapeParams).forEach((s) => lineStr += s + "\t");

            return lineStr.substring(0, lineStr.length - 1);
        }).join("\r\n");

        navigator.clipboard.writeText(clipboardStr).then(() => {
            setAlert({text: tm.Get("Successfully copied to clipboard."), alertType: "success", visible: true});
        }).catch(() => {
            setAlert({text: tm.Get("Unable to copy to clipboard."), alertType: "error", visible: true});
        });
    }, [tm, lines, qtyColumnVisible, callSizeColumnVisible, widthColumnVisible, heightColumnVisible, 
        thicknessColumnVisible, customerRefColumnVisible, itemGroupColumnVisible, shapeParamColumns]);

    const generateLineFromArray = useCallback((data: string[], id: number): IPasteSpecialLine => {
        let newLine: IPasteSpecialLine = {
            id: id,
            qty: "",
            callSize: "",
            width: "",
            height: "",
            thickness: "",
            comment: "",
            customerRef: "",
            itemGroup: "",
            shapeParams: {},
            shapeParamErrors: {}
        };

        let index = 0;
        if (qtyColumnVisible && index < data.length) {
            newLine.qty = data[index];
            index++;
        }
        if (callSizeColumnVisible && index < data.length) {
            newLine.callSize = data[index];
            index++;
        }
        if (widthColumnVisible && index < data.length) {
            newLine.width = data[index];
            index++;
        }
        if (heightColumnVisible && index < data.length) {
            newLine.height = data[index];
            index++;
        }
        if (thicknessColumnVisible && index < data.length) {
            newLine.thickness = data[index];
            index++;
        }
        if (index < data.length) {
            newLine.comment = data[index];
            index++;
        }
        if (customerRefColumnVisible && index < data.length) {
            newLine.customerRef = data[index];
            index++;
        }
        if (itemGroupColumnVisible && index < data.length) {
            newLine.itemGroup = data[index];
            index++;
        }

        shapeParamColumns.forEach((spc) => {
            if (index < data.length) {
                newLine.shapeParams[spc] = data[index];
                index++;
            }
        });

        return newLine;
    }, [qtyColumnVisible, callSizeColumnVisible, widthColumnVisible, heightColumnVisible, 
        thicknessColumnVisible, customerRefColumnVisible, itemGroupColumnVisible, shapeParamColumns]);

    const generateLineFromArrayWithHeaderMap = useCallback((data: string[], id: number, headerMap: string[]): IPasteSpecialLine => {
        let newLine: IPasteSpecialLine = {
            id: id,
            qty: "",
            callSize: "",
            width: "",
            height: "",
            thickness: "",
            comment: "",
            customerRef: "",
            itemGroup: "",
            shapeParams: {},
            shapeParamErrors: {}
        };

        data.forEach((d, i) => {
            const hm = headerMap[i];
            if (headerColumnIsShapeParam(hm)) {
                newLine.shapeParams[hm.toLowerCase()] = d;
            } else {
                newLine[hm] = d;
            }
        });

        return newLine;
    }, [headerColumnIsShapeParam]);

    const importFromClipboard = useCallback(() => {
        if (!navigator.clipboard) {
            setAlert({text: tm.Get("Unable to import from clipboard."), alertType: "error", visible: true});
            return;
        }
        setLoading(true);
        let clipboardRows: string[][] = [];
        navigator.clipboard.readText().then((clipboardStr) => {
            let lineData: string[] = clipboardStr.replaceAll('\r', '').split('\n');
            if (lineData[lineData.length - 1].length === 0) {
                // Excel sometimes tacks on an extra line break, causing us to have a completely empty line. Remove that line
                lineData = lineData.slice(0, -1);
            }

            lineData.forEach((l, i) => lineData[i] = (l + '\r\n'));
            let inMiddleOfMultiLineField = false;

            lineData.forEach((l, i) => {
                let cr: string[];
                if (inMiddleOfMultiLineField) {
                    cr = clipboardRows[clipboardRows.length - 1];
                } else {
                    cr = [];
                    clipboardRows.push(cr);
                }

                const currentRowSplitByTabs: string[] = l.split("\t");
                currentRowSplitByTabs.forEach((c, i2) => {
                    if (inMiddleOfMultiLineField && (c.endsWith('"') || c.endsWith('"\r\n'))) {
                        // Last row of multi line text
                        let strCurr: string = cr[cr.length - 1] + c.trim();

                        // if this is the last row.. remove the ending \r\n
                        if (i === lineData.length - 1 && i2 === currentRowSplitByTabs.length - 1) {
                            if (strCurr.endsWith('\r\n')) {
                                strCurr = strCurr.substring(0, strCurr.length - 2).trim();
                            } else if (strCurr.endsWith('\n')) {
                                strCurr = strCurr.substring(0, strCurr.length - 1).trim();
                            }
                        }

                        // In a multi-line comment, every single quotation mark gets escaped with another quotation 
                        // Strip those out now.
                        if (strCurr.endsWith('"') && strCurr.startsWith('"')) {
                            strCurr = strCurr.substring(1);
                            if (strCurr.length > 0) {
                                strCurr = strCurr.substring(0, strCurr.length - 1);
                            }
                        }
                        strCurr = strCurr.replace('""', '"');
                        cr[cr.length - 1] = strCurr;
                        inMiddleOfMultiLineField = false;
                    } else if (!inMiddleOfMultiLineField && c.startsWith('"') && c.endsWith('\r\n')) {
                        // If the very first character is a double quote, we might be starting a multiline text
                        // As long as the next line has either ends with CrLf or has a quote
                        if (lineData.length > i + 1) {
                            if (lineData[i + 1].split('\t')[0].endsWith('\r\n') || lineData[i + 1].split('\t')[0].indexOf('"') !== -1) {
                                inMiddleOfMultiLineField = true;
                            }
                        }

                        if (inMiddleOfMultiLineField) {
                            cr.push(c.trim() + '\r\n');
                        } else {
                            cr.push(c.trim());
                        }
                    } else if (inMiddleOfMultiLineField) {
                        // If we are in the middle of a multiline text, tack it on to the last row's last column
                        cr[cr.length - 1] += (c.trim() + '\r\n');
                    } else {
                        // Regular case, just trim the string and add it to the new row's columns
                        cr.push(c.trim());
                    }
                });
            });


            if (clipboardRows.find(cr => cr.length > columnVisibleCount)) {
                setAlert({text: tm.Get("Clipboard data has too many columns."), alertType: "error", visible: true});
            } else if (clipboardRows.length > Constants.Max.PasteSpecialRows) {
                setAlert({text: tm.GetWithParams("Clipboard data has more than {0} rows.", Constants.Max.PasteSpecialRows.toString()), alertType: "error", visible: true});
            } else {
                // Create new lines and sequentially assign to each visible column
                if (clipboardRows.length > 0) {
                    let newLines: IPasteSpecialLine[] = clipboardRows.slice(1).map((cr, i) => generateLineFromArray(cr, i));
                    if (excelRowIsHeaderRow(clipboardRows[0])) {
                        const [headerMap, error] = getHeaderMap(clipboardRows[0]);
                        if (error !== "") {
                            setAlert({text: error, alertType: "error", visible: true});
                            return;
                        }
                        newLines = clipboardRows.slice(1).map((cr, i) => generateLineFromArrayWithHeaderMap(cr, i, headerMap));
                    } else {
                        newLines = clipboardRows.map((cr, i) => generateLineFromArray(cr, i));
                    }
                    if (newLines.length > Number(rows)) {
                        setRows(newLines.length.toString());
                    } else {
                        newLines = resizeGrid(newLines, Number(rows));
                    }
                    if (newLines.length > Constants.Max.PasteSpecialRowsSoft) {
                        setOverflowRowsAfterPaste(true);
                    }
                    const [validatedLines, isValid] = performFullValidation(newLines);
                    setLines(validatedLines);
                    if (isValid) {
                        setAlert({text: tm.Get("Successfully imported from clipboard."), alertType: "success", visible: true});
                    } else {
                        setAlert({text: tm.Get("Imported from clipboard with data error(s)."), alertType: "warning", visible: true});
                    }
                }
            }

        }).catch(() => {
            setAlert({text: tm.Get("Unable to import from clipboard."), alertType: "error", visible: true});
        }).finally(() => {
            setLoading(false);
        });
    }, [tm, rows, columnVisibleCount, performFullValidation, getHeaderMap, generateLineFromArray, generateLineFromArrayWithHeaderMap, resizeGrid, excelRowIsHeaderRow]);

    const convertToAPIParams = useCallback((): IPasteSpecialLineAPIParams[] => {
        return lines.filter((l) => !rowIsEmpty(l)).map((l) => {
            let convertedLine: IPasteSpecialLineAPIParams = {
                quantity: qtyColumnVisible ? Number(l.qty) : lineItem!.quantity,
                callSize: callSizeColumnVisible ? l.callSize : lineItem!.callSize,
                displayWidth: widthColumnVisible ? formatDimensionStringToNumber(l.width) : lineItem!.displayWidth,
                displayHeight: heightColumnVisible ? formatDimensionStringToNumber(l.height) : lineItem!.displayHeight,
                displayThickness: thicknessColumnVisible ? formatDimensionStringToNumber(l.thickness) : lineItem!.displayThickness,
                comment: l.comment,
                customerRef: customerRefColumnVisible ? l.customerRef : lineItem!.customerRef,
                itemGroup: itemGroupColumnVisible ? l.itemGroup : lineItem!.itemGroup,
                shapeParams: l.shapeParams
            }
            return convertedLine;
        });
    }, [lines, lineItem, qtyColumnVisible, callSizeColumnVisible, widthColumnVisible, heightColumnVisible, 
        thicknessColumnVisible, customerRefColumnVisible, itemGroupColumnVisible, formatDimensionStringToNumber, rowIsEmpty]);

    const saveClicked = useCallback(() => {
        let [validatedLines, valid] = performFullValidation(lines);
        if (!valid) {
            setLines(validatedLines);
            setAlert({text: tm.Get("Validation errors exist. Cannot save."), alertType: "error", visible: true});
        } else {
            setLoading(true);
            const apiParams = convertToAPIParams();
            PasteSpecialAPI.PasteLineItems(oKey, odKey, apiParams).then(async () => {
                await quoteActions.LoadQuoteAsync(oKey);
                navigate(QuoteNavigation.QuoteEntryURL(oKey));
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [lines, tm, oKey, odKey, quoteActions, navigate, performFullValidation, convertToAPIParams]);

    useEffect(() => {
        actionButtons.Clear();
        const submitButton: IActionButton = {
            text: tm.Get("Save"),
            color: ThemeColorEnum.Secondary,
            disabled: loading,
            onClick: saveClicked
        };
        actionButtons.Set(0, submitButton);

        const cancelButton: IActionButton = {
            text: tm.Get("Cancel"),
            color: ThemeColorEnum.Primary,
            disabled: loading,
            onClick: () => navigate(QuoteNavigation.QuoteEntryURL(oKey)),
        };
        actionButtons.Set(1, cancelButton);

    }, [oKey, actionButtons, tm, loading, navigate, saveClicked]);

    useEffect(() => {
        wait.Show(loading);
    }, [loading, wait])

    useEffect(() => {
        if (oKey && odKey && lineItems && lineItem === null) {
            let findLineItem = lineItems.find((li) => li.odKey === odKey);
            if (findLineItem) {
                if (!findLineItem?.pasteSpecialAllowed) {
                    navigate(QuoteNavigation.QuoteEntryURL(oKey));
                }
                setLoading(true);
                setLineItem(findLineItem);
                const partDefaultsPromise = partDefaultsRepo(findLineItem.partNo, findLineItem.partNoSuffix, "");
                const partCallSizesPromise = callSizeRepo(findLineItem.partNo, findLineItem.partNoSuffix);
                const lineItemShapeParamsPromise = PasteSpecialAPI.GetLineItemShapeParams(oKey, odKey);

                Promise.all([partDefaultsPromise, partCallSizesPromise, lineItemShapeParamsPromise]).then(([partDefaults, partCallSizesResult, lineItemShapeParams]) => {
                    const partDefaultsObject = new PartDefaults(partDefaults);
                    if (!partDefaultsObject.QuantityEnabled()) {
                        setQtyColumnVisible(false);
                    }
                    if (!partDefaultsObject.CallSizeEnabled()) {
                        setCallSizeColumnVisible(false);
                    }
                    if (!partDefaultsObject.WidthEnabled()) {
                        setWidthColumnVisible(false);
                    }
                    if (!partDefaultsObject.HeightEnabled()) {
                        setHeightColumnVisible(false);
                    }
                    if (!partDefaultsObject.ThicknessEnabled()) {
                        setThicknessColumnVisible(false);
                    }
                    if (!partDefaultsObject.CustomerRefEnabled()) {
                        setCustomerRefColumnVisible(false);
                    }
                    if (!partDefaultsObject.ItemGroupEnabled()) {
                        setItemGroupColumnVisible(false);
                    }

                    let startingLines: IPasteSpecialLine[] = [{
                        id: 0,
                        qty: findLineItem!.quantity.toString(),
                        callSize: findLineItem!.callSize,
                        width: formatMethods.formatDimensionText(findLineItem!.displayWidth, unitSetID!, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false),
                        height: formatMethods.formatDimensionText(findLineItem!.displayHeight, unitSetID!, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false),
                        thickness: formatMethods.formatDimensionText(findLineItem!.displayThickness, unitSetID!, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false),
                        comment: findLineItem!.comment,
                        customerRef: findLineItem!.customerRef,
                        itemGroup: findLineItem!.itemGroup,
                        shapeParams: lineItemShapeParams,
                        shapeParamErrors: {}
                    }];
                    startingLines = resizeGrid(startingLines, 25);
                    setLines(startingLines);
                    
                    setPartCallSizes(partCallSizesResult);
                    setShapeParamColumns(Object.keys(lineItemShapeParams));
                }).finally(() => {
                    setLoading(false);
                    setVisibilityEvaluated(true);
                });
            }
        }
    }, [oKey, odKey, lineItems, lineItem, tm, isMobile, theme, formatMethods, unitSetID, partDefaultsRepo, callSizeRepo, resizeGrid, navigate]);

    const moveToFirstCell = useCallback((event: MuiEvent<React.KeyboardEvent>) => {
        if (event.key !== "Tab") {
            return;
        }
        event.preventDefault();
        const rowIds = gridVisibleSortedRowIdsSelector(apiRef.current.state, apiRef.current.instanceId);
        const visibleColumns = apiRef.current.getVisibleColumns();
        const firstColumn = visibleColumns[0];
        apiRef.current.setCellFocus(rowIds[0], firstColumn.field);
        if (!overflowRowsAfterPaste) {
            apiRef.current.setCellMode(rowIds[0], firstColumn.field, "edit");
        }
    }, [apiRef, overflowRowsAfterPaste]);

    const handleCellKeyDown = useCallback(async (params, event: MuiEvent<React.KeyboardEvent>) => {
        // https://github.com/mui/mui-x/issues/3016#issuecomment-955079280
        if (event.key !== "Tab" && event.key !== "Enter") {
            return;
        }

        if (event.key === "Enter" && apiRef.current.getCellMode(params.id, params.field) !== "edit" && !overflowRowsAfterPaste) {
            // If enter is pressed on a cell while in view mode, don't skip to the next row before switing to edit mode
            return;
        }

        const isTab = (event.key === "Tab");
        const isShift = event.shiftKey;

        const rowIds = gridVisibleSortedRowIdsSelector(apiRef.current.state, apiRef.current.instanceId);
        const visibleColumns = apiRef.current.getVisibleColumns();

        const nextCell = {
            rowIndex: rowIds.findIndex((id) => id === params.id),
            columnIndex: apiRef.current.getColumnIndex(params.field),
        };

        if (isTab && nextCell.columnIndex === visibleColumns.length - 1 &&
            nextCell.rowIndex === rowIds.length - 1 && !isShift) {
            // Do nothing if we are at the last cell of the last row and tabbing
            return;
        }

        if (isTab && nextCell.columnIndex === 0 && nextCell.rowIndex === 0 && isShift) {
            // Do nothing if we are at the first cell of first row and tabbing
            return;
        }

        if (!isTab && nextCell.rowIndex === rowIds.length - 1 && !isShift) {
            // Do nothing if we are on last row and entering
            return;
        }

        if (!isTab && nextCell.rowIndex === 0 && isShift) {
            // Just save if we are on the first row and entering; it's close to what the other cases do
            event.preventDefault();
            event.defaultMuiPrevented = true;
            if (apiRef.current.getCellMode(params.id, params.field) === "edit") {
                await apiRef.current.commitCellChange({id: params.id, field: params.field});
                apiRef.current.setCellMode(params.id, params.field, "view");
            }
            return;
        }

        event.preventDefault();
        event.defaultMuiPrevented = true;

        const oldField = visibleColumns[nextCell.columnIndex].field;
        const oldId = rowIds[nextCell.rowIndex];

        if (isTab) {
            if (!isShift) {
                if (nextCell.columnIndex < visibleColumns.length - 1) {
                    nextCell.columnIndex += 1;
                } else {
                    nextCell.rowIndex += 1;
                    nextCell.columnIndex = 0;
                }
            } else if (nextCell.columnIndex > 0) {
                nextCell.columnIndex -= 1;
            } else {
                nextCell.rowIndex -= 1;
                nextCell.columnIndex = visibleColumns.length - 1;
            }
        } else {
            if (!isShift) {
                nextCell.rowIndex += 1;
            } else {
                nextCell.rowIndex -= 1;
            }
        }

        const field = visibleColumns[nextCell.columnIndex].field;
        const id = rowIds[nextCell.rowIndex];

        if (apiRef.current.getCellMode(oldId, oldField) === "edit") {
            await apiRef.current.commitCellChange({id: oldId, field: oldField});
            apiRef.current.setCellMode(oldId, oldField, "view");
        }
        apiRef.current.setCellFocus(id, field);
        if (!overflowRowsAfterPaste) {
            apiRef.current.setCellMode(id, field, "edit");
        }

    }, [apiRef, overflowRowsAfterPaste]);

    const onCellEditCommit = useCallback((e: GridCellEditCommitParams) => {
        let newLine = {...lines.find((l) => l.id === e.id)!};
        if (newLine[e.field] === e.value) {
            return;
        }

        let value: string, newWidth: string, newHeight: string, error: string;

        switch (e.field) {
            case "qty":
                if (e.value === "") {
                    newLine.qty = "";
                    newLine.qtyError = tm.Get("Invalid value")
                } else {
                    const newQty = lnf.Parse(e.value);
                    if (newQty >= Constants.Min.Quantity && newQty <= Constants.Max.Quantity) {
                        newLine.qty = Math.trunc(newQty).toString();
                        newLine.qtyError = "";
                    } else {
                        newLine.qty = e.value;
                        newLine.qtyError = tm.GetWithParams("The value must be between {0} and {1}.", Constants.Min.Quantity.toString(), Constants.Max.Quantity.toString());
                    }
                }
                break;
            case "callSize":
                if ((partCallSizes?.callSizes?.length ?? 0) > 0) {
                    [newWidth, newHeight, error] = parseCallSizeSelection(e.value, newLine.width, newLine.height);
                    newLine.callSize = e.value;
                    newLine.callSizeError = error;
                    newLine.width = newWidth;
                    newLine.height = newHeight;
                    newLine.widthError = "";
                    newLine.heightError = "";
                } else {
                    [newWidth, newHeight, error] = parseCallSizeText(e.value, newLine.width, newLine.height);
                    newLine.callSize = e.value;
                    newLine.callSizeError = error;
                    newLine.width = newWidth;
                    newLine.height = newHeight;
                    if (error === "") {
                        newLine.widthError = "";
                        newLine.heightError = "";
                    }
                }
                if (newLine.callSize === "" && callSizeRequired) {
                    newLine.callSizeError = tm.Get("Invalid Value");
                }
                break;
            case "width":
                newLine.callSize = "";
                newLine.callSizeError = "";
                if (e.value === "") {
                    newLine.width = "";
                    newLine.widthError = tm.Get("Invalid value");
                } else {
                    [value, error] = formatDimensionValue(e.value);
                    newLine.width = value;
                    newLine.widthError = error;
                }
                break;
            case "height":
                newLine.callSize = "";
                newLine.callSizeError = "";
                if (e.value === "") {
                    newLine.height = "";
                    newLine.heightError = tm.Get("Invalid value");
                } else {
                    [value, error] = formatDimensionValue(e.value);
                    newLine.height = value;
                    newLine.heightError = error;
                }
                break;
            case "thickness":
                if (e.value === "") {
                    newLine.thickness = "";
                } else {
                    [value, error] = formatDimensionValue(e.value);
                    newLine.thickness = value;
                    newLine.thicknessError = error;
                }
                break;
            case "comment":
                newLine.comment = e.value.substring(0, 255);
                break;
            case "customerRef":
                newLine.customerRef = e.value.substring(0, 255);
                break;
            case "itemGroup":
                newLine.itemGroup = e.value.substring(0, 255);
                break;
            default:
                // Assume this is a shape param. Should never be anything else
                if (e.value === "") {
                    newLine.shapeParams[e.field] = "";
                    newLine.shapeParamErrors[e.field] = tm.Get("Invalid value");
                } else {
                    [value, error] = formatDimensionValue(e.value);
                    newLine.shapeParams[e.field] = value;
                    newLine.shapeParamErrors[e.field] = error;
                }
        }

        if (rowIsEmpty(newLine)) {
            // Remove all errors
            newLine.qtyError = "";
            newLine.callSizeError = "";
            newLine.widthError = "";
            newLine.heightError = "";
            newLine.thicknessError = "";
            newLine.commentError = "";
            newLine.customerRefError = "";
            newLine.itemGroupError = "";
            newLine.shapeParamErrors = {};
        }
        
        setLines((curLines) => curLines.map((cl) => (cl.id === newLine.id ? { ...cl, ...newLine } : cl)));
    }, [lines, tm, lnf, partCallSizes?.callSizes?.length, callSizeRequired, rowIsEmpty, formatDimensionValue, parseCallSizeText, parseCallSizeSelection]);

    const getRowId: GridRowIdGetter = useCallback((row: GridRowModel) => {
        const line = row as IPasteSpecialLine;
        return line.id;
    }, []);

    const handleClose = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert({ ...alert, visible: false });
    }, [alert]);

    return <>

        <Snackbar open={alert.visible} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert.alertType} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert.text}</Alert>
        </Snackbar>
        <Container maxWidth="xl">

            {lineItem && generator && visibilityEvaluated &&
                <Stack spacing={1} m={1}>
                    <Stack direction="row" spacing={1}>
                        <TextField label={tm.Get("Rows")} value={rows} onChange={handleRowsChange} onBlur={handleRowsBlur} sx={{width: "100px"}} type="number"
                            onFocus={e => e.target.select()}
                            onKeyDown={moveToFirstCell}
                            size="small"
                        />
                        {clipboardAccessible && <>
                            <Tooltip title={tm.Get("Copy to clipboard")}>
                                <IconButton onClick={copyToClipboard}>
                                    <FileCopyIcon fontSize="small" color="primary"/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={tm.Get("Import from clipboard")}>
                                <IconButton onClick={importFromClipboard}>
                                    <ContentPasteIcon fontSize="small" color="primary"/>
                                </IconButton>
                            </Tooltip>
                        </>}
                    </Stack>
                    <Tooltip title={overflowRowsAfterPaste ? tm.Get("Manual editing is disabled while many rows are present.") : ""}>
                        <Box>
                            <CustomDataGridPro
                                getRowId={getRowId}
                                columns={generator.GetColumns()}
                                rows={lines}
                                apiRef={apiRef}
                                onCellKeyDown={handleCellKeyDown}
                                hideFooterPagination
                                hideFooterRowCount
                                pagination={false}
                                onCellEditCommit={onCellEditCommit}
                            />
                        </Box>
                    </Tooltip>
                </Stack>
            }

        </Container>

    </>
};

export default PasteSpecial;