import { useTranslations } from "@fenetech/translations";
import { FormControl, InputLabel, Select, MenuItem, Grid, SelectChangeEvent } from "@mui/material";
import React, { useCallback } from 'react';
import { ICADEdgework, ICADOperationEditor, ICADParameterEdit, ICADSideOperationEdit } from "../../interfaces";
import APIHelper from "helpers/APIHelper";
import OptionGroupBox from "components/OptionsWizard/OptionsWizard/OptionGroupBox";
import OperationParameters from "./OperationParameters";
import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";

interface IEdgeworkSelectionProps {
    availableEdgework: ICADEdgework[] | undefined;
    selectedEdgework: ICADSideOperationEdit | null;
    onUpdateEdgework: (edgework: Partial<ICADSideOperationEdit>) => void;
    loading: boolean;
}

function GetOperationImageUrl(operationID: number): string {
    const imageSource: string = APIHelper.GetBaseURL() + `api/cad/operations/${operationID}/image`;
    return imageSource;
}

const EdgeworkSelection: React.FC<IEdgeworkSelectionProps> = ({ availableEdgework, selectedEdgework, onUpdateEdgework, loading }) => {

    const tm = useTranslations();

    const handleEdgeworkChange = useCallback((event: SelectChangeEvent) => {

        let newEdgework: Partial<ICADSideOperationEdit> | null = null;

        newEdgework = { uniqueID: selectedEdgework?.uniqueID, label: event.target.value as string, side: selectedEdgework?.side };

        if (event.target.value === "-1") {
            newEdgework.label = "";
        }

        onUpdateEdgework(newEdgework);

    }, [onUpdateEdgework, selectedEdgework?.uniqueID, selectedEdgework?.side]);

    const postPropertyChange = useCallback((opParam: ICADParameterEdit) => {
        return CADOperationEditorAPI.PostEdgeworkParameterAsync(selectedEdgework?.uniqueID!, opParam);
    }, [selectedEdgework?.uniqueID]);

    const getParameterFromLabel = useCallback((newEditor: ICADOperationEditor, label: string) => {

        const edgeworkSide = newEditor.edgework.sideOperations.find((p) => p.side === selectedEdgework?.side!)!;
        const opParam = edgeworkSide.parameters.find((p) => p.name === label)!;
        return opParam;

    }, [selectedEdgework?.side]);


    if (!availableEdgework || availableEdgework.length === 0) return null;

    return <>

        <FormControl fullWidth size="small">
            <InputLabel id="rake-position-label">{tm.Get("Edgework")}</InputLabel>
            <Select
                labelId="measured-from-position-label"
                label={tm.Get("Edgework")}
                size="small"
                fullWidth
                value={selectedEdgework?.label ?? "-1"}
                onChange={handleEdgeworkChange}
            >

                <MenuItem key={-1} value={"-1"} >
                    <Grid container alignItems={"center"} minHeight={56}>
                        {tm.Get("{None}")}
                    </Grid>
                </MenuItem>

                {availableEdgework.map((edgework, index) => {
                    return <MenuItem key={index} value={edgework.label}>
                        <Grid container alignItems={"center"} minHeight={56} flexWrap={"nowrap"} overflow={"hidden"} >
                            <Grid item width={52} >
                                {edgework.imageExists && <img alt={edgework.label} src={GetOperationImageUrl(edgework.operationID)} width={50} height={50} style={{ marginRight: 2 }} />}
                            </Grid>
                            <Grid item xs >
                                {`${edgework.label} - ${edgework.description}`}
                            </Grid>
                        </Grid>
                    </MenuItem>
                })}

            </Select>

            {selectedEdgework && selectedEdgework.parameters.length > 0 &&

                <Grid item mt={1}>
                    <OptionGroupBox id={"edgeworkparams"} label={selectedEdgework?.label} >
                        <Grid item mt={.5}>

                            <OperationParameters operationEdit={selectedEdgework ?? undefined} loading={loading} PostParameterAsync={postPropertyChange} GetParameterFromLabel={getParameterFromLabel} />

                        </Grid>

                    </OptionGroupBox>
                </Grid>
            }

        </FormControl>

    </>;
}

export default EdgeworkSelection;