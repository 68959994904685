import jsSHA from "jssha";
import { ICADOperationEditor } from "../interfaces";
import React from "react";

const getOperationEditorHash = (newProps: ICADOperationEditor | null) => {

    if (!newProps)
        return "";

    let jsonString: string = JSON.stringify({ ...newProps }); 

    let objHash: any = new jsSHA("SHA-1", "TEXT");
    objHash.update(jsonString);
    return objHash.getHash("HEX") as string;
}


export default function useOperationEditorHash(props: ICADOperationEditor | null): string {

    const optionHash = React.useMemo(() => getOperationEditorHash(props), [props]);

    return optionHash;

}