import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import { useTheme } from "@mui/material";
import { DateTime } from "luxon";

import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import DocumentsAPI from "components/Documents/DocumentsAPI";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import useIsMobile from "helpers/hooks/useIsMobile";
import { useTranslations } from "@fenetech/translations";
import { IFile } from "helpers/interfaces";
import APIHelper from "helpers/APIHelper";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

interface IProps {
    selectedFolder?: string,
}

const FileList: React.FC<IProps> = ({ selectedFolder }: IProps) => {

    const tm = useTranslations();
    const [files, setFiles] = useState<IFile[] | undefined>(undefined);
    const theme = useTheme();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("FileList");

    const getParams = useCallback((file: IFile) => {
        return {
            path: selectedFolder,
            fileName: file.name,
        };
    }, [selectedFolder]);

    const getFileSize = useCallback((value: number) => {
        return `${(value / 1024).toFixed(0)} KB`;
    }, []);

    const generator = useMemo(() => {
        const columns = new DataGridColumnGenerator(tm, files, theme, isMobile);
        columns.TryAddFileLinkColumn("name", "Name", `${APIHelper.GetBaseURL()}api/documents/files/download`, getParams, { hideable: false, flex: 3 });
        columns.TryAddNumericColumn("size", "Size", getFileSize, {});
        columns.TryAddDateTimeColumn("lastWriteTime", "Date", DateTime.DATETIME_SHORT_WITH_SECONDS, {});
        return columns;
    }, [tm, files, theme, isMobile, getFileSize, getParams]);

    useEffect(() => {
        if (selectedFolder !== undefined) {
            DocumentsAPI.QueryFiles(selectedFolder).then((files) => {
                setFiles(files);
            });
        }
    }, [selectedFolder]);

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        const file = row as IFile;
        return file.name;
    };

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <CustomDataGridPro
            getRowId={getRowId}
            columns={generator.GetColumns()}
            rows={files}
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
        />
    </>;
};

export default FileList;
