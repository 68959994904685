import { useTranslations } from "@fenetech/translations";
import { Button, Stack, Typography } from "@mui/material";
import React, { useCallback } from 'react';
import { useCADActions, useCADState } from "../Context/CADContext";
import CADItemAPI from "../API/CADItemAPI";

interface ICADUISettingsProps {
    style?: React.CSSProperties
}

const CADUISettings: React.FC<ICADUISettingsProps> = (props: ICADUISettingsProps) => {

    const tm = useTranslations();
    const cadState = useCADState();
    const cadActions = useCADActions();

    const handleSelectionToggle = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {

        let newUISelections = { ...cadState.model!.uiSelections };

        const selectionPropertyName: string = event.currentTarget.getAttribute("data-value")!;
        newUISelections[selectionPropertyName] = !newUISelections[selectionPropertyName];

        const newModel = await CADItemAPI.PostUISelections(newUISelections);
        cadActions.setModel(newModel);

    }, [cadActions, cadState.model]);

    if (cadState.model === null) return null;

    return <>
        <Stack direction="row" justifyContent="center" style={props.style} spacing={.5} >

            <Button size="small"
                data-value="showDimensions"
                variant={cadState.model?.uiSelections.showDimensions ? "contained" : "outlined"}
                color="secondary"
                onClick={handleSelectionToggle}
            >
                <Typography>{tm.Get("Show Drawing Dimensions")}</Typography>
            </Button>

            <Button size="small"
                data-value="showEdgeNumbers"
                variant={cadState.model?.uiSelections.showEdgeNumbers ? "contained" : "outlined"}
                color="secondary"
                onClick={handleSelectionToggle}
            >
                <Typography>{tm.Get("Show Edge Numbers")}</Typography>
            </Button>

        </Stack>
    </>;
}

export default CADUISettings;