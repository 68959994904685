import { useTheme } from "@mui/material";
import ScalableImageWithCanvasOverlay, { ScalableCanvasDrawHandler } from "components/OptionsWizard/GenericComponents/ScalableImageWithCanvasOverlay";
import React from 'react';
import { ICADState, useCADState } from "../Context/CADContext";
import { CADDrawingHelpers } from "../CADDrawingHelpers";
import { IPoint } from "../interfaces";

interface ICADDrawingProps {
    loadedImageUrl: string;
    imageScale: number;
    imageOffset: IPoint;
}

const DrawCanvas = (cadState:ICADState, ctx:CanvasRenderingContext2D|null, canvasBounds: DOMRect|null, canvasRatio:number, canvasOffset:IPoint, imageScale:number, imageOffset:IPoint) => {
    if (ctx && canvasBounds && cadState.model ) {
        const drawingHelpers = new CADDrawingHelpers(ctx, canvasBounds, canvasRatio, canvasOffset, imageScale, imageOffset);

        drawingHelpers.ClearCanvas();
        drawingHelpers.DrawAddingEditingAdjustment(cadState.model, cadState.activeEdgeAdjustment);
        drawingHelpers.DrawHoveredOperation(cadState.hoveredOperationID, cadState.model);
        drawingHelpers.DrawAddingOperation(cadState.operationEditor?.placement, cadState.operationEditor?.operation, cadState.model);

    }
}

const InitCanvas  = (ctx:CanvasRenderingContext2D,canvasBounds: DOMRect) => {
        ctx.translate(0, canvasBounds.height);

        // Scale the y-axis to be positive upwards
        ctx.scale(1, -1);
}

const CADDrawing: React.FC<ICADDrawingProps> = (props: ICADDrawingProps) => {

    const theme = useTheme();
    const cadState = useCADState();

    const drawCanvasInternal:ScalableCanvasDrawHandler = React.useCallback((ctx, canvasBounds, ratio, offsetX, offsetY) => {
        DrawCanvas(cadState, ctx, canvasBounds, ratio, {x:offsetX, y:offsetY}, props.imageScale, props.imageOffset);
    }, [cadState, props.imageScale, props.imageOffset]);

    return <>
        <ScalableImageWithCanvasOverlay
            src={props.loadedImageUrl}
            theme={theme}
            renderAsDiv={false}
            imageStyle={undefined}
            onMouseDown={() => null}
            onMouseMove={() => null}
            onMouseLeave={() => null}
            onTouchStart={() => null}
            drawCanvas={drawCanvasInternal}
            onInitCanvas={InitCanvas}
        />
    </>;

}

export default CADDrawing;