import APIHelper from "helpers/APIHelper";
import { IBaseContent } from "models/IBaseContent";
import { IQuoteAttachment, IFileUpload } from "models/IAttachment";

export default class QuoteAttachmentsAPI {

    public static async GetAttachmentsEnabledAsync(): Promise<boolean> {
        const query = "api/QuoteAttachments/Enabled";

        const res = await APIHelper.GetAsync<boolean>(query);
        return res.Result;
    }
    
    public static async GetQuoteAttachmentsAsync(oKey: number): Promise<IQuoteAttachment[]> {
        const query = "api/QuoteAttachments/List";
        const params = { oKey };

        const res = await APIHelper.GetAsync<IQuoteAttachment[]>(query, params);
        return res.Result;
    }

    public static async InsertQuoteAttachmentsAsync(oKey: number, overwrite: boolean, fileUploads: IFileUpload[]) {
        const query = "api/QuoteAttachments"
        const params = { oKey, overwrite };
        const body = fileUploads;

        const res = await APIHelper.PostAsync<IBaseContent>(query, params, body);
        return res.Result;
    }

    public static async DeleteQuoteAttachmentAsync(qaKey: number) {
        const query = "api/QuoteAttachments"
        const params = { qaKey };

        const res = await APIHelper.DeleteAsync<IBaseContent>(query, params);
        return res.Result;
    }

    public static async MoveQuoteAttachmentsAsync(qaKey: number, odKey: number, overwrite: boolean) {
        const query = "api/QuoteAttachments/Move"
        const params = { 
            qaKey: qaKey, 
            destinationODKey: odKey, 
            overwrite: overwrite
        };

        const res = await APIHelper.PutAsync<IBaseContent>(query, params);
        return res.Result;
    }

    public static async CopyAllQuoteAttachmentsAsync(oKey: number, destinationOKey: number) {
        const query = "api/QuoteAttachments/List/Copy"
        const params = { oKey, destinationOKey };

        const res = await APIHelper.PostAsync<IBaseContent>(query, params);
        return res.Result;
    }

    public static async SubmitQuoteAttachmentsAsync(oKey: number, destinationOKey: number) {
        const query = "api/QuoteAttachments/List/Submit"
        const params = { oKey, destinationOKey };

        const res = await APIHelper.PostAsync<IBaseContent>(query, params);
        return res.Result;
    }

    public static async CopyAllLineItemAttachmentsAsync(oKey: number, odKey: number, destinationODKey: number) {
        const query = "api/QuoteAttachments/LineItem/Copy"
        const params = { oKey, odKey, destinationODKey };

        const res = await APIHelper.PostAsync<IBaseContent>(query, params);
        return res.Result;
    }

    public static async SetAttachmentCustomerVisibleAsync(qaKey: number, isVisible: boolean) {
        const query = "api/QuoteAttachments/CustomerVisible"
        const params = { qaKey, isVisible };

        const res = await APIHelper.PutAsync(query, params);
        return res.Result;
    }

    public static async SetAttachmentParentCustomerVisibleAsync(qaKey: number, isVisible: boolean) {
        const query = "api/QuoteAttachments/ParentCustomerVisible"
        const params = { qaKey, isVisible };

        const res = await APIHelper.PutAsync(query, params);
        return res.Result;
    }
}