import { useTranslations } from "@fenetech/translations";
import { Grid, Typography, TextField, Select, MenuItem } from "@mui/material";
import React from 'react';
import { IEdgeElbow, IEdgeRake, IEdgeShift } from "../../interfaces";
import { useMeasurementUnitLabel } from "helpers/hooks/useMeasurementUnitLabel";
import { useCADState } from "../../Context/CADContext";
import { ShiftDirection } from "../../enums";

interface IAdjustmentSelectionsProps {
    editing: boolean;
    existingAdjustment: IEdgeShift | IEdgeRake | IEdgeElbow | null;
    offsetDescription?: string;
    shiftOffset: string;
    shiftDirection: ShiftDirection;
    setShiftOffset: (value: string) => void;
    setShiftDirection: (value: ShiftDirection) => void;
}

const AdjustmentSelection: React.FC<IAdjustmentSelectionsProps> = (props: IAdjustmentSelectionsProps) => {

    const tm = useTranslations();
    const cadState = useCADState();
    const measurementLabel = useMeasurementUnitLabel(cadState.unitSetID);

    if (props.editing) {

        return <Grid item container spacing={1} alignItems={"center"}>

            <Grid item xs >
                <TextField
                    label={props.offsetDescription ?? tm.Get("Offset")}
                    fullWidth
                    size="small"
                    disabled={!props.editing}
                    value={ props.shiftOffset.toString()}
                    onChange={(event) => props.setShiftOffset(event.target.value)}
                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                />
            </Grid>

            <Grid item xs={2}>
                <Select
                    size="small"
                    fullWidth
                    value={props.shiftDirection}
                    onChange={(event) => props.setShiftDirection(event.target.value as ShiftDirection)}
                >
                    <MenuItem value={"In"}>{tm.Get("In")}</MenuItem>
                    <MenuItem value={"Out"}>{tm.Get("Out")}</MenuItem>
                </Select>
            </Grid>

        </Grid>;

    } else {

        return <Grid item container spacing={1} alignItems={"center"}>

            <Grid item xs>
                <TextField
                    label={props.offsetDescription ?? tm.Get("Offset")}
                    fullWidth
                    size="small"
                    disabled
                    value={props.shiftOffset.toString()}
                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                />
            </Grid>

            <Grid item xs={2}>
                <TextField
                    fullWidth
                    size="small"
                    disabled
                    value={`${props.shiftDirection === "Out" ? tm.Get("Out") : tm.Get("In")}`}
                />
            </Grid>

        </Grid>;

    }

}

export default AdjustmentSelection;
