import { useContext } from "react";
import { QuoteAttachmentsActions, QuoteAttachmentsContextDispatch } from "./QuoteAttachmentsContext";

export function useQuoteActions(): QuoteAttachmentsActions {

    const actions = useContext(QuoteAttachmentsContextDispatch)!;
    return actions;
};

export default useQuoteActions;
