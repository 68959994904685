import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from 'react';


const EdgeShiftIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {

    return <SvgIcon {...props}>
        <path d="m 12 8 l 0 2 L 11 11 H 22 v 2 h -11 l 1 1 L 12 16 l -4 -4 z M 4 2 L 7 2 L 7 22 L 4 22 L 4 2" />
    </SvgIcon>;
}

export default EdgeShiftIcon;