
import { useCADState } from "../../Context/CADContext";
import OperationParameters from "./OperationParameters";
import { Grid } from "@mui/material";
import OperationProperties from "./OperationProperties";
import OperationPlacement from "./OperationPlacement";
import OperationPreviewImage from "./OperationPreviewImage";
import useIsMobile from "helpers/hooks/useIsMobile";
import OperationEdgework from "./OperationEdgwork";
import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";
import { useCallback } from "react";
import { ICADOperationEditor, ICADParameterEdit } from "../../interfaces";
import OperationDimensions from "./OperationDimensions";
import React from "react";
import { useCADImageBackgroundColor } from "../../hooks/useCADImageBackgroundColor";

interface IOperationEditorProps {

}

const OperationEditor: React.FC<IOperationEditorProps> = (props: IOperationEditorProps) => {

    const cadState = useCADState();
    const isMobile = useIsMobile();
    const imageBackground = useCADImageBackgroundColor();

    //We must use useCallback to ensure we have access to the base URL static variable
    const postParameterAsync = useCallback((opParam: ICADParameterEdit) => {
        return CADOperationEditorAPI.PostParameterAsync(opParam);
    }, []);

    const getParameterFromLabel = useCallback((newEditor: ICADOperationEditor, label: string) => {
        const param = newEditor.operation.parameters.find((p) => p.name === label)!;
        return param;
    }, []);

    return <>
        <Grid container direction={isMobile ? "column-reverse" : "row"} spacing={1}>
            <Grid container item xs direction={"column"} spacing={1}   >
                <Grid item>
                    <OperationPlacement
                        operationEdit={cadState.operationEditor?.operation}
                        operationPlacement={cadState.operationEditor?.placement}
                        loading={cadState.operationEditorLoading}
                    />
                </Grid>
                <Grid item>
                    <OperationProperties
                        operationEdit={cadState.operationEditor?.operation}
                        loading={cadState.operationEditorLoading}
                    />
                </Grid>
                <Grid item>
                    <OperationParameters
                        operationEdit={cadState.operationEditor?.operation}
                        loading={cadState.operationEditorLoading}
                        PostParameterAsync={postParameterAsync}
                        GetParameterFromLabel={getParameterFromLabel}
                    />
                </Grid>

                <Grid item>
                    <OperationDimensions dimensions={cadState.operationEditor?.dimensions} />
                </Grid>

                <Grid item>
                    <OperationEdgework edgework={cadState.operationEditor?.edgework} operation={cadState.operationEditor?.operation} loading={cadState.operationEditorLoading} />
                </Grid>

            </Grid>

            <Grid item container xs={isMobile ? 1 : 6}>
                <Grid item style={{ backgroundColor: imageBackground }} xs>
                    <OperationPreviewImage />
                </Grid>
            </Grid>

        </Grid>
    </>;

}

export default OperationEditor;
