
import { Card, CardHeader, CardHeaderProps, CardProps } from '@mui/material';

const style = {
    minHeight: 0,
    padding: 0,
    margin: 0,
    '.MuiCardHeader-root':
    {
        margin: 0,
        padding: 1,
        bgcolor: "secondary.main"
    },
    '.MuiCardHeader-content':
    {
        color: "secondary.contrastText",
    },
    '.MuiCardContent-root':
    {
        margin: 2,
        padding: 0,
        '&:last-child': { paddingBottom: 0}
    }

};

export function CardHeaderSlim(props: React.PropsWithChildren<CardHeaderProps>) {
    return <CardHeader
        {...props}
        titleTypographyProps={{ variant: 'body1' }}
    >
        {props.children}
    </CardHeader>
}

export default function CardSlim(props: React.PropsWithChildren<CardProps>) {
    return <Card
        sx={{ ...style, ...props.sx }}
    >
        {props.children}
    </Card>
}