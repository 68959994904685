import { useTranslations } from "@fenetech/translations";
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import { IEdgeElbow } from "../../interfaces";

interface IEdgeElbowBreakDistanceProps {
    editing: boolean;
    existingElbow: IEdgeElbow | null;
    startFixed: boolean;
    setStartFixed: (startFixed: boolean) => void;
    breakDistance: string;
    setBreakDistance: (breakDistance: string) => void;

}

const EdgeElbowBreakDistance: React.FC<IEdgeElbowBreakDistanceProps> = (props: IEdgeElbowBreakDistanceProps) => {

    const tm = useTranslations();

    if (props.editing) {

        return <>
            <Grid container item spacing={1}>

                <Grid item xs={2}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="rake-position-label">{tm.Get("Fixed corner")}</InputLabel>
                        <Select
                            labelId="rake-position-label"
                            label={tm.Get("Fixed corner")}
                            size="small"
                            fullWidth
                            value={props.startFixed ? "Start" : "End"}
                            onChange={(event) => props.setStartFixed(event.target.value === "Start" ? true : false)}
                        >
                            <MenuItem value={"Start"}>{tm.Get("Start")}</MenuItem>
                            <MenuItem value={"End"}>{tm.Get("End")}</MenuItem>
                        </Select>

                    </FormControl>

                </Grid>

                <Grid item xs>
                    <TextField fullWidth size="small" label={tm.Get("Break distance")}
                        value={props.breakDistance}
                        onChange={(event) => props.setBreakDistance(event.target.value)}
                    />
                </Grid>

            </Grid>
        </>;

    } else {

        return <Grid container item spacing={1}>

            <Grid item xs={2}>

                <TextField
                    label={tm.Get("Fixed corner")}
                    fullWidth
                    size="small"
                    disabled
                    value={`${props.startFixed ? tm.Get("Start") : tm.Get("End")}`}
                />

            </Grid>

            <Grid item xs>
                <TextField
                    fullWidth
                    size="small"
                    label={tm.Get("Break distance")}
                    value={props.breakDistance}
                    disabled
                />
            </Grid>

        </Grid>
    }

}

export default EdgeElbowBreakDistance;
