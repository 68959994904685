import { DispatchActionsBase } from "components/Common/DispatchActionsBase";
import React from "react";
import { ThemeColorEnum } from "../../enums";

export interface IPageInfo {
    title: string;
    waitVisible: boolean;
    dialog: IDialogBox | undefined;
    actionButtons: (IActionButton | IActionBackButton)[];
    actionContent: JSX.Element | null;
    footerContent: JSX.Element | null;
};

export interface IDialogBox {
    message: string;
    title: string;
    visible: boolean;
    yesNoPrompt: boolean;
    callback?: ((dialogResult?: boolean) => void);
    imageSource: string;
};

export interface IActionButton {
    text: string;
    color: ThemeColorEnum;
    onClick?: (() => void);
    type?: "button" | "submit" | "reset" | undefined;
    form?: string;
    disabled?: boolean;
};

export interface IActionBackButton {
    navigateURL: string
}

export interface IActionCreateQuoteButton {
    untranslatedText: string,
    navigateURL: string
}

export interface IActionFileUploadButton {
    text: string;
    color: ThemeColorEnum;
    onClick?: (() => void);
    fileRef: React.RefObject<HTMLInputElement>;
    disabled?: boolean;
}

export interface IActionFileDownloadButton {
    text: string;
    download: (downloadURL: string, params: any) => Promise<void>;
    params: any;
    color: ThemeColorEnum;
    href: string;
    disabled?: boolean;
}

const initialState: IPageInfo = {
    title: "",
    waitVisible: false,
    dialog: undefined,
    actionButtons: [],
    actionContent: null,
    footerContent: null,
};

export enum PageActionEnum {
    SetTitle,
    SetWaitVisible,
    SetDialog,
    HideDialog,
    SetActionButton,
    RemoveActionButton,
    ClearActionButtons,
    SetBackButton,
    SetCreateQuoteButton,
    SetActionContent,
    SetFooterContent,
}

/*Define Reducer and Actions*/
export type PageActionType =
    | { type: PageActionEnum.SetTitle, value: string }
    | { type: PageActionEnum.SetWaitVisible, value: boolean }
    | { type: PageActionEnum.SetDialog, value: IDialogBox | undefined }
    | { type: PageActionEnum.HideDialog }
    | { type: PageActionEnum.SetActionButton, value: { index: number, actionButton: IActionButton } }
    | { type: PageActionEnum.RemoveActionButton, value: number }
    | { type: PageActionEnum.ClearActionButtons }
    | { type: PageActionEnum.SetBackButton, value: { index: number, navigateURL: string } }
    | { type: PageActionEnum.SetCreateQuoteButton, value: number }
    | { type: PageActionEnum.SetActionContent, value: JSX.Element | null }
    | { type: PageActionEnum.SetFooterContent, value: JSX.Element | null }

const pageReducer: React.Reducer<IPageInfo, PageActionType> = (pageInfo: IPageInfo, action) => {

    switch (action.type) {
        case PageActionEnum.SetTitle:
            return { ...pageInfo, title: action.value };
        case PageActionEnum.SetWaitVisible:
            return { ...pageInfo, waitVisible: action.value };
        case PageActionEnum.SetDialog:
            return { ...pageInfo, dialog: action.value }
        case PageActionEnum.HideDialog:
            if (pageInfo.dialog) {
                return { ...pageInfo, dialog: { ...pageInfo.dialog, visible: false } };
            }
            else {
                return { ...pageInfo, dialog: undefined };
            }
        case PageActionEnum.SetActionButton:
            {
                const newActionButtons = [...pageInfo.actionButtons];
                newActionButtons[action.value.index] = action.value.actionButton;
                return { ...pageInfo, actionButtons: newActionButtons };
            }
        case PageActionEnum.RemoveActionButton:
            {
                let newActionButtons = [...pageInfo.actionButtons];
                newActionButtons = newActionButtons.splice(action.value, 1);
                return { ...pageInfo, actionButtons: newActionButtons };
            }
        case PageActionEnum.ClearActionButtons:
            return { ...pageInfo, actionButtons: [] };
        case PageActionEnum.SetBackButton:
            {
                const backButton: IActionBackButton = { navigateURL: action.value.navigateURL }
                let newActionButtons = [...pageInfo.actionButtons];
                newActionButtons[action.value.index] = backButton;
                return { ...pageInfo, actionButtons: newActionButtons }
            }
        case PageActionEnum.SetCreateQuoteButton:
            {
                const quoteButton: IActionCreateQuoteButton = { untranslatedText: "Create Quote", navigateURL: "/quotes/add" };
                let newActionButtons = [...pageInfo.actionButtons];
                newActionButtons[action.value] = quoteButton;
                return { ...pageInfo, actionButtons: newActionButtons }
            }
        case PageActionEnum.SetActionContent:
            return { ...pageInfo, actionContent: action.value };
        case PageActionEnum.SetFooterContent:
            return { ...pageInfo, footerContent: action.value };
    }
}

/*Create context for state and reducer dispatch*/

export const PageContextDispatch = React.createContext<PageActions | undefined>(undefined);
export const PageContext = React.createContext<IPageInfo>(initialState);

export class PageActions extends DispatchActionsBase<PageActionType> {
    
    SetTitle(title: string) {
        this.dispatch({ type: PageActionEnum.SetTitle, value: title });
    }

    SetCreateQuoteButton(index: number) {
        this.dispatch({ type: PageActionEnum.SetCreateQuoteButton, value: index });
    }

    SetActionButton(index: number, actionButton: IActionButton) {
        this.dispatch({ type: PageActionEnum.SetActionButton, value: { index: index, actionButton: actionButton } });
    }

    RemoveActionButton(index: number) {
        this.dispatch({ type: PageActionEnum.RemoveActionButton, value: index });
    }

    ClearActionButtons() {
        this.dispatch({ type: PageActionEnum.ClearActionButtons });
    }

    SetBackButton(index: number, navigateURL: string) {
        this.dispatch({ type: PageActionEnum.SetBackButton, value: { index: index, navigateURL: navigateURL } });
    }

    SetActionContent(element: JSX.Element | null) {
        this.dispatch({ type: PageActionEnum.SetActionContent, value: element });
    }

    SetFooterContent(element: JSX.Element | null) {
        this.dispatch({ type: PageActionEnum.SetFooterContent, value: element });
    }

    SetDialog(dialog: IDialogBox | undefined) {
        this.dispatch({ type: PageActionEnum.SetDialog, value: dialog });
    }

    HideDialog() {
        this.dispatch({ type: PageActionEnum.HideDialog });
    }

    SetWaitVisible(visible: boolean) {
        this.dispatch({ type: PageActionEnum.SetWaitVisible, value: visible });
    }

}

/*Create provider*/
export const PageProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(pageReducer, initialState);

    const actions = React.useMemo<PageActions>(() => {
        return new PageActions(dispatch);
    }, [dispatch]);

    return <PageContext.Provider value={state}>
        <PageContextDispatch.Provider value={actions}>
            {props.children}
        </PageContextDispatch.Provider>
    </PageContext.Provider>
}

export default PageContext;
