import { Grid, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton } from "@mui/material";
import React from 'react';
import EdgeworkSelection from "./EdgeworkSelection";
import { ICADEdgework, ICADSideOperationEdit } from "../../interfaces";
import { useTranslations } from "@fenetech/translations";
import { Delete } from "@mui/icons-material";
import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";
import { useCADActions } from "../../Context/CADContext";

interface IEdgeworkPerSideSelectionProps {
    availableEdgework: ICADEdgework[] | undefined;
    sideSelection: ICADSideOperationEdit;
    sideCount: number;
    index: number;
    updateSideSelection: (index: number, edgeworkLabel: string, side: number) => void;
    removeSideSelection: (index: number) => void;
    loading: boolean;
}

const EdgeworkPerSideSelection: React.FC<IEdgeworkPerSideSelectionProps> = (props: IEdgeworkPerSideSelectionProps) => {

    const tm = useTranslations();
    const cadActions = useCADActions();

    const sides = Array.from(Array(props.sideCount + 1).keys()).slice(1);

    const handleUpdateEdgework = React.useCallback(async (edgework: Partial<ICADSideOperationEdit>) => {
        const newEditor = await CADOperationEditorAPI.PostEdgeworkAsync(edgework.uniqueID ?? -1, edgework);
        cadActions.setOperationEditor(newEditor);
    }, [cadActions]);

    return <Grid item container spacing={1} alignItems={"center"} flexWrap="nowrap" >

        <Grid item style={{width:"calc(75% - 44px)"}} >
            <EdgeworkSelection availableEdgework={props.availableEdgework} selectedEdgework={props.sideSelection} onUpdateEdgework={handleUpdateEdgework} loading={props.loading} />
        </Grid>

        <Grid item xs={3} >

            <FormControl fullWidth size="small"  >
                <InputLabel id="side-label">{tm.Get("Side")}</InputLabel>
                <Select
                    labelId="side-label"
                    label={tm.Get("Side")}
                    size="small"
                    fullWidth
                    value={props.sideSelection.side}
                    onChange={(event) => props.updateSideSelection(props.index, props.sideSelection.label, event.target.value as number)}
                >
                    <MenuItem key={-1} value={"0"} >
                        <Grid container alignItems={"center"} minHeight={56}>
                            {tm.Get("{All}")}
                        </Grid>
                    </MenuItem>

                    {sides.map((side, index) => {
                        return <MenuItem key={index} value={side}>
                            <Grid container alignItems={"center"} minHeight={56}>
                                {side}
                            </Grid>
                        </MenuItem>;
                    })}

                </Select>
            </FormControl>

        </Grid>

        <Grid item >
            <Tooltip title={tm.GetWithParams("Delete")} >
                <IconButton onClick={() => props.removeSideSelection(props.index)} >
                    <Delete fontSize="small" color="primary" />
                </IconButton>
            </Tooltip>
        </Grid>

    </Grid>;
}

export default EdgeworkPerSideSelection;