import { useTranslations } from "@fenetech/translations";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React from 'react';
import { useCADActions, useCADState } from "../../Context/CADContext";
import { CADOperationTypeEnum, EdgeAdjustmentsEnum } from "../../enums";
import { CADHelpers } from "../../CADHelpers";

interface ICADOperationTabsMobileProps {
    currentTab: string;
    availableOperationTypes: CADOperationTypeEnum[];
}

const CADOperationTabsMobile: React.FC<ICADOperationTabsMobileProps> = (props: ICADOperationTabsMobileProps) => {

    const cadState = useCADState();
    const cadActions = useCADActions();
    const tm = useTranslations();

    const handleTabChange = (event: SelectChangeEvent<string>) => {
        cadActions.setCurrentTab(event.target.value);
        cadActions.setOperationEditor(null);
        cadActions.setHoveredOperation(null);
        cadActions.setActiveEdgeAdjustment(null);
    }

    const enabledTools = cadState.model?.layers[0].edgeAdjustmentTools ?? EdgeAdjustmentsEnum.None;

    return <>
        <FormControl fullWidth>
            <InputLabel>
                {
                    tm.Get("Operation Type")
                }
            </InputLabel>

            <Select
                label={tm.Get("Operation Type")}
                value={props.currentTab}
                onChange={handleTabChange}
                variant="outlined"
                fullWidth
            >

                {props.availableOperationTypes.map((opType, index) => {
                    return <MenuItem key={`TBL_${index}_${opType.toString()}`} value={opType.toString()} >
                        <Typography >{tm.Get(CADHelpers.OperationTypeDescriptionFromEnum(opType))}</Typography>
                    </MenuItem>
                })}

                {enabledTools > 0 &&
                    <MenuItem key={`TBL_-1_EdgeAdjustments`} value="EdgeAdjustments"  >
                        <Typography>{tm.Get("Edge Adjustments")}</Typography>
                    </MenuItem>
                }

            </Select>

        </FormControl>
    </>

}

export default CADOperationTabsMobile;