import * as React from "react";
import { ICADParameterEdit } from "../../interfaces";
import { Tooltip } from "@mui/material";

interface IParameterTooltipProps {
    parameter: ICADParameterEdit;
    children: React.ReactElement<any, any>;
}

const ParameterTooltip: React.FC<IParameterTooltipProps> = (props: IParameterTooltipProps) => {

    return <Tooltip title={props.parameter.tooltip?.trim() ?? ''} enterDelay={500} enterTouchDelay={500} enterNextDelay={500}>
        {props.children}
    </Tooltip>

}

export default ParameterTooltip;