import APIHelper from "helpers/APIHelper";
import { ICADModel, ICADUISelections, IImageData } from "../interfaces";
import WebResponse from "helpers/WebResponse";

export default class CADItemAPI {

    private static baseURL = 'api/cad/item';

    static async PostCADInit(): Promise<WebResponse<ICADModel>> {

        return await APIHelper.PostAsync<ICADModel>(`${this.baseURL}/init`, undefined, undefined, false);
    }

    static async PostCADCancel(): Promise<void> {
        await APIHelper.PostAsync(`${this.baseURL}/cancel`, undefined, undefined, false);
    }

    static async PostCADSave(): Promise<WebResponse<unknown>> {
        return await APIHelper.PostAsync(`${this.baseURL}/save`, undefined, undefined, false);
    }

    static async GetCADItem(): Promise<ICADModel> {
        return (await APIHelper.GetAsync<ICADModel>(`${this.baseURL}/`)).Result;
    }

    static async GetItemDrawing(includeDimensions: boolean, includeEdges: boolean) {
        return await APIHelper.GetAsync(`${this.baseURL}/drawing`, { params: { includeDimensions, includeEdges } });
    }

    static async GetItemDrawingInfoAsync(): Promise<IImageData> {
        return (await APIHelper.GetAsync<IImageData>(`${this.baseURL}/drawing/info`)).Result;
    }

    static async DeleteItemOperation(uniqueid: number) {
        return await APIHelper.DeleteAsync<ICADModel>(`${this.baseURL}/operations/${uniqueid}`);
    }

    static async CopyItemOperation(uniqueid: string) {
        return await APIHelper.CopyAsync(`${this.baseURL}/operations/${uniqueid}`);
    }

    static async GetItemOperation(uniqueid: string) {
        return await APIHelper.GetAsync(`${this.baseURL}/operations/${uniqueid}`);
    }

    static async PostUISelections(uiSelections: ICADUISelections): Promise<ICADModel> {
        return (await APIHelper.PostAsync<ICADModel>(`${this.baseURL}/uiSelections`, null, uiSelections)).Result;
    }

};
