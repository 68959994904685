import * as React from "react";
import { TextField } from "@mui/material";
import { ICADOperationEditor, ICADParameterEdit } from "../../interfaces";
import { useCADActions } from "../../Context/CADContext";
import ParameterTooltip from "./ParameterTooltip";

interface IOperationParameterNumericProps {
    parameter: ICADParameterEdit;
    PostParameterAsync: (opParam: ICADParameterEdit) => Promise<ICADOperationEditor>;
    GetParameterFromLabel: (newEditor: ICADOperationEditor, label: string) => ICADParameterEdit;
    loading: boolean;
}

const OperationParameterNumeric: React.FC<IOperationParameterNumericProps> = ({ parameter, PostParameterAsync, GetParameterFromLabel, loading }) => {

    const opParam = parameter;
    const cadActions = useCADActions();

    const [inputText, setInputText] = React.useState<string>(opParam.numericValueInputBuffer);

    const paramLabel = React.useMemo(() => {
        if (opParam.description.length > 0) {
            return `${opParam.name} - ${opParam.description}`;
        }
        else {
            return opParam.name;
        }
    }, [opParam]);

    const handleValueChange = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;

        if (newValue !== opParam.numericValueInputBuffer) {
            cadActions.setOperationEditorLoading();
            const newEditor = await PostParameterAsync({ ...opParam, numericValueInputBuffer: newValue });
            cadActions.setOperationEditor(newEditor);
            const newParam = GetParameterFromLabel(newEditor, opParam.name);
            if (newParam) {
                setInputText(newParam.numericValueInputBuffer);
            }
        }

    }, [cadActions, opParam, PostParameterAsync, GetParameterFromLabel]);

    return <ParameterTooltip parameter={opParam}>
        <TextField
            disabled={loading}
            label={paramLabel}
            placeholder={paramLabel}
            fullWidth
            variant="outlined"
            size="small"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onBlur={handleValueChange}
            error={opParam.validationErrorText ? true : false}
            helperText={opParam.validationErrorText}
        />
    </ParameterTooltip>

}

export default OperationParameterNumeric;