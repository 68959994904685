import { useTheme } from "@mui/material";
import { GridCallbackDetails, GridRenderCellParams, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useIsMobile from "helpers/hooks/useIsMobile";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import { deleteCommandColumn, itemColumn, partColumn } from "./QuoteAttachmentColumns";
import { DateTime } from "luxon";
import { IQuoteAttachment } from "models/IAttachment";
import { ILineItem } from "models/IQuote";
import useQuoteAttachmentActions from "./useQuoteAttachmentActions";
import useQuoteData from "components/Quotes/useQuoteData";
import { ThemeColorEnum } from "helpers/enums";
import { IBaseContent } from "helpers/interfaces";
import useFileDownloadWithFeedback from "helpers/hooks/useFileDownloadWithParams";

interface IProps {
    attachments: IQuoteAttachment[] | null,
    lineItems: ILineItem[],
    moveAttachment: (qaKey: number, odKey: number, fileName: string) => void,
    deleteAttachment: (qaKey: number) => void,
    isContractor: boolean,
    isEditEnabled: boolean,
    isLoading: boolean
}

const AttachmentsTable: React.FC<IProps> = ({ attachments, lineItems, moveAttachment, deleteAttachment, isContractor, isEditEnabled, isLoading }: IProps) => {

    const tm = useTranslations();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("QuoteAttachments");
    const { quote } = useQuoteData();
    const quoteAttachmentActions = useQuoteAttachmentActions();

    const preDownload = React.useCallback(() => {
        quoteAttachmentActions.SetIsLoading(true);
    }, [quoteAttachmentActions]);

    const postDownload = React.useCallback(() => {
        quoteAttachmentActions.SetIsLoading(false);
    }, [quoteAttachmentActions]);

    const onFileDownloadError = React.useCallback((baseContent: IBaseContent) => {
        const errorMessage = tm.GetWithParams(baseContent.message, ...baseContent.params ?? []);
        quoteAttachmentActions.SetAlert({text: errorMessage, alertType: ThemeColorEnum.Error, visible: true})
    }, [quoteAttachmentActions, tm]);

    const { download, ref } = useFileDownloadWithFeedback({preDownload, postDownload, onError: onFileDownloadError});

    const generator = new DataGridColumnGenerator(tm, attachments, theme, isMobile);

    const getParams = React.useCallback((attachment: IQuoteAttachment) => {
        return { qaKey: attachment.qaKey };
    }, []);

    const formatSizeColumn = React.useCallback((params: GridRenderCellParams<any, IQuoteAttachment, any>) => {
        return `${params.row.sizeKB.toString()} KB`;
    }, []);

    const customerVisibleColumnVisible = React.useMemo(() => {
        if (!isEditEnabled) {
            return false;
        }
        if (quote) {
            if (quote.isContractorCreatedQuote) {
                // we never display this for not submitted contractor quotes since there is no level lower
                return quote.isSubmitted;
            } else {
                // if a dealer created this quote, there is no contractor involved
                return false;
            }
        }
    }, [isEditEnabled, quote]);

    const handleSetCustomerVisible = React.useCallback(async (row: IQuoteAttachment, isVisible: boolean) => {
        quoteAttachmentActions.SetCustomerVisible(row.qaKey, isVisible);
    }, [quoteAttachmentActions]);

    const handleSetParentCustomerVisible = React.useCallback(async (row: IQuoteAttachment, isVisible: boolean) => {
        quoteAttachmentActions.SetParentCustomerVisible(row.qaKey, isVisible);
    }, [quoteAttachmentActions]);

    if (attachments && attachments.length > 0) {
        generator.TryAddFileLinkWithFeedbackColumn("fileName", "Name", "api/QuoteAttachments/Download", getParams, download, { hideInMobile: false });
        generator.TryAddFormattedTextColumn("sizeKB", "Size", formatSizeColumn, { hideInMobile: true });
        generator.TryAddDateTimeColumn("createDate", "Date", DateTime.DATETIME_SHORT_WITH_SECONDS, { hideInMobile: true })
        generator.AddColumn(itemColumn("lineItem", "Item", moveAttachment, lineItems, !isEditEnabled, tm, isMobile));
        generator.AddColumn(partColumn("part", "Part", lineItems, tm, isMobile));
        if (customerVisibleColumnVisible) {
            generator.TryAddCheckBoxColumn("customerVisible", "Customer Visible", handleSetCustomerVisible, { hideInMobile: true });
        }
        if (isEditEnabled) {
            generator.TryAddCheckBoxColumn("parentCustomerVisible", isContractor ? "Submit" : "Upload", handleSetParentCustomerVisible, { hideInMobile: true });
            generator.AddColumn(deleteCommandColumn(deleteAttachment, isLoading || !isEditEnabled));
        }
    }
    
    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);
    const getRowId: GridRowIdGetter = (row: GridRowModel) => row["fileName"] + row["odKey"];

    return <>
        <CustomDataGridPro
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
            getRowId={getRowId}
            columns={generator.GetColumns()}
            rows={attachments}
            loading={isLoading}
        />
        {/* Hidden link to host file downloads */}
        <a hidden ref={ref} />
    </>;
}

export default AttachmentsTable;