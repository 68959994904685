import APIHelper from "helpers/APIHelper";
import { IQuoteClassification } from "models/IQuote";
import { IApplicationInfo, IThemeInfo, IUserInfo, IMeasurementType, IPricingMethod, ISystemReport, IOfflineSettings, IPingResult, IPingTest, IDiscountTableGrouping } from "../helpers/interfaces";
import WebResponse from "helpers/WebResponse";


export default class API {

    public static async GetApplicationTheme(): Promise<IThemeInfo> {
        const response = await APIHelper.GetAsync<IThemeInfo>("api/application/theme");
        return response.Result;
    }

    public static async GetApplicationTitle(): Promise<string> {
        const query: string = `api/Application/title`;
        const response = await APIHelper.GetAsync<string>(query);
        return response.Result;
    }

    public static async GetHomeAddress(): Promise<string> {
        const query: string = `api/Application/HomeAddress`;
        const response = await APIHelper.GetAsync<string>(query);
        return response.Result;
    }

    public static async GetApplicationInfo(): Promise<IApplicationInfo> {
        const query: string = `api/Application`;
        const response = await APIHelper.GetAsync<IApplicationInfo>(query);
        return response.Result;
    }

    public static async GetUserInfo(): Promise<IUserInfo> {
        const query: string = `api/User/Info`;
        const response = await APIHelper.GetAsync<IUserInfo>(query);
        return response.Result;
    }

    public static async GetMeasurementTypes(): Promise<WebResponse<IMeasurementType[]>> {
        const query: string = `api/Application/MeasurementTypes`;
        return await APIHelper.GetAsync<IMeasurementType[]>(query);
    }

    public static async GetPricingMethods(): Promise<WebResponse<IPricingMethod[]>> {
        const query: string = `api/Application/PricingMethods`;
        return await APIHelper.GetAsync<IPricingMethod[]>(query);
    }

    public static async GetDiscountTables(parentCustomerID: string): Promise<WebResponse<IDiscountTableGrouping[]>> {
        const query: string = `api/Application/DiscountTables`;
        const params = { parentCustomerID: parentCustomerID }
        return await APIHelper.GetAsync<IDiscountTableGrouping[]>(query, params);
    }

    public static async GetQuoteClassifications(): Promise<WebResponse<IQuoteClassification[]>> {
        const query: string = `api/Application/QuoteClassifications`;
        return await APIHelper.GetAsync<IQuoteClassification[]>(query);
    }

    public static async GetReports(app: "core" | "web", reportType: string): Promise<WebResponse<ISystemReport[]>> {
        const query: string = `api/Reports/${app === "core" ? "Orders" : "Quotes"}/List`;
        const params = {
            app: app,
            reportType: reportType,
        }
        return await APIHelper.GetAsync<ISystemReport[]>(query, params);
    }

    public static async GetOfflineSettings(): Promise<IOfflineSettings> {
        const response = await APIHelper.GetAsync<IOfflineSettings>("api/application/offline");
        return response.Result;
    }

    public static async PingOnline(): Promise<IPingResult> {
        const response = await APIHelper.GetAsync<IPingResult>("api/application/pingonline", undefined, undefined, false);
        if (response.IsOk()) {
            return response.Result;
        }
        else {
            return Promise.resolve({ authenticated: false, online: false });
        }
    }

    public static async PingTest(): Promise<IPingTest> {
        const response = await APIHelper.GetAsync<IPingTest>("api/application/pingtest", undefined, undefined, false); //, undefined, undefined, false
        if (response.IsOk()) {
            return response.Result;
        }
        else {
            return Promise.resolve({ webApiOnline: false, sessionApiOnline: false, roeApiOnline: false, apiDbTest: false, sessionApiDbTest: false, roeApiDbTest: false });
        }
    }


    public static async PutTest(): Promise<boolean> {
        const response = await APIHelper.PutAsync("api/application/puttest", undefined, undefined, false); //, undefined, undefined, false
        if (response.IsOk()) {
            return true;
        }
        else {
            return false;
        }
    }

    public static async DeleteTest(): Promise<boolean> {
        const response = await APIHelper.DeleteAsync("api/application/deletetest", undefined, undefined, false); //, undefined, undefined, false
        if (response.IsOk()) {
            return true;
        }
        else {
            return false;
        }
    }
}
