import * as React from "react";
import { Theme, alpha } from "@mui/material"
import { ISectionRegion, ICoordinate } from "../interfaces";
import { MapAreaShapeTypeEnum } from "../enum";
import ScalableImageWithCanvasOverlay, { ScalableCanvasDrawHandler, ScalableCanvasMouseEvent, ScalableCanvasTouchEvent } from "./ScalableImageWithCanvasOverlay";


interface IProps {
    src: string | undefined;
    clickableRegions: ISectionRegion[];
    clickRegionHandler?: (clickableReg: ISectionRegion) => void;
    selected: number[] | null;
    theme: Theme;
    renderAsDiv: boolean;
    imageStyle?: React.CSSProperties;
}

interface IState {
    hoveredRegion: ISectionRegion | null;
}

interface IRect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export default class ClickableImage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { hoveredRegion: null };
    }

    render(): React.ReactNode {

        return <ScalableImageWithCanvasOverlay
            src={this.props.src}
            renderAsDiv={this.props.renderAsDiv}
            imageStyle={this.props.imageStyle}
            onMouseMove={this.onMouseMove}
            onMouseLeave={this.onMouseLeave}
            onMouseDown={this.onMouseDown}
            onTouchStart={this.onTouchStart}
            drawCanvas={this.drawCanvas}
            theme={this.props.theme}

        />


    }

    private onMouseMove: ScalableCanvasMouseEvent = (e: React.MouseEvent<HTMLCanvasElement>, ctx: CanvasRenderingContext2D, canvasBounds: DOMRect, ratio: number, offsetX: number, offsetY: number) => {

        if (this.props.clickRegionHandler) {
            let hoveredRegion: ISectionRegion | null = this.getHoveredRegion(e.clientX, e.clientY, ctx, canvasBounds, ratio, offsetX, offsetY);
            this.setState({ hoveredRegion });

            if (hoveredRegion !== null) {
                return "pointer";
            }
            else {
                return "default";
            }
        }

        return null;

    };

    private onMouseLeave: ScalableCanvasMouseEvent = () => {
        if (this.props.clickRegionHandler) {
            this.setState({ hoveredRegion: null });
        }
        return null;
    };

    private onMouseDown: ScalableCanvasMouseEvent = (e: React.MouseEvent<HTMLCanvasElement>, ctx: CanvasRenderingContext2D, canvasBounds: DOMRect, ratio: number, offsetX: number, offsetY: number) => {

        const clickedRegion = this.getHoveredRegion(e.clientX, e.clientY, ctx, canvasBounds, ratio, offsetX, offsetY);
        if (clickedRegion !== null && this.props.clickRegionHandler) {
            this.props.clickRegionHandler(clickedRegion);
            return "pointer";
        }
    };

    private onTouchStart: ScalableCanvasTouchEvent = (e: React.TouchEvent<HTMLCanvasElement>, ctx: CanvasRenderingContext2D, canvasBounds: DOMRect, ratio: number, offsetX: number, offsetY: number) => {

        const touch = e.touches[0];
        const touchedRegion = this.getHoveredRegion(touch.clientX, touch.clientY, ctx, canvasBounds, ratio, offsetX, offsetY);

        if (touchedRegion !== null && this.props.clickRegionHandler) {
            this.props.clickRegionHandler(touchedRegion);
        }
    };

    private getHoveredRegion(clientX: number, clientY: number, ctx: CanvasRenderingContext2D, canvasBounds: DOMRect, ratio: number, offsetX: number, offsetY: number) {
        let hoveredRegion: ISectionRegion | null = null;
        if (ctx && canvasBounds) {
            const x = clientX - canvasBounds.left;
            const y = clientY - canvasBounds.top;
            for (const r of this.props.clickableRegions) {
                const path = ClickableImage.getRegionAsPath(r, ratio, offsetX, offsetY);
                if (ctx.isPointInPath(path, x, y)) {
                    hoveredRegion = r;
                    break;
                }
            }
        }
        return hoveredRegion;
    }

    private drawCanvas: ScalableCanvasDrawHandler = (ctx: CanvasRenderingContext2D | null, canvasBounds: DOMRect|null, ratio: number, offsetX: number, offsetY: number) => {

        if (!canvasBounds || !ctx)
            return;

        this.drawCanvasInternal(ctx, canvasBounds, this.state.hoveredRegion, ratio, offsetX, offsetY);
    }


    private drawCanvasInternal(ctx: CanvasRenderingContext2D, canvasBounds: DOMRect, hoveredRect: ISectionRegion | null, ratio: number, offsetX: number, offsetY: number) {

        const width: number = canvasBounds.width;
        const height: number = canvasBounds.height;
        ctx.clearRect(0, 0, width, height);

        if (this.props.selected && this.props.selected.length > 0) {
            if (!(this.props.selected.length === 1 && this.props.selected[0] === 0)) {

                let selectedRegions: ISectionRegion[] = [];
                if (this.props.selected !== null) {
                    const selected = this.props.selected;
                    selectedRegions = this.props.clickableRegions.filter(cr => selected.indexOf(cr.id) > -1);
                }

                ClickableImage.shadeNonSelectedRegions(ctx, selectedRegions, ratio, offsetX, offsetY, width, height, this.props.theme);
                ClickableImage.drawSelectedRegions(ctx, selectedRegions, ratio, offsetX, offsetY);
            }
        }

        // only show hovered recs if we are in a mode where we can click.
        if (this.props.clickRegionHandler !== undefined) {
            ClickableImage.drawHoveredRegion(ctx, hoveredRect, ratio, offsetX, offsetY);
        }

    }

    private static shadeNonSelectedRegions(ctx: CanvasRenderingContext2D, regions: ISectionRegion[], ratio: number, offsetX: number, offsetY: number, width: number, height: number, theme: Theme) {

        ctx.save(); //save context so we can restore after we are done with our clipping region

        // create path containing the selected sections
        const clip = new Path2D();
        for (const r of regions) {
            clip.addPath(this.getRegionAsPath(r, ratio, offsetX, offsetY));
        }

        // add rect to canvas perimeter to get evenodd clipping to invert, so we can draw everything except selected regions
        clip.rect(0, 0, width, height);
        ctx.clip(clip, "evenodd");

        //white out non-selected area
        ctx.fillStyle = alpha(theme.palette.background.default, 0.5);
        ctx.fillRect(0, 0, width, height);

        //restore so we can draw outside of clip region
        ctx.restore();
    }

    private static drawSelectedRegions(ctx: CanvasRenderingContext2D, regions: ISectionRegion[], ratio: number, offsetX: number, offsetY: number) {
        for (const r of regions) {
            if (r) {
                ctx.lineWidth = 7;
                ctx.strokeStyle = "rgba(0,0,255, 0.85)";
                ClickableImage.strokeScaledRegion(ctx, r, ratio, offsetX, offsetY);
            }
        }
    }

    private static drawHoveredRegion(ctx: CanvasRenderingContext2D, r: ISectionRegion | null, ratio: number, offsetX: number, offsetY: number) {
        if (r) {
            ctx.fillStyle = 'rgba(0, 0, 255, 0.3)';
            ctx.strokeStyle = 'pink';
            ctx.lineWidth = 4;
            this.fillScaledRegion(ctx, r, ratio, offsetX, offsetY);
        }
    }

    private static getRegionAsPath(r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        switch (r.shape) {
            case MapAreaShapeTypeEnum.Rect:
                return this.getRectRegionAsPath(r, ratio, offsetX, offsetY);
            case MapAreaShapeTypeEnum.Poly:
                return this.getPolyRegionAsPath(r, ratio, offsetX, offsetY);
            case MapAreaShapeTypeEnum.Circle:
                return this.getCircleRegionAsPath(r, ratio, offsetX, offsetY);
        }
    }

    private static getRectRegionAsPath(r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        const rect: IRect = this.getScaledRect(r, ratio, offsetX, offsetY);
        const path = new Path2D();
        path.rect(rect.x, rect.y, rect.width, rect.height);
        return path;
    }

    private static getPolyRegionAsPath(r: ISectionRegion, ratio: number, offsetX: number, offsetY) {
        // need at least at least 3 points to draw polygon 
        const path = new Path2D();
        if (r.coords.length > 2) {
            const scaledPoints: ICoordinate[] = r.coords.map(c => ({ x: (c.x * ratio) + offsetX, y: (c.y * ratio) + offsetY } as ICoordinate));
            path.moveTo(scaledPoints[0].x, scaledPoints[0].y);
            for (let i = 1; i < scaledPoints.length; i++) {
                path.lineTo(scaledPoints[i].x, scaledPoints[i].y);
            }
            path.closePath();
        }
        return path;
    }

    private static getCircleRegionAsPath(r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        const path = new Path2D();
        const xCenter: number = r.coords[0].x * ratio;
        const yCenter: number = r.coords[1].y * ratio;
        const rScaled: number = r.radius * ratio;
        path.arc(xCenter, yCenter, rScaled, 0, 2 * Math.PI, false);
        return path;
    }

    private static fillScaledRegion(ctx: CanvasRenderingContext2D, r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        ctx.beginPath();
        ctx.fill(this.getRegionAsPath(r, ratio, offsetX, offsetY));
    }

    private static strokeScaledRegion(ctx: CanvasRenderingContext2D, r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        ctx.beginPath();
        ctx.stroke(this.getRegionAsPath(r, ratio, offsetX, offsetY));
    }

    private static getScaledRect(r: ISectionRegion, ratio: number, offsetX: number, offsetY: number): IRect {
        const width: number = (r.coords[1].x - r.coords[0].x) * ratio;
        const height: number = (r.coords[1].y - r.coords[0].y) * ratio;
        const x: number = (r.coords[0].x * ratio) + offsetX;
        const y: number = (r.coords[0].y * ratio) + offsetY;
        return { x, y, width, height };
    }

 
}
