import * as React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ICADOperationEditor, ICADParameterEdit } from "../../interfaces";
import { useCADActions } from "../../Context/CADContext";
import ParameterTooltip from "./ParameterTooltip";

interface IOperationParameterBooleanProps {
    parameter: ICADParameterEdit
    PostParameterAsync: (opParam: ICADParameterEdit) => Promise<ICADOperationEditor>
    GetParameterFromLabel: (newEditor: ICADOperationEditor, label: string) => ICADParameterEdit;
    loading: boolean;
}

const OperationParameterBoolean: React.FC<IOperationParameterBooleanProps> = ({ parameter, PostParameterAsync, GetParameterFromLabel, loading }) => {

    const opParam = parameter;
    const cadActions = useCADActions();

    const [isChecked, setIsChecked] = React.useState<boolean>(opParam.booleanValue);

    const paramLabel = React.useMemo(() => {
        if (opParam.description.length > 0) {
            return `${opParam.name} - ${opParam.description}`;
        }
        else {
            return opParam.name;
        }
    }, [opParam]);

    const handleValueChange = React.useCallback(async (event: React.MouseEvent<any>) => {
        event.preventDefault();
        cadActions.setOperationEditorLoading();
        const newEditor = await PostParameterAsync({ ...opParam, booleanValue: !opParam.booleanValue });
        cadActions.setOperationEditor(newEditor);
        const newParam = GetParameterFromLabel(newEditor, opParam.name);
        if (newParam) {
            setIsChecked(newParam.booleanValue);
        }

    }, [GetParameterFromLabel, PostParameterAsync, cadActions, opParam]);

    return <ParameterTooltip parameter={opParam}>
        <FormControlLabel
            disabled={loading}
            control={
                <Checkbox
                    sx={(theme) => {
                        return {
                            padding: theme.spacing(.375),
                            marginLeft: theme.spacing(.625)
                        };
                    }}
                    size="small"
                    checked={isChecked}
                    disabled={false}
                />
            }
            label={paramLabel}
            onClick={handleValueChange}
        />
    </ParameterTooltip>

}

export default OperationParameterBoolean;