import React, { useState, useEffect } from "react";
import { DataGridPro, DataGridProProps, GridColumnVisibilityModel, GridRowModel } from "@mui/x-data-grid-pro";
import { alpha, Skeleton } from "@mui/material";
import { useDataGridLocale } from "helpers/hooks/useDataGridLocale";
import { IStatusProperties } from "./DataGridColumnGenerator";

//FUTURE: Add custom props to this for any custom functionality
interface IProps extends Omit<DataGridProProps, "rows"> {
    rows: (GridRowModel & IStatusProperties)[] | GridRowModel[] | null | undefined;
}

const CustomDataGridPro: React.FC<IProps> = ({ rows, ...rest }) => {

    const localeText = useDataGridLocale();

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

    useEffect(() => {
        //FUTURE: hide is deprecated. We should consider passing in a "hide if mobile" object in the custom props
        let initialModel: GridColumnVisibilityModel = {};
        rest.columns.forEach((c) => { initialModel[c.field] = !c.hide });
        setColumnVisibilityModel(initialModel);
    }, [rest.columns]);

    /*Set defaults for pagination behaviour.  They can be overridden by any props passed in */
    return <>
        {rows &&
            <DataGridPro
                sx={(theme) => {
                    return {
                        "& .MuiDataGrid-row": {
                            "&:nth-of-type(2n)": {
                                backgroundColor: alpha(theme.palette.secondary.light, 0.1),
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.secondary.light, 0.2),
                                },
                            },
                            "$ .Mui-selected": {
                                backgroundColor: alpha(theme.palette.secondary.dark, 0.5),
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.secondary.dark, 0.6),
                                },
                            }
                        },
                        "& .MuiDataGrid-iconButtonContainer, .MuiDataGrid-menuIcon": {
                            "& svg": {
                                fill: theme.palette.secondary.contrastText,
                            }
                        },
                        "& .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders": {
                            background: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText
                        },
                        ".MuiDataGrid-pointer": {
                            cursor: "pointer"
                        },
                    }
                }}
                pagination
                rowHeight={35}
                headerHeight={35}
                autoHeight={true}
                disableSelectionOnClick
                disableColumnSelector
                disableColumnReorder
                disableColumnPinning
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={setColumnVisibilityModel}
                rows={rows}
                style={{ minHeight: 456 }}
                localeText={{
                    ...rest.localeText,
                    ...localeText
                }}
                {...rest}

            />
        }
        {!rows && <>
            <Skeleton variant="text" height={40} />
            <Skeleton variant="rectangular" height={416} />
        </>}
    </>
};

export default CustomDataGridPro;


