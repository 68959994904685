import React from 'react';
import { IMeasurementType, IWebCustomer, IMfgCustomer, IMfgSecondaryShipTo, IEmployee, IShipVia, ITerms, ISystemReport, IPricingMethod, IDiscountTableGrouping, IQuoteClassification } from 'helpers/interfaces'
import QuoteClassifications from 'helpers/context/SelectionValues/QuoteClassifications';
import MfgCustomersAndSites from 'helpers/context/SelectionValues/MfgCustomersAndSites';
import { DispatchActionsBase } from "components/Common/DispatchActionsBase";
import QuotesAPI from "components/Quotes/QuotesAPI";
import API from "helpers/API";
import QuoteClassificationMethods from "helpers/context/SelectionValues/QuoteClassifications";
import { QuoteDataTypeEnum } from "components/Dashboard/Quotes/DashboardQuotes";
import useUserInfo from "../User/useUserInfo";
import ApiUser from "models/ApiUser";
import { RoleEnum } from "helpers/enums";
import { OrderDataTypeEnum } from "components/Dashboard/Orders/SelectOrderType";

export interface ISelectionValuesData {
    mfgCustomersAndSites?: MfgCustomersAndSites,
    mfgSecondaryShipTos: Map<string, IMfgSecondaryShipTo[]>;
    webCustomers: Map<string, IWebCustomer[]>;
    salespersons: Map<string, IEmployee[]>;
    employees: Map<number, IEmployee>;
    webCustomerNames: Map<string, IWebCustomer>,
    mfgCustomerNames: Map<string, IMfgCustomer>;
    shipVias: Map<string, IShipVia[]>;
    terms: Map<string, ITerms[]>;
    measurementTypes?: IMeasurementType[];
    pricingMethods?: IPricingMethod[];
    discountTables?: IDiscountTableGrouping[];
    quoteClassifications?: QuoteClassifications;
    reports: Map<string, ISystemReport[]>;
    dashboardQuoteType: QuoteDataTypeEnum;
    dashboardOrderType: OrderDataTypeEnum;
    maintenanceClassification: IQuoteClassification | null;
}

export enum ActionTypeEnum {
    SetMfgCustomersAndSites,
    SetMfgSecondaryShipTos,
    SetWebCustomers,
    ClearWebCustomers,
    SetSalespersons,
    SetEmployee,
    SetWebCustomerName,
    ClearWebCustomerNames,
    SetMfgCustomerName,
    SetShipVias,
    ClearShipVias,
    SetTerms,
    ClearTerms,
    SetMeasurementTypes,
    SetPricingMethods,
    SetDiscountTables,
    SetQuoteClassifications,
    SetReports,
    SetDashboardQuoteType,
    SetDashboardOrderType,
    SetMaintenanceClassification
}

export type SelectionValuesActionType =
    { type: ActionTypeEnum.SetMfgCustomersAndSites, value: MfgCustomersAndSites }
    | { type: ActionTypeEnum.SetMfgSecondaryShipTos, value: { cacheKey: string, shipTos: IMfgSecondaryShipTo[] } }
    | { type: ActionTypeEnum.SetWebCustomers, value: { cacheKey: string, webCustomers: IWebCustomer[] } }
    | { type: ActionTypeEnum.ClearWebCustomers }
    | { type: ActionTypeEnum.SetSalespersons, value: { cacheKey: string, salespersons: IEmployee[] } }
    | { type: ActionTypeEnum.SetEmployee, value: { cacheKey: number, employee: IEmployee } }
    | { type: ActionTypeEnum.SetWebCustomerName, value: { cacheKey: string, webCustomer: IWebCustomer } }
    | { type: ActionTypeEnum.ClearWebCustomerNames }
    | { type: ActionTypeEnum.SetMfgCustomerName, value: { cacheKey: string, mfgCustomer: IMfgCustomer } }
    | { type: ActionTypeEnum.SetShipVias, value: { cacheKey: string, shipVias: IShipVia[] } }
    | { type: ActionTypeEnum.ClearShipVias }
    | { type: ActionTypeEnum.SetTerms, value: { cacheKey: string, terms: ITerms[] } }
    | { type: ActionTypeEnum.ClearTerms }
    | { type: ActionTypeEnum.SetMeasurementTypes, value: IMeasurementType[] }
    | { type: ActionTypeEnum.SetPricingMethods, value: IPricingMethod[] }
    | { type: ActionTypeEnum.SetDiscountTables, value: IDiscountTableGrouping[] }
    | { type: ActionTypeEnum.SetQuoteClassifications, value: QuoteClassifications }
    | { type: ActionTypeEnum.SetReports, value: { reportType: string, reports: ISystemReport[] } }
    | { type: ActionTypeEnum.SetDashboardQuoteType, value: QuoteDataTypeEnum }
    | { type: ActionTypeEnum.SetDashboardOrderType, value: OrderDataTypeEnum }
    | { type: ActionTypeEnum.SetMaintenanceClassification, value: IQuoteClassification | null }
    ;

const selectionValuesReducer: React.Reducer<ISelectionValuesData, SelectionValuesActionType> = (data: ISelectionValuesData, action) => {

    switch (action.type) {
        case ActionTypeEnum.SetMfgCustomersAndSites:
            return { ...data, mfgCustomersAndSites: action.value };
        case ActionTypeEnum.SetMfgSecondaryShipTos:
            return { ...data, mfgSecondaryShipTos: new Map<string, IMfgSecondaryShipTo[]>([...data.mfgSecondaryShipTos, [action.value.cacheKey, action.value.shipTos]]) };
        case ActionTypeEnum.SetWebCustomers:
            return { ...data, webCustomers: new Map<string, IWebCustomer[]>([...data.webCustomers, [action.value.cacheKey, action.value.webCustomers]]) };
        case ActionTypeEnum.ClearWebCustomers:
            return { ...data, webCustomers: new Map<string, IWebCustomer[]>() };
        case ActionTypeEnum.SetSalespersons:
            return { ...data, salespersons: new Map<string, IEmployee[]>([...data.salespersons, [action.value.cacheKey, action.value.salespersons]]) };
        case ActionTypeEnum.SetEmployee:
            return { ...data, employees: new Map<number, IEmployee>([...data.employees, [action.value.cacheKey, action.value.employee]]) };
        case ActionTypeEnum.SetWebCustomerName:
            return { ...data, webCustomerNames: new Map<string, IWebCustomer>([...data.webCustomerNames, [action.value.cacheKey, action.value.webCustomer]]) };
        case ActionTypeEnum.ClearWebCustomerNames:
            return { ...data, webCustomerNames: new Map<string, IWebCustomer>() };
        case ActionTypeEnum.SetMfgCustomerName:
            return { ...data, mfgCustomerNames: new Map<string, IMfgCustomer>([...data.mfgCustomerNames, [action.value.cacheKey, action.value.mfgCustomer]]) };
        case ActionTypeEnum.SetShipVias:
            return { ...data, shipVias: new Map<string, IShipVia[]>([...data.shipVias, [action.value.cacheKey, action.value.shipVias]]) };
        case ActionTypeEnum.ClearShipVias:
            return { ...data, shipVias: new Map<string, IShipVia[]>() };
        case ActionTypeEnum.SetTerms:
            return { ...data, terms: new Map<string, ITerms[]>([...data.terms, [action.value.cacheKey, action.value.terms]]) };
        case ActionTypeEnum.ClearTerms:
            return { ...data, terms: new Map<string, ITerms[]>() };
        case ActionTypeEnum.SetMeasurementTypes:
            return { ...data, measurementTypes: action.value };
        case ActionTypeEnum.SetPricingMethods:
            return { ...data, pricingMethods: action.value };
        case ActionTypeEnum.SetDiscountTables:
            return { ...data, discountTables: action.value };
        case ActionTypeEnum.SetQuoteClassifications:
            return { ...data, quoteClassifications: action.value };
        case ActionTypeEnum.SetReports:
            return { ...data, reports: new Map<string, ISystemReport[]>([...data.reports, [action.value.reportType, action.value.reports]]) };
        case ActionTypeEnum.SetDashboardQuoteType:
            return { ...data, dashboardQuoteType: action.value };
        case ActionTypeEnum.SetDashboardOrderType:
            return { ...data, dashboardOrderType: action.value };
        case ActionTypeEnum.SetMaintenanceClassification:
            return { ...data, maintenanceClassification: action.value }
    }

}

const initialState: ISelectionValuesData = {
    mfgSecondaryShipTos: new Map<string, IMfgSecondaryShipTo[]>(),
    webCustomers: new Map<string, IWebCustomer[]>(),
    salespersons: new Map<string, IEmployee[]>(),
    employees: new Map<number, IEmployee>(),
    webCustomerNames: new Map<string, IWebCustomer>(),
    mfgCustomerNames: new Map<string, IMfgCustomer>(),
    shipVias: new Map<string, IShipVia[]>(),
    terms: new Map<string, ITerms[]>(),
    reports: new Map<string, ISystemReport[]>(),
    dashboardQuoteType: QuoteDataTypeEnum.CompanyQuotes,
    dashboardOrderType: OrderDataTypeEnum.Orders,
    maintenanceClassification: null
}

export class SelectionValuesActions extends DispatchActionsBase<SelectionValuesActionType> {

    #user: ApiUser;

    constructor(dispatcher: React.Dispatch<SelectionValuesActionType>, user: ApiUser) {
        super(dispatcher);
        this.#user = user;
        this.SetDashboardQuoteType(user.HasRole(RoleEnum.Contractor) ? QuoteDataTypeEnum.CustomerQuotes : QuoteDataTypeEnum.CompanyQuotes);
    }

    public async LoadMeasurementTypesAsync() {
        const response = await API.GetMeasurementTypes();
        const measurementTypes = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetMeasurementTypes, value: measurementTypes });
        }
    }

    public async LoadPricingMethodsAsync() {
        const response = await API.GetPricingMethods();
        const pricingMethods = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetPricingMethods, value: pricingMethods });
        }
    }

    public async LoadEmployeeAsync(employeeID: number) {
        const response = await QuotesAPI.GetEmployee(employeeID);
        let employee = response.Result;
        if (employee === undefined) {
            employee = { employeeID: employeeID, fullName: "", inactive: false };
        }

        this.dispatch({ type: ActionTypeEnum.SetEmployee, value: { cacheKey: employeeID, employee: employee } });
    }

    public async LoadMfgCustomerAsync(mfgCustomerID: string, mfgSiteID: string, cacheKey: string) {
        const response = await QuotesAPI.GetMfgCustomer(mfgCustomerID, mfgSiteID);
        const mfgCustomer = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetMfgCustomerName, value: { cacheKey: cacheKey, mfgCustomer: mfgCustomer } });
        }
    }

    public async LoadMfgCustomersAndSitesAsync() {
        const response = await QuotesAPI.GetMfgCustomerList();
        const mfgCustomerList = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetMfgCustomersAndSites, value: new MfgCustomersAndSites(mfgCustomerList) });
        }
    }

    public async LoadMfgSecondaryShipTosAsync(mfgCustomerID: string, mfgSiteID: string, cacheKey: string) {
        const response = await QuotesAPI.GetMfgSecondaryShipTos(mfgCustomerID, mfgSiteID);
        const secondaryShipTos = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetMfgSecondaryShipTos, value: { cacheKey: cacheKey, shipTos: secondaryShipTos } });
        }
    }

    public async LoadQuoteClassificationsAsync() {
        const response = await API.GetQuoteClassifications();
        const quoteClassifications = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetQuoteClassifications, value: new QuoteClassificationMethods(quoteClassifications) });
        }
    }

    public async LoadReportsAsync(app: "core" | "web", reportType: string) {
        const response = await API.GetReports(app, reportType);
        const reports = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetReports, value: { reportType: reportType, reports: reports } });
        }
    }

    public async LoadSalespersonsAsync(parentCustomerID: string, parentSiteID: string, cacheKey: string) {
        const response = await QuotesAPI.GetSalespersons(parentCustomerID, parentSiteID);
        const salesPersons = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetSalespersons, value: { cacheKey: cacheKey, salespersons: salesPersons } });
        }
    }

    public async LoadShipViasAsync(mfgCustomerID: string, cacheKey: string) {
        const response = await QuotesAPI.GetShipVia(mfgCustomerID);
        const shipVias = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetShipVias, value: { cacheKey: cacheKey, shipVias: shipVias } });
        }
    }

    public async LoadTermsAsync(mfgCustomerID: string, cacheKey: string) {
        const response = await QuotesAPI.GetTerms(mfgCustomerID);
        const terms = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetTerms, value: { cacheKey: cacheKey, terms: terms } });
        }
    }

    public async LoadWebCustomerAsync(webCustomerID: string, cacheKey: string) {
        const response = await QuotesAPI.GetWebCustomer(webCustomerID);
        const webCustomer = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetWebCustomerName, value: { cacheKey: cacheKey, webCustomer: webCustomer } });
        }
    }

    public async LoadWebCustomerListAsync(parentCustomerID: string, parentSiteID: string, cacheKey: string) {
        const response = await QuotesAPI.GetWebCustomerList(parentCustomerID, parentSiteID, true);
        const webCustomers = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetWebCustomers, value: { cacheKey: cacheKey, webCustomers: webCustomers } });
        }
    }

    public async LoadContractorCustomerListAsync(parentCustomerID: string, cacheKey: string) {
        const response = await QuotesAPI.GetWebContractorCustomerList(parentCustomerID, true);
        const webCustomers = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetWebCustomers, value: { cacheKey: cacheKey, webCustomers: webCustomers } });
        }
    }

    public async LoadDiscountTablesAsync(parentCustomerID: string) {
        const response = await API.GetDiscountTables(parentCustomerID);
        const dt = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: ActionTypeEnum.SetDiscountTables, value: dt });
        }

    }

    public SetDashboardQuoteType(quoteType: QuoteDataTypeEnum) {
        this.dispatch({ type: ActionTypeEnum.SetDashboardQuoteType, value: quoteType });
    }

    public SetDashboardOrderType(orderType: OrderDataTypeEnum) {
        this.dispatch({ type: ActionTypeEnum.SetDashboardOrderType, value: orderType });
    }

    public SetMaintenanceClassification(classification: IQuoteClassification) {
        this.dispatch({ type: ActionTypeEnum.SetMaintenanceClassification, value: classification })
    }

    public ClearCustomersCache() {
        this.dispatch({ type: ActionTypeEnum.ClearWebCustomers });
        this.dispatch({ type: ActionTypeEnum.ClearWebCustomerNames });
    }

    public ClearTermsCache() {
        this.dispatch({ type: ActionTypeEnum.ClearTerms });
    }

    public ClearShipViaCache() {
        this.dispatch({ type: ActionTypeEnum.ClearShipVias })
    }

}

export const SelectionValuesContextDispatch = React.createContext<SelectionValuesActions | undefined>(undefined);
const SelectionValuesContext = React.createContext<ISelectionValuesData>(initialState);

export const SelectionValuesContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(selectionValuesReducer, initialState);
    const user = useUserInfo();

    const actions = React.useMemo<SelectionValuesActions>(() => {
        return new SelectionValuesActions(dispatch, user);
    }, [dispatch, user]);

    return <SelectionValuesContext.Provider value={state}>
        <SelectionValuesContextDispatch.Provider value={actions}>
            {props.children}
        </SelectionValuesContextDispatch.Provider>
    </SelectionValuesContext.Provider>
}

export default SelectionValuesContext;





