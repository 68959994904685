import { useContext } from "react";
import OrderAttachmentsContext, { IOrderAttachments } from "./OrderAttachmentsContext";

export function useOrderAttachments(): IOrderAttachments {

    const orderAttachments = useContext(OrderAttachmentsContext);
    return orderAttachments;
};

export default useOrderAttachments;
