import { TabContext, TabPanel } from "@mui/lab";
import React, { useCallback } from 'react';
import { EdgeAdjustmentsEnum } from "../enums";
import CustomTabList from "components/Common/CustomTabList";
import { Tab } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import { CADHelpers } from "../CADHelpers";
import CADOperationComponent from "./CADOperationComponent";
import { useCADActions, useCADState } from "../Context/CADContext";
import EdgeAdjustmentsComponent from "./EdgeTools/EdgeAdjustmentsComponent";
import useCADAvailableOperations from "../hooks/useCADAvailableOperations";
import { ICADLayer } from "../interfaces";

interface ICADOperationsTabsProps {

}

const CADOperationsTabs: React.FC<ICADOperationsTabsProps> = (props: ICADOperationsTabsProps) => {

    const availableOperationTypes = useCADAvailableOperations();
    const cadActions = useCADActions();

    const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
        cadActions.setCurrentTab(newValue);
        cadActions.setOperationEditor(null);
        cadActions.setHoveredOperation(null);
        cadActions.setActiveEdgeAdjustment(null);
    }, [cadActions]);

    const tm = useTranslations();
    const cadState = useCADState();

    if (!cadState.model || cadState.model.layers.length === 0) return null;

    const layer: ICADLayer = cadState.model.layers[0];
    const enabledTools = layer.edgeAdjustmentTools ?? EdgeAdjustmentsEnum.None;

    return <TabContext value={cadState.currentTab} >

        <CustomTabList variant="scrollable" scrollButtons="auto" onChange={handleTabChange} sx={{ position: "sticky", top: "40px", zIndex: 40, maxWidth: "1000px" }}    >

            {availableOperationTypes.map((opType, index) => {

                const description = tm.Get(CADHelpers.OperationTypeDescriptionFromEnum(opType));

                return <Tab key={`TBL_${index}_${opType.toString()}`}
                    value={opType.toString()}
                    label={description}
                />

            })}

            {enabledTools > 0 &&
                <Tab key={`TBL_-1_EdgeAdjustments`} value="EdgeAdjustments" label={tm.Get("Edge Adjustments")} />
            }

        </CustomTabList>

        {availableOperationTypes.map((opType, index) => {
            return <TabPanel key={`TBPL_${index}_${opType.toString()}`} value={opType.toString()} sx={{ padding: 0, marginTop: 1 }}               >
                <CADOperationComponent operationType={opType} />
            </TabPanel>
        })}

        {enabledTools > 0 &&
            <TabPanel key={`TBPL_-1_EdgeAdjustments`} value="EdgeAdjustments" sx={{ padding: 0, marginTop: 1 }} >
                <EdgeAdjustmentsComponent enabledAdjustments={enabledTools} layer={layer} />
            </TabPanel>
        }

    </TabContext>

}

export default CADOperationsTabs;