import { Container } from "@mui/material";
import { IBaseContent, useTranslations } from "@fenetech/translations";
import React from 'react';
import AttachmentsTable from "./QuoteAttachmentTable";
import { IFileUpload } from "models/IAttachment";
import useQuoteData from "components/Quotes/useQuoteData";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { IActionFileDownloadButton, IActionFileUploadButton } from "helpers/context/Page/PageContext";
import { RoleEnum, ThemeColorEnum } from "helpers/enums";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import { useNavigate } from "react-router-dom";
import useQuoteAttachments from "./useQuoteAttachments";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { CaseInsensitiveCompare } from "helpers/objects";
import useQuoteAttachmentActions from "./useQuoteAttachmentActions";
import useUserInfo from "helpers/context/User/useUserInfo";
import useFileDownloadWithFeedback from "helpers/hooks/useFileDownloadWithParams";

const QuoteAttachments: React.FC = () => {

    const tm = useTranslations();
    const user = useUserInfo();
    const navigate = useNavigate();
    const messageBox = useMessageBox();
    const actionButtons = useActionButtons();
    const { quote, lineItems, permissions } = useQuoteData();
    const { attachments, isLoading } = useQuoteAttachments();
    const attachmentActions = useQuoteAttachmentActions();

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const preDownload = React.useCallback(() => {
        attachmentActions.SetIsLoading(true);
    }, [attachmentActions]);

    const postDownload = React.useCallback(() => {
        attachmentActions.SetIsLoading(false);
    }, [attachmentActions]);

    const onFileDownloadError = React.useCallback((baseContent: IBaseContent) => {
        attachmentActions.SetAlert({ text: tm.GetWithParams(baseContent.message, ...baseContent.params ?? []), alertType: ThemeColorEnum.Error, visible: true})
    }, [attachmentActions, tm]);

    const { download, ref } = useFileDownloadWithFeedback({preDownload, postDownload, onError: onFileDownloadError});

    const title = React.useMemo(() => {
        if (quote) {
            return (`${tm.Get("Attachments")} - #${quote.orderNumber ?? ""}`);
        }
        return "";
    }, [quote, tm]);

    const isContractor = React.useMemo(() => {
        return user.HasRole(RoleEnum.Contractor)
    }, [user])

    const isDealer = React.useMemo(() => {
        return user.HasRole(RoleEnum.Dealer)
    }, [user])

    const editEnabled = React.useMemo(() => {
        if (quote && permissions) {
            if (isContractor) {
                if (quote.isContractorCreatedQuote) {
                    if (quote.isSubmitted && quote.contractorOrderNumber) {
                        return false;
                    }
                    return permissions.editQuote;
                }
                return false;
            } else if (isDealer) {
                if (quote.isContractorCreatedQuote) {
                    if (quote.isSubmitted) {
                        return permissions.editQuote;
                    }
                    return false;
                }
                return permissions.editQuote;
            } else {
                //CSR
                return true;
            }
        }
        return false;
    }, [quote, permissions, isContractor, isDealer]);

    const readFileData = React.useCallback((file: File) => {
        return new Promise<IFileUpload>((resolve, reject) => {
            let reader = new FileReader();
            reader.onerror = reject;
            reader.onload = function () {
                if (reader.DONE && typeof reader.result === "string") {
                    resolve({ fileName: file.name, fileDataBase64: reader.result.split(',')[1] });
                } else {
                    reject();
                }
            }
            reader.readAsDataURL(file)
        });
    }, []);

    const uploadAttachments = React.useCallback(async (fileUploads: IFileUpload[]) => {
        const fileMatches = fileUploads.filter(f => attachments.some(a => CaseInsensitiveCompare(a.fileName.trim(), f.fileName.trim()) && a.odKey === 0));

        if (fileMatches.length === 0) {
            await attachmentActions.InsertQuoteAttachmentsAsync(fileUploads, false);
        } else {
            let dialogMessage = tm.Get("The following files already exist. Overwrite them?");
            dialogMessage += "\n\n" + fileMatches.map(f => f.fileName).join("\n");
    
            const callback = async (dialogResult: boolean | undefined) => {
                if (dialogResult) {
                    await attachmentActions.InsertQuoteAttachmentsAsync(fileUploads, true);
                }
            }
            messageBox.Show({ yesNoPrompt: true, message: dialogMessage, callback })
        }
    }, [tm, messageBox, attachments, attachmentActions]);

    const handleFileSelect = React.useCallback(() => {
        if (!fileInputRef.current?.files) return;
        let files = fileInputRef.current.files;
        Promise.all(Array.from(files).map(readFileData)).then((uploadFiles) => {
            uploadAttachments(uploadFiles);
        });
        fileInputRef.current.value = "";
    }, [readFileData, fileInputRef, uploadAttachments]);

    const handleAttachmentMove = React.useCallback((qaKey: number, odKey: number, fileName: string) => {
        if (attachments.some(a => CaseInsensitiveCompare(a.fileName.trim(), fileName.trim()) && a.odKey === odKey)) {
            let dialogMessage = tm.Get("The following files already exist. Overwrite them?");
            dialogMessage += "\n\n" + fileName;
    
            const callback = async (dialogResult: boolean | undefined) => {
                if (dialogResult) {
                    await attachmentActions.MoveAttachmentAsync(qaKey, odKey, true);
                }
            }
            messageBox.Show({ yesNoPrompt: true, message: dialogMessage, callback })
        } else {
            attachmentActions.MoveAttachmentAsync(qaKey, odKey, false);
        }
    }, [tm, attachments, messageBox, attachmentActions]);

    useWindowTitle(title);

    React.useEffect(() => {
        actionButtons.Clear();

        if (quote && quote.oKey) {
            actionButtons.SetBackButton(0, QuoteNavigation.QuoteEntryURL(quote.oKey ?? 0))

            const downloadAllButton: IActionFileDownloadButton = {
                text: tm.Get("Download All"),
                download: download,
                params: {oKey: quote.oKey},
                color: ThemeColorEnum.Primary,
                disabled: isLoading || (!attachments || attachments.length === 0),
                href: "api/QuoteAttachments/List/Download",
            }

            actionButtons.Set(1, downloadAllButton);

            const addButton: IActionFileUploadButton = {
                text: tm.Get("Add File"),
                color: ThemeColorEnum.Primary,
                disabled: isLoading || !editEnabled,
                fileRef: fileInputRef,
                onClick: handleFileSelect,
            };
            actionButtons.Set(2, addButton);
        }

    }, [quote, actionButtons, tm, attachments, isLoading, editEnabled, handleFileSelect, navigate]);

    if (!quote) {
        return null;
    }

    return <Container maxWidth="xl" sx={{marginTop: 2}}>
        <AttachmentsTable
            attachments={attachments}
            lineItems={lineItems}
            moveAttachment={handleAttachmentMove}
            deleteAttachment={async (qaKey: number) => { await attachmentActions.DeleteQuoteAttachmentAsync(qaKey) }}
            isContractor={isContractor}
            isEditEnabled={editEnabled}
            isLoading={isLoading}
        />

        {/* Hidden link to host file downloads */}
        <a hidden ref={ref} />
    </Container>
}

export default QuoteAttachments;