import React, { useCallback } from 'react';
import { CADOperationTypeEnum } from "../enums";
import { useTranslations } from "@fenetech/translations";
import { useCADActions, useCADState } from "../Context/CADContext";
import { ICADOperation } from "../interfaces";
import CADOperationCommands from "./CADOperationCommands";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import CADItemAPI from "../API/CADItemAPI";
import { CADActions } from "../Context/CADActions";
import AlertDialog from "components/Common/AlertDialog";
import CADTable, { ITableColumn } from "./CADTable";
import CADOperationEditorAPI from "../API/CADOperationEditorAPI";
import APIHelper from "helpers/APIHelper";
import CADOperationAddEdit from "./OperationEditor/CADOperationAddEdit";
import { useCADImageBackgroundColor } from "../hooks/useCADImageBackgroundColor";

interface ICADOperationComponentProps {
    operationType: CADOperationTypeEnum;
}

const handleOperationDelete = (operation: ICADOperation | null, cadActions: CADActions) => {
    if (operation) {
        CADItemAPI.DeleteItemOperation(operation.uniqueID).then((response) => {
            if (response.IsOk()) {
                cadActions.setModel(response.Result);
                cadActions.setHoveredOperation(null);
                cadActions.setOperationEditor(null);
            }
        });
    }
}

const CADOperationComponent: React.FC<ICADOperationComponentProps> = (props: ICADOperationComponentProps) => {

    const cadActions = useCADActions();
    const cadState = useCADState();
    const tm = useTranslations();
    const imageBackground = useCADImageBackgroundColor();

    const operations = React.useMemo(() => {
        return cadState.model?.operations.sort(s => s.uniqueID).filter(o => o.operationType === props.operationType) ?? [];
    }, [cadState.model?.operations, props.operationType]);

    const [deleteDialogInfo, setDeleteDialogInfo] = React.useState<[showDialog: boolean, operation: ICADOperation | null]>([false, null]);
    const [showDeleteDialog, deleteDialogOperation] = deleteDialogInfo;

    const handleDeleteCloseDialog = useCallback((result: boolean, item: ICADOperation | null) => {

        if (result) {
            handleOperationDelete(item, cadActions);
        }

        setDeleteDialogInfo([false, null]);
    }, [cadActions]);

    const onRowClick = (row: ICADOperation) => {
        cadActions.setHoveredOperation(row.uniqueID);
    }

    const onCopyClick = useCallback((item: ICADOperation) => {
        cadActions.setHoveredOperation(null);
        CADOperationEditorAPI.InitForCopyAsync(item.uniqueID).then((editor) => {
            cadActions.setOperationEditor(editor);
        });
    }, [cadActions]);

    const onDeleteClick = useCallback((item: ICADOperation) => setDeleteDialogInfo([true, item]), []);

    const onEditClick = useCallback((item: ICADOperation) => {
        CADOperationEditorAPI.InitExistingAsync(item.uniqueID).then((editor) => {
            cadActions.setOperationEditor(editor);
        });
    }, [cadActions]);

    const columns: ITableColumn<ICADOperation>[] = [
        {
            fieldName: "",
            headerText: tm.Get("Operation"),
            width: 100,
            render: (value: any, row: ICADOperation) => {
                const imageSource: string = row.imageExists ? APIHelper.GetBaseURL() + `api/cad/operations/${row.operationID}/image` : "";

                if (row.imageExists) {
                    return <Grid container p={.5} >
                        <img src={imageSource} alt={row.label} width={100} height={100} style={{ backgroundColor: imageBackground }} />
                    </Grid>;
                } else {
                    return <></>;
                }

            }
        }, {
            fieldName: "label",
            width: 250,
            render: (value: any, row: ICADOperation) => {
                return <>{value + " - " + row.description}</>;
            }
        },
        {
            fieldName: "detail",
            headerText: tm.Get("Details"),
        },
        {
            fieldName: "uniqueID",
            headerText: "",
            width: 100,
            render: (value: any, row: ICADOperation) => {

                const isAllEdgeProcessing:boolean = (row.operationType === CADOperationTypeEnum.EdgeProcessing && row.edgeName === "0");

                return <>
                    <CADOperationCommands item={row}
                        events=
                        {{
                            onDeleteClick: onDeleteClick,
                            onCopyClick: isAllEdgeProcessing? undefined : onCopyClick,
                            onEditClick: onEditClick,
                        }}
                    />

                </>;
            }
        }
    ]

    const getRowClassName = (item: ICADOperation) => {
        if (item.uniqueID === cadState.hoveredOperationID) {
            return "fv-selected";
        }
        return "fv-pointer";
    }

    return <Stack spacing={1} mr={0}>

        <CADTable
            columns={columns}
            data={operations}
            onRowClick={onRowClick}
            getRowClassName={getRowClassName}
        />

        <CADOperationAddEdit operationType={props.operationType} />

        <AlertDialog
            open={showDeleteDialog}
            handleClose={(result) => handleDeleteCloseDialog(result, deleteDialogOperation)}
            headerText={tm.Get("Delete Operation")}
            message={tm.GetWithParams("Are you sure you want to delete the operation {0}?", deleteDialogOperation?.label ?? "")} />

    </Stack>;

}

export default CADOperationComponent;


