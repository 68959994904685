import APIHelper from "helpers/APIHelper";
import React from 'react';
import { useCADState } from "../../Context/CADContext";
import LoadingOverlay from "components/OptionsWizard/GenericComponents/LoadingOverlay";
import useImageLoader from "helpers/hooks/useImageLoader";
import CADItemAPI from "../../API/CADItemAPI";
import ScalableImage from "components/OptionsWizard/GenericComponents/ScalableImage";
import useOperationEditorHash from "../../Context/useOperationEditorHash";
import AutoScaleToWidthDiv from "components/OptionsWizard/GenericComponents/AutoScaleToWidthDiv";

interface ICADImageProps {
    imageStyle?: React.CSSProperties;
}

const OperationPreviewImage: React.FC<ICADImageProps> = (props: ICADImageProps) => {

    const cad = useCADState();
    const editorHash = useOperationEditorHash(cad.operationEditor);

    const url: string = APIHelper.PrependBaseURL(`api/cad/operationeditor/preview?hash=${editorHash}`);

    const [loading, loadedImageUrl] = useImageLoader(url);


    React.useEffect(() => {
        if (loadedImageUrl) {
            CADItemAPI.GetItemDrawingInfoAsync();
        }
    }, [loadedImageUrl]);

    if (editorHash) {

        return <>
            <AutoScaleToWidthDiv>

                <LoadingOverlay loading={loading}>
                    {loadedImageUrl ?
                        <ScalableImage
                            src={loadedImageUrl}
                            renderAsDiv={false}
                            imageStyle={undefined}
                        />
                        :
                        <div style={props.imageStyle} />
                    }

                </LoadingOverlay>
            </AutoScaleToWidthDiv>
        </>

    } else {
        return <></>;
    }

}

export default OperationPreviewImage;