import { Button, Grid, Divider, Typography } from "@mui/material";
import { EdgeAdjustmentsEnum } from "../../enums";
import EdgeShiftIcon from "./EdgeShiftIcon";
import EdgeRakeIcon from "./EdgeRakeIcon";
import EdgeElbowIcon from "./EdgeElbowIcon";

interface IEdgeAdjustmentItemDisplayProps {
    description: string;
    adjustmentType: EdgeAdjustmentsEnum;
    handleAdjustmentClick: (adjustmentType: EdgeAdjustmentsEnum) => void;
}

const AddEdgeAdjustmentItemDisplay: React.FC<IEdgeAdjustmentItemDisplayProps> = (props: IEdgeAdjustmentItemDisplayProps) => {

    return <>
        <Button sx={{ width: 203, height: 300, textTransform: "none" }} onClick={() => props.handleAdjustmentClick(props.adjustmentType)} >
            <Grid item border={1} borderColor={"lightgray"} height={"100%"}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"space-between"} >
                    <Grid container width={195} height={195} marginTop={.25} marginBottom={1} justifyContent={"center"} alignContent={"center"} >

                        {props.adjustmentType === EdgeAdjustmentsEnum.Shift &&
                            <EdgeShiftIcon style={{ fontSize: 100 }} />
                        }

                        {props.adjustmentType === EdgeAdjustmentsEnum.Rake &&
                            <EdgeRakeIcon style={{ fontSize: 100 }} />
                        }

                        {props.adjustmentType === EdgeAdjustmentsEnum.Elbow &&
                            <EdgeElbowIcon style={{ fontSize: 100 }} />
                        }

                    </Grid>
                    <Divider variant="middle" flexItem sx={{ bgcolor: "black", opacity: .25 }} />
                    <Typography variant={"body2"} align="center">
                        {props.description}
                    </Typography>
                </Grid>
            </Grid>
        </Button >
    </>;
}

export default AddEdgeAdjustmentItemDisplay;