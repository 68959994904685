import * as React from "react";
import { ICADOperationEditor, ICADParameterEdit } from "../../interfaces";
import { ParameterTypeEnum } from "../../enums";
import OperationParameterNumeric from "./OperationParameterNumeric";
import OperationParameterBoolean from "./OperationParameterBoolean";
import OperationParameterText from "./OperationParameterText";
import OperationParameterList from "./OperationParameterList";

interface IOPCSelectorProps {
    opParam: ICADParameterEdit;
    PostParameterAsync: (opParam: ICADParameterEdit) => Promise<ICADOperationEditor>;
    GetParameterFromLabel: (newEditor: ICADOperationEditor, label: string) => ICADParameterEdit;
    loading: boolean;
}

const OperationParameterComponentSelector: React.FC<IOPCSelectorProps> = (props: IOPCSelectorProps) => {

    const opParam = props.opParam;

    const paramComponent = React.useMemo(() => {

        if (opParam.visible) {

            switch (opParam.parameterType) {
                case ParameterTypeEnum.Numeric:
                    return <OperationParameterNumeric parameter={opParam} PostParameterAsync={props.PostParameterAsync} GetParameterFromLabel={props.GetParameterFromLabel} loading={props.loading} />
                case ParameterTypeEnum.Boolean:
                    return <OperationParameterBoolean parameter={opParam} PostParameterAsync={props.PostParameterAsync} GetParameterFromLabel={props.GetParameterFromLabel} loading={props.loading} />
                case ParameterTypeEnum.List:
                    return <OperationParameterList parameter={opParam} PostParameterAsync={props.PostParameterAsync} GetParameterFromLabel={props.GetParameterFromLabel} loading={props.loading} />
                case ParameterTypeEnum.Text:
                    return <OperationParameterText parameter={opParam} PostParameterAsync={props.PostParameterAsync} GetParameterFromLabel={props.GetParameterFromLabel} loading={props.loading} />
            }
        }

        return <></>;
    }, [opParam, props.PostParameterAsync, props.GetParameterFromLabel, props.loading]);

    return paramComponent
}

export default OperationParameterComponentSelector;