import * as React from "react";
import { Button, Grid, TextField } from "@mui/material";

import { ICADOperationProperties } from "../../interfaces";
import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";
import { useCADActions } from "../../Context/CADContext";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import { useTranslations } from "@fenetech/translations";
import FlipRotateTools from "./FlipRotateTools";
import { East, North, South, West } from "@mui/icons-material";

interface IOperationParametersProps {
    operationEdit?: ICADOperationProperties,
    loading: boolean,
}

interface IInputBuffer {
    d: string,
    x: string,
    y: string,
    a: string,
    xPositive: boolean,
    yPositive: boolean,
    rotationAngle: string,
}

const OperationProperties: React.FC<IOperationParametersProps> = (props: IOperationParametersProps) => {

    const op = props.operationEdit;
    const cadActions = useCADActions();
    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const defaultInputBuffer: IInputBuffer = {
        d: op?.d ?? '',
        x: op?.x ?? '',
        y: op?.y ?? '',
        a: op?.a ?? '',
        xPositive: op?.xPositive ?? true,
        yPositive: op?.yPositive ?? true,
        rotationAngle: op?.rotationAngle ?? ''
    };

    const [inputBuffer, setInputBuffer] = React.useState<IInputBuffer>(defaultInputBuffer);

    React.useEffect(() => {
        //Updating the edge position may cause xPositive/yPositive to change depending on the new corner being measure from.
        if (inputBuffer.xPositive !== op?.xPositive || inputBuffer.yPositive !== op?.yPositive) {
            setInputBuffer({ ...inputBuffer, xPositive: op?.xPositive ?? true, yPositive: op?.yPositive ?? true });
        }
    }, [op?.xPositive, op?.yPositive, inputBuffer.xPositive, inputBuffer.yPositive, inputBuffer, setInputBuffer]);

    const handleValueChange = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, parameterName: keyof ICADOperationProperties) => {
        const newValue = event.target.value;

        if (op && newValue !== op[parameterName]) {
            cadActions.setOperationEditorLoading();
            const newEditor = await CADOperationEditorAPI.PostPropertiesAsync({ ...op, [parameterName]: newValue });
            cadActions.setOperationEditor(newEditor);
            setInputBuffer({ d: newEditor.operation.d, x: newEditor.operation.x, y: newEditor.operation.y, a: newEditor.operation.a, rotationAngle: newEditor.operation.rotationAngle, xPositive: newEditor.operation.xPositive, yPositive: newEditor.operation.yPositive });
        }

    }, [cadActions, op]);

    const toolsVisible = React.useMemo(() => {
        return op?.flipHorizontalVisible || op?.flipVerticalVisible || op?.rotationAngleVisible;
    }, [op]);

    const handleXYSignChange = React.useCallback(async (parameterName: 'xPositive' | 'yPositive') => {
        if (op) {
            const newValue = !inputBuffer[parameterName];
            cadActions.setOperationEditorLoading();
            const newEditor = await CADOperationEditorAPI.PostPropertiesAsync({ ...op, [parameterName]: newValue });
            cadActions.setOperationEditor(newEditor);
            setInputBuffer({ d: newEditor.operation.d, x: newEditor.operation.x, y: newEditor.operation.y, a: newEditor.operation.a, rotationAngle: newEditor.operation.rotationAngle, xPositive: newEditor.operation.xPositive, yPositive: newEditor.operation.yPositive });
        }
    }, [cadActions, inputBuffer, op]);

    const handleRotationChange = React.useCallback(async (rotationDelta: number) => {
        if (op) {
            const currentRotation = lnf.Parse(op.rotationAngle);
            if (!isNaN(currentRotation)) {
                cadActions.setOperationEditorLoading();
                const newRotation = (currentRotation + rotationDelta + 360) % 360;
                const newEditor = await CADOperationEditorAPI.PostPropertiesAsync({ ...op, rotationAngle: newRotation.toString() });
                cadActions.setOperationEditor(newEditor);
                setInputBuffer({ d: newEditor.operation.d, x: newEditor.operation.x, y: newEditor.operation.y, a: newEditor.operation.a, rotationAngle: newEditor.operation.rotationAngle, xPositive: newEditor.operation.xPositive, yPositive: newEditor.operation.yPositive });
            }
        }
    }, [cadActions, op, lnf]);

    return <Grid container direction="column" spacing={1}>

        {op?.dVisible &&
            <Grid container item>
                <TextField
                    disabled={props.loading}
                    label={tm.Get("D")}
                    placeholder={tm.Get("D")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={inputBuffer.d}
                    onChange={(e) => setInputBuffer({ ...inputBuffer, d: e.target.value })}
                    onBlur={(e) => handleValueChange(e, 'd')}
                    error={op.dErrorText ? true : false}
                    helperText={op.dErrorText}
                />
            </Grid>
        }

        {op?.xVisible &&
            <Grid container item>
                <TextField
                    disabled={props.loading}
                    label={tm.Get("X")}
                    placeholder={tm.Get("X")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={inputBuffer.x}
                    onChange={(e) => setInputBuffer({ ...inputBuffer, x: e.target.value })}
                    onBlur={(e) => handleValueChange(e, 'x')}
                    error={op.xErrorText ? true : false}
                    helperText={op.xErrorText}
                    InputProps={{
                        endAdornment: <Button style={{ border: 0, borderRadius: "40%" }} value="xsign" size="small" title={tm.Get("Direction")} tabIndex={-1} onClick={(e) => {
                            handleXYSignChange('xPositive');
                        }} >
                            {inputBuffer.xPositive ? <East /> : <West />}
                        </Button>
                    }}
                />
            </Grid>
        }



        {op?.yVisible &&
            <Grid container item>
                <TextField
                    disabled={props.loading}
                    label={tm.Get("Y")}
                    placeholder={tm.Get("Y")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={inputBuffer.y}
                    onChange={(e) => setInputBuffer({ ...inputBuffer, y: e.target.value })}
                    onBlur={(e) => handleValueChange(e, 'y')}
                    error={op.yErrorText ? true : false}
                    helperText={op.yErrorText}
                    InputProps={{
                        endAdornment: <Button style={{ border: "0px", borderRadius: "40%" }} value="xsign" size="small" title={tm.Get("Direction")} tabIndex={-1} onClick={() => {
                            handleXYSignChange('yPositive');
                        }} >
                            {inputBuffer.yPositive ? <North /> : <South />}
                        </Button>
                    }}

                />
            </Grid>
        }

        {op?.aVisible &&
            <Grid container item>
                <TextField
                    disabled={props.loading}
                    label={tm.Get("A")}
                    placeholder={tm.Get("A")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={inputBuffer.a}
                    onChange={(e) => setInputBuffer({ ...inputBuffer, a: e.target.value })}
                    onBlur={(e) => handleValueChange(e, 'a')}
                    error={op.aErrorText ? true : false}
                    helperText={op.aErrorText}
                />
            </Grid>
        }

        {op?.rotationAngleVisible &&
            <Grid container item>
                <TextField
                    disabled={props.loading}
                    label={tm.Get("Rotation")}
                    placeholder={tm.Get("Rotation")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={inputBuffer.rotationAngle}
                    onChange={(e) => setInputBuffer({ ...inputBuffer, rotationAngle: e.target.value })}
                    onBlur={(e) => handleValueChange(e, 'rotationAngle')}
                    error={op.rotationAngleErrorText ? true : false}
                    helperText={op.rotationAngleErrorText}
                />
            </Grid>
        }

        {(toolsVisible && op) &&
            <Grid item>
                <FlipRotateTools operationEdit={op} onRotationChange={handleRotationChange} />
            </Grid>
        }

    </Grid>

}

export default OperationProperties;

