import { Transform } from "@mui/icons-material";
import { Stack, Button, Typography } from "@mui/material";
import { useWizardStateActions } from "components/OptionsWizard/WebDesigner/useWizardState";
import { WizardModeEnum } from "components/OptionsWizard/WizardContext";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useWait from "helpers/context/Page/useWait";
import React from 'react';
import CADItemAPI from "../API/CADItemAPI";
import { useTranslations } from "@fenetech/translations";
import { IPartPreferences } from "components/OptionsWizard/interfaces";
import { CADActions } from "../Context/CADActions";

interface ICADButtonProps {
    partPreferences: IPartPreferences | null;
    cadActions: CADActions; /* NOTE: this must be passed in because in mobile this shows in the action footer which is not within the CADAction context provider */
}

const CADButton: React.FC<ICADButtonProps> = (props: ICADButtonProps) => {

    const wizardActions = useWizardStateActions();
    const wait = useWait();
    const messagebox = useMessageBox();
    const navBlockerActions = useNavigationBlockerActions();
    const tm = useTranslations();

    const cadEnabled = props.partPreferences?.supportsCAD;

    const showCAD = React.useCallback(() => {

        wait.Show(true);

        CADItemAPI.PostCADInit().then((response) => {

            if (response.BadRequest) {
                messagebox.Show({ message: response.GetErrorMessage() ?? tm.Get("An unknown error has occurred."), title: tm.Get("CAD") });
            } else {
                props.cadActions.setModel(response.Result);
                props.cadActions.setOperationEditor(null);
                wizardActions.SwitchToMode(WizardModeEnum.CAD);
            }

        }).finally(() => {
            wait.Show(false);
        });

    }, [wizardActions, props.cadActions, wait]);


    return <>
        {cadEnabled &&
            <Stack direction="row" justifyContent="center"  >
                <Button variant="contained" onClick={() => {
                    showCAD();
                    navBlockerActions.Unblock();
                }}>
                    <Transform />
                    <Typography>{tm.Get("CAD")}</Typography>
                </Button>
            </Stack>
        }
    </>;
}

export default CADButton;