import { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";

import QuoteEntry from "components/Quotes/QuoteEntry/QuoteEntry";
import EditQuote from "components/Quotes/EditQuote/EditQuote";
import ProductNavigatorWrapper from "components/ProductNavigator/ProductNavigatorWrapper";
import WizardEntry from "components/OptionsWizard/WizardEntry";
import LineItemPriceBreakdown from "components/LineItemPriceBreakdown/LineItemPriceBreakdown";
import GlobalOptionsWizardMain from "components/GlobalOptions/GlobalOptionsWizardMain";
import TransLog from "components/Quotes/QuoteEntry/TransLog/TransLog";

import useQuoteData from "components/Quotes/useQuoteData";
import useQuoteActions from "../useQuoteActions";
import LoadingLinearProgress from "components/Common/LoadingLinearProgress";
import { EditQuoteContextProvider } from "../EditQuote/EditQuoteContext";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import NotFound from "components/Layout/NotFound";
import QuoteAttachments from "./Attachments/QuoteAttachments";
import { QuoteAttachmentsContextProvider } from "./Attachments/QuoteAttachmentsContext";
import PasteSpecial from "components/PasteSpecial/PasteSpecial";

const QuoteEntryMain: React.FC<any> = () => {

    const query = new URLSearchParams(useLocation().search);
    const oKeyString = query.get("oKey") ?? undefined;
    const quoteActions = useQuoteActions();
    const user = useUserInfo();
    const { isLoading } = useQuoteData();

    const oKey = oKeyString ? parseInt(oKeyString) : 0;

    useEffect(() => {
        if (oKey) {
            quoteActions.LoadQuoteAsync(oKey);
            if (user.HasRole(RoleEnum.CSR)) {
                quoteActions.LogRecentQuoteAsync(oKey);
            }
        }

        return () => {
            quoteActions.Clear();
        }
    }, [oKey, quoteActions, user]);

    if (!oKey) {
        return <NotFound />
    }

    if (isLoading) {
        return <LoadingLinearProgress />
    }

    return <Routes>
        <Route path="" element={
            <QuoteAttachmentsContextProvider oKey={oKey}>
                <QuoteEntry />
            </QuoteAttachmentsContextProvider>
        } />
        <Route path="lineitem" element={<WizardEntry />} />
        <Route path="edit" element={
            <EditQuoteContextProvider>
                <QuoteAttachmentsContextProvider oKey={oKey}>
                    <EditQuote />
                </QuoteAttachmentsContextProvider>
            </EditQuoteContextProvider>
        } />
        <Route path="navigator" element={<ProductNavigatorWrapper />} />
        <Route path="pricebreakdown" element={<LineItemPriceBreakdown />} />
        <Route path="globaloptions" element={<GlobalOptionsWizardMain />} />
        <Route path="attachments" element={
            <QuoteAttachmentsContextProvider oKey={oKey}>
                <QuoteAttachments />
            </QuoteAttachmentsContextProvider>
        } />
        <Route path="translog" element={<TransLog />} />
        <Route path="pastespecial" element={<PasteSpecial />} />
        <Route path="*" element={<NotFound />} />

    </Routes>

};

export default QuoteEntryMain;