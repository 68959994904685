import APIHelper from "helpers/APIHelper";
import { IBaseContentWithPayload } from "helpers/interfaces";
import { IOrderAttachment } from "models/IAttachment";

export default class OrderAttachmentsAPI {

    public static async GetAttachmentsEnabledAsync(): Promise<boolean> {
        const query = "api/OrderAttachments/Enabled";

        const res = await APIHelper.GetAsync<boolean>(query);
        return res.Result;
    }

    public static async GetOrderAttachmentsAsync(oKey: number): Promise<IBaseContentWithPayload<IOrderAttachment[]>> {
        const query = "api/OrderAttachments/List";
        const params = { oKey };

        const res = await APIHelper.GetAsync<IBaseContentWithPayload<IOrderAttachment[]>>(query, params);
        return res.Result;
    }
}