import { useTheme } from "@mui/material";
import { GridCallbackDetails, GridRenderCellParams, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useIsMobile from "helpers/hooks/useIsMobile";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import { itemColumn, partColumn } from "./OrderAttachmentColumns";
import { DateTime } from "luxon";
import { IOrderAttachment } from "models/IAttachment";
import { ILineItem } from "models/IQuote";
import useOrderData from "components/Orders/useOrderData";
import { ThemeColorEnum } from "helpers/enums";
import useOrderAttachments from "./useOrderAttachments";
import { IBaseContent } from "helpers/interfaces";
import useFileDownloadWithFeedback from "helpers/hooks/useFileDownloadWithParams";

interface IProps {
    lineItems: ILineItem[]
}

const OrderAttachmentsTable: React.FC<IProps> = ({ lineItems }: IProps) => {

    const tm = useTranslations();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("OrderAttachments");

    const { order } = useOrderData();
    const { attachments, setAlert: setAttachmentsAlert, setIsLoading } = useOrderAttachments();

    const preDownload = React.useCallback(() => {
        setIsLoading(true);
    }, [setIsLoading]);

    const postDownload = React.useCallback(() => {
        setIsLoading(false);
    }, [setIsLoading]);

    const onFileDownloadError = React.useCallback((baseContent: IBaseContent) => {
        const errorMessage = tm.GetWithParams(baseContent.message, ...baseContent.params ?? []);
        setAttachmentsAlert({text: errorMessage, alertType: ThemeColorEnum.Error, visible: true})
    }, [tm]);

    const { download, ref } = useFileDownloadWithFeedback({preDownload, postDownload, onError: onFileDownloadError});

    const generator = new DataGridColumnGenerator(tm, attachments, theme, isMobile);

    const getParams = React.useCallback((attachment: IOrderAttachment) => {
        return { oKey: order?.oKey, lineItem: attachment.lineItem, fileName: attachment.fileName };
    }, [order]);

    const formatSizeColumn = React.useCallback((params: GridRenderCellParams<any, IOrderAttachment, any>) => {
        return `${params.row.sizeKB.toString()} KB`;
    }, []);
    
    if (attachments && attachments.length > 0) {
        generator.TryAddFileLinkWithFeedbackColumn("fileName", "Name", "api/OrderAttachments/Download", getParams, download, { hideInMobile: false });
        generator.TryAddFormattedTextColumn("sizeKB", "Size", formatSizeColumn, { hideInMobile: true });
        generator.TryAddDateTimeColumn("createDate", "Date", DateTime.DATETIME_SHORT_WITH_SECONDS, { hideInMobile: true })
        generator.AddColumn(itemColumn("lineItem", "Item", tm, isMobile));
        generator.AddColumn(partColumn("part", "Part", lineItems, tm, isMobile));
    }
    
    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);
    const getRowId: GridRowIdGetter = (row: GridRowModel) => row["fileName"] + row["lineItem"];

    return <>
        <CustomDataGridPro
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
            getRowId={getRowId}
            columns={generator.GetColumns()}
            rows={attachments}
        />
        {/* Hidden link to host file downloads */}
        <a hidden ref={ref} />
    </>;
}

export default OrderAttachmentsTable;