import { Container } from "@mui/material";
import { IBaseContent, useTranslations } from "@fenetech/translations";
import React from 'react';
import OrderAttachmentsTable from "./OrderAttachmentsTable";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { useNavigate } from "react-router-dom";
import OrderNavigation from "components/Orders/OrderNavigation";
import useOrderData from "components/Orders/useOrderData";
import useOrderAttachments from "./useOrderAttachments";
import { ThemeColorEnum } from "helpers/enums";
import { IActionFileDownloadButton } from "helpers/context/Page/PageContext";
import useFileDownloadWithFeedback from "helpers/hooks/useFileDownloadWithParams";

const OrderAttachments: React.FC = () => {

    const tm = useTranslations();
    const navigate = useNavigate();
    const actionButtons = useActionButtons();
    const { order, lineItems } = useOrderData();
    const { attachments, isLoading, setAlert: setAttachmentsAlert, setIsLoading } = useOrderAttachments();

    const title = React.useMemo(() => {
        if (order) {
            return (`${tm.Get("Attachments")} - #${order.orderNumber ?? ""}`);
        }
        return "";
    }, [order, tm]);
    
    const preDownload = React.useCallback(() => {
        setIsLoading(true);
    }, [setIsLoading]);

    const postDownload = React.useCallback(() => {
        setIsLoading(false);
    }, [setIsLoading]);

    const onFileDownloadError = React.useCallback((baseContent: IBaseContent) => {
        setAttachmentsAlert({ text: tm.GetWithParams(baseContent.message, ...baseContent.params ?? []), alertType: ThemeColorEnum.Error, visible: true})
    }, [tm]);

    const { download, ref } = useFileDownloadWithFeedback({preDownload, postDownload, onError: onFileDownloadError});

    useWindowTitle(title);

    React.useEffect(() => {
        if (order && order.oKey) {
            actionButtons.SetBackButton(0, OrderNavigation.OrdersURL(order.oKey ?? 0))

            const downloadAllButton: IActionFileDownloadButton = {
                text: tm.Get("Download All"),
                download: download,
                params: { oKey: order.oKey},
                color: ThemeColorEnum.Primary,
                disabled: isLoading || (!attachments || attachments.length === 0),
                href: "api/OrderAttachments/List/Download"
            }

            actionButtons.Set(1, downloadAllButton);
        }

    }, [order, actionButtons, attachments, isLoading, tm, navigate]);
    
    return <Container maxWidth="xl" sx={{marginTop: 2}}>
        <OrderAttachmentsTable
            lineItems={lineItems}
        />
        {/* Hidden link to host file downloads */}
        <a hidden ref={ref} />
    </Container>
}

export default OrderAttachments;