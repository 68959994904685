import APIHelper from "helpers/APIHelper";
import { ClassificationTypeEnum } from "helpers/enums";
import { IMaintenanceEditQuote, IMaintenanceEditQuoteSubmit, ISubmittedQuote, IUploadedOrder, IUploadedOrSubmittedQuotes } from "helpers/interfaces";
import { IMaintenanceQuote } from "models/IMaintenanceQuote";

export interface IPagedData<T> {
    data: T[]
    totalRecords: number
}

export default class MaintenanceAPI {


    public static QueryUploadedQuotes(pageSize: number | null, pageNumber: number | null, mfgCustomerID: string, mfgSiteID: string, signal: AbortSignal): Promise<IPagedData<IUploadedOrder>> {

        const params = {
            uploaded: true
            , pageNumber: 1
            , mfgCustomerID
            , mfgSiteID
        };

        return APIHelper.GetAsync<IPagedData<IUploadedOrder>>('api/orders/list', params,).then(wr => {
            return wr.Result;
        });
    }

    public static QueryQuotesByClassification(classificationType: ClassificationTypeEnum, classificationID: number, pageSize: number | null, pageNumber: number | null, mfgCustomerID: string, mfgSiteID: string, signal: AbortSignal): Promise<IPagedData<IMaintenanceQuote>> {
        const params = {
            classificationType
            , classificationID
            , mfgCustomerID
            , mfgSiteID
        };

        return APIHelper.GetAsync<IPagedData<IMaintenanceQuote>>('api/quotes/list', params).then(wr => {
            if (classificationType === ClassificationTypeEnum.Submitted)
                return wr.Result as IPagedData<ISubmittedQuote>;
            else
                return wr.Result;
        });
    }

    public static async CopyQuote(okey: number): Promise<number> {

        return APIHelper.CopyAsync<number>(`api/quotes/${okey}`).then((wr) => {
            return wr.Result;
        });

    }

    public static DeleteQuote(okey: number): Promise<void> {

        return APIHelper.DeleteAsync(`api/quotes/${okey}`, {}).then((wr) => { return; })

    }

    public static async MultiDeleteQuotes(okeys: number[]): Promise<void> {
        console.log(okeys);
        return APIHelper.PostAsync(`api/quotes/maintenance/multiDelete`, null, okeys).then((wr) => { return; })

    }

    public static GetMaintenanceEditQuote(okey: number): Promise<IMaintenanceEditQuote> {
        return APIHelper.GetAsync<IMaintenanceEditQuote>(`/api/quotes/maintenance/${okey}`).then((wr) => {
            return wr.Result;
        });
    }

    public static async PutMaintenanteQuoteEditAsync(editInfo:IMaintenanceEditQuoteSubmit) {
        
        const wr = await APIHelper.PutAsync<string>(`/api/quotes/maintenance`, null, editInfo, false );

        if ( wr.BadRequest ) {
            return wr.GetErrorMessage();
        } else {
            return "";
        }

    }

    public static async UploadQuotes(quotes:number[]): Promise<IUploadedOrSubmittedQuotes[]> {
        
        const response = await APIHelper.PostAsync<IUploadedOrSubmittedQuotes[]>(`/api/quotes/maintenance/upload`, null, quotes );
        return response.Result;

    }

    public static async SubmitQuotes(quotes:number[]): Promise<IUploadedOrSubmittedQuotes[]> {
        
        const response = await APIHelper.PostAsync<IUploadedOrSubmittedQuotes[]>(`/api/quotes/maintenance/submit`, null, quotes );
        return response.Result;

    }

}
