import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import CheckBoxForm from "components/Common/CheckBoxForm";
import SelectForm from "components/Common/SelectForm";
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { LoginAuthenticationTypeEnum, SecurityLevelEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";
import { IMfgSite } from "helpers/interfaces";
import { GetEnumAsListOfKeyValuePairs } from "helpers/objects";
import React from 'react';
import { useFormContext } from "react-hook-form";
import UserStatusChips from "../UserStatusChips";
import MfgSiteSelect from "./MfgSiteSelect";

interface IProps {
    windowTitle: string,
    mfgSites: IMfgSite[] | null,
    mfgSitesVisible: boolean,
    isEditMode: boolean,
    isAllSites: boolean,
    allSitesVisible: boolean,
    securityLevelDisabled: boolean,
    loginAuthenticationVisible: boolean,
    userNameRequired: boolean,
    passwordRequired: boolean,
    firstNameDisabled: boolean,
    lastNameDisabled: boolean,
    emailDisabled: boolean,
    inactiveDisabled: boolean,
    customerInactiveVisible: boolean,
    mfgInactiveVisible: boolean,
    mfgCreatedVisible: boolean,
    mfgCreatedText: string
}

const UserGeneral: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const [isExpanded, setIsEpanded] = React.useState<boolean>(true);
    const formMethods = useFormContext();
    const { setValue, watch, resetField } = formMethods;

    const handleSecurityLevelChange = (data) => {
        if (data.target.value === SecurityLevelEnum.Administrator) {
            const permissions = watch("permissions");
            for (var i = 0; i < permissions.length; i++) {
                setValue(`permissions.${i}.enabled`, true);
            }
        } else {
            resetField("permissions");
        }
    }

    const isDisplayed = (property: boolean) => property ? "flex" : "none";
    const allSitesVisible: boolean = (props.isAllSites ? true : props.isEditMode) && props.allSitesVisible && props.mfgSitesVisible;
    const isInactive: boolean = watch('inactive');
    const isCustomerInactive: boolean = watch('customerInactive');
    const isMfgInactive: boolean = watch('mfgInactive');
    const isMfgCreatedEmployee: boolean = watch('mfgCreatedEmployee');
    const showStatusChips = !props.isEditMode && (isInactive || isCustomerInactive || isMfgInactive || isMfgCreatedEmployee);
    useWindowTitle(props.windowTitle);

    return <>
        <Accordion expanded={isExpanded} onChange={() => setIsEpanded((prev) => !prev)} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("General")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0} paddingTop={allSitesVisible ? 0 : 1}>
                    <Grid item xs={12} sm={props.isEditMode ? 6: 3}>
                        <TextFieldForm
                            fieldName="userName"
                            readOnly={!props.isEditMode}
                            variant="standard"
                            id="user.userName"
                            inputProps={{ maxLength: Constants.MaxLength.UserName }}
                            label={tm.Get("User Name")}
                            placeholder={tm.Get("User Name")}
                            disabled={!props.userNameRequired && props.isEditMode}
                            requiredRule={{ value: props.userNameRequired && props.isEditMode, message: tm.GetWithParams("{0} Required", tm.Get("Username")) }}
                            validateValue={{
                                isNotEmpty: (value: string) => new RegExp("^(?!\\s*$).+").test(value) || tm.GetWithParams("{0} cannot be blank.", tm.Get("Username"))
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sx={{ display: isDisplayed(props.passwordRequired && props.isEditMode) }} xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="password"
                            readOnly={false}
                            type="password"
                            variant="standard"
                            id="user.password"
                            inputProps={{ maxLength: Constants.MaxLength.Password }}
                            label={tm.Get("Password")}
                            placeholder={tm.Get("Password")}
                            requiredRule={{ value: (props.passwordRequired && props.isEditMode), message: tm.GetWithParams("{0} Required", tm.Get("Password")) }}
                            fullWidth
                        />
                    </Grid>
                    {showStatusChips &&
                        <Grid item xs={12} sm={9}>
                            <UserStatusChips
                                isInactive={isInactive}
                                isCustomerInactive={isCustomerInactive}
                                isMfgInactive={isMfgInactive}
                                isMfgCreatedEmployee={isMfgCreatedEmployee}
                                mfgCreatedText={props.mfgCreatedText}
                            />
                        </Grid>
                    }
                    <MfgSiteSelect
                        mfgSites={props.mfgSites}
                        isAllSites={props.isAllSites}
                        allSitesVisible={allSitesVisible}
                        isVisible={props.mfgSitesVisible}
                        readOnly={!props.isEditMode}
                    />
                    <Grid container item xs={12} spacing={2} padding={0}>
                        <Grid item xs={12} sm={6}>
                            <SelectForm
                                fieldName="securityLevel"
                                dataSource={GetEnumAsListOfKeyValuePairs(Object.values(SecurityLevelEnum))}
                                keyField="key"
                                valueField="value"
                                displayField="key"
                                translateDisplayField={true}
                                label={tm.Get("Type")}
                                onChange={handleSecurityLevelChange}
                                readOnly={!props.isEditMode}
                                disabled={props.securityLevelDisabled && props.isEditMode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: isDisplayed(props.loginAuthenticationVisible) }}>
                            <SelectForm
                                fieldName="authenticationType"
                                dataSource={GetEnumAsListOfKeyValuePairs(Object.values(LoginAuthenticationTypeEnum))}
                                keyField="key"
                                valueField="value"
                                displayField="key"
                                translateDisplayField={true}
                                label={tm.Get("Authentication Type")}
                                readOnly={!props.isEditMode}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2} padding={0}>
                        <Grid item xs={12} sm={6}>
                            <TextFieldForm
                                fieldName="firstName"
                                readOnly={!props.isEditMode}
                                variant="standard"
                                id="user.firstName"
                                inputProps={{ maxLength: Constants.MaxLength.FirstName }}
                                label={tm.Get("First Name")}
                                placeholder={tm.Get("First Name")}
                                disabled={props.firstNameDisabled && props.isEditMode}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldForm
                                fieldName="lastName"
                                readOnly={!props.isEditMode}
                                variant="standard"
                                id="user.lastName"
                                inputProps={{ maxLength: Constants.MaxLength.LastName }}
                                label={tm.Get("Last Name")}
                                placeholder={tm.Get("Last Name")}
                                disabled={props.lastNameDisabled && props.isEditMode}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldForm
                                fieldName="emailAddress"
                                readOnly={!props.isEditMode}
                                variant="standard"
                                id="user.emailAddress"
                                inputProps={{ maxLength: Constants.MaxLength.EmailAddress }}
                                label={tm.Get("Email Address")}
                                placeholder={tm.Get("Email Address")}
                                disabled={props.emailDisabled && props.isEditMode}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2} padding={0}>
                        <Grid item sx={{ display: isDisplayed(props.isEditMode) }} xs={6} sm={3}>
                            <CheckBoxForm
                                fieldName="inactive"
                                label={tm.Get("User Inactive")}
                                id="user.inactive"
                                readOnly={!props.isEditMode}
                                disabled={props.inactiveDisabled && props.isEditMode}
                            />
                        </Grid>
                        <Grid item sx={{ display: isDisplayed(props.customerInactiveVisible && props.isEditMode) }} xs={6} sm={3}>
                            <CheckBoxForm
                                fieldName="customerInactive"
                                label={tm.Get("Customer Inactive")}
                                id="user.customerInactive"
                                readOnly={!props.isEditMode}
                                disabled={props.isEditMode}
                            />
                        </Grid>
                        <Grid item sx={{ display: isDisplayed(props.mfgInactiveVisible && props.isEditMode) }} xs={6} sm={3}>
                            <CheckBoxForm
                                fieldName="mfgInactive"
                                label={tm.Get("Mfg Inactive")}
                                id="user.mfgInactive"
                                readOnly={!props.isEditMode}
                                disabled={props.isEditMode}
                            />
                        </Grid>
                        <Grid item sx={{ display: isDisplayed(props.mfgCreatedVisible && props.isEditMode) }} xs={6} sm={3}>
                            <CheckBoxForm
                                fieldName="mfgCreatedEmployee"
                                label={props.mfgCreatedText}
                                id="user.mfgCreated"
                                readOnly={!props.isEditMode}
                                disabled={props.isEditMode}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default UserGeneral;