import jsSHA from "jssha";
import { ICADModel } from "../interfaces";
import React from "react";

const getCADHash = (newProps: ICADModel | null) => {

    if (!newProps)
        return "";

    let jsonString: string = JSON.stringify({ ...newProps }); 

    let objHash: any = new jsSHA("SHA-1", "TEXT");
    objHash.update(jsonString);
    return objHash.getHash("HEX") as string;
}


export default function useCADHash(props: ICADModel | null): string {

    const optionHash = React.useMemo(() => getCADHash(props), [props]);

    return optionHash;

}