import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Typography, Stack, CircularProgress } from "@mui/material";
import { useTranslations } from '@fenetech/translations';
import React from 'react';




const ApiStatusItem: React.FC<any> = ({loading, condition, text}) => {
    const tm = useTranslations();

    if(loading){
        return (
            <Stack direction="row" spacing={1.5}>
                <CircularProgress size={24}/><Typography>{tm.Get(text)}</Typography>
            </Stack>
    )
    }else{
        return(
            <Stack direction="row" spacing={1.5}>
                {condition ?   
                    <React.Fragment><CheckCircleIcon color="success" /><Typography>{tm.Get(text)}</Typography></React.Fragment>
                :
                    <React.Fragment><ErrorIcon color="error" /><Typography>{tm.Get(text)}</Typography></React.Fragment>
                }
            </Stack>
        )
        
    }
    
    
};

export default ApiStatusItem;