import { Autocomplete, Box, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import Format, { ICallsizeArgs, ImperialFormatModeEnum } from "helpers/fv.format";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { useTranslations } from "@fenetech/translations";
import { IPartCallSize, IPartCallSizePresets } from "helpers/interfaces";
import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import useWizardState from "../WebDesigner/useWizardState";
import useItemPropertiesData, { useItemPropertiesBuffer, useItemPropertiesLoaded } from "./useItemPropertiesData";
import useItemPropertiesActions from "./useItemPropertiesActions";
import useDimensionBlurCallback from "./useDimensionBlurCallback";
import { IItemPropertiesData } from "./ItemPropertiesContext";
import useWizardInteractions from "../useWizardInteractions";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useIsMobile from "helpers/hooks/useIsMobile";
import DesignerItemProperties from "../MainWizard/DesignerItemProperties";
import OptionGroupBox from "../OptionsWizard/OptionGroupBox";
import Constants from "helpers/constants";

interface IProps {
    isSubLineItem: boolean;
    unitSetID: number;
    partDefaults: PartDefaults;
    partCallSizes: IPartCallSizePresets;
    autoSubmit?: boolean;
    sizeEditable: boolean;
};

const ItemProperties: React.FC<IProps> = ({ isSubLineItem, unitSetID, partDefaults, partCallSizes, autoSubmit, sizeEditable }: IProps) => {

    const tm = useTranslations();
    const wizardState = useWizardState();
    const wizardInteractions = useWizardInteractions();
    const itemPropertiesActions = useItemPropertiesActions();
    const isMobile = useIsMobile();

    const qtyInput = useRef<HTMLInputElement>(null);
    const formatMethods = useFormatHelper();
    const itemProperties = useItemPropertiesData();
    const itemPropertiesBuffer = useItemPropertiesBuffer();
    const loaded = useItemPropertiesLoaded();
    const { qty, width, height, thickness, customerRef, itemGroup, callSize, comment } = itemProperties;
    const { qtyBuffer, widthBuffer, heightBuffer, thicknessBuffer, customerRefBuffer, itemGroupBuffer, callSizeBuffer, commentBuffer } = itemPropertiesBuffer;

    const submitItemSize = React.useCallback((newState: Partial<IItemPropertiesData>) => {
        wizardInteractions.ChangeItemPropertiesAsync({ ...itemProperties, ...newState });
    }, [wizardInteractions, itemProperties]);

    const measurementLabel = useMemo(() => {
        if (unitSetID === 1)
            return tm.Get("inches");
        else
            return tm.Get("mm");
    }, [unitSetID, tm]);

    const itemSize: string = useMemo(() => {
        return formatMethods.FormatDimensions(itemProperties.width, itemProperties.height, itemProperties.thickness, unitSetID)
    }, [formatMethods, itemProperties.height, itemProperties.thickness, itemProperties.width, unitSetID]);

    //#region Qty

    const handleQtyChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        itemPropertiesActions.SetQtyBuffer(e.target.value);
    }, [itemPropertiesActions]);

    const handleQtyBlur = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newQty = parseInt(qtyBuffer);
        if (isNaN(newQty) || newQty < 1) {
            newQty = 1;
        }

        if (newQty === qty) {
            return;
        }

        itemPropertiesActions.SetQty(newQty);
        itemPropertiesActions.SetQtyBuffer(newQty.toString());
        if (autoSubmit) {
            submitItemSize({ qty: newQty });
        }
    }, [autoSubmit, itemPropertiesActions, submitItemSize, qtyBuffer, qty]);

    //#endregion

    //#region Width

    const setWidth = React.useCallback((value: number) => {
        itemPropertiesActions.SetWidth(value);
    }, [itemPropertiesActions]);

    const setWidthBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetWidthBuffer(value);
    }, [itemPropertiesActions]);

    const onWidthCommitted = useCallback((newWidth: number) => {
        if (autoSubmit) {
            submitItemSize({ width: newWidth, callSize: "" });
        }
    }, [autoSubmit, submitItemSize]);

    //#endregion

    //#region Height

    const setHeight = React.useCallback((value: number) => {
        itemPropertiesActions.SetHeight(value);
    }, [itemPropertiesActions]);

    const setHeightBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetHeightBuffer(value);
    }, [itemPropertiesActions]);

    const onHeightCommitted = useCallback((newHeight: number) => {
        if (autoSubmit) {
            submitItemSize({ height: newHeight, callSize: "" });
        }
    }, [autoSubmit, submitItemSize]);

    //#endregion

    //#region Thickness

    const setThickness = React.useCallback((value: number) => {
        itemPropertiesActions.SetThickness(value);
    }, [itemPropertiesActions]);

    const setThicknessBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetThicknessBuffer(value);
    }, [itemPropertiesActions]);

    const onThicknessCommitted = useCallback((newThickness: number) => {
        if (autoSubmit) {
            submitItemSize({ thickness: newThickness });
        }
    }, [autoSubmit, submitItemSize]);

    //#endregion

    //#region Customer Ref

    const setCustomerRefBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetCustomerRefBuffer(value);
    }, [itemPropertiesActions]);

    const handleCustomerRefBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {

        const newCustomerRef = e.target.value;

        if (newCustomerRef === customerRef) {
            return;
        }

        itemPropertiesActions.SetCustomerRef(newCustomerRef);
        if (autoSubmit) {
            submitItemSize({ customerRef: newCustomerRef });
        }
    }, [itemPropertiesActions, autoSubmit, submitItemSize, customerRef]);

    //#endregion

    //#region Item Group

    const setItemGroupBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetItemGroupBuffer(value);
    }, [itemPropertiesActions]);

    const handleItemGroupBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {

        const newItemGroup = e.target.value;

        if (newItemGroup === itemGroup) {
            return;
        }

        itemPropertiesActions.SetItemGroup(newItemGroup);
        if (autoSubmit) {
            submitItemSize({ itemGroup: newItemGroup });
        }
    }, [itemPropertiesActions, autoSubmit, submitItemSize, itemGroup]);

    //#endregion

    //#region Comment

    const setCommentBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetCommentBuffer(value);
    }, [itemPropertiesActions]);

    const handleCommentBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {

        const newComment = e.target.value;

        if (newComment === comment) {
            return;
        }

        itemPropertiesActions.SetComment(newComment);
        if (autoSubmit) {
            submitItemSize({ comment: newComment });
        }
    }, [itemPropertiesActions, autoSubmit, submitItemSize, comment]);

    //#endregion

    const callSizeSelectChosen = React.useMemo(() => {
        return partCallSizes && partCallSizes.callSizes.length > 0 && callSize !== "";
    }, [callSize, partCallSizes]);

    const callSizeLocked = React.useMemo<boolean>(() => {
        if (partCallSizes && partCallSizes.callSizes.length > 0 && partCallSizes.locked) {
            return true;
        } else {
            return false;
        }
    }, [partCallSizes]);

    const selectedCallSize = React.useMemo(() => {
        if (partCallSizes && partCallSizes.callSizes && partCallSizes.callSizes.length > 0) {
            if (callSize) {
                const item = partCallSizes.callSizes.find(cs => cs.callSize === callSize);
                return item ?? null;
            } else {
                return null;
            }

        }
        return null;
    }, [callSize, partCallSizes]);

    const setCallSize = React.useCallback((value: string | null) => {
        itemPropertiesActions.SetCallSize(value);
    }, [itemPropertiesActions]);

    const setCallSizeBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetCallSizeBuffer(value);
    }, [itemPropertiesActions]);

    const parseCallSizeText = React.useCallback((newCallSize: string) => {

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        let callsizeArgs: ICallsizeArgs = {
            callSize: newCallSize,
            width: currentWidth,
            height: currentHeight,
        };

        if (Format.formatCallSize(callsizeArgs)) {
            //valid
            newWidth = callsizeArgs.width;
            newHeight = callsizeArgs.height;
        } else {
            //Invalid
            newCallSize = "";
            newWidth = 0;
            newHeight = 0;
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthBuffer(newFormattedWidth);
        setHeight(newHeight);
        setHeightBuffer(newFormattedHeight);

        setCallSize(newCallSize);
        setCallSizeBuffer(newCallSize);

        if (autoSubmit) {
            submitItemSize({ width: newWidth, height: newHeight, callSize: newCallSize });
        }

    }, [width, height, formatMethods, unitSetID, setWidth, setWidthBuffer, setHeight, setHeightBuffer, setCallSize, setCallSizeBuffer, autoSubmit, submitItemSize]);

    const handleCallSizeTextChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCallSizeBuffer(e.target.value);
    }, [setCallSizeBuffer]);

    const handleCallSizeTextBlur = React.useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newCallSize: string = e.target.value;

        if (newCallSize !== callSize) {
            parseCallSizeText(newCallSize);
        }

    }, [parseCallSizeText, callSize]);

    const parseCallSizeSelection = React.useCallback((newCallSize: string) => {

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        if (newCallSize) {
            if (partCallSizes) {
                const selectedCallSize = partCallSizes.callSizes.filter(cs => cs.callSize === newCallSize);
                if (selectedCallSize.length > 0) {
                    newWidth = selectedCallSize[0].width;
                    newHeight = selectedCallSize[0].height;
                }
            }
        } else {
            newWidth = 0;
            newHeight = 0;
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthBuffer(newFormattedWidth);
        setHeight(newHeight);
        setHeightBuffer(newFormattedHeight);
        setCallSize(newCallSize);
        setCallSizeBuffer(newCallSize);
        

        if (autoSubmit) {
            submitItemSize({ width: newWidth, height: newHeight, callSize: newCallSize });
        }
    }, [width, height, formatMethods, unitSetID, setWidth, setWidthBuffer, setHeight, setHeightBuffer, setCallSize, setCallSizeBuffer, partCallSizes, autoSubmit, submitItemSize]);

    const handleCallSizeSelectChange = React.useCallback((event: any, data: IPartCallSize | null) => {
        const newCallSize = data?.callSize ?? "";
        parseCallSizeSelection(newCallSize);
    }, [parseCallSizeSelection]);

    //#endregion

    const handleWidthBlur = useDimensionBlurCallback(formatMethods, unitSetID, width, widthBuffer, setWidthBuffer, setWidth, setCallSize, onWidthCommitted);
    const handleHeightBlur = useDimensionBlurCallback(formatMethods, unitSetID, height, heightBuffer, setHeightBuffer, setHeight, setCallSize, onHeightCommitted);
    const handleThicknessBlur = useDimensionBlurCallback(formatMethods, unitSetID, thickness, thicknessBuffer, setThicknessBuffer, setThickness, undefined, onThicknessCommitted);

    useEffectOnLoad(() => {
        const callSizeString = callSize ?? "";
        setCallSize(callSizeString);
        if (callSizeString !== "") {
            if (callSizeSelectChosen) {
                parseCallSizeSelection(callSizeString);
            }
            else {
                parseCallSizeText(callSizeString);
            }
        }
    });

    useEffect(() => {
        if (qtyInput?.current) {
            qtyInput.current.focus();
            qtyInput.current.select();
        }
    }, [qtyInput]);

    return <>
        <Box display="flex" flexDirection="column">
            <Grid container direction="column" rowSpacing={1} justifyItems="stretch">

                {!sizeEditable &&
                    <Grid item>
                        <OptionGroupBox id="READONLY_ITEMSIZE" label="">
                            <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                                <Typography>{tm.Get("Item Size:")}</Typography>
                                <Typography>{itemSize}</Typography>
                            </Stack>
                        </OptionGroupBox>
                    </Grid>
                }

                {partDefaults.QuantityEnabled() &&
                    <Grid item >
                        <TextField onFocus={e => e.target.select()} inputRef={qtyInput} label={tm.Get("Qty")} value={loaded ? qtyBuffer : ""} onChange={handleQtyChange} onBlur={handleQtyBlur} fullWidth
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                    </Grid>
                }

                {partDefaults.CallSizeEnabled() && sizeEditable &&
                    <Grid item>
                        <FormControl id="callsize" fullWidth>
                            {partCallSizes && partCallSizes.callSizes.length > 0 ?
                                <Autocomplete
                                    autoComplete
                                    disableClearable={callSizeLocked}
                                    autoSelect
                                    autoHighlight
                                    blurOnSelect
                                    selectOnFocus
                                    handleHomeEndKeys
                                    disabled={!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false}
                                    options={partCallSizes.callSizes}
                                    getOptionLabel={(cs: IPartCallSize) => cs.callSize}
                                    isOptionEqualToValue={(cs: IPartCallSize, value: IPartCallSize) => cs.callSize === value.callSize}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={tm.Get("Call Size")}
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                                            }}
                                        />
                                    )}
                                    onChange={handleCallSizeSelectChange}
                                    value={loaded ? selectedCallSize : null}
                                />
                                :
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Call Size")} value={loaded ? callSizeBuffer : ""} onChange={handleCallSizeTextChange} onBlur={handleCallSizeTextBlur} fullWidth
                                    disabled={!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false} />
                            }
                        </FormControl>

                    </Grid>
                }

                {partDefaults.WidthEnabled() && sizeEditable &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Width")} value={loaded ? widthBuffer : ""} onChange={(e) => setWidthBuffer(e.target.value)} onBlur={handleWidthBlur} fullWidth
                                disabled={callSizeLocked || (!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false) || callSizeSelectChosen}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                        </Stack>
                    </Grid>
                }

                {partDefaults.HeightEnabled() && sizeEditable &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Height")} value={loaded ? heightBuffer : ""} onChange={(e) => setHeightBuffer(e.target.value)} onBlur={handleHeightBlur} fullWidth
                                disabled={callSizeLocked || (!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false) || callSizeSelectChosen}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                        </Stack>
                    </Grid>
                }

                {partDefaults.ThicknessEnabled() && sizeEditable &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Thickness")} value={loaded ? thicknessBuffer : ""} onChange={(e) => setThicknessBuffer(e.target.value)} onBlur={handleThicknessBlur} fullWidth
                                disabled={callSizeLocked || (!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false)}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                        </Stack>
                    </Grid>
                }

                {partDefaults.CustomerRefEnabled() &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Customer Ref")} value={loaded ? customerRefBuffer : ""} onChange={(e) => setCustomerRefBuffer(e.target.value.substring(0, Constants.MaxLength.ItemCustomerRef))} onBlur={handleCustomerRefBlur} fullWidth />
                        </Stack>
                    </Grid>
                }

                {partDefaults.ItemGroupEnabled() &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Group")} value={loaded ? itemGroupBuffer : ""} onChange={(e) => setItemGroupBuffer(e.target.value.substring(0, Constants.MaxLength.ItemGroup))} onBlur={handleItemGroupBlur} fullWidth />
                        </Stack>
                    </Grid>
                }

                {!isSubLineItem &&
                    <Grid item >
                        <TextField onFocus={e => e.target.select()} label={tm.Get("Line Item Comment")} multiline fullWidth minRows={2} value={loaded ? commentBuffer : ""} onChange={(e) => setCommentBuffer(e.target.value)} onBlur={handleCommentBlur} />
                    </Grid>
                }

                {isMobile &&
                    <DesignerItemProperties includeGridItem />
                }

            </Grid>
        </Box>
    </>;
}

export default ItemProperties;



