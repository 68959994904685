import React, { useCallback, useMemo } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from '@fenetech/translations';
import useSalespersons from 'helpers/context/SelectionValues/useSalespersons';
import { IEmployee, IQuotePermissions } from "helpers/interfaces";
import useAddQuoteData from "../AddQuote/useAddQuoteData";
import useQuoteData from "../useQuoteData";

interface IProps {
    parentCustomerID: string,
    parentSiteID: string,
    permissions?: IQuotePermissions,
    [x: string] : any  // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function SelectSalesperson ({ parentCustomerID, parentSiteID, permissions, ...rest }: IProps)
{

    const tm = useTranslations();
    
    const salespersonList = useSalespersons(parentCustomerID, parentSiteID);
    const { quoteDefaults: addQuoteDefaults } = useAddQuoteData();
    const { quote } = useQuoteData();

    const inactiveTranslation = useMemo(() => {
        return "<" + tm.Get("Inactive") + ">";
    }, [tm]);

    const quoteDefaultSalespersonID = useMemo(() => {
        return (addQuoteDefaults ?? quote)?.salespersonID;
    }, [addQuoteDefaults, quote]);

    const { control } = useFormContext();
    const { field } = useController({
        name: "salespersonID", 
        control,
        rules: { required: false },
    });

    const filteredSalesPersonList = useMemo(() => {
        if (salespersonList === undefined) {
            return undefined;
        }
        let filteredList: IEmployee[] = [];
        salespersonList.forEach(sp => {
            if (!sp.inactive || sp.employeeID === quoteDefaultSalespersonID) {
                filteredList.push(sp);
            }
        });
        return filteredList;
    }, [quoteDefaultSalespersonID, salespersonList]);

    const selectedSalesperson = useCallback(() => {
        return filteredSalesPersonList?.find((sp) => field.value && sp.employeeID === field.value) ?? null
    }, [filteredSalesPersonList, field]);


    return <>
        <Autocomplete 
            id="salesperson"
            autoComplete
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={filteredSalesPersonList ?? []} 
            getOptionLabel={(option) => (option?.inactive ? inactiveTranslation : "") + (option?.fullName || "")}
            disabled={!permissions?.salesperson ?? true}
            renderInput={(params) => (
                <TextField 
                    variant="outlined" 
                    {...params} 
                    label={tm.Get("Salesperson")} 
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                            {(filteredSalesPersonList === undefined) && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                            </>
                        ),
                    }}                      
                />
            )}
            onChange = {(_, value) => {
                field.onChange(value?.employeeID ?? 0);
            }}
            value = {selectedSalesperson()}   
            ref = {field.ref}
            {...rest}
        /> 
    </>

}

export default SelectSalesperson;