import APIHelper from "helpers/APIHelper";
import { ICADOperationProperties, ICADOperationEditor, ICADOperationPlacement, ICADParameterEdit, ICADSideOperationEdit, ICADOperationDimension } from "../interfaces";
import { CADOperationTypeEnum } from "../enums";

export default class CADOperationEditorAPI {

    private static baseURL = 'api/cad/operationeditor';

    static async InitExistingAsync(uniqueID: number): Promise<ICADOperationEditor> {
        return (await APIHelper.GetAsync<ICADOperationEditor>(`${this.baseURL}/init/${uniqueID}`)).Result;
    }

    static async InitForCopyAsync(uniqueID: number): Promise<ICADOperationEditor> {
        return (await APIHelper.GetAsync<ICADOperationEditor>(`${this.baseURL}/copy/${uniqueID}`)).Result;
    }

    static async InitNewAsync(operationType: CADOperationTypeEnum, operationID: number): Promise<ICADOperationEditor> {
        return (await APIHelper.GetAsync<ICADOperationEditor>(`${this.baseURL}/initnew/${operationType as number}/${operationID}`)).Result;
    }

    static async PostParameterAsync(opParam: ICADParameterEdit): Promise<ICADOperationEditor> {
        return (await APIHelper.PostAsync<ICADOperationEditor>(`${this.baseURL}/parameter`, null, opParam)).Result;
    }

    static async PostEdgeworkParameterAsync(uniqueID: number, opParam: ICADParameterEdit): Promise<ICADOperationEditor> {
        return (await APIHelper.PostAsync<ICADOperationEditor>(`${this.baseURL}/edgeworkparameter/${uniqueID}`, null, opParam)).Result;
    }

    static async PostPropertiesAsync(opEdit: ICADOperationProperties): Promise<ICADOperationEditor> {
        return (await APIHelper.PostAsync<ICADOperationEditor>(`${this.baseURL}/properties`, null, opEdit)).Result;
    }

    static async PostPlacementAsync(opPlacement: ICADOperationPlacement): Promise<ICADOperationEditor> {
        return (await APIHelper.PostAsync<ICADOperationEditor>(`${this.baseURL}/placement`, null, opPlacement)).Result;
    }

    static async PostEdgeworkAsync(uniqueID: number, edgework: Partial<ICADSideOperationEdit> | null): Promise<ICADOperationEditor> {
        return (await APIHelper.PostAsync<ICADOperationEditor>(`${this.baseURL}/edgework/${uniqueID}`, null, edgework)).Result;
    }

    static async DeleteEdgeworkAsync(side: number): Promise<ICADOperationEditor> {
        return (await APIHelper.DeleteAsync<ICADOperationEditor>(`${this.baseURL}/edgework/${side}`)).Result;
    }

    static async PostDimensionAsync(dimension: ICADOperationDimension): Promise<ICADOperationEditor> {
        return (await APIHelper.PostAsync<ICADOperationEditor>(`${this.baseURL}/dimension`, null, dimension)).Result;
    }

    static async ApplyOperationAsync(): Promise<[boolean, string]> {

        var res = await APIHelper.PostAsync<void>(`${this.baseURL}/apply`, null, null, false);

        if (res.BadRequest) {
            return [false, res.GetErrorMessage()];
        } else {
            return [true, ""];
        }

    }

}
