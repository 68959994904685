import { Paper, Box, Grid } from "@mui/material";
import { FactCheck, ModeStandby, Event, LocalShipping, Receipt } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";

import { useTranslations } from "@fenetech/translations";
import useOrderData from "components/Orders/useOrderData";

import Format from "helpers/fv.format";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import { useMemo } from "react";
import { DateTimeIsEmpty } from "helpers/objects";
import { DateTime } from "luxon";

interface IProps {
    isPickup: boolean | undefined;
}

function StatusCard({ isPickup }: IProps) {
    const tm = useTranslations();
    const { order } = useOrderData();
    const application = useApplicationInfo();

    const deliveryDateLabel = useMemo(() => {
        let label: string;
        if (isPickup) {
            label = "Est. Pickup Date";
        }
        else {
            label = "Est. Delivery Date";
            if (application && application.parameters.customDeliveryDateLabel) {
                label = application.parameters.customDeliveryDateLabel;
            }
        }
        return tm.Get(label);
    }, [application, tm, isPickup]);

    const showDeliveryDateLabel = useMemo(() => {
        return !isPickup && order && order.hasOwnProperty("requestedDeliveryDate");
    }, [order, isPickup]);

    const showShipDate = useMemo(() => {
        return !isPickup;
    }, [isPickup]);

    const showInvoiceDate = useMemo(() => {
        return !isPickup && order?.invoicedDate && !DateTimeIsEmpty(DateTime.fromISO(order.invoicedDate));
    }, [isPickup, order?.invoicedDate]);

    const requestedDeliveryLabel = useMemo(() => {
        let label: string = "Requested Date";
        if (application && application.parameters.customRequestedDeliveryDateLabel) {
            label = application.parameters.customRequestedDeliveryDateLabel;
        }
        return tm.Get(label);
    }, [application, tm]);

    return <>
        <Paper>
            <Box sx={{ margin: 1 }} display="flex">
                <Grid container direction="row" display="flex" alignItems="start">
                    <Grid xs={6} md item container mt={1} direction="row">
                        <Grid item mr={1}>
                            <FactCheck color="primary" />
                        </Grid>
                        <Grid item xs>
                            <LabeledText
                                label={tm.Get("Ack. Date")}
                                text={Format.FormatDate(order?.acknowledgedDate)}
                            />
                        </Grid>
                    </Grid>

                    <Grid xs={6} md item container mt={1} direction="row">
                        <Grid item mr={1}>
                            <ModeStandby color="primary" />
                        </Grid>
                        <Grid item xs display="flex">
                            <LabeledText
                                label={deliveryDateLabel}
                                text={Format.FormatDate(order?.estimatedDeliveryDate)}
                            />
                        </Grid>
                    </Grid>

                    {showDeliveryDateLabel && requestedDeliveryLabel &&
                        <Grid xs={6} md item container mt={1} direction="row">
                            <Grid item mr={1} display="flex">
                                <Event color="primary" />
                            </Grid>
                            <Grid item xs display="flex">
                                <LabeledText
                                    label={requestedDeliveryLabel}
                                    text={Format.FormatDate(order?.requestedDeliveryDate)}
                                />
                            </Grid>
                        </Grid>
                    }

                    {showShipDate &&
                        <Grid xs={6} md item container mt={1} direction="row">
                            <Grid item mr={1} display="flex">
                                <LocalShipping color="primary" />
                            </Grid>
                            <Grid item xs display="flex">
                                <LabeledText
                                    label={tm.Get("Ship Date")}
                                    text={Format.FormatDate(order?.shippedDate)}
                                />
                            </Grid>
                        </Grid>
                    }

                    {showInvoiceDate &&
                        <Grid xs={6} md item container mt={1} direction="row" display="flex">
                            <Grid item mr={1} display="flex">
                                <Receipt color="primary" />
                            </Grid>
                            <Grid item xs display="flex">
                                <LabeledText
                                    label={tm.Get("Invoice Date")}
                                    text={Format.FormatDate(order?.invoicedDate)}
                                />
                            </Grid>
                        </Grid>
                    }

                </Grid>
            </Box>
        </Paper>

    </>

}


export default StatusCard;