import { Badge, IconButton, Tooltip } from "@mui/material";
import PrintReport from "components/Orders/OrderView/Header/PrintReport";
import { IOrder } from "helpers/interfaces";
import { useTranslations } from "@fenetech/translations";
import { AttachFile, Link } from "@mui/icons-material";
import React from "react";
import OrderNavigation from "components/Orders/OrderNavigation";
import { useNavigate } from "react-router-dom";
import useOrderAttachments from "../Attachments/useOrderAttachments";

interface IProps {
    order?: IOrder,
    orderHasRelatedLinks: boolean,
    setRelatedLinksDialogOpen: (value: boolean) => void
}

function OrderHeaderActions({ order, orderHasRelatedLinks, setRelatedLinksDialogOpen }: IProps) {

    const tm = useTranslations();
    const navigate = useNavigate();
    const { attachments, isEnabled: attachmentsEnabled } = useOrderAttachments();

    const handleOrderAttachmentsClick = React.useCallback(() => {
        navigate(OrderNavigation.OrderAttachmentsURL(order?.oKey ?? 0));
    }, [navigate, order]);

    return <>
        {attachmentsEnabled && <>
            <Tooltip title={tm.Get("Attachments")} sx={{marginBottom: attachments.length > 0 ? 1 : 0}}>
                <Badge badgeContent={attachments.length} color="secondary"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <IconButton aria-label="attachments" sx={{ padding: 0 }} onClick={handleOrderAttachmentsClick}>
                        <AttachFile color="primary" />
                    </IconButton>
                </Badge>
            </Tooltip>
        </>}
        {orderHasRelatedLinks &&
            <Tooltip title={tm.Get("Links")}>
                <IconButton aria-label="related links" sx={{ padding: 0 }} onClick={() => setRelatedLinksDialogOpen(true)}>
                    <Link color="primary" />
                </IconButton>
            </Tooltip>
        }
        {order && <>
            <PrintReport order={order} />
        </>}
    </>;
};

export default OrderHeaderActions;
