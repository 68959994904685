import React from 'react';
import { useTranslations } from "@fenetech/translations";
import Delete from "@mui/icons-material/Delete";
import FileCopy from "@mui/icons-material/FileCopy";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { ThemeColorEnum } from "helpers/enums";
import Cancel from "@mui/icons-material/Cancel";
import useIsMobile from "helpers/hooks/useIsMobile";

export interface ICADOperationCommandEvents<T> {
    onDeleteClick?: (item: T) => void;
    onCopyClick?: (item: T) => void;
    onEditClick?: (item: T) => void;
    onCancelClick?: () => void;
}

interface ICADOperationCommandsProps<T> {
    item: T;
    events: ICADOperationCommandEvents<T>;
}

const CADOperationCommands = <T,>(props: ICADOperationCommandsProps<T>) => {

    const tm = useTranslations();

    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    const isMoble = useIsMobile();
    const iconSize = isMoble ? "large" : "medium";

    return <>
        <Stack direction="row" justifyContent={"flex-end"} onClick={preventDefault}>

            {props.events.onEditClick &&
                <Tooltip title={tm.Get("Edit")}>
                    <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => props.events.onEditClick && props.events.onEditClick(props.item)}>
                        <EditIcon fontSize={iconSize} />
                    </IconButton>
                </Tooltip>
            }

            {props.events.onCopyClick &&
                <Tooltip title={tm.Get("Copy")}>
                    <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => props.events.onCopyClick && props.events.onCopyClick(props.item)}>
                        <FileCopy fontSize={iconSize} />
                    </IconButton>
                </Tooltip>
            }

            {props.events.onCancelClick &&
                <Tooltip title={tm.Get("Cancel")}>
                    <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => props.events.onCancelClick && props.events.onCancelClick()}>
                        <Cancel fontSize={iconSize} />
                    </IconButton>
                </Tooltip>
            }

            {props.events.onDeleteClick &&
                <Tooltip title={tm.Get("Delete")}>
                    <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => { props.events.onDeleteClick && props.events.onDeleteClick(props.item); }}>
                        <Delete fontSize={iconSize} />
                    </IconButton>
                </Tooltip>
            }

        </Stack>
    </>;

};

export default CADOperationCommands;