import { useCADActions, useCADState } from "../../Context/CADContext";
import { EdgeAdjustmentsEnum } from "../../enums";
import Stack from "@mui/material/Stack";
import { ITranslationManager, useTranslations } from "@fenetech/translations";
import { ICADEdge, ICADLayer, ICADModel, IEdgeElbow, IEdgeRake, IEdgeShift } from "../../interfaces";
import CADOperationCommands from "../CADOperationCommands";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import Format, { ImperialFormatModeEnum } from "helpers/fv.format";
import { useMeasurementUnitLabel } from "helpers/hooks/useMeasurementUnitLabel";
import React, { useCallback } from "react";
import CADTable, { ITableColumn } from "../CADTable";
import AddEdgeAdjustment, { AddEdgeAdjustmentStateEnum } from "./AddEdgeAdjustment";
import { EdgeToolAPI } from "./EdgeToolAPI";
import WebResponse from "helpers/WebResponse";
import { CADActions } from "../../Context/CADActions";
import AlertDialog from "components/Common/AlertDialog";
import EdgeElbowIcon from "./EdgeElbowIcon";
import EdgeRakeIcon from "./EdgeRakeIcon";
import EdgeShiftIcon from "./EdgeShiftIcon";
import { Grid } from "@mui/material";
import { CompareCommonProperties } from "helpers/objects";

interface IEdgeAdjustmentsComponentProps {
    layer: ICADLayer;
    enabledAdjustments: EdgeAdjustmentsEnum;
}

export type EdgeAndAdjustment = { edgeName: string, edge: ICADEdge, adjustmentType: EdgeAdjustmentsEnum, adjustment: IEdgeShift | IEdgeRake | IEdgeElbow, description: string };


const EdgesWidthAdjustments = (layer: ICADLayer, tm: ITranslationManager, formatMethods: Format, unitSetID: number, unitLabel: string): EdgeAndAdjustment[] => {

    let details: EdgeAndAdjustment[] = [];

    for (const edge of layer.edges) {

        const existingShift = layer.edgeShifts.find((shift) => shift.edgeName === edge.edgeName);
        const existingRake = layer.edgeRakes.find((rake) => rake.edgeName === edge.edgeName);
        const existingElbow = layer.edgeElbows.find((elbow) => elbow.edgeName === edge.edgeName);

        if (existingShift)
            details.push({ edgeName: edge.edgeName, edge, adjustmentType: EdgeAdjustmentsEnum.Shift, adjustment: existingShift, description: edgeShiftDetail(existingShift, tm, formatMethods, unitSetID, unitLabel) });

        if (existingRake)
            details.push({ edgeName: edge.edgeName, edge, adjustmentType: EdgeAdjustmentsEnum.Rake, adjustment: existingRake, description: edgeRakeDetail(existingRake, tm, formatMethods, unitSetID, unitLabel) });

        if (existingElbow)
            details.push({ edgeName: edge.edgeName, edge, adjustmentType: EdgeAdjustmentsEnum.Elbow, adjustment: existingElbow, description: edgeElbowDetail(existingElbow, tm, formatMethods, unitSetID, unitLabel) });

    }

    return details;

};

const formatOffset = (offset: number, formatMethods: Format, unitSetID: number, unitLabel: string) => {
    return formatMethods.formatDimensionText(Math.abs(offset), unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false) + " " + unitLabel;
}

const formatDirectionalOffset = (tm: ITranslationManager, offset: number, formatMethods: Format, unitSetID: number) => {

    let formattedOffset:string;

    const formattedDimension = formatMethods.formatDimensionText(Math.abs(offset), unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false)

    if ( unitSetID === 1 ) {
        if ( offset < 0){
            formattedOffset = tm.GetWithParams("{0} inches in", formattedDimension);  
        } else {
            formattedOffset = tm.GetWithParams("{0} inches out", formattedDimension);
        }
    } else {
        if ( offset < 0){
            formattedOffset = tm.GetWithParams("{0} mm in", formattedDimension);  
        } else {
            formattedOffset = tm.GetWithParams("{0} mm out", formattedDimension);
        }
    }

    return formattedOffset;
}

const edgeShiftDetail = (adjustment: IEdgeShift, tm: ITranslationManager, formatMethods: Format, unitSetID: number, unitLabel: string) => {
    return tm.GetWithParams("Shift {0}", formatOffset(adjustment.offset, formatMethods, unitSetID, unitLabel));
}

const edgeRakeDetail = (adjustment: IEdgeRake, tm: ITranslationManager, formatMethods: Format, unitSetID: number, unitLabel: string) => {

    const rakeString: string = "Rake {0}";
    return tm.GetWithParams(rakeString, formatOffset(adjustment.offset, formatMethods, unitSetID, unitLabel));
}

const edgeElbowDetail = (adjustment: IEdgeElbow, tm: ITranslationManager, formatMethods: Format, unitSetID: number, unitLabel: string) => {

    return tm.GetWithParams("Elbow with break at {2} and offsets of {0} and {1}"
        , formatDirectionalOffset(tm,adjustment.breakOffset, formatMethods, unitSetID)
        , formatDirectionalOffset(tm,adjustment.overallOffset, formatMethods, unitSetID)
        , formatOffset(adjustment.breakDistance, formatMethods, unitSetID, unitLabel)
    );

}

const deleteAdjustment = (item: EdgeAndAdjustment, cadActions: CADActions) => {

    let deletePromise: Promise<WebResponse<ICADModel>> | null = null;

    switch (item.adjustmentType) {
        case EdgeAdjustmentsEnum.Shift:
            deletePromise = EdgeToolAPI.PostEdgeShift(item.edgeName, 0);
            break;

        case EdgeAdjustmentsEnum.Rake:
            const rake = item.adjustment as IEdgeRake;
            deletePromise = EdgeToolAPI.PostEdgeRake(item.edgeName, 0, rake.rakeStart);
            break;

        case EdgeAdjustmentsEnum.Elbow:
            const elbow = item.adjustment as IEdgeElbow;
            deletePromise = EdgeToolAPI.PostEdgeElbow(item.edgeName, elbow.startFixed, 0, 0, 0);
            break;
    }

    return deletePromise?.then((response) => {
        if (!response.BadRequest) {
            cadActions.setModel(response.Result);
        }
    });

}

const EdgeAdjustmentsComponent: React.FC<IEdgeAdjustmentsComponentProps> = (props: IEdgeAdjustmentsComponentProps) => {

    const cadState = useCADState();
    const cadActions = useCADActions();
    const tm = useTranslations();
    const formatMethods = useFormatHelper();
    const unitLabel = useMeasurementUnitLabel(cadState.unitSetID);

    const edgesAndAdjustments = EdgesWidthAdjustments(props.layer, tm, formatMethods, cadState.unitSetID, unitLabel);

    const [addingAdjustmentState, setAddingAdjustmentState] = React.useState<AddEdgeAdjustmentStateEnum>(AddEdgeAdjustmentStateEnum.None);

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [adjustmentToDelete, setAdjustmentToDelete] = React.useState<EdgeAndAdjustment | null>(null);

    const handleDeleteCloseDialog = useCallback(async (result: boolean) => {

        setDeleteOpen(false);

        if (result && adjustmentToDelete) {
            await deleteAdjustment(adjustmentToDelete, cadActions);
        }

        cadActions.setActiveEdgeAdjustment(null);

        return result;

    }, [cadActions, adjustmentToDelete]);

    const onRowClick = useCallback((row: EdgeAndAdjustment) => {
        cadActions.setActiveEdgeAdjustment({ ...row.adjustment, adjustmentType: row.adjustmentType });
    }, [cadActions]);

    const getRowClassName = useCallback((item: EdgeAndAdjustment) => {
        if (CompareCommonProperties({ ...item.adjustment, adjustmentType: item.adjustmentType }, cadState.activeEdgeAdjustment)) {
            return "fv-selected";
        }
        return "fv-pointer";
    }, [cadState.activeEdgeAdjustment]);

    const columns: ITableColumn<EdgeAndAdjustment>[] = [
        {
            width: 150,
            fieldName: "edgeName",
            headerText: tm.Get("Edge")
        },
        {
            headerText: tm.Get("Adjustment"),
            fieldName: "description",
            render: (value: any, row: EdgeAndAdjustment) => {
                let iconComponent: JSX.Element | null = null;

                switch (row.adjustmentType) {
                    case EdgeAdjustmentsEnum.Shift:
                        iconComponent = <EdgeShiftIcon style={{ fontSize: 100 }} color={"primary"} />;
                        break;
                    case EdgeAdjustmentsEnum.Rake:
                        iconComponent = <EdgeRakeIcon style={{ fontSize: 100 }} color={"primary"} />;
                        break;
                    case EdgeAdjustmentsEnum.Elbow:
                        iconComponent = <EdgeElbowIcon style={{ fontSize: 100 }} color={"primary"} />;
                        break;
                }

                return <Grid container p={.5} alignItems={"center"} >
                    {iconComponent} {value}
                </Grid>
            }
        },
        {
            fieldName: "adjustmentType",
            headerText: "",
            width: 100,
            render: (value: any, row: EdgeAndAdjustment) => {
                return <>
                    <CADOperationCommands item={row}
                        events=
                        {{
                            onDeleteClick: (item: EdgeAndAdjustment) => {
                                cadActions.setActiveEdgeAdjustment({ ...item.adjustment, adjustmentType: item.adjustmentType });
                                setAdjustmentToDelete(item);
                                setDeleteOpen(true);
                            },
                            onEditClick: (item: EdgeAndAdjustment) => {
                                cadActions.setActiveEdgeAdjustment({ ...item.adjustment, adjustmentType: item.adjustmentType });
                                setAddingAdjustmentState(AddEdgeAdjustmentStateEnum.EnterAdjustment);
                            },
                        }}
                    />
                </>;
            }
        }
    ];

    return <>

        <Stack spacing={1.5} >

            <CADTable
                columns={columns}
                data={edgesAndAdjustments}
                onRowClick={onRowClick}
                getRowClassName={getRowClassName}
                footer={
                    <AddEdgeAdjustment layer={props.layer}
                        addingAdjustmentState={addingAdjustmentState}
                        setAddingAdjustmentState={setAddingAdjustmentState}
                    />
                }
            />

        </Stack>

        <AlertDialog
            open={deleteOpen}
            handleClose={handleDeleteCloseDialog}
            headerText={tm.Get("Delete Adjustment")}
            message={tm.GetWithParams("Are you sure you want to delete the adjustment '{0}' on edge '{1}'?", adjustmentToDelete?.description ?? "", adjustmentToDelete?.edgeName ?? "")} />

    </>;
}


export default EdgeAdjustmentsComponent;
