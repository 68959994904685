import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import React from 'react';

const EdgeRakeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {

return <SvgIcon {...props}>
        <path d="m 12 13 l 0 2 L 11 16 H 22 v 2 h -11 l 1 1 L 12 21 l -4 -4 z M 7 3 L 10 3 L 6 21 L 3 21 L 7 3" />
    </SvgIcon>
}

export default EdgeRakeIcon;