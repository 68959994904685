import { InputBase } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import React, { useCallback } from "react";
import ErrorAdornment from "./ErrorAdornment";
import { isNullOrWhiteSpace } from "helpers/objects";

interface IDataGridColumnEditProps {
    align?: "left" | "right" | "center",
    error?: string,
    maxLength?: number,
    inputMode?: "search" | "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal",
    pattern?: string,
    multiLine?: boolean
}

const DataGridColumnEdit: React.FC<GridRenderEditCellParams & IDataGridColumnEditProps> = (props) => {
    const { id, value, field, hasFocus, align, error, type, maxLength, inputMode, pattern, multiLine } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLInputElement | null>(null);
  
    React.useLayoutEffect(() => {
        if (hasFocus && ref.current) {
            ref.current.focus();
            ref.current.select();
        }
    }, [hasFocus, ref]);
  
    const handleValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value; // The new value entered by the user
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    }, [apiRef, field, id]);
  
    return <InputBase inputRef={ref} type={type} fullWidth value={value} onChange={handleValueChange} error={!isNullOrWhiteSpace(error)}
        endAdornment={!isNullOrWhiteSpace(error) ? <ErrorAdornment validationError={error!} /> : null} rows={1} multiline={multiLine}
        inputProps={{
            style:{padding: "0 16px", height: "100%", border: 0, fontSize: "14px", textAlign: align, overflow: "hidden"}, 
            maxLength: maxLength, 
            inputMode: inputMode, 
            pattern: pattern ,
        }} />
}

export default DataGridColumnEdit;