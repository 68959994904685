import { EdgeAdjustment } from "../Components/EdgeTools/AddEdgeAdjustment";
import { CADOperationTypeEnum } from "../enums";
import { ICADModel, ICADOperationLibraryItem, ICADOperationEditor } from "../interfaces";
import { CADActionTypes } from "./CADStateReducer";

export class CADActions {

    #dispatcher: React.Dispatch<CADActionTypes>;
    #debounceTimer: NodeJS.Timeout | null = null;

    constructor(dispatcher: React.Dispatch<CADActionTypes>) {
        this.#dispatcher = dispatcher;
    }

    setModel(model: ICADModel) {
        this.#dispatcher({ type: "SET_MODEL", model });
    }

    setHoveredOperation(uniqueID: number | null) {
        this.#dispatcher({ type: "SET_HOVERED_OPERATION", uniqueID });
    }

    setUnitSetID(unitSetID: number) {
        this.#dispatcher({ type: "SET_UNIT_SET_ID", unitSetID });
    }

    addOperationLibrary(opType: CADOperationTypeEnum, libraryItems: ICADOperationLibraryItem[]) {
        this.#dispatcher({ type: "ADD_OPERATION_LIBRARY", opType, libraryItems });
    }

    setOperationEditor(editor: ICADOperationEditor | null) {
        this.#clearDebounceTimer();
        this.#dispatcher({ type: "SET_OP_EDITOR", editor });
    }

    setOperationEditorLoading() {
        //Only lock down fields if the API response takes more than 250ms
        this.#clearDebounceTimer();
        this.#debounceTimer = setTimeout(() => {
            this.#dispatcher({ type: "SET_OP_EDITOR_LOADING", loading: true });
        }, 250);
    }

    setActiveEdgeAdjustment(edgeAdjustment: EdgeAdjustment | null) {
        this.#dispatcher({ type: "SET_ACTIVE_EDGE_ADJUSTMENT", edgeAdjustment });
    }

    setCurrentTab(tab: string) {
        this.#dispatcher({ type: "SET_CURRENT_TAB", tab });
    }


    #clearDebounceTimer() {
        if (this.#debounceTimer) {
            clearTimeout(this.#debounceTimer);
            this.#debounceTimer = null;
        }
    }

};
