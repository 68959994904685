import { EdgeAdjustment } from "./Components/EdgeTools/AddEdgeAdjustment";
import { CADOperationTypeEnum, CADPlacementReferenceTypeEnum, SegmentTypeEnum } from "./enums";
import { IArc, ICADEdge, ICADModel, ICADOperationPlacement, ICADOperationProperties, ICircle, IPoint, ISegment } from "./interfaces";

enum HighlightModeEnum {
    None = 0,
    Normal = 1,
    AddingCutout = 2,
    CopyingCutout = 3,
    SelectingCutouts = 4,
    SelectingEdges = 5,
    NarrowNone = 6,
    ReadableText = 7,
}
export class CADDrawingHelpers {

    #canvasBounds: DOMRect;
    #ctx: CanvasRenderingContext2D;
    #canvasRatio: number;
    #canvasOffset: IPoint;
    #imageRatio: number;
    #imageOffset: IPoint;


    constructor(ctx: CanvasRenderingContext2D, canvasBounds: DOMRect, canvasRatio: number, canvasOffset: IPoint, imageRatio: number, imageOffset: IPoint) {
        this.#canvasBounds = canvasBounds;
        this.#ctx = ctx;
        this.#canvasRatio = canvasRatio;
        this.#canvasOffset = canvasOffset;
        this.#imageRatio = imageRatio;
        this.#imageOffset = imageOffset;
    }


    public ClearCanvas(): void {
        this.#ctx.clearRect(0, 0, this.#canvasBounds.width, this.#canvasBounds.height);
    }

    public DrawHoveredOperation(uniqueID: number | null, model: ICADModel): void {

        if (!uniqueID) return;

        const hoveredOp = model.operations.find(op => op.uniqueID === uniqueID);

        if (!hoveredOp) return;

        //Set hovered line styles to be consistent with CORE
        this.#ctx.lineJoin = "round";
        this.#ctx.lineCap = "round";
        this.#ctx.strokeStyle = this.GetStrokeStyle(HighlightModeEnum.SelectingCutouts);
        this.#ctx.lineWidth = this.GetStrokeWidth(HighlightModeEnum.SelectingCutouts);

        if (hoveredOp.operationType === CADOperationTypeEnum.EdgeProcessing) {
            const edgeworkEdges: ISegment[] = [];

            if (hoveredOp.edgeName === "0" || hoveredOp.edgeName === "") {
                for (const edge of model.layers[0].edges) {
                    edgeworkEdges.push(...edge.edgeSegments);
                }
                this.DrawRegion(edgeworkEdges, false);
            }
            else {
                const edge = model.layers[0].edges.find(e => e.edgeName === hoveredOp.edgeName);
                if (edge) {
                    this.DrawRegion(edge.edgeSegments, false);
                }
            }

        } else {
            // draw the hovered operation  
            if (hoveredOp.regions) {
                for (const region of hoveredOp.regions) {
                    this.DrawRegion(region, (hoveredOp.operationType === CADOperationTypeEnum.InternalCutout));
                }
            }
        }

    }

    private GetCornerPoint(edge: ICADEdge, fromStart: boolean): IPoint {
        if (fromStart) {
            return edge.startPoint;
        } else {
            return edge.endPoint;
        }
    }

    private DrawCornerMarker(cornerPoint: IPoint, highlightMode: HighlightModeEnum) {

        const radius = this.ConvertCanvasToModelSpace(5);

        const circle: ICircle = { type: SegmentTypeEnum.Circle, centerPoint: cornerPoint, radius: radius, startPoint: cornerPoint, endPoint: cornerPoint, side: 0 };
        this.#ctx.strokeStyle = "red"
        this.#ctx.lineWidth = 2;
        this.DrawRegion([circle], false);

        this.#ctx.strokeStyle = this.GetStrokeStyle(highlightMode);
        this.#ctx.lineWidth = this.GetStrokeWidth(highlightMode);
        this.DrawRegion([circle], false);

    }


    private DrawCrosshair(centerPoint: IPoint, radius: number, highlightMode: HighlightModeEnum) {

        this.#ctx.strokeStyle = "black"
        this.#ctx.lineWidth = 1;

        const xyCircle: ICircle = { type: SegmentTypeEnum.Circle, centerPoint: centerPoint, radius: radius, startPoint: centerPoint, endPoint: centerPoint, side: 0 };
        const crossHair = [
            { type: SegmentTypeEnum.Line, startPoint: { x: centerPoint.x - (radius / 2), y: centerPoint.y }, endPoint: { x: centerPoint.x + (radius / 2), y: centerPoint.y }, side: 0 },
            { type: SegmentTypeEnum.Line, startPoint: { x: centerPoint.x, y: centerPoint.y - (radius / 2) }, endPoint: { x: centerPoint.x, y: centerPoint.y + (radius / 2) }, side: 0 }
        ]

        this.DrawRegion([xyCircle], false);
        this.DrawRegion(crossHair, false);

        this.#ctx.strokeStyle = this.GetStrokeStyle(highlightMode);
        this.#ctx.lineWidth = this.GetStrokeWidth(highlightMode);
        this.DrawRegion([xyCircle], false);

    }

    public DrawAddingEditingAdjustment(model: ICADModel, adjustment: EdgeAdjustment | null) {

        if (!adjustment) return;

        const layer = model.layers[0];
        const edge = layer.edges.find(e => e.edgeName === adjustment.edgeName);

        if (edge) {
            this.#ctx.lineJoin = "round";
            this.#ctx.lineCap = "round";
            this.#ctx.strokeStyle = this.GetStrokeStyle(HighlightModeEnum.SelectingEdges);
            this.#ctx.lineWidth = this.GetStrokeWidth(HighlightModeEnum.SelectingEdges);
            this.DrawRegion(edge.edgeSegments, false);
        }

    }

    public DrawAddingOperation(placement: ICADOperationPlacement | undefined, operation: ICADOperationProperties | undefined, model: ICADModel) {

        if (!placement || !operation) return;

        const layer = model.layers[0];

        let edge: ICADEdge | undefined = undefined;

        this.#ctx.lineJoin = "round";
        this.#ctx.lineCap = "round";
        this.#ctx.strokeStyle = this.GetStrokeStyle(HighlightModeEnum.AddingCutout);
        this.#ctx.lineWidth = this.GetStrokeWidth(HighlightModeEnum.AddingCutout);

        switch (operation.placementReferenceType) {
            case CADPlacementReferenceTypeEnum.Edge:

                edge = layer.edges.find(e => e.edgeName === placement.edgeName);
                if (edge) {
                    this.DrawRegion(edge.edgeSegments, false);
                }

                break;

            case CADPlacementReferenceTypeEnum.Corner:

                edge = layer.edges.find(e => e.edgeName === placement.edgeName);
                if (edge) {
                    const cornerPoint = this.GetCornerPoint(edge, placement.fromStart);
                    this.DrawCornerMarker(cornerPoint, HighlightModeEnum.AddingCutout);
                }

                break;

            case CADPlacementReferenceTypeEnum.CornerAndXY:

                edge = layer.edges.find(e => e.edgeName === placement.edgeName);
                if (edge) {
                    const cornerPoint = this.GetCornerPoint(edge, placement.fromStart);
                    this.DrawCornerMarker(cornerPoint, HighlightModeEnum.AddingCutout);

                    //Future: this must use relative coordinates based on the corner being measure from
                    const dx = parseFloat(operation.x) * (operation.xPositive ? 1 : -1);
                    const dy = parseFloat(operation.y) * (operation.yPositive ? 1 : -1);

                    if (!isNaN(dx) && !isNaN(dy)) {
                        const xyPoint: IPoint = { x: cornerPoint.x + dx, y: cornerPoint.y + dy };
                        this.DrawCrosshair(xyPoint, this.ConvertCanvasToModelSpace(10), HighlightModeEnum.AddingCutout);
                    }
                }

                break;

            case CADPlacementReferenceTypeEnum.EdgeAndDistanceFromCorner:
                edge = layer.edges.find(e => e.edgeName === placement.edgeName);
                if (edge) {
                    this.DrawRegion(edge.edgeSegments, false);

                    const cornerPoint = this.GetCornerPoint(edge, placement.fromStart);
                    this.DrawCornerMarker(cornerPoint, HighlightModeEnum.AddingCutout);
                }

                break;
        }

    }

    private ConvertModelToImageSpace(point: IPoint): IPoint {
        const x = point.x * this.#imageRatio + this.#imageOffset.x;
        const y = point.y * this.#imageRatio + this.#imageOffset.y;
        return { x, y };
    }


    private ConvertImageToCanvasSpace(point: IPoint): IPoint {
        const x = point.x * this.#canvasRatio + this.#canvasOffset.x;
        const y = point.y * this.#canvasRatio + this.#canvasOffset.y;
        return { x, y };
    }

    private ConvertModelToCanvasSpace(point: IPoint): IPoint {
        return this.ConvertImageToCanvasSpace(this.ConvertModelToImageSpace(point));
    }

    private ConvertModelToConvasSpace(distance: number): number {
        return distance * this.#canvasRatio * this.#imageRatio;
    }

    private ConvertCanvasToModelSpace(distance: number): number {
        return distance / (this.#canvasRatio * this.#imageRatio);
    }

    private DrawRegion(region: ISegment[], closeRegion: boolean): void {
        // draw the region

        if (region.length === 0) return;

        if (region.length === 1 && region[0].type === SegmentTypeEnum.Circle) {

            const circle = region[0] as ICircle;
            const centerPoint = this.ConvertModelToCanvasSpace(circle.centerPoint);
            const radius = this.ConvertModelToConvasSpace(circle.radius);

            this.#ctx.beginPath();
            this.#ctx.arc(centerPoint.x, centerPoint.y, radius, 0, 2 * Math.PI);

        } else if (region.length === 1 && region[0].type === SegmentTypeEnum.Arc) {

            const arc = region[0] as IArc;
            const centerPoint = this.ConvertModelToCanvasSpace(arc.centerPoint);
            const radius = this.ConvertModelToConvasSpace(arc.radius);
            this.#ctx.beginPath();
            this.#ctx.arc(centerPoint.x, centerPoint.y, radius, arc.startAngle, arc.endAngle, arc.isClockwise);

        } else {

            let lastPoint: IPoint | null = null;

            this.#ctx.beginPath();

            for (let i = 0; i < region.length; i++) {
                const segment = region[i];
                if (lastPoint === null || lastPoint.x !== segment.startPoint.x || lastPoint.y !== segment.startPoint.y) {
                    const startPoint = this.ConvertModelToCanvasSpace(segment.startPoint);
                    this.#ctx.moveTo(startPoint.x, startPoint.y);
                }

                if (segment.type === SegmentTypeEnum.Line) {

                    const endPoint = this.ConvertModelToCanvasSpace(segment.endPoint);
                    this.#ctx.lineTo(endPoint.x, endPoint.y);

                } else if (segment.type === SegmentTypeEnum.Arc) {

                    const arc = segment as IArc;
                    const centerPoint = this.ConvertModelToCanvasSpace(arc.centerPoint);
                    const radius = this.ConvertModelToConvasSpace(arc.radius);
                    this.#ctx.arc(centerPoint.x, centerPoint.y, radius, arc.startAngle, arc.endAngle, arc.isClockwise);

                }

                lastPoint = segment.endPoint;

            }

        }

        if (closeRegion) {
            this.#ctx.closePath();
        }

        this.#ctx.stroke();
    }

    private GetStrokeStyle(highlightMode: HighlightModeEnum): string {

        switch (highlightMode) {
            case HighlightModeEnum.None:
            case HighlightModeEnum.NarrowNone:
                return "rgba(0, 0, 0, 0)";

            case HighlightModeEnum.Normal:
            case HighlightModeEnum.SelectingCutouts:
            case HighlightModeEnum.SelectingEdges:
                return "rgba(0, 0, 255, 0.3921)"; //CORE uses a=100 which when divided by 255 is 0.3921

            case HighlightModeEnum.AddingCutout:
                return "rgba(255, 0, 0, 0.3137)"; //CORE uses a=80 which when divided by 255 is 0.3137

            case HighlightModeEnum.CopyingCutout:
                return "rgba(0, 255, 0, 0.3137)"; //CORE uses a=80 which when divided by 255 is 0.3137

            case HighlightModeEnum.ReadableText:
                return "rgba(255, 255, 2558, 1)";

        }

    }

    private GetStrokeWidth(highlightMode: HighlightModeEnum): number {

        let penWidth = 10;

        switch (highlightMode) {
            case HighlightModeEnum.AddingCutout:
            case HighlightModeEnum.CopyingCutout:
            case HighlightModeEnum.SelectingCutouts:
                penWidth = 15;
                break;
            case HighlightModeEnum.NarrowNone:
                penWidth = 5;
                break;
        }

        return this.#canvasRatio * penWidth; //taken from DrawingMethods.GetHighLightPen()
    }

}