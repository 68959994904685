import { useTranslations } from "@fenetech/translations";
import { useMemo } from "react";

export function useMeasurementUnitLabel(unitSetID:number){

    const tm = useTranslations();

    const measurementLabel = useMemo(() => {
        if (unitSetID === 1)
            return tm.Get("inches");
        else
            return tm.Get("mm");
    }, [unitSetID, tm]);

    return measurementLabel;

}

