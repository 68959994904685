import { ICADModel, ICADOperationLibraryItem, ICADOperationEditor } from "../interfaces";
import { ICADState } from "./CADContext";
import { EdgeAdjustment } from "../Components/EdgeTools/AddEdgeAdjustment";

export type CADActionTypes =
    { type: "SET_MODEL", model: ICADModel, } |
    { type: "SET_HOVERED_OPERATION", uniqueID: number | null, } |
    { type: "SET_UNIT_SET_ID", unitSetID: number, } |
    { type: "ADD_OPERATION_LIBRARY", opType: number, libraryItems: ICADOperationLibraryItem[] } |
    { type: "SET_OP_EDITOR", editor: ICADOperationEditor | null, } |
    { type: "SET_OP_EDITOR_LOADING", loading: boolean, } |
    { type: "SET_CURRENT_TAB", tab: string,} |
    { type: "SET_ACTIVE_EDGE_ADJUSTMENT", edgeAdjustment: EdgeAdjustment | null };


const cadReducer: React.Reducer<ICADState, CADActionTypes> = (state, action) => {
    switch (action.type) {

        case "SET_UNIT_SET_ID":
            return {
                ...state, unitSetID: action.unitSetID,
            };

        case "SET_MODEL":
            return {
                ...state, model: action.model,
            };

        case "SET_HOVERED_OPERATION":
            return {
                ...state, hoveredOperationID: action.uniqueID,
            };

        case "ADD_OPERATION_LIBRARY":
            return {
                ...state, operationLibraries: {
                    ...state.operationLibraries,
                    [action.opType]: action.libraryItems
                }
            };

        case "SET_OP_EDITOR":
            return {
                ...state, operationEditor: action.editor, operationEditorLoading: false,
            };

        case "SET_OP_EDITOR_LOADING":
            return {
                ...state, operationEditorLoading: action.loading,
            };

        case "SET_ACTIVE_EDGE_ADJUSTMENT":
            return {
                ...state, activeEdgeAdjustment: action.edgeAdjustment,
            };

        case "SET_CURRENT_TAB":
            return {
                ...state, currentTab: action.tab,
            };

        default:
            return state;
    }
};

export default cadReducer;