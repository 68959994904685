import React, { PropsWithChildren } from "react";
import cadReducer from "./CADStateReducer";
import { CADActions } from "./CADActions";
import { ICADModel, ICADOperationLibraryItem, ICADOperationEditor } from "../interfaces";

import { Dictionary } from "lodash";
import { EdgeAdjustment } from "../Components/EdgeTools/AddEdgeAdjustment";

export interface ICADState {
    model: ICADModel | null;
    unitSetID: number;
    hoveredOperationID: number | null;
    operationLibraries: Dictionary<number, ICADOperationLibraryItem[]>;
    operationEditor: ICADOperationEditor | null;
    operationEditorLoading: boolean;
    activeEdgeAdjustment: EdgeAdjustment | null;
    currentTab:string;
}

const DEFAULT_STATE: ICADState = {
    model: null,
    hoveredOperationID: null,
    unitSetID: 1,
    operationLibraries: {},
    operationEditor: null,
    operationEditorLoading: false,
    activeEdgeAdjustment: null,
    currentTab:""
}

const CADStateContext = React.createContext<ICADState>(DEFAULT_STATE);
const CADActionContext = React.createContext<CADActions>(null!);

export const CADContextProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {

    const [state, dispatch] = React.useReducer(cadReducer, DEFAULT_STATE);

    const actions = React.useMemo<CADActions>(() => {
        return new CADActions(dispatch);
    }, [dispatch]);

    return (
        <CADStateContext.Provider value={state}>
            <CADActionContext.Provider value={actions}>
                {children}
            </CADActionContext.Provider>
        </CADStateContext.Provider>
    );
}

export function useCADState() {
    return React.useContext(CADStateContext);
}

export function useCADActions() {
    return React.useContext(CADActionContext);
}