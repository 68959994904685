import { IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import PricingFieldForm from "./PricingFieldForm";
import { useFieldArray, useFormContext } from "react-hook-form";
import { PricingMethodEnum } from "helpers/enums";
import { IDiscountTableGrouping } from "helpers/interfaces";
import useDiscountTables from "helpers/context/SelectionValues/useDiscountTables";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import AutocompleteForm from "components/Common/AutocompleteForm";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import useMessageBox from "helpers/context/Page/useMessageBox";

interface IProps {
    pricingMethod: PricingMethodEnum,
    isPricingMethodPercentBased: boolean,
    readOnly: boolean,
    parentCustomerID: string
}

const CustomerPricingCategories: React.FC<IProps> = ({pricingMethod, isPricingMethodPercentBased, readOnly, parentCustomerID}: IProps) => {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4 });
    const discountTables: IDiscountTableGrouping[] | undefined = useDiscountTables(parentCustomerID);
    const messageBox = useMessageBox();
    const { control, formState, watch, getValues, setValue } = useFormContext();
    const { fields } = useFieldArray({
        control: control,
        name: "pricing"
    });

    const getSecondColumnTitle = React.useMemo(() => {
        switch (pricingMethod) {
            case PricingMethodEnum.Discount:
                return isPricingMethodPercentBased ? tm.Get("Discount %") : tm.Get("Discount Mult.");
            case PricingMethodEnum.Margin:
                return tm.Get("Gross Profit Margin");
            case PricingMethodEnum.Markup:
                return isPricingMethodPercentBased ? tm.Get("Markup %") : tm.Get("Markup Mult.");
            default: return "";
        }
    }, [pricingMethod, isPricingMethodPercentBased, tm]);

    const getValidDiscountTables = React.useCallback((categoryID: number) => {
        return discountTables?.find(dtg => dtg.tableType === pricingMethod)?.discountTables.filter(dt => dt.categoryID === categoryID) ?? [];
    }, [pricingMethod, discountTables]);

    const fillDownHandler = React.useCallback((fillDownIndex: number) => {
        const sourceValue = getValues(`pricing.${fillDownIndex}.discountPercent`);
        for (var i: number = fillDownIndex + 1; i < fields.length; i++) {
            setValue(`pricing.${i}.discountPercent`, sourceValue, {shouldValidate: true, shouldDirty: true});
        }
    }, [getValues, setValue, fields.length]);
    
    const promptToFillDown = React.useCallback((fillDownIndex: number) => {
        if (formState.errors?.pricing && formState.errors.pricing[fillDownIndex]) {
            messageBox.Show({
                message: formState.errors.pricing[fillDownIndex].discountPercent.message, 
                title: tm.Get("Customers")
            });
        } else {
            messageBox.Show({
                message: tm.Get("Are you sure you would like to fill down the value?"), 
                title: tm.Get("Customers"),
                yesNoPrompt: true,
                callback: (result) => {
                    if (result) fillDownHandler(fillDownIndex);
                }
            });
        }
    }, [messageBox, tm, formState.errors.pricing, fillDownHandler]);

    if (fields.length === 0) return <></>;
    return <>
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: "33%" }}><Typography>{tm.Get("Category")}</Typography></TableCell>
                        <TableCell sx={{ width: "33%", minWidth: "135px" }}><Typography>{getSecondColumnTitle}</Typography></TableCell>
                        <TableCell sx={{ width: "33%" }}><Typography>{tm.Get("Table")}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, index) => {
                        const category = watch(`pricing.${index}.category`);
                        const categoryID = watch(`pricing.${index}.id`);
                        return <TableRow key={field.id}>
                            <TableCell>{category}</TableCell>
                            <TableCell>
                                <Stack direction="row">
                                    <PricingFieldForm
                                        pricingMethod={pricingMethod}
                                        isPricingMethodPercentBased={isPricingMethodPercentBased}
                                        fieldName={`pricing.${index}.discountPercent`}
                                        readOnly={readOnly}
                                        lnf={lnf}
                                        fullWidth
                                        />
                                    {!readOnly &&
                                        <Tooltip title={tm.Get("Fill Down")}>
                                            <IconButton onClick={() => promptToFillDown(index)} tabIndex={-1}>
                                                <ArrowCircleDownIcon fontSize="small" color="primary"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <AutocompleteForm
                                    dataSource={getValidDiscountTables(categoryID)}
                                    fieldName={`pricing.${index}.discountTableID`}
                                    valueField="discountTableID"
                                    displayField="description"
                                    translateDisplayField={false}
                                    readOnly={readOnly}
                                    label=""
                                    defaultValue={-1}
                                    size="small"
                                />
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

export default CustomerPricingCategories;