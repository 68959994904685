import isNumeric from "fast-isnumeric";
import React, { useState, useCallback, useMemo } from "react";
import { TextField, IconButton, Checkbox, Tooltip, Autocomplete } from "@mui/material";
import { StyledRow, StyledCell } from "./RowStyles"
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import { useTranslations } from "@fenetech/translations";
import useIsMobile from "helpers/hooks/useIsMobile";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { ISimpleListItem, IPriceTablePartGridRow } from "helpers/interfaces";
import { PricingMethodEnum } from "helpers/enums";
import SetupAPI from "components/Setup/SetupAPI"
import HelperFunctions from "components/Setup/PriceTables/HelperFunctions";
import ErrorAdornment from "components/Common/ErrorAdornment";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";

interface IProps {
    tableID: string,
    schedules: ISimpleListItem[],
    part: IPriceTablePartGridRow,
    index: number,
    usesMultiplier: boolean,
    tableType: PricingMethodEnum,
    fillDownHandler: (index: number, scheduleID: number, discount: string, fixedPrice: boolean, checked: boolean) => void,
    cf: ICurrencyFormatter,
    lnf: ILocaleNumberFormatter
}

const PartsGridRow = ({tableID, schedules, part, index, usesMultiplier, tableType, fillDownHandler, cf, lnf}: IProps) => {
    const tm = useTranslations();
    const isMobile = useIsMobile();
    const messageBox = useMessageBox();

    const [scheduleValue, setScheduleValue] = useState<number>(part.wcPriceScheduleID);
    const [discountValue, setDiscountValue] = useState<number | null>(part.discount ?? part.fixedPrice);
    const [displayDiscountValue, setDisplayDiscountValue] = useState<string>(HelperFunctions.FormatDiscountValue(part.discount, part.fixedPrice, usesMultiplier, tableType, cf, lnf));
    const [isOptionValue, setIsOptionValue] = useState<boolean>(part.optionDiscount);
    const [errorText, setErrorText] = useState<string>("");

    const handleDiscountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayDiscountValue(e.target.value);
    }, []);

    const handleDiscountBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        let value: string = e.target.value;
        if (value === (discountValue === null ? "" : lnf.Format(discountValue))) {
            return;
        }
        if (value === "") {
            setIsOptionValue(false);
            setDiscountValue(null);
            SetupAPI.UpdatePartPricingItemWithValue(parseInt(tableID), part.partNo, part.partNoSuffix, "", null, false, false);
        } else {
            let strippedValue = cf.Parse(value);
            if (isNumeric(strippedValue)) {
                if (!cf.IsCurrencyFormat(value)) {
                    let numericValue = strippedValue;
                    let displayValueToShow = lnf.Format(numericValue);
                    let errorText = "";
                    if (usesMultiplier) {
                        errorText = HelperFunctions.ValidateMultiplier(numericValue, tableType, tm);
                        if (errorText !== "") {
                            // Error
                            setErrorText(errorText);
                            return;
                        }
                        numericValue = HelperFunctions.ConvertMultiplierToPercent(numericValue, tableType);
                    }
                    errorText = HelperFunctions.ValidatePercent(numericValue, tableType, tm);
                    if (errorText !== "") {
                        // Error
                        setErrorText(errorText);
                        return;
                    }
                    // Valid and not a Fixed Price
                    setScheduleValue(0);
                    setDiscountValue(numericValue);
                    setDisplayDiscountValue(displayValueToShow);
                    SetupAPI.UpdatePartPricingItemWithValue(parseInt(tableID), part.partNo, part.partNoSuffix, "", numericValue, false, isOptionValue);
                } else {
                    // Fixed Price
                    setScheduleValue(0);
                    setDiscountValue(strippedValue);
                    setDisplayDiscountValue(cf.Format(strippedValue));
                    SetupAPI.UpdatePartPricingItemWithValue(parseInt(tableID), part.partNo, part.partNoSuffix, "", strippedValue, true, isOptionValue);
                }
            } else {
                // NaN error
                setErrorText(tm.Get("Invalid Value"));
                return;
            }
        }
        setErrorText("");
    }, [cf, lnf, tm, discountValue, isOptionValue, usesMultiplier, tableID, tableType, part.partNo, part.partNoSuffix]);

    const handleScheduleChange = useCallback((data: ISimpleListItem) => {
        setDiscountValue(null);
        setDisplayDiscountValue("");
        setIsOptionValue(false);
        setErrorText("");
        setScheduleValue(data.id);
        SetupAPI.UpdatePricingItemWithSchedule(parseInt(tableID), part.partNo, part.partNoSuffix, "", data.id);
    }, [tableID, part.partNo, part.partNoSuffix]);

    const handleIsOptionChange = useCallback((e: any) => {
        let value = e.target.checked;
        if (discountValue === null) {
            // Cannot check: must have a value
            messageBox.Show({
                title: tm.Get("Price Tables"),
                message: tm.Get("A value must be filled in for this part before this box can be checked.")
            });
            return;
        }
        if (errorText !== "") {
            // Cannot check: must resolve issues
            messageBox.Show({
                title: tm.Get("Price Tables"),
                message: tm.Get("Errors must be fixed before this box can be checked.")
            });
            return;
        }
        // We can update
        setIsOptionValue(value);
        SetupAPI.UpdatePartPricingItemWithValue(parseInt(tableID), part.partNo, part.partNoSuffix, "", discountValue, cf.IsCurrencyFormat(displayDiscountValue), value);
    }, [discountValue, displayDiscountValue, tableID, part.partNo, part.partNoSuffix, errorText, tm, cf, messageBox]);

    const promptToFillDown = useCallback(() => {
        messageBox.Show({
            message: tm.Get("Are you sure you would like to fill down the value?"), 
            title: tm.Get("Price Tables"),
            yesNoPrompt: true,
            callback: (result) => {
                if (result) fillDownHandler(index, scheduleValue, discountValue?.toString() ?? "", cf.IsCurrencyFormat(displayDiscountValue), isOptionValue);
            }
        });
    }, [messageBox, tm, cf, discountValue, displayDiscountValue, fillDownHandler, index, isOptionValue, scheduleValue]);

    const scheduleList = useMemo(() => {
        return [{id: 0, description: tm.Get("{None}")}].concat(schedules);
    }, [schedules, tm]);

    const schedule = useMemo(() => {
        return scheduleList.find((s) => s.id === scheduleValue) ?? scheduleList[0];
    }, [scheduleList, scheduleValue]);

    return <>
        <StyledRow>
            {isMobile && <>
                <StyledCell>{part.partNo} - {part.description}</StyledCell>
            </>}
            {!isMobile && <>
                <StyledCell>{part.partNo}</StyledCell>
                <StyledCell>{part.description}</StyledCell>
            </>}
            <StyledCell>
                <Autocomplete
                    sx={{marginY: 1}}
                    autoComplete
                    autoHighlight
                    autoSelect
                    selectOnFocus
                    disableClearable
                    handleHomeEndKeys
                    options={scheduleList}
                    fullWidth
                    size="small"
                    disabled={part.isSurchargePart}
                    getOptionLabel={(option : ISimpleListItem) => option.description}
                    renderInput={(params) => <TextField
                        variant="outlined"
                        {...params}
                    />}
                    value={schedule}
                    onChange={(_, data) => handleScheduleChange(data)}
                />
            </StyledCell>
            <StyledCell>
                <TextField size="small" value={displayDiscountValue} fullWidth 
                    error={errorText !== ""}
                    onChange={handleDiscountChange}
                    onBlur={handleDiscountBlur}
                    variant="outlined"
                    InputProps={{
                        inputProps: {style: { textAlign: "right" }},
                        endAdornment: errorText !== "" ? 
                            <ErrorAdornment validationError={errorText}/> : null
                    }}
                />
            </StyledCell>
            <StyledCell style={{ textAlign: "center" }}>
                <Checkbox checked={isOptionValue} onChange={handleIsOptionChange}/>
            </StyledCell>
            <StyledCell style={{ textAlign: "right" }}>
                <Tooltip title={tm.Get("Fill Down")}>
                    <IconButton onClick={promptToFillDown} tabIndex={-1}>
                        <ArrowCircleDownIcon fontSize="small" color="primary"/>
                    </IconButton>
                </Tooltip>
            </StyledCell>
        </StyledRow>
    </>
}

export default PartsGridRow;