import { Grid, Stack, Container, useTheme } from "@mui/material";
import React from 'react';
import CADImage from "./CADImage";
import CADOperationsTabs from "./CADOperationsTabs";
import CADUISettings from "./CADUISettings";

interface ICADMainDesktopProps { }

const CADMainDesktop: React.FC<ICADMainDesktopProps> = (props: ICADMainDesktopProps) => {

    const theme = useTheme();

    return <Grid container position="relative">

        {/*Left Pane*/}
        <Grid item xs={7} >
            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="column" spacing={1} mt={2} mx={1}>
                        <Stack spacing={1}>
                            <CADOperationsTabs />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>

        {/*Right Pane*/}
        <Grid container item xs={5}>
            <Container disableGutters>
                <Container disableGutters sx={{ position: "sticky", top: "40px" }}>
                    <CADImage />
                    <CADUISettings style={{marginTop:theme.spacing(1)}}/>
                </Container>
            </Container>
        </Grid>

    </Grid>;
}

export default CADMainDesktop;
