import { SxProps, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Theme, alpha, styled, tableCellClasses } from "@mui/material";
import { StyledCell } from "components/Quotes/QuoteEntry/LineItems/Rows/RowStyles";
import { useCallback } from "react";

const StyledRow = styled(TableRow)(({ theme }) => ({
    [`&:nth-of-type(even)`]: {
        backgroundColor: alpha(theme.palette.secondary.light, 0.1),
        '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.light, 0.15),
        },
    },
    "&:hover": {
        backgroundColor: alpha(theme.palette.secondary.light, 0.025),
    },

    "&.fv-selected": {
        backgroundColor: alpha(theme.palette.primary.dark, 0.2),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.dark, 0.25),
        },
    },
    "&.fv-pointer": {
        cursor: "pointer"
    },
}));




export interface ITableColumn<T> {
    width?: string | number | undefined;
    headerText?: string | null | undefined;
    fieldName?: keyof T | "";
    render?: (value: any  , row: T) => JSX.Element|null|undefined;
}

export type RowTemplate<T> = (props: IRowTemplateProps<T>) => JSX.Element;
export type HeaderRowTemplate<T> = (props: { columns: ITableColumn<T>[] }) => JSX.Element;

interface ICADTableProps<T> {
    columns: ITableColumn<T>[];
    data: T[];
    onRowClick?: (row: T) => void;
    getRowClassName?: (row: T) => string;
    headerTemplate?: HeaderRowTemplate<T>;
    rowTemplate?: RowTemplate<T>;
    footer?: JSX.Element;
}


export const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
}));

const CADTable = <T,>(props: ICADTableProps<T>) => {

    return <TableContainer>

        <Table sx={{ width: "100%" }} size="small" padding="none">

            <TableHead>

                {props.headerTemplate ?
                    <TableRow style={{userSelect:"none"}}>
                        <props.headerTemplate columns={props.columns} />
                    </TableRow>
                    :
                    <TableRow style={{userSelect:"none"}}>
                        {props.columns.map((column, index) => {
                            return <StyledHeaderCell key={index} sx={{ width: column.width }} >
                                {column.headerText}
                            </StyledHeaderCell>
                        })}
                    </TableRow>
                }

            </TableHead>

            <TableBody>

                {props.data.map((row, index) => {

                    if (props.rowTemplate) {
                        return <props.rowTemplate key={index} columns={props.columns} row={row} onRowClick={props.onRowClick} getRowClassName={props.getRowClassName} />
                    } else {
                        return <StandardRow key={index} columns={props.columns} row={row} onRowClick={props.onRowClick} getRowClassName={props.getRowClassName} />
                    }

                })}

            </TableBody>

            {props.footer &&
                <TableFooter>
                    <TableCell colSpan={props.columns.length}   >
                        {props.footer}
                    </TableCell>
                </TableFooter>
            }
        </Table>

    </TableContainer>

}


export interface IRowTemplateProps<T> {
    columns: ITableColumn<T>[];
    row: T;
    sx?: SxProps<Theme>;
    minHeight?: string;
    onRowClick?: (row: T) => void;
    getRowClassName?: (row: T) => string;
}


export const StandardRow = <T,>(props: IRowTemplateProps<T>) => {

    const onRowClick = useCallback(() => {
        if (props.onRowClick) {
            props.onRowClick(props.row)
        }
    }, [props.onRowClick, props.row])

    return <StyledRow onClick={onRowClick} className={props.getRowClassName ? props.getRowClassName(props.row) : ""} >

        {props.columns.map((column, index) => {

            const value = props.row[column.fieldName as string]
            const height = (props.minHeight && index === 0) ? props.minHeight : "auto"

            if (column.render) {

                return <StyledCell key={index} height={height} >
                    {column.render(value, props.row)}
                </StyledCell>

            } else if (Array.isArray(value)) {

                return <StyledCell key={index} height={height}  >
                    {value.map((item, index) => {
                        return <div key={index} >{item}</div>
                    })}
                </StyledCell>

            } else {

                return <StyledCell key={index} height={height} >
                    {value}
                </StyledCell>
            }

        })}

    </StyledRow>
}

export interface IEditableRowProps<T> extends IRowTemplateProps<T> {
    editing: boolean;
    setEditing: (editing: boolean) => void;
    editTemplate: RowTemplate<T>;
}

export const EditableRow = <T,>(props: IEditableRowProps<T>) => {

    return <>
        <StandardRow columns={props.columns} row={props.row} sx={props.sx} minHeight={props.minHeight} onRowClick={props.onRowClick} getRowClassName={props.getRowClassName} />

        {props.editing && <>
            <TableRow></TableRow> {/* Dummy row to prevent alternating row color from getting messed up due to the extra row */}
            <StyledRow>
                <TableCell width="100%" colSpan={props.columns.length} padding="normal">
                    <props.editTemplate row={props.row} columns={props.columns} />
                </TableCell>
            </StyledRow>
        </>
        }
    </>;

}


export default CADTable;