import { IOrderAttachment } from "models/IAttachment";
import React from "react";
import OrderAttachmentsAPI from "./OrderAttachmentsAPI";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useTranslations } from "@fenetech/translations";

export interface IOrderAttachments {
    attachments: IOrderAttachment[],
    isLoading: boolean,
    isEnabled: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setAlert: React.Dispatch<React.SetStateAction<{ text: string; alertType: AlertColor; visible: boolean; }>>
}

const initialState: IOrderAttachments = {
    attachments: [],
    isLoading: false,
    isEnabled: false,
    setIsLoading: () => { return; },
    setAlert: () => { return; }
}

const OrderAttachmentsContext = React.createContext<IOrderAttachments>(initialState);

interface IProps {
    oKey: number,
}

export const OrderAttachmentsContextProvider: React.FC<React.PropsWithChildren<IProps>> = (props: React.PropsWithChildren<IProps>) => {

    const { oKey } = props;

    const [orderAttachments, setOrderAttachments] = React.useState<IOrderAttachment[]>(initialState.attachments);
    const [isEnabled, setIsEnabled] = React.useState<boolean>(initialState.isEnabled);
    const [isLoading, setIsLoading] = React.useState<boolean>(initialState.isLoading);
    const [alert, setAlert] = React.useState<{ text: string, alertType: AlertColor, visible: boolean }>({text: "", alertType: "success", visible: false});

    const tm = useTranslations();

    const queryOrderAttachments = React.useCallback(async (oKey: number) => {
        const enabled = await OrderAttachmentsAPI.GetAttachmentsEnabledAsync();
        setIsEnabled(enabled);

        if (enabled) {
            const result = await OrderAttachmentsAPI.GetOrderAttachmentsAsync(oKey);
            if (result.baseContent.message !== "" && result.baseContent.params) {
                setAlert({ text: tm.GetWithParams(result.baseContent.message, ...result.baseContent.params), alertType: "warning", visible: true});
            } else {
                return result.payload;
            }
        }

        return null;
    }, [tm]);

    React.useEffect(() => {
        if (!oKey) {
            return;
        }
        setIsLoading(true);
        try {
            queryOrderAttachments(oKey).then((attachments: IOrderAttachment[] | null) => {
                if (attachments) {
                    setOrderAttachments(attachments);
                }
            });
        }
        finally {
            setIsLoading(false);
        }
    }, [oKey, queryOrderAttachments]);

    const handleClose = React.useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert({ ...alert, visible: false });
    }, [alert]);

    const state = React.useMemo(() => {
        return {
            attachments: orderAttachments,
            isEnabled: isEnabled,
            isLoading: isLoading,
            setIsLoading: setIsLoading,
            setAlert: setAlert
        }
    }, [orderAttachments, isEnabled, isLoading])

    return <>
        <OrderAttachmentsContext.Provider value={state}>
            <Snackbar open={alert.visible} autoHideDuration={5000} onClose={handleClose} >
                <Alert onClose={handleClose} severity={alert.alertType} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert.text}</Alert>
            </Snackbar>

            {props.children}
        </OrderAttachmentsContext.Provider>
    </>;
}

export default OrderAttachmentsContext;