import { Grid, Typography } from "@mui/material";
import ErrorAdornment from "components/Common/ErrorAdornment";
import React, { useCallback } from 'react';
import { IAdjustmentIdentifier, IEdgeElbow } from "../../interfaces";
import { useTranslations } from "@fenetech/translations";
import AdjustmentSelection from "./AdjustmentSelection";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import EdgeElbowBreakDistance from "./EdgeElbowBreakDistance";
import { EdgeAdjustment } from "./AddEdgeAdjustment";
import { EdgeAdjustmentsEnum, ShiftDirection } from "../../enums";
import { CADHelpers } from "../../CADHelpers";

interface IEdgeElbowComponentProps {
    edgeName: string;
    existingElbow: IEdgeElbow | null;
    handleCanApply: (value: boolean, item: EdgeAdjustment | null) => void;
    errorMessage: string;
}

const EdgeElbowComponent: React.FC<IEdgeElbowComponentProps> = ({ edgeName, existingElbow, handleCanApply, errorMessage }) => {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const [breakOffset, setBreakOffset] = React.useState<string>(lnf.Format(Math.abs(existingElbow?.breakOffset ?? 0)));
    const [breakDirection, setBreakDirection] = React.useState<ShiftDirection>((existingElbow?.breakOffset ?? 0) > 0 ? "Out" : "In");
    const [oppositeOffset, setOppositeOffset] = React.useState<string>(lnf.Format(Math.abs(existingElbow?.overallOffset ?? 0)));
    const [oppositeDirection, setOppositeDirection] = React.useState<ShiftDirection>((existingElbow?.overallOffset ?? 0) > 0 ? "Out" : "In");
    const [breakDistance, setBreakDistance] = React.useState<string>(lnf.Format(existingElbow?.breakDistance ?? 0));
    const [startFixed, setStartFixed] = React.useState<boolean>(existingElbow?.startFixed ?? true);

    const description = tm.Get("Elbow Edge");

    const handleValueChange = useCallback((breakOffset: string, breakDirection: ShiftDirection, oppositeOffset: string, oppositeDirection: ShiftDirection, breakDistance: string, startFixed: boolean) => {

        let isValid: boolean = true;
        let parsedBreakOffset = CADHelpers.ParseNumericValue(breakOffset, breakDirection, lnf);
        let parsedOppositeOffset = CADHelpers.ParseNumericValue(oppositeOffset, oppositeDirection, lnf);
        let parsedBreakDistance = CADHelpers.ParseNumericValue(breakDistance, null, lnf);

        if (parsedBreakDistance <= 0) {
            isValid = false;
        } else if (parsedBreakOffset === 0 && parsedOppositeOffset === 0) {
            isValid = false;
        }

        const elbow: IEdgeElbow & IAdjustmentIdentifier = { adjustmentType: EdgeAdjustmentsEnum.Elbow, edgeName: edgeName, startFixed: startFixed, breakDistance: parsedBreakDistance, breakOffset: parsedBreakOffset, overallOffset: parsedOppositeOffset }
        handleCanApply(isValid, elbow);

    }, [edgeName, handleCanApply, lnf]);

    const handleSetBreakOffset = useCallback((value: string) => {
        setBreakOffset(value);
        handleValueChange(value, breakDirection, oppositeOffset, oppositeDirection, breakDistance, startFixed);
    }, [breakDirection, breakDistance, handleValueChange, oppositeDirection, oppositeOffset, startFixed]);

    const handleSetBreakDirection = useCallback((value: ShiftDirection) => {
        setBreakDirection(value);
        handleValueChange(breakOffset, value, oppositeOffset, oppositeDirection, breakDistance, startFixed);
    }, [breakDistance, breakOffset, handleValueChange, oppositeDirection, oppositeOffset, startFixed]);

    const handleSetOppositeOffset = useCallback((value: string) => {
        setOppositeOffset(value);
        handleValueChange(breakOffset, breakDirection, value, oppositeDirection, breakDistance, startFixed);
    }, [breakDirection, breakDistance, breakOffset, handleValueChange, oppositeDirection, startFixed]);

    const handleSetOppositeDirection = useCallback((value: ShiftDirection) => {
        setOppositeDirection(value);
        handleValueChange(breakOffset, breakDirection, oppositeOffset, value, breakDistance, startFixed);
    }, [handleValueChange, breakOffset, breakDirection, oppositeOffset, breakDistance, startFixed]);

    const handleSetBreakDistance = useCallback((value: string) => {
        setBreakDistance(value);
        handleValueChange(breakOffset, breakDirection, oppositeOffset, oppositeDirection, value, startFixed);
    }, [breakDirection, breakOffset, handleValueChange, oppositeDirection, oppositeOffset, startFixed]);

    const handleSetStartFixed = useCallback((value: boolean) => {
        setStartFixed(value);
        handleValueChange(breakOffset, breakDirection, oppositeOffset, oppositeDirection, breakDistance, value);
    }, [breakDirection, breakDistance, breakOffset, handleValueChange, oppositeDirection, oppositeOffset]);

    return <Grid xs item container spacing={1} alignItems={"center"}  >

        <Grid item xs={2}>
            <Grid item >
                <Typography>{description}:</Typography>
            </Grid>
        </Grid>

        <Grid item xs>
            <Grid container direction={"column"} spacing={1} >

                <EdgeElbowBreakDistance
                    editing={true}
                    existingElbow={existingElbow}
                    startFixed={startFixed}
                    setStartFixed={handleSetStartFixed}
                    breakDistance={breakDistance}
                    setBreakDistance={handleSetBreakDistance}
                />

                <Grid item>
                    <AdjustmentSelection
                        offsetDescription={tm.GetWithParams("Offset {0}", "1")}
                        editing={true}
                        existingAdjustment={existingElbow}
                        shiftOffset={breakOffset}
                        shiftDirection={breakDirection}
                        setShiftOffset={handleSetBreakOffset}
                        setShiftDirection={handleSetBreakDirection}
                    />
                </Grid>

                <Grid item container>
                    <AdjustmentSelection
                        offsetDescription={tm.GetWithParams("Offset {0}", "2")}
                        editing={true}
                        existingAdjustment={existingElbow}
                        shiftOffset={oppositeOffset}
                        shiftDirection={oppositeDirection}
                        setShiftOffset={handleSetOppositeOffset}
                        setShiftDirection={handleSetOppositeDirection}
                    />
                </Grid>

            </Grid>

        </Grid>

        <Grid item>
            {errorMessage ? <ErrorAdornment validationError={errorMessage} /> : null}
        </Grid>

    </Grid>

}

export default EdgeElbowComponent;
