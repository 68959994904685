export type EdgePlacement = "Start" | "End";
export type ShiftDirection = "In" | "Out";

export enum ParameterTypeEnum {
    Numeric,
    Boolean,
    Text,
    List,
}

export enum CADOperationTypeEnum {
    EdgeProcessing = 1,
    EdgeCutout = 2,
    CornerCutout = 4,
    DrillHole = 5,
    InternalCutout = 6,
    GroupedOperations = 7,
    EdgeShift = 9,
    EdgeRake = 10,
    EdgeElbow = 11,
    SurfaceProcessing = 12,
    UProfile = 14,
    Grids = 15,
    Mask = 16,
    Template = 100,
}

export enum CADPlacementReferenceTypeEnum {
    None = 0,
    Corner = 1,
    CornerAndXY = 2,
    Edge = 3, 
    EdgeAndDistanceFromCorner = 4,
}

export enum SegmentTypeEnum {
    Line = 0,
    Arc = 1,
    Circle = 2,
    Unknown = 99
}

export enum EdgeAdjustmentsEnum {
    None = 0,
    Shift = 0x1 << 0,
    Rake = 0x1 << 1,
    Elbow = 0x1 << 2,
}
