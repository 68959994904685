import { IQuoteAttachment, IFileUpload } from "models/IAttachment";
import React from "react";
import QuoteAttachmentsAPI from "./QuoteAttachmentsAPI";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { IBaseContent, ITranslationManager, useTranslations } from "@fenetech/translations";
import { DispatchActionsBase } from "components/Common/DispatchActionsBase";

export enum QuoteAttachmentsActionTypeEum {
    SetAttachments,
    SetCustomerVisible,
    SetParentCustomerVisible,
    SetIsLoading,
    SetAlert,
    SetIsEnabled
}

export type QuoteAttachmentsActionType = 
    { type: QuoteAttachmentsActionTypeEum.SetAttachments, value: IQuoteAttachment[] } |
    { type: QuoteAttachmentsActionTypeEum.SetCustomerVisible, value: {qaKey: number, isVisible: boolean} } |
    { type: QuoteAttachmentsActionTypeEum.SetParentCustomerVisible, value: {qaKey: number, isVisible: boolean} } |
    { type: QuoteAttachmentsActionTypeEum.SetIsLoading, value: boolean } |
    { type: QuoteAttachmentsActionTypeEum.SetAlert, value: { text: string, alertType: AlertColor, visible: boolean } } |
    { type: QuoteAttachmentsActionTypeEum.SetIsEnabled, value: boolean };

export interface IQuoteAttachments {
    attachments: IQuoteAttachment[],
    isLoading: boolean,
    alert: { text: string, alertType: AlertColor, visible: boolean },
    isEnabled: boolean
}

const initialState: IQuoteAttachments = {
    attachments: [],
    isLoading: false,
    alert: { text: "", alertType: "success" , visible: false },
    isEnabled: false
}

const quoteAttachmentsReducer: React.Reducer<IQuoteAttachments, QuoteAttachmentsActionType> = (data: IQuoteAttachments, action) => {
    switch (action.type) {
        case QuoteAttachmentsActionTypeEum.SetAttachments:
            return { ...data, attachments: action.value }
        case QuoteAttachmentsActionTypeEum.SetCustomerVisible:
            return { ...data, attachments: data.attachments.map(a => a.qaKey === action.value.qaKey ? {...a, customerVisible: action.value.isVisible} : a) }
        case QuoteAttachmentsActionTypeEum.SetParentCustomerVisible:
            return { ...data, attachments: data.attachments.map(a => a.qaKey === action.value.qaKey ? {...a, parentCustomerVisible: action.value.isVisible} : a) }
        case QuoteAttachmentsActionTypeEum.SetIsLoading:
            return { ...data, isLoading: action.value }
        case QuoteAttachmentsActionTypeEum.SetAlert:
            return { ...data, alert: action.value }
        case QuoteAttachmentsActionTypeEum.SetIsEnabled:
            return { ...data, isEnabled: action.value }
    }
}

export class QuoteAttachmentsActions extends DispatchActionsBase<QuoteAttachmentsActionType> {

    #oKey: number;
    #tm: ITranslationManager;

    constructor(dispatcher: React.Dispatch<QuoteAttachmentsActionType>, oKey: number, tm: ITranslationManager) {
        super(dispatcher);
        this.#oKey = oKey;
        this.#tm = tm;
        this.OnLoad();
    }

    SetIsLoading = (isLoading: boolean) => {
        this.dispatch({type: QuoteAttachmentsActionTypeEum.SetIsLoading, value: isLoading});
    }

    SetAlert = (alert: { text: string, alertType: AlertColor, visible: boolean }) => {
        this.dispatch({type: QuoteAttachmentsActionTypeEum.SetAlert, value: alert});
    }

    OnCollectionChanged = async (result: IBaseContent) => {
        if (result.message !== "" && result.params) {
            this.SetAlert({ text: this.#tm.GetWithParams(result.message, ...result.params), alertType: "error", visible: true});
        } else {
            await this.QueryQuoteAttachments();
        }
    }

    private async OnLoad() {
        const enabled = await QuoteAttachmentsAPI.GetAttachmentsEnabledAsync();
        if (enabled) {
            await this.QueryQuoteAttachments();
        }
        this.dispatch({type: QuoteAttachmentsActionTypeEum.SetIsEnabled, value: enabled});
    }

    private async QueryQuoteAttachments() {
        const attachments = await QuoteAttachmentsAPI.GetQuoteAttachmentsAsync(this.#oKey);
        this.dispatch({type: QuoteAttachmentsActionTypeEum.SetAttachments, value: attachments})
    }

    public async InsertQuoteAttachmentsAsync(fileUploads: IFileUpload[], overwrite: boolean): Promise<void> {
        this.SetIsLoading(true);
        try {
            const result = await QuoteAttachmentsAPI.InsertQuoteAttachmentsAsync(this.#oKey, overwrite, fileUploads);
            await this.OnCollectionChanged(result);
        }
        finally {
            this.SetIsLoading(false);
        }
    }

    public async DeleteQuoteAttachmentAsync(qaKey: number): Promise<void> {
        this.SetIsLoading(true);
        try {
            const result = await QuoteAttachmentsAPI.DeleteQuoteAttachmentAsync(qaKey);
            await this.OnCollectionChanged(result);
        }
        finally {
            this.SetIsLoading(false);
        }
    }

    public async MoveAttachmentAsync(qaKey: number, odKey: number, overwrite: boolean): Promise<void> {
        this.SetIsLoading(true);
        try {
            const result = await QuoteAttachmentsAPI.MoveQuoteAttachmentsAsync(qaKey, odKey, overwrite);
            await this.OnCollectionChanged(result);
        }
        finally {
            this.SetIsLoading(false);
        }
    }

    public async CopyAllAttachmentsAsync(oKey: number, destinationOKey: number): Promise<void> {
        this.SetIsLoading(true);
        try {
            const result = await QuoteAttachmentsAPI.CopyAllQuoteAttachmentsAsync(oKey, destinationOKey);
            await this.OnCollectionChanged(result);
        }
        finally {
            this.SetIsLoading(false);
        }
    }

    public async SetCustomerVisible(qaKey: number, isVisible: boolean): Promise<void> {
        this.SetIsLoading(true);
        try {
            await QuoteAttachmentsAPI.SetAttachmentCustomerVisibleAsync(qaKey, isVisible);
            this.dispatch({type: QuoteAttachmentsActionTypeEum.SetCustomerVisible, value: { qaKey: qaKey, isVisible: isVisible}});
        }
        finally {
            this.SetIsLoading(false);
        }
    }

    public async SetParentCustomerVisible(qaKey: number, isVisible: boolean): Promise<void> {
        this.SetIsLoading(true);
        try {
            await QuoteAttachmentsAPI.SetAttachmentParentCustomerVisibleAsync(qaKey, isVisible);
            this.dispatch({type: QuoteAttachmentsActionTypeEum.SetParentCustomerVisible, value: { qaKey: qaKey, isVisible: isVisible}});
        }
        finally {
            this.SetIsLoading(false);
        }
    }
}

export const QuoteAttachmentsContextDispatch = React.createContext<QuoteAttachmentsActions | null>(null);
const QuoteAttachmentsContext = React.createContext<IQuoteAttachments>(initialState);

interface IProps {
    oKey: number,
}

export const QuoteAttachmentsContextProvider: React.FC<React.PropsWithChildren<IProps>> = (props: React.PropsWithChildren<IProps>) => {

    const { oKey } = props;
    const tm = useTranslations();

    const [state, dispatch] = React.useReducer(quoteAttachmentsReducer, initialState);

    const actions = React.useMemo<QuoteAttachmentsActions>(() => {
        return new QuoteAttachmentsActions(dispatch, oKey, tm);
    }, [dispatch, oKey, tm])

    const handleClose = React.useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        actions.SetAlert({ ...state.alert, visible: false });
    }, [actions, state]);

    return <>
        <QuoteAttachmentsContext.Provider value={state}>
            <QuoteAttachmentsContextDispatch.Provider value={actions}>
                <Snackbar open={state.alert.visible} autoHideDuration={5000} onClose={handleClose} >
                    <Alert onClose={handleClose} severity={state.alert.alertType} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{state.alert.text}</Alert>
                </Snackbar>

                {props.children}
            </QuoteAttachmentsContextDispatch.Provider>
        </QuoteAttachmentsContext.Provider>
    </>;
}

export default QuoteAttachmentsContext;