import { useTranslations } from "@fenetech/translations";
import React, { useCallback } from 'react';
import { IEdgeShift } from "../../interfaces";
import AdjustmentSelection from "./AdjustmentSelection";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import ErrorAdornment from "components/Common/ErrorAdornment";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import { EdgeAdjustment } from "./AddEdgeAdjustment";
import { EdgeAdjustmentsEnum, ShiftDirection } from "../../enums";
import { CADHelpers } from "../../CADHelpers";

interface IEdgeShiftComponentProps {
    edgeName: string;
    existingShift: IEdgeShift | null;
    handleCanApply: (value: boolean, item: EdgeAdjustment | null) => void;
    errorMessage: string;
}

const EdgeShiftComponent: React.FC<IEdgeShiftComponentProps> = (props: IEdgeShiftComponentProps) => {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const [shiftOffset, setShiftOffset] = React.useState<string>(lnf.Format(Math.abs(props.existingShift?.offset ?? 0)));
    const [shiftDirection, setShiftDirection] = React.useState<ShiftDirection>((props.existingShift?.offset ?? 0) > 0 ? "Out" : "In");

    const description = tm.Get("Shift Edge");

    const handleValueChange = useCallback((shiftOffset: string, shiftDirection: ShiftDirection) => {

        let parsedOffset: number = CADHelpers.ParseNumericValue(shiftOffset, shiftDirection, lnf);

        const canApply = parsedOffset !== 0;
        props.handleCanApply(canApply, { adjustmentType: EdgeAdjustmentsEnum.Shift, edgeName: props.edgeName, offset: parsedOffset });

    }, [lnf, props]);

    const handleSetShiftOffset = useCallback((value: string) => {
        setShiftOffset(value);
        handleValueChange(value, shiftDirection);
    }, [handleValueChange, shiftDirection]);

    const handleSetShiftDirection = useCallback((value: ShiftDirection) => {
        setShiftDirection(value);
        handleValueChange(shiftOffset, value);
    }, [handleValueChange, shiftOffset]);


    return <Grid xs item container spacing={1} alignItems={"center"}  >

        <Grid item xs={2}>
            <Grid item >
                <Typography>{description}:</Typography>
            </Grid>
        </Grid>

        <Grid item xs>
            <AdjustmentSelection
                shiftOffset={shiftOffset}
                shiftDirection={shiftDirection}
                editing={true}
                existingAdjustment={props.existingShift}
                setShiftOffset={handleSetShiftOffset}
                setShiftDirection={handleSetShiftDirection}
            />
        </Grid>

        <Grid item>
            {props.errorMessage ? <ErrorAdornment validationError={props.errorMessage} /> : null}
        </Grid>

    </Grid>

}

export default EdgeShiftComponent;
