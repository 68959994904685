import isNumeric from "fast-isnumeric";
import { Autocomplete, Box, FormControl, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import Format, { ICallsizeArgs, ImperialFormatModeEnum } from "helpers/fv.format";
import { useTranslations } from "@fenetech/translations";
import { IPartCallSize, IPartCallSizePresets } from "helpers/interfaces";
import { OpeningShapeEnum, SizingModeEnum, ShapeDirectionEnum } from "helpers/enums";
import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { IOpeningDesignerOpeningSubLineItemInfo } from "components/OptionsWizard/interfaces";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import useWizardState from "../WebDesigner/useWizardState";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useWizardInteractions from "../useWizardInteractions";
import { IItemPropertiesData } from "./ItemPropertiesContext";
import useDimensionBlurCallback from "./useDimensionBlurCallback";
import useItemPropertiesActions from "./useItemPropertiesActions";
import useItemPropertiesData, { useItemPropertiesBuffer, useItemPropertiesLoaded } from "./useItemPropertiesData";
import useIsMobile from "helpers/hooks/useIsMobile";
import DesignerItemProperties from "../MainWizard/DesignerItemProperties";
import Constants from "helpers/constants";


interface IProps {
    unitSetID: number;
    partDefaults: PartDefaults;
    partCallSizes: IPartCallSizePresets;
    autoSubmit?: boolean;
};

export interface IODOpeningProperties extends IOpeningDesignerOpeningSubLineItemInfo {
    comment: string;
    customerRef: string;
    itemGroup: string;
}

const ODOpeningProperties: React.FC<IProps> = ({ unitSetID, partDefaults, partCallSizes, autoSubmit }: IProps) => {

    const formatMethods = useFormatHelper();

    const qtyInput = useRef<HTMLInputElement>(null);

    const tm = useTranslations();
    const wizardState = useWizardState();
    const wizardInteractions = useWizardInteractions();
    const itemPropertiesActions = useItemPropertiesActions();
    const isMobile = useIsMobile();

    const itemProperties = useItemPropertiesData();
    const itemPropertiesBuffer = useItemPropertiesBuffer();
    const loaded = useItemPropertiesLoaded();
    const { qty, width, height, callSize, customerRef, itemGroup, comment, shape, legHeight, radius } = itemProperties;
    const { qtyBuffer, widthBuffer, heightBuffer, callSizeBuffer, customerRefBuffer, itemGroupBuffer, commentBuffer, sizingBuffer, shapeBuffer, directionBuffer, legHeightBuffer, radiusBuffer } = itemPropertiesBuffer;

    const submitItemSize = React.useCallback((newState: Partial<IItemPropertiesData>) => {
        const openingProperites: IODOpeningProperties = { ...itemProperties };
        wizardInteractions.ChangeODOpeningPropertiesAsync({ ...openingProperites, ...newState });
    }, [wizardInteractions, itemProperties]);

    const measurementLabel = useMemo(() => {
        if (unitSetID === 1)
            return tm.Get("inches");
        else
            return tm.Get("mm");
    }, [unitSetID, tm]);

    //#region Qty

    const handleQtyChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        itemPropertiesActions.SetQtyBuffer(e.target.value);
    }, [itemPropertiesActions]);

    const handleQtyBlur = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newQty = parseInt(qtyBuffer);
        if (isNaN(newQty) || newQty < 1) {
            newQty = 1;
        }

        if (newQty === qty) {
            return;
        }

        itemPropertiesActions.SetQty(newQty);
        itemPropertiesActions.SetQtyBuffer(newQty.toString());
        if (autoSubmit) {
            submitItemSize({ qty: newQty });
        }
    }, [autoSubmit, itemPropertiesActions, submitItemSize, qtyBuffer, qty]);

    //#endregion

    //#region Sizing

    const handleSizingChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            const sizingValue = parseInt(e.target.value);
            itemPropertiesActions.SetSizingBuffer(sizingValue);
            itemPropertiesActions.SetSizing(sizingValue);
            if (autoSubmit) {
                submitItemSize({ sizing: sizingValue });
            }
        }
    }, [autoSubmit, itemPropertiesActions, submitItemSize]);

    //#endregion

    //#region Width

    const setWidth = React.useCallback((value: number) => {
        itemPropertiesActions.SetWidth(value);
    }, [itemPropertiesActions]);

    const setWidthBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetWidthBuffer(value);
    }, [itemPropertiesActions]);

    const onWidthCommitted = useCallback((newWidth: number) => {
        if (autoSubmit) {
            submitItemSize({ width: newWidth, callSize: "" });
        }
    }, [autoSubmit, submitItemSize]);

    //#endregion

    //#region Height

    const setHeight = React.useCallback((value: number) => {
        itemPropertiesActions.SetHeight(value);
    }, [itemPropertiesActions]);

    const setHeightBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetHeightBuffer(value);
    }, [itemPropertiesActions]);

    const onHeightCommitted = useCallback((newHeight: number) => {
        if (autoSubmit) {
            submitItemSize({ height: newHeight, callSize: "" });
        }
    }, [autoSubmit, submitItemSize]);

    //#endregion

    //#region Comment

    const setCommentBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetCommentBuffer(value);
    }, [itemPropertiesActions]);

    const handleCommentBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {

        const newComment = e.target.value;

        if (newComment === comment) {
            return;
        }

        itemPropertiesActions.SetComment(newComment);
        if (autoSubmit) {
            submitItemSize({ comment: newComment });
        }
    }, [itemPropertiesActions, autoSubmit, submitItemSize, comment]);

    //#endregion

    const callSizeSelectChosen = React.useMemo(() => {
        return partCallSizes && partCallSizes.callSizes.length > 0 && callSize !== "";
    }, [callSize, partCallSizes]);

    const callSizeLocked = React.useMemo<boolean>(() => {
        if (partCallSizes && partCallSizes.callSizes.length > 0 && partCallSizes.locked) {
            return true;
        } else {
            return false;
        }
    }, [partCallSizes]);

    const selectedCallSize = React.useMemo(() => {
        if (partCallSizes && partCallSizes.callSizes && partCallSizes.callSizes.length > 0) {
            if (callSize) {
                const item = partCallSizes.callSizes.find(cs => cs.callSize === callSize);
                return item ?? null;
            } else {
                return null;
            }

        }
        return null;
    }, [callSize, partCallSizes]);

    const setCallSize = React.useCallback((value: string | null) => {
        itemPropertiesActions.SetCallSize(value);
    }, [itemPropertiesActions]);

    const setCallSizeBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetCallSizeBuffer(value);
    }, [itemPropertiesActions]);

    const parseCallSizeText = React.useCallback((newCallSize: string) => {

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        let callsizeArgs: ICallsizeArgs = {
            callSize: newCallSize,
            width: currentWidth,
            height: currentHeight,
        };

        if (Format.formatCallSize(callsizeArgs)) {
            //valid
            newWidth = callsizeArgs.width;
            newHeight = callsizeArgs.height;
        } else {
            //Invalid
            newCallSize = "";
            newWidth = 0;
            newHeight = 0;
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthBuffer(newFormattedWidth);
        setHeight(newHeight);
        setHeightBuffer(newFormattedHeight);

        setCallSize(newCallSize);
        setCallSizeBuffer(newCallSize);

        if (autoSubmit) {
            submitItemSize({ width: newWidth, height: newHeight, callSize: newCallSize });
        }

    }, [width, height, formatMethods, unitSetID, setWidth, setWidthBuffer, setHeight, setHeightBuffer, setCallSize, setCallSizeBuffer, autoSubmit, submitItemSize]);

    const handleCallSizeTextChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCallSizeBuffer(e.target.value);
    }, [setCallSizeBuffer]);

    const handleCallSizeTextBlur = React.useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newCallSize: string = e.target.value;

        if (newCallSize !== callSize) {
            parseCallSizeText(newCallSize);
        }

    }, [parseCallSizeText, callSize]);

    const parseCallSizeSelection = React.useCallback((newCallSize: string) => {

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        if (newCallSize) {
            if (partCallSizes) {
                const selectedCallSize = partCallSizes.callSizes.filter(cs => cs.callSize === newCallSize);
                if (selectedCallSize.length > 0) {
                    newWidth = selectedCallSize[0].width;
                    newHeight = selectedCallSize[0].height;
                }
            }
        } else {
            newWidth = 0;
            newHeight = 0;
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthBuffer(newFormattedWidth);
        setHeight(newHeight);
        setHeightBuffer(newFormattedHeight);
        setCallSize(newCallSize);
        setCallSizeBuffer(newCallSize);

        if (autoSubmit) {
            submitItemSize({ width: newWidth, height: newHeight, callSize: newCallSize });
        }
    }, [width, height, formatMethods, unitSetID, setWidth, setWidthBuffer, setHeight, setHeightBuffer, setCallSize, setCallSizeBuffer, partCallSizes, autoSubmit, submitItemSize]);

    const handleCallSizeSelectChange = React.useCallback((event: any, data: IPartCallSize | null) => {
        const newCallSize = data?.callSize ?? "";
        parseCallSizeSelection(newCallSize);
    }, [parseCallSizeSelection]);

    //#endregion

    //#region Shape

    const handleShapeChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            const shapeValue = parseInt(e.target.value);
            itemPropertiesActions.SetShapeBuffer(shapeValue);
            itemPropertiesActions.SetShape(shapeValue);
            if (autoSubmit) {
                submitItemSize({ shape: shapeValue });
            }
        }
    }, [autoSubmit, itemPropertiesActions, submitItemSize]);

    //#endregion

    //#region Direction

    const handleDirectionChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            const directionValue = parseInt(e.target.value);
            itemPropertiesActions.SetDirectionBuffer(directionValue);
            itemPropertiesActions.SetDirection(directionValue);
            if (autoSubmit) {
                submitItemSize({ direction: directionValue });
            }
        }
    }, [autoSubmit, itemPropertiesActions, submitItemSize]);

    //#endregion

    //#region Leg Height

    const setLegHeight = useCallback((value: number) => {
        itemPropertiesActions.SetLegHeight(value);
    }, [itemPropertiesActions]);

    const setLegHeightBuffer = useCallback((value: string) => {
        itemPropertiesActions.SetLegHeightBuffer(value);
    }, [itemPropertiesActions]);

    const onLegHeightCommitted = useCallback((newLegHeight: number) => {
        if (autoSubmit) {
            submitItemSize({ legHeight: newLegHeight });
        }
    }, [autoSubmit, submitItemSize]);

    //#endregion

    //#region Radius

    const setRadius = React.useCallback((value: number) => {
        itemPropertiesActions.SetRadius(value);
    }, [itemPropertiesActions]);

    const setRadiusBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetRadiusBuffer(value);
    }, [itemPropertiesActions]);

    const onRadiusCommitted = useCallback((newRadius: number) => {
        if (autoSubmit) {
            submitItemSize({ legHeight: newRadius });
        }
    }, [autoSubmit, submitItemSize]);

    //#endregion

    //#region Customer Ref

    const setCustomerRefBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetCustomerRefBuffer(value);
    }, [itemPropertiesActions]);

    const handleCustomerRefBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {

        const newCustomerRef = e.target.value;
        if (newCustomerRef === customerRef) {
            return;
        }

        itemPropertiesActions.SetCustomerRef(newCustomerRef);
        if (autoSubmit) {
            submitItemSize({ customerRef: newCustomerRef });
        }
    }, [itemPropertiesActions, autoSubmit, submitItemSize, customerRef]);

    //#endregion

    //#region Item Group

    const setItemGroupBuffer = React.useCallback((value: string) => {
        itemPropertiesActions.SetItemGroupBuffer(value);
    }, [itemPropertiesActions]);

    const handleItemGroupBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {

        const newItemGroup = e.target.value;

        if (newItemGroup === itemGroup) {
            return;
        }

        itemPropertiesActions.SetItemGroup(newItemGroup);
        if (autoSubmit) {
            submitItemSize({ itemGroup: newItemGroup });
        }
    }, [itemPropertiesActions, autoSubmit, submitItemSize, itemGroup]);

    //#endregion

    const handleWidthBlur = useDimensionBlurCallback(formatMethods, unitSetID, width, widthBuffer, setWidthBuffer, setWidth, setCallSize, onWidthCommitted);
    const handleHeightBlur = useDimensionBlurCallback(formatMethods, unitSetID, height, heightBuffer, setHeightBuffer, setHeight, setCallSize, onHeightCommitted);

    const handleLegHeightBlur = useDimensionBlurCallback(formatMethods, unitSetID, legHeight, legHeightBuffer, setLegHeightBuffer, setLegHeight, undefined, onLegHeightCommitted);
    const handleRadiusBlur = useDimensionBlurCallback(formatMethods, unitSetID, radius, radiusBuffer, setRadiusBuffer, setRadius, undefined, onRadiusCommitted);

    useEffectOnLoad(() => {
        const callSizeString = callSize ?? "";
        setCallSize(callSizeString);
        if (callSizeString !== "") {
            if (callSizeSelectChosen) {
                parseCallSizeSelection(callSizeString);
            }
            else {
                parseCallSizeText(callSizeString);
            }
        }
    });

    useEffect(() => {
        if (qtyInput?.current) {
            qtyInput.current.focus();
            qtyInput.current.select();
        }
    }, [qtyInput]);

    return <>
        <Box display="flex" flexDirection="column">
            <Grid container direction="column" rowSpacing={1} justifyItems="stretch">

                {partDefaults.QuantityEnabled() &&
                    <Grid item >
                        <TextField onFocus={e => e.target.select()} inputRef={qtyInput} label={tm.Get("Qty")} value={loaded ? qtyBuffer : ""} onChange={handleQtyChange} onBlur={handleQtyBlur} fullWidth
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                    </Grid>
                }

                <Grid item >
                    <TextField label={tm.Get("Sizing")} value={sizingBuffer} onChange={handleSizingChange} fullWidth select>
                        <MenuItem value={SizingModeEnum.Fixed}>{tm.Get("Fixed")}</MenuItem>
                        <MenuItem value={SizingModeEnum.Grow}>{tm.Get("Grow")}</MenuItem>
                    </TextField>
                </Grid>

                {partDefaults.CallSizeEnabled() &&
                    <Grid item>
                        <FormControl id="callsize" fullWidth>
                            {partCallSizes && partCallSizes.callSizes.length > 0 ?
                                <Autocomplete
                                    autoComplete
                                    disableClearable={callSizeLocked}
                                    autoSelect
                                    autoHighlight
                                    blurOnSelect
                                    selectOnFocus
                                    handleHomeEndKeys
                                    disabled={!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false}
                                    options={partCallSizes.callSizes}
                                    getOptionLabel={(cs: IPartCallSize) => cs.callSize}
                                    isOptionEqualToValue={(cs: IPartCallSize, value: IPartCallSize) => cs.callSize === value.callSize}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={tm.Get("Call Size")}
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                                            }}
                                        />
                                    )}
                                    onChange={handleCallSizeSelectChange}
                                    value={loaded ? selectedCallSize : undefined}
                                />
                                :
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Call Size")} value={loaded ? callSizeBuffer : ""} onChange={handleCallSizeTextChange} onBlur={handleCallSizeTextBlur} fullWidth
                                    disabled={!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false} />
                            }
                        </FormControl>

                    </Grid>
                }

                {partDefaults.WidthEnabled() &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Width")} value={loaded ? widthBuffer : ""} onChange={(e) => setWidthBuffer(e.target.value)} onBlur={handleWidthBlur} fullWidth
                                disabled={callSizeLocked || (!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false) || callSizeSelectChosen}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                        </Stack>
                    </Grid>
                }

                {partDefaults.HeightEnabled() &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Height")} value={loaded ? heightBuffer : ""} onChange={(e) => setHeightBuffer(e.target.value)} onBlur={handleHeightBlur} fullWidth
                                disabled={callSizeLocked || (!partDefaults.Defaults?.shortcutAllowsChangesToDimensions ?? false) || callSizeSelectChosen}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                        </Stack>
                    </Grid>
                }

                <Grid item>
                    <TextField label={tm.Get("Shape")} value={shapeBuffer} onChange={handleShapeChange} fullWidth select>
                        <MenuItem value={OpeningShapeEnum.Standard}>{tm.Get("Standard")}</MenuItem>
                        <MenuItem value={OpeningShapeEnum.Rake}>{tm.Get("Rake")}</MenuItem>
                        <MenuItem value={OpeningShapeEnum.Gable}>{tm.Get("Gable")}</MenuItem>
                        <MenuItem value={OpeningShapeEnum.Arc}>{tm.Get("Arc")}</MenuItem>
                        <MenuItem value={OpeningShapeEnum.HalfArc}>{tm.Get("Half Arc")}</MenuItem>
                    </TextField>
                </Grid>

                {(shape === OpeningShapeEnum.Rake || shape === OpeningShapeEnum.HalfArc) &&
                    <Grid item>
                        <TextField onFocus={e => e.target.select()} label={tm.Get("Direction")} value={directionBuffer} onChange={handleDirectionChange} fullWidth select>
                            <MenuItem value={ShapeDirectionEnum.Left}>{tm.Get("Left")}</MenuItem>
                            <MenuItem value={ShapeDirectionEnum.Right}>{tm.Get("Right")}</MenuItem>
                        </TextField>
                    </Grid>
                }

                {shape !== OpeningShapeEnum.Standard &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Leg")} value={legHeightBuffer} onChange={(e) => setLegHeightBuffer(e.target.value)} onBlur={handleLegHeightBlur} fullWidth
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                        </Stack>
                    </Grid>
                }

                {shape === OpeningShapeEnum.HalfArc &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Radius")} value={radiusBuffer} onChange={(e) => setRadiusBuffer(e.target.value)} onBlur={handleRadiusBlur} fullWidth
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                        </Stack>
                    </Grid>
                }

                {partDefaults.CustomerRefEnabled() &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Customer Ref")} value={loaded ? customerRefBuffer : ""} onChange={(e) => setCustomerRefBuffer(e.target.value.substring(0, Constants.MaxLength.ItemCustomerRef))} onBlur={handleCustomerRefBlur} fullWidth />
                        </Stack>
                    </Grid>
                }

                {partDefaults.ItemGroupEnabled() &&
                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Group")} value={loaded ? itemGroupBuffer : ""} onChange={(e) => setItemGroupBuffer(e.target.value.substring(0, Constants.MaxLength.ItemGroup))} onBlur={handleItemGroupBlur} fullWidth />
                        </Stack>
                    </Grid>
                }

                <Grid item >
                    <TextField onFocus={e => e.target.select()} label={tm.Get("Line Item Comment")} multiline fullWidth minRows={2} value={loaded ? commentBuffer : ""} onChange={(e) => setCommentBuffer(e.target.value)} onBlur={handleCommentBlur} />
                </Grid>

                {isMobile &&
                    <DesignerItemProperties includeGridItem />
                }

            </Grid>
        </Box>
    </>;
}

export default ODOpeningProperties;