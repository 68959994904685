import React from "react";
import { useTranslations, ITranslationManager } from "@fenetech/translations";
import { GridActionsCellItem, GridColumnHeaderParams, GridEnrichedColDef, GridRowParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Delete } from "@mui/icons-material";
import { MenuItem, Select, Tooltip, Typography } from "@mui/material";
import AlertDialog from "components/Common/AlertDialog";
import { ILineItem } from "models/IQuote";

type MoveActionHandler = (qaKey: number, odKey: number, fileName: string) => void;
type DeleteActionHandler = (qaKey: number) => void;

interface IDeleteCommandProps {
    params: GridRowParams;
    deleteActionHandler: DeleteActionHandler;
}

export const itemColumn = (field: string, title: string, moveAttachment: MoveActionHandler, lineItems: ILineItem[], disabled: boolean, tm: ITranslationManager, isMobile: boolean) => {

    const col = {
        field: field,
        headerName: tm.Get(title),
        mindWidth: isMobile ? 90 : 130,
        hideable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            if (!params || !params.row) return <></>;

            return <Select
                        size="small" fullWidth sx={{paddingY: 2}}
                        value={params.row.odKey}
                        onChange={(e) => moveAttachment(params.row.qaKey, e.target.value, params.row.fileName)}
                        disabled={disabled}>                
                <MenuItem key="ORDER" value={0}>{tm.Get("ORDER")}</MenuItem>
                {lineItems.filter(l => !l.isSurchargePart).map((l) =>
                    <MenuItem key={l.odKey} value={l.odKey} >
                        {l.lineItemNumber}
                    </MenuItem>
                )}
            </Select>
        }
    }

    return col;
}

export const partColumn = (field: string, title: string, lineItems: ILineItem[], tm: ITranslationManager, isMobile: boolean) => {

    const col = {
        field: field,
        headerName: tm.Get(title),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            if (!params || !params.row) return <></>;
            
            return <Typography variant="body2">
                {lineItems.find(x => x.odKey === params.row.odKey)?.formattedPart ?? ""}
            </Typography>
        }
    }

    return col;
}

export const deleteCommandColumn = (deleteHandler: DeleteActionHandler | null, disabled: boolean) => {

    const minWidth = 92;

    const DeleteCommand: React.FC<IDeleteCommandProps> = ({ params, deleteActionHandler }: IDeleteCommandProps) => {

        const tm = useTranslations();
        const [open, setOpen] = React.useState<boolean>(false);

        const handlerShowDialog = React.useCallback(() => {
            setOpen(true);
        }, [setOpen]);

        const handleDialogClose = React.useCallback((result: boolean) => {
            if (result) {
                deleteActionHandler(params.row.qaKey);
            }
            setOpen(false);
        }, [deleteActionHandler, params])

        return <>
            <Tooltip title={tm.Get("Delete Attachment")}>
                <GridActionsCellItem
                    icon={<Delete />}
                    color="primary"
                    label={tm.Get("Delete Attachment")}
                    onClick={(handlerShowDialog)}
                />
            </Tooltip>
            <AlertDialog open={open}
                handleClose={handleDialogClose}
                headerText={params.row.fileName}
                message={tm.Get("Are you sure you want to delete this attachment?")}
            />
        </>
    }

    const col = {
        field: "Commands",
        type: "actions",
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                Commands
            </div>
        },
        minWidth: minWidth,
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {

            const actions: JSX.Element[] = [];

            if (deleteHandler && !disabled) actions.push(<DeleteCommand params={params} deleteActionHandler={deleteHandler} />);

            return actions;
        },
    } as GridEnrichedColDef;

    return col;
}
