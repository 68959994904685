import { GridRenderCellParams, GridEnrichedColDef, GridRenderEditCellParams, GridValueGetterParams, GridValueSetterParams } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import DataGridColumnEdit from "components/Common/DataGridColumnEdit";
import ErrorAdornment from "components/Common/ErrorAdornment";
import { ITranslationManager } from "@fenetech/translations";
import { IPartCallSizePresets } from "helpers/interfaces";
import PasteSpecialEditCallSizeColumn from "./PasteSpecialEditCallSizeColumn";

export function qtyColumn (tm: ITranslationManager, isMobile: boolean, editable: boolean) {

    const col = {
        field: "qty",
        headerName: tm.Get("Quantity"),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        headerAlign: "center",
        align: "center",
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2">
                    {params.value}
                </Typography>
                {params.row.qtyError && params.row.qtyError !== "" && 
                    <ErrorAdornment validationError={params.row.qtyError} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <DataGridColumnEdit {...params} align="center" error={params.row.qtyError} inputMode="numeric" pattern="[0-9]*" />
            </>
        },
    } as GridEnrichedColDef;

    return col;
}

export function callSizeColumn (partCallSizes: IPartCallSizePresets | undefined, tm: ITranslationManager, isMobile: boolean, editable: boolean) {

    const col = {
        field: "callSize",
        headerName: tm.Get("Call Size"),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        headerAlign: "right",
        align: "right",
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2">
                    {params.value}
                </Typography>
                {params.row.callSizeError && params.row.callSizeError !== "" && 
                    <ErrorAdornment validationError={params.row.callSizeError} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <PasteSpecialEditCallSizeColumn {...params} align="right" error={params.row.callSizeError} maxLength={255} callSizes={partCallSizes} />
            </>
        }
    } as GridEnrichedColDef;

    return col;
}

export function widthColumn (tm: ITranslationManager, isMobile: boolean, editable: boolean) {

    const col = {
        field: "width",
        headerName: tm.Get("Width"),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        headerAlign: "right",
        align: "right",
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2">
                    {params.value}
                </Typography>
                {params.row.widthError && params.row.widthError !== "" && 
                    <ErrorAdornment validationError={params.row.widthError} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <DataGridColumnEdit {...params} align="right" error={params.row.widthError} />
            </>
        },
    } as GridEnrichedColDef;

    return col;
}

export function heightColumn (tm: ITranslationManager, isMobile: boolean, editable: boolean) {

    const col = {
        field: "height",
        headerName: tm.Get("Height"),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        headerAlign: "right",
        align: "right",
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2">
                    {params.value}
                </Typography>
                {params.row.heightError && params.row.heightError !== "" && 
                    <ErrorAdornment validationError={params.row.heightError} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <DataGridColumnEdit {...params} align="right" error={params.row.heightError} />
            </>
        },
    } as GridEnrichedColDef;

    return col;
}

export function thicknessColumn (tm: ITranslationManager, isMobile: boolean, editable: boolean) {

    const col = {
        field: "thickness",
        headerName: tm.Get("Thickness"),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        headerAlign: "right",
        align: "right",
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2">
                    {params.value}
                </Typography>
                {params.row.thicknessError && params.row.thicknessError !== "" && 
                    <ErrorAdornment validationError={params.row.thicknessError} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <DataGridColumnEdit {...params} align="right" error={params.row.thicknessError} />
            </>
        },
    } as GridEnrichedColDef;

    return col;
}

export function commentColumn (tm: ITranslationManager, isMobile: boolean, editable: boolean) {

    const col = {
        field: "comment",
        headerName: tm.Get("Comment"),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2" width="100%">
                    {params.value.split("\n")[0]}
                </Typography>
                {params.row.commentError && params.row.commentError !== "" && 
                    <ErrorAdornment validationError={params.row.commentError} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <DataGridColumnEdit {...params} error={params.row.commentError} maxLength={255} multiLine />
            </>
        }
    } as GridEnrichedColDef;

    return col;
}

export function customerRefColumn (tm: ITranslationManager, isMobile: boolean, editable: boolean) {

    const col = {
        field: "customerRef",
        headerName: tm.Get("Customer Ref"),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2" width="100%">
                    {params.value}
                </Typography>
                {params.row.customerRefError && params.row.customerRefError !== "" && 
                    <ErrorAdornment validationError={params.row.customerRefError} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <DataGridColumnEdit {...params} error={params.row.customerRefError} maxLength={255} />
            </>
        }
    } as GridEnrichedColDef;

    return col;
}

export function itemGroupColumn (tm: ITranslationManager, isMobile: boolean, editable: boolean) {

    const col = {
        field: "itemGroup",
        headerName: tm.Get("Group"),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2" width="100%">
                    {params.value}
                </Typography>
                {params.row.itemGroupError && params.row.itemGroupError !== "" && 
                    <ErrorAdornment validationError={params.row.itemGroupError} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <DataGridColumnEdit {...params} error={params.row.itemGroupError} maxLength={255} />
            </>
        }
    } as GridEnrichedColDef;

    return col;
}

export function shapeParamColumn (tm: ITranslationManager, isMobile: boolean, paramField: string, editable: boolean) {
    const col = {
        field: paramField,
        headerName: paramField.toUpperCase(),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        sortable: false,
        headerAlign: "right",
        align: "right",
        editable,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.shapeParams[paramField];
        },
        valueSetter: (params: GridValueSetterParams) => {
            let newParams = {...params.row.shapeParams};
            newParams[paramField] = params.value;
            return { ...params.row, shapeParams: newParams};
        },
        renderCell: (params: GridRenderCellParams) => {
            return <>
                <Typography variant="body2">
                    {params.value}
                </Typography>
                {params.row.shapeParamErrors && params.row.shapeParamErrors[paramField] && params.row.shapeParamErrors[paramField] !== "" && 
                    <ErrorAdornment validationError={params.row.shapeParamErrors[paramField]} />
                }
            </>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
            return <>
                <DataGridColumnEdit {...params} value={params.value ?? ""} align="right" error={params.row.shapeParamErrors ? params.row.shapeParamErrors[paramField] : null} />
            </>
        },
    } as GridEnrichedColDef;

    return col;
}