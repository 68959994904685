import { Box, Grid, Paper, Stack, Typography } from "@mui/material"
import { useTranslations } from "@fenetech/translations";

import { IOrderLineItem, ILineItem, IOrder, IOrderPermissions } from "helpers/interfaces";
import LineItemImage from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemImage";
import LineItemPart from "components/Quotes/QuoteEntry/LineItems/Fields//LineItemPart";
import LineItemSize from "components/Quotes/QuoteEntry/LineItems/Fields//LineItemSize";
import { ILineItemGridProps } from "components/Orders/OrderView/LineItems/LineItemGrid";
import LineItemUnitPrice from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemUnitPrice";
import LineItemTotal from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemTotal";
import LineItemExceptionIcon from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemExceptionIcon";
import { useMemo } from "react";

interface IProps {
    order: IOrder,
    permissions: IOrderPermissions,
    lineItem: IOrderLineItem,
    lineItemGridProps: ILineItemGridProps,
    onImageClick: (imageSource: string) => void,
    onExceptionClick: (lineItemn: ILineItem) => void,
}

const LineItemCard: React.FC<IProps> = ({ order, permissions, lineItem, lineItemGridProps, onImageClick, onExceptionClick }: IProps) => {

    const tm = useTranslations();
    const { isPickup, priceVisible } = lineItemGridProps;

    const pickedUpQtyVisible = useMemo(() => {
        return isPickup
    }, [isPickup]);

    const receivedQtyVisible = useMemo(() => {
        return isPickup
    }, [isPickup]);

    return <>

        <Paper>
            <Box m={1} display="flex">
                <Grid container display="flex" flexDirection="column">
                    <Grid item container xs display="flex" flexDirection="row">
                        <Grid item xs="auto">
                            <Box width="150px" height="150px">
                                <LineItemImage
                                    oKey={order.oKey}
                                    lineItem={lineItem}
                                    imageAccessKey={permissions.imageAccessKey}
                                    isOrder={true}
                                    onClick={onImageClick}
                                    showMediumSize={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs container display="flex" flexDirection="column">
                            <Grid item>
                                <Stack ml={1} direction="column" display="flex">
                                    <Grid container display="flex" direction="row">
                                        <Grid item xs>
                                            <LineItemPart
                                                oKey={order.oKey}
                                                includeItemNumber={true}
                                                isReadOnly={true}
                                                variant="h6"
                                                lineItem={lineItem}
                                            />
                                        </Grid>
                                        {lineItem.hasValidationExceptions &&
                                            <Box display="flex" alignSelf="flex-end">
                                                <LineItemExceptionIcon
                                                    lineItem={lineItem}
                                                    validationExceptionsOnly={true}
                                                    onClick={onExceptionClick}
                                                />
                                            </Box>
                                        }
                                    </Grid>
                                    <LineItemSize
                                        lineItem={lineItem}
                                        engineeringUnitSetID={order.engineeringUnitSetID}
                                        variant="body1"
                                    />
                                    {priceVisible ?
                                        <>
                                            <Box display="flex" alignSelf="flex-end">
                                                <LineItemUnitPrice
                                                    lineItem={lineItem}
                                                    variant="body1"
                                                    showQuantity={true}
                                                    showSqFtPrice={false}
                                                    isOrder={true}
                                                />
                                            </Box>
                                            <Box display="flex" alignSelf="flex-end">
                                                <LineItemTotal
                                                    lineItem={lineItem}
                                                    variant="body1"
                                                />
                                            </Box>
                                        </>
                                        :
                                        <>
                                            <Box display="flex" alignSelf="flex-end">
                                                <Typography>
                                                    {tm.Get("Qty:")} {lineItem.quantity.toString()}
                                                </Typography>
                                            </Box>
                                        </>
                                    }
                                    {pickedUpQtyVisible &&
                                        <>
                                            <Box display="flex" alignSelf="flex-end">
                                                <Typography>
                                                    {tm.Get("Picked Up:")} {lineItem.deliveredQuantity.toString()}
                                                </Typography>
                                            </Box>
                                        </>
                                    }
                                    {receivedQtyVisible &&
                                        <>
                                            <Box display="flex" alignSelf="flex-end">
                                                <Typography>
                                                    {tm.Get("Received:")} {lineItem.receivedQuantity.toString()}
                                                </Typography>
                                            </Box>
                                        </>
                                    }
                                </Stack>
                            </Grid>

                        </Grid>
                    </Grid>
                    {lineItem.itemGroup &&
                        <Grid item xs="auto">
                            <Typography textAlign="left">
                                {tm.Get("Group:") + " " + lineItem.itemGroup}
                            </Typography>
                        </Grid>
                    }
                    {lineItem.comment &&
                        <Grid item xs="auto">
                            <Typography textAlign="left">
                                {lineItem.comment}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Paper>

    </>;
}

export default LineItemCard;
