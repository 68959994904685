import useBrowserBackedMap, { useBrowserBackedMapAsync } from "helpers/hooks/useBrowserBackedMap";
import { IPartDefaults } from "helpers/interfaces";
import PartsApi from "helpers/PartsAPI";

const sessionKey = "FV_PART_DEFAULT_CACHE";

const blankDefaults: IPartDefaults = {
    partNo: "",
    partNoSuffix: "",
    insideView: false,
    outsideView: false,
    bothView: false,
    quantity: null,
    callSize: null,
    width: null,
    height: null,
    thickness: null,
    customerRef: null,
    itemGroup: null,
    comment: "",
    shortcutAllowsChangesToDimensions: false
};

const getPartDefaultsFetchAsync = async (partNo?: string, partNoSuffix?: string, shortcutName?: string) => {
    if (partNo !== undefined && partNoSuffix !== undefined && shortcutName !== undefined) {
        return await PartsApi.GetPartDefaults(partNo, partNoSuffix, shortcutName);
    }
    else {
        return blankDefaults;
    }
}

export default function usePartDefaults(partNo?: string, partNoSuffix?: string, shortcutName?: string): IPartDefaults {


    const partDefault: IPartDefaults | null = useBrowserBackedMap<IPartDefaults>(sessionKey, getPartDefaultsFetchAsync, partNo, partNoSuffix, shortcutName);

    return partDefault ?? blankDefaults;
}

export function usePartDefaultsRepo(): (partNo: string, partNoSuffix: string, shortcutName: string) => Promise<IPartDefaults> {
    return useBrowserBackedMapAsync<IPartDefaults>(sessionKey, getPartDefaultsFetchAsync);
}


export class PartDefaults {

    constructor(defaults: IPartDefaults | null) {
        this.Defaults = defaults;
    }

    public Defaults: IPartDefaults | null;

    public CallSizeEnabled(): boolean {
        if (this.Defaults?.callSize === null || this.Defaults?.width === null || this.Defaults?.height === null)
            return false;
        else
            return true;
    }

    public QuantityEnabled(): boolean {
        return this.Defaults?.quantity !== null;
    }

    public WidthEnabled(): boolean {
        return this.Defaults?.width !== null;
    }

    public HeightEnabled(): boolean {
        return this.Defaults?.height !== null;
    }

    public ThicknessEnabled(): boolean {
        return this.Defaults?.thickness !== null;
    }

    public CustomerRefEnabled(): boolean {
        return this.Defaults?.customerRef !== null;
    }

    public ItemGroupEnabled(): boolean {
        return this.Defaults?.itemGroup !== null;
    }

}