import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import React from 'react';
import { useCADActions } from "./Context/CADContext";
import { ILineItemInfo, IUserPreferences } from "../interfaces";
import useIsMobile from "helpers/hooks/useIsMobile";
import CADMainDesktop from "./Components/CADMainDesktop";
import CADMainMobile from "./Components/Mobile/CADMainMobile";
import useCADAvailableOperations from "./hooks/useCADAvailableOperations";

interface ICADMainProps {
    owPreferences: IUserPreferences;
    itemInfo: ILineItemInfo | null;
};

const CADMain: React.FC<ICADMainProps> = (props) => {

    const cadActions = useCADActions();
    const isMobile = useIsMobile();
    const availableOperationTypes = useCADAvailableOperations();

    useEffectOnLoad(() => {
        cadActions.setUnitSetID((props.itemInfo?.unitSet ?? 0));
        cadActions.setCurrentTab(availableOperationTypes[0].toString());
    });

    if (isMobile) {
        return <CADMainMobile />;
    } else {
        return <CADMainDesktop />
    }

};

export default CADMain;
