import React, { MouseEventHandler, TouchEventHandler } from 'react';
import ScalableImage from "./ScalableImage";
import { Theme } from "@mui/material/styles";

export type ScalableCanvasMouseEvent = (e: React.MouseEvent<HTMLCanvasElement>, ctx: CanvasRenderingContext2D, canvasBounds: DOMRect, ratio: number, offsetX: number, offsetY: number) => string | null | void;
export type ScalableCanvasTouchEvent = (e: React.TouchEvent<HTMLCanvasElement>, ctx: CanvasRenderingContext2D, canvasBounds: DOMRect, ratio: number, offsetX: number, offsetY: number) => string | null | void;
export type ScalableCanvasDrawHandler = (ctx: CanvasRenderingContext2D | null, canvasBounds: DOMRect | null, ratio: number, offsetX: number, offsetY: number) => void;

interface IScalableImageWithCanvasOverlayProps {
    src: string | undefined;
    theme: Theme;
    renderAsDiv: boolean;
    imageStyle?: React.CSSProperties;
    drawCanvas: ScalableCanvasDrawHandler;
    onMouseMove: ScalableCanvasMouseEvent;
    onMouseLeave: ScalableCanvasMouseEvent;
    onMouseDown: ScalableCanvasMouseEvent;
    onTouchStart: ScalableCanvasTouchEvent;
    onInitCanvas?: (ctx: CanvasRenderingContext2D, canvasBounds: DOMRect) => void;
}

interface IState {
    ratio: number;
    offsetX: number;
    offsetY: number;
}

export default class ScalableImageWithCanvasOverlay extends React.Component<IScalableImageWithCanvasOverlayProps, IState> {

    #imgRef: React.RefObject<HTMLImageElement>;
    #divRef: React.RefObject<HTMLDivElement>;
    #cvRef: React.RefObject<HTMLCanvasElement>;
    #ctx: CanvasRenderingContext2D | null = null;
    #resizeObserver: ResizeObserver | null = null;
    #canvasBounds: DOMRect | null = null;

    static readonly WEB_DESIGNER_IMAGE_SIZE: number = 750;  // If you ever change this search all the code for other constants with the same name
    static readonly DEFAULT_IMG_SIZE: number = ScalableImageWithCanvasOverlay.WEB_DESIGNER_IMAGE_SIZE;

    constructor(props: IScalableImageWithCanvasOverlayProps) {
        super(props);
        this.#imgRef = React.createRef<HTMLImageElement>();
        this.#divRef = React.createRef<HTMLDivElement>();
        this.#cvRef = React.createRef<HTMLCanvasElement>();

        if (!this.props.onMouseDown || !this.props.onMouseMove || !this.props.onMouseLeave || !this.props.onTouchStart) {
            throw new Error("ScalableCanvasOverlay: onMouseDown, onMouseMove, onMouseLeave, and onTouchStart are required props.");
        }

        this.state = { ratio: 1, offsetX: 0, offsetY: 0 };
        this.#resizeObserver = new ResizeObserver(() => {
            this.handleCanvasResized();
        });
    }

    public componentDidMount() {
        this.initCanvas();
        if (this.#divRef.current) {
            this.#resizeObserver?.observe(this.#divRef.current);
        }
    }

    public componentWillUnmount() {
        if (this.#divRef.current) {
            this.#resizeObserver?.unobserve(this.#divRef.current);
        }
    }

    public componentDidUpdate() {
        //handling resize here corrects the ratio when the image updated while it was excluded from the dom
        //a better fix might be to avoid updating the image when its in this state (and wait until the user clicks the image tab)
        this.handleCanvasResized();
        this.props.drawCanvas(this.#ctx, this.#canvasBounds, this.state.ratio, this.state.offsetX, this.state.offsetY);
    }

    render(): React.ReactNode {

        return <div ref={this.#divRef} style={{ display: "flex", height: "100%", flexGrow: 1 }} >

            <ScalableImage
                src={this.props.src}
                renderAsDiv={this.props.renderAsDiv}
                ref={this.#imgRef}
                onLoad={this.onImageLoad}
                imageStyle={this.props.imageStyle}
            />

            <canvas ref={this.#cvRef}
                style={{ position: "absolute", top: "0px", left: "0px", pointerEvents: "auto" }}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}
                onMouseDown={this.onMouseDown}
                onTouchStart={this.onTouchStart}
            />

        </div>
    }

    private HandleEventCursorResult = (cursorResult: string | null | void) => {
        if (this.#cvRef.current) {
            if (cursorResult) {
                this.#cvRef.current.style.cursor = cursorResult;
            }
        }
    }

    private onMouseMove: MouseEventHandler<HTMLCanvasElement> = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (this.#canvasBounds && this.#ctx && this.props.onMouseMove) {
            this.HandleEventCursorResult(this.props.onMouseMove(e, this.#ctx, this.#canvasBounds, this.state.ratio, this.state.offsetX, this.state.offsetY));
        }
    };

    private onMouseLeave: MouseEventHandler<HTMLCanvasElement> = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (this.#canvasBounds && this.#ctx && this.props.onMouseLeave) {
            this.HandleEventCursorResult(this.props.onMouseLeave(e, this.#ctx, this.#canvasBounds, this.state.ratio, this.state.offsetX, this.state.offsetY));
        }
    };

    private onMouseDown: MouseEventHandler<HTMLCanvasElement> = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (e.target === this.#cvRef.current) {
            e.preventDefault();
        }

        if (this.#canvasBounds && this.#ctx && this.props.onMouseDown) {
            this.HandleEventCursorResult(this.props.onMouseDown(e, this.#ctx, this.#canvasBounds, this.state.ratio, this.state.offsetX, this.state.offsetY));
        }

    };

    private onTouchStart: TouchEventHandler<HTMLCanvasElement> = (e: React.TouchEvent<HTMLCanvasElement>) => {
        if (e.target === this.#cvRef.current) {
            e.preventDefault();
        }

        if (this.#canvasBounds && this.#ctx && this.props.onTouchStart) {
            this.HandleEventCursorResult(this.props.onTouchStart(e, this.#ctx, this.#canvasBounds, this.state.ratio, this.state.offsetX, this.state.offsetY));
        }
    };


    private initCanvas() {

        if (!this.#cvRef.current || !this.#imgRef.current || !this.#divRef.current)
            return;

        this.#cvRef.current.width = this.#imgRef.current.clientWidth;
        this.#cvRef.current.height = this.#imgRef.current.clientHeight;
        this.#divRef.current.style.width = this.#imgRef.current.clientWidth + ' px';
        this.#divRef.current.style.height = this.#imgRef.current.clientHeight + ' px';

        const canvas: HTMLCanvasElement = this.#cvRef.current;

        this.#ctx = canvas.getContext('2d');
        if (!this.#ctx)
            throw Error("Could not get canvas drawing context.");

        this.#ctx.fillStyle = this.props.theme.palette.background.default;
        this.#ctx.strokeStyle = 'pink';
        this.#ctx.lineWidth = 4;
        this.#canvasBounds = canvas.getBoundingClientRect();

        if (this.props.onInitCanvas)
            this.props.onInitCanvas(this.#ctx, this.#canvasBounds);

        if (this.props.renderAsDiv) {
            const ratio = this.getRatioFromImage(this.#imgRef.current);
            const offsetX = this.getOffsetXFromImage(ratio, this.#imgRef.current);
            const offsetY = this.getOffsetYFromImage(ratio, this.#imgRef.current);
            this.setState({ ratio, offsetX, offsetY });
        }

    }

    onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        let img: HTMLImageElement = e.target as HTMLImageElement;
        const ratio = this.getRatioFromImage(img);
        const offsetX = this.getOffsetXFromImage(ratio, img);
        const offsetY = this.getOffsetYFromImage(ratio, img);
        this.setState({ ratio, offsetX, offsetY });
        this.initCanvas();
        this.props.drawCanvas(this.#ctx, this.#canvasBounds, ratio, offsetX, offsetY);
    }

    private getRatioFromImage(img: HTMLImageElement) {
        if (this.props.renderAsDiv) {
            const smallestDimension = Math.min(img.clientWidth, img.clientHeight);
            const ratio: number = this.calculateRatio(smallestDimension);
            return ratio;
        }
        else {
            const ratio: number = this.calculateRatio(img.width);
            return ratio;
        }
    }

    private getOffsetXFromImage(ratio: number, img: HTMLImageElement) {
        if (this.props.renderAsDiv) {
            const offsetX: number = (img.clientWidth - (ScalableImageWithCanvasOverlay.DEFAULT_IMG_SIZE * ratio)) / 2;
            return offsetX;
        }
        else {
            return 0;
        }
    }

    private getOffsetYFromImage(ratio: number, img: HTMLImageElement) {
        if (this.props.renderAsDiv) {
            const offsetY: number = (img.clientHeight - (ScalableImageWithCanvasOverlay.DEFAULT_IMG_SIZE * ratio)) / 2;
            return offsetY;
        }
        else {
            return 0;
        }
    }

    private handleCanvasResized = () => {
        if (!this.#imgRef.current)
            return;

        const newRatio: number = this.getRatioFromImage(this.#imgRef.current);
        const newOffsetX: number = this.getOffsetXFromImage(newRatio, this.#imgRef.current);
        const newOffsetY: number = this.getOffsetYFromImage(newRatio, this.#imgRef.current);

        if (newRatio !== this.state.ratio || newOffsetX !== this.state.offsetX || newOffsetY !== this.state.offsetY) {
            this.setState({ ratio: newRatio, offsetX: newOffsetX, offsetY: newOffsetY });
            this.initCanvas(); //Handle resize of canvas to match new image size
            this.props.drawCanvas(this.#ctx, this.#canvasBounds, newRatio, newOffsetX, newOffsetY);
        }
    }

    private calculateRatio(imgWidth: number): number {
        return imgWidth / ScalableImageWithCanvasOverlay.DEFAULT_IMG_SIZE;
    }

}
