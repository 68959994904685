export enum ResponseTypeEnum {
    Ok,
    BadRequest,
    ServerError,
    Redirect, //301
    Found, //302
    UnAuthorized,
    Unknown,
    Forbidden,
    ServiceUnavailable
}

export interface ISessionErrorMessage {
    message: string;
}

export interface IAPIErrorMessage {
    error: string;
}

export default class WebResponse<T> {

    public Result!: T;
    public Message!: IAPIErrorMessage | ISessionErrorMessage | string | null;
    public AuthRequired!: boolean;
    public BadRequest: boolean;
    private responseType: ResponseTypeEnum;

    public Location!: string;
    public FileName!: string;

    constructor(responseType: ResponseTypeEnum) {
        this.responseType = responseType;
        this.BadRequest = (responseType === ResponseTypeEnum.BadRequest);
    }

    //WEB API 2 and NET6 serialize 400 errors differently.  This will find the error message regardless of its source.
    public GetErrorMessage():string {
        if (this.Message) {
            if ( this.Message.hasOwnProperty("message") ) {
                return this.Message["message"];
            } else if ( this.Message.hasOwnProperty("error") ) {
                return this.Message["error"];
            } else {
                return this.Message as string;
            }
        }
        return "";
    
    }

    public GetResponseType(): ResponseTypeEnum {
        return this.responseType;
    }

    public IsOk = () => this.responseType === ResponseTypeEnum.Ok
}
