import { useTranslations } from "@fenetech/translations";
import { Grid, Box } from "@mui/material";
import { EdgeAdjustmentsEnum } from "../../enums";
import { ICADLayer, ICADEdge } from "../../interfaces";
import AddEdgeAdjustmentItemDisplay from "./AddEdgeAdjustmentItemDisplay";


interface IAddEdgeAdjustmentSelectTypeProps {
    layer: ICADLayer;
    selectedEdge: ICADEdge | undefined;
    cancelCallback: () => void;
    handleAdjustmentClick: (adjustmentType: EdgeAdjustmentsEnum) => void;
}

const AddEdgeAdjustmentSelectType: React.FC<IAddEdgeAdjustmentSelectTypeProps> = (props: IAddEdgeAdjustmentSelectTypeProps) => {

    const tm = useTranslations();

    if (!props.selectedEdge) return null;

    //Get tools enabled at a setup level
    let enabledTools = props.layer.edgeAdjustmentTools ?? EdgeAdjustmentsEnum.None;

    //Remove tools that are already applied to the edge
    if (props.layer.edgeShifts.find((shift) => shift.edgeName === props.selectedEdge!.edgeName)) { enabledTools &= ~EdgeAdjustmentsEnum.Shift; }
    if (props.layer.edgeRakes.find((rake) => rake.edgeName === props.selectedEdge!.edgeName)) { enabledTools &= ~(EdgeAdjustmentsEnum.Rake | EdgeAdjustmentsEnum.Elbow); }
    if (props.layer.edgeElbows.find((elbow) => elbow.edgeName === props.selectedEdge!.edgeName)) { enabledTools &= ~(EdgeAdjustmentsEnum.Rake | EdgeAdjustmentsEnum.Elbow); }


    return <>

        <Grid item container xs justifyContent={"center"} >
            <Box flexWrap={"wrap"} display={"inline-flex"} justifyContent={"center"}>

                {(enabledTools & EdgeAdjustmentsEnum.Shift) !== 0 &&
                    <AddEdgeAdjustmentItemDisplay description={tm.Get("Edge Shift")} adjustmentType={EdgeAdjustmentsEnum.Shift} handleAdjustmentClick={props.handleAdjustmentClick} />
                }

                {(enabledTools & EdgeAdjustmentsEnum.Rake) !== 0 &&
                    <AddEdgeAdjustmentItemDisplay description={tm.Get("Edge Rake")} adjustmentType={EdgeAdjustmentsEnum.Rake} handleAdjustmentClick={props.handleAdjustmentClick} />
                }

                {(enabledTools & EdgeAdjustmentsEnum.Elbow) !== 0 &&
                    <AddEdgeAdjustmentItemDisplay description={tm.Get("Edge Elbow")} adjustmentType={EdgeAdjustmentsEnum.Elbow} handleAdjustmentClick={props.handleAdjustmentClick} />
                }

            </Box>
        </Grid>

    </>;
}

export default AddEdgeAdjustmentSelectType;