import React, { useCallback, useMemo } from "react";
import { TableContainer, Table, TableBody } from "@mui/material"

import LineItemHeader from "components/Orders/OrderView/LineItems/Rows/LineItemHeader";
import LineItem from "components/Orders/OrderView/LineItems/Rows/LineItem";
import LineItemFooter from "components/Orders/OrderView/LineItems/Rows/LineItemFooter";
import LineItemColumn from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";
import LineItemExceptionIconColumn from "components/Orders/OrderView/LineItems/Columns/LineItemExceptionIconColumn";
import LineItemSizeColumn from "components/Orders/OrderView/LineItems/Columns/LineItemSizeColumn";
import LineItemTextColumn from "components/Orders/OrderView/LineItems/Columns/LineItemTextColumn";
import LineItemPartColumn from "components/Orders/OrderView/LineItems/Columns/LineItemPartColumn";
import LineItemUnitPriceColumn, { ILineItemUnitPriceEvents } from "components/Orders/OrderView/LineItems/Columns/LineItemUnitPriceColumn";
import LineItemTotalsColumn from "components/Orders/OrderView/LineItems/Columns/LineItemTotalsColumn";
import LineItemImageColumn from "components/Orders/OrderView/LineItems/Columns/LineItemImageColumn";
import LineItemCard from "components/Orders/OrderView/LineItems/Cards/LineItemCard";
import LineItemFooterCard from "components/Orders/OrderView/LineItems/Cards/LineItemFooterCard";

import { useTranslations, ITranslationManager } from "@fenetech/translations";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useOrderData from "components/Orders/useOrderData";
import useIsMobile from "helpers/hooks/useIsMobile";

import OrdersAPI from "components/Orders/OrdersAPI";
import { ILineItem } from "helpers/interfaces";

export interface ILineItemGridProps {
    tm: ITranslationManager;
    priceVisible: boolean;
    sqFtPriceVisible: boolean;
    costVisible: boolean;
    isPickup: boolean | undefined;
    onSurchargeClick: () => void;
    onTaxClick: () => void;
}

const LineItemGrid: React.FC<ILineItemGridProps> = (gridProps: ILineItemGridProps) => {

    const { priceVisible, sqFtPriceVisible, isPickup, onSurchargeClick, onTaxClick } = gridProps;
    const { order, lineItems, permissions } = useOrderData();

    const tm = useTranslations();
    const messageBox = useMessageBox();
    const isMobile = useIsMobile();

    const itemGroupVisible = useMemo(() => {
        return lineItems?.findIndex((li) => li.itemGroup !== "") !== -1;
    }, [lineItems]);

    const onLineItemExceptionClick = useCallback((lineItem: ILineItem) => {

        if (order?.oKey && lineItem.odKey) {
            OrdersAPI.GetLineItemExceptions(order.oKey, lineItem.odKey).then((exceptions) => {
                messageBox.Show({ message: exceptions.join("\n"), title: tm.Get("Exceptions") });
            });
        }

    }, [messageBox, tm, order]);

    const onLineItemImageClick = useCallback((imageSource: string) => {

        messageBox.Show({ imageSource: imageSource });

    }, [messageBox]);

    const unitPriceEvents: ILineItemUnitPriceEvents = useMemo(() => {
        return {
            onSurchargeClick: onSurchargeClick,
            onTaxClick: onTaxClick,
        };
    }, [onSurchargeClick, onTaxClick]);

    const sqFtPriceText = useMemo(() => {
        return order?.engineeringUnitSetID === 2 ? tm.Get("SqM Price") : tm.Get("SqFt Price");
    }, [order, tm]);

    const columns = useMemo(() => {
        let cols: LineItemColumn[] = [];

        cols.push(new LineItemExceptionIconColumn("colException", "", "8px", (li) => onLineItemExceptionClick(li)));
        cols.push(new LineItemTextColumn("colItem", tm.Get("Item"), "50px", "center", (li) => li.lineItemNumber.toString()));
        cols.push(new LineItemTextColumn("colQty", tm.Get("Qty"), "", "center", (li) => li.quantity.toString()));
        if (isPickup) {
            cols.push(new LineItemTextColumn("colPickedUpQty", tm.Get("Picked Up"), "8%", "center", (li) => li.deliveredQuantity.toString()));
            cols.push(new LineItemTextColumn("colReceivedUpQty", tm.Get("Received"), "8%", "center", (li) => li.receivedQuantity.toString()));
        }
        cols.push(new LineItemPartColumn("colPart", tm.Get("Part"), "50%"));
        cols.push(new LineItemSizeColumn("colSize", tm.Get("Size"), "20%"));
        if (itemGroupVisible) {
            cols.push(new LineItemTextColumn("colItemGroup", tm.Get("Group"), "10%", "left", (li) => li.itemGroup));
        }
        if (priceVisible) {
            cols.push(new LineItemUnitPriceColumn("colPrice", tm.Get("Price"), "10%", false, !sqFtPriceVisible, unitPriceEvents));
            if (sqFtPriceVisible) {
                cols.push(new LineItemUnitPriceColumn("colSqFtPrice", sqFtPriceText, "10%", true, true, unitPriceEvents));
            }
            cols.push(new LineItemTotalsColumn("colTotal", tm.Get("Total"), "10%"));
        }
        cols.push(new LineItemImageColumn("colImage", "", "16px", onLineItemImageClick));
        return cols;
    }, [isPickup, priceVisible, sqFtPriceText, sqFtPriceVisible, itemGroupVisible, tm, onLineItemExceptionClick, unitPriceEvents, onLineItemImageClick]);

    return <>
        {isMobile ?
            <>
                {order && permissions &&
                    lineItems?.filter(li => li.isSurchargePart === false).map((li) => (
                        <LineItemCard
                            key={li.lineItemNumber}
                            order={order}
                            permissions={permissions}
                            lineItem={li}
                            lineItemGridProps={gridProps}
                            onImageClick={onLineItemImageClick}
                            onExceptionClick={onLineItemExceptionClick}
                        />
                    ))}
                {priceVisible && 
                    <LineItemFooterCard
                        onSurchargeClick={onSurchargeClick}
                        onTaxClick={onTaxClick}
                    />
                }
            </>
            :
            <TableContainer>
                <Table sx={{ width: "100%", tableLayout: "auto" }} size="small" padding="none">
                    <LineItemHeader lineItemGridProps={gridProps} columns={columns} />
                    <TableBody>
                        {lineItems?.filter(li => li.isSurchargePart === false).map((li) => (
                            <LineItem key={li.lineItemNumber} lineItemGridProps={gridProps} columns={columns} lineItem={li} />
                        )
                        )}
                        <LineItemFooter lineItemGridProps={gridProps} columns={columns} />
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </>;
}

export default LineItemGrid;
