import { ITranslationManager, useTranslations } from "@fenetech/translations";
import { Grid, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { ICADLayer, ICADEdge, IEdgeShift, IEdgeElbow, IEdgeRake, IAdjustmentIdentifier, ICADModel } from "../../interfaces";
import EdgeAdjustmentEdgeComponent from "./EdgeAdjustmentEdgeComponent";
import { EdgeAdjustmentsEnum } from "../../enums";
import AddEdgeAdjustmentSelectEdge from "./AddEdgeAdjustmentSelectEdge";
import AddEdgeAdjustmentSelectType from "./AddEdgeAdjustmentSelectType";
import { LoadingButton } from "@mui/lab";
import { EdgeToolAPI } from "./EdgeToolAPI";
import { useCADActions, useCADState } from "../../Context/CADContext";
import { CADActions } from "../../Context/CADActions";
import WebResponse from "helpers/WebResponse";
import useIsMobile from "helpers/hooks/useIsMobile";

interface IAddEdgeAdjustmentProps {
    layer: ICADLayer;
    addingAdjustmentState: AddEdgeAdjustmentStateEnum;
    setAddingAdjustmentState: (state: AddEdgeAdjustmentStateEnum) => void;
}

export enum AddEdgeAdjustmentStateEnum {
    None,
    SelectEdge,
    SelectType,
    EnterAdjustment
}

export type EdgeAdjustment = (IEdgeShift | IEdgeElbow | IEdgeRake) & IAdjustmentIdentifier;

function submitAPIAndHandleResult(apiCall: Promise<WebResponse<ICADModel>>, setShiftErrorMessage: (message: string) => void, cadActions: CADActions, setSubmitting: (value: boolean) => void, tm: ITranslationManager) {

    setShiftErrorMessage("");
    setSubmitting(true);

    return apiCall.then((response) => {
        if (response.BadRequest) {
            setShiftErrorMessage(tm.GetWithParams("Error applying edge adjustment: {0}", (response.GetErrorMessage() ?? tm.Get("An unknown error has occurred."))));
            return false;
        } else {
            const result = response.Result;
            cadActions.setModel(result);
            setShiftErrorMessage("");
            return true;
        }
    }).finally(() => {
        setSubmitting(false);
    });
}

const AddEdgeAdjustment: React.FC<IAddEdgeAdjustmentProps> = ({ layer, addingAdjustmentState, setAddingAdjustmentState }) => {

    //Edges are not adjustable if they have a shift and either an elbow or rake
    const adjustableEdges = layer.edges.filter((edge) => {
        return !layer.edgeShifts.find((shift) => shift.edgeName === edge.edgeName) ||
            (!layer.edgeRakes.find((rake) => rake.edgeName === edge.edgeName) &&
                !layer.edgeElbows.find((elbow) => elbow.edgeName === edge.edgeName));
    });

    const tm = useTranslations();
    const isMobile = useIsMobile();

    const [selectedEdge, setSelectedEdge] = React.useState<ICADEdge | null>(null);
    const [selectedAdjustmentType, setSelectedAdjustmentType] = React.useState<EdgeAdjustmentsEnum | null>(null);

    const cadActions = useCADActions();
    const cadState = useCADState();
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [canApply, setCanApply] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    useEffect(() => {

        if (cadState.activeEdgeAdjustment) {
            setSelectedEdge(layer.edges.find((edge) => edge.edgeName === cadState.activeEdgeAdjustment!.edgeName) ?? null);
            setSelectedAdjustmentType(cadState.activeEdgeAdjustment.adjustmentType);
        } else {
            setSelectedEdge(null);
            setSelectedAdjustmentType(null);
        }

    }, [cadActions, cadState.activeEdgeAdjustment, layer.edges]);

    const handleAdd = useCallback(() => {
        setAddingAdjustmentState(AddEdgeAdjustmentStateEnum.SelectEdge);
        cadActions.setActiveEdgeAdjustment(null);
    }, [cadActions, setAddingAdjustmentState]);

    const handleEdgeSelect = useCallback((edge: ICADEdge) => {
        setSelectedEdge(edge);
        setAddingAdjustmentState(AddEdgeAdjustmentStateEnum.SelectType);
        cadActions.setActiveEdgeAdjustment({ adjustmentType: EdgeAdjustmentsEnum.Shift, edgeName: edge.edgeName, offset: 0 });
    }, [cadActions, setAddingAdjustmentState]);

    const handleAdjustmentClick = useCallback((adjustmentType: EdgeAdjustmentsEnum) => {
        setSelectedAdjustmentType(adjustmentType);
        setAddingAdjustmentState(AddEdgeAdjustmentStateEnum.EnterAdjustment);
        cadActions.setActiveEdgeAdjustment({ adjustmentType: adjustmentType, edgeName: selectedEdge?.edgeName ?? "", offset: 0 });
    }, [cadActions, selectedEdge?.edgeName, setAddingAdjustmentState]);

    const handleCancel = useCallback(() => {
        setAddingAdjustmentState(AddEdgeAdjustmentStateEnum.None);
        setSelectedEdge(null);
        cadActions.setActiveEdgeAdjustment(null);
        setSelectedAdjustmentType(null);
        setCanApply(false);
    }, [cadActions, setAddingAdjustmentState]);

    const handleCanApply = useCallback((canApply: boolean, item: EdgeAdjustment | null) => {
        cadActions.setActiveEdgeAdjustment(item);
        setCanApply(canApply);
    }, [cadActions]);

    const handleApply = useCallback(async () => {

        if (!selectedEdge)
            return false;

        let result: boolean = false;

        if (canApply && cadState.activeEdgeAdjustment) {

            setSubmitting(true);

            let apiCall: Promise<WebResponse<ICADModel>> | null = null;

            switch (cadState.activeEdgeAdjustment.adjustmentType) {
                case EdgeAdjustmentsEnum.Shift:
                    const shift = cadState.activeEdgeAdjustment as IEdgeShift;
                    apiCall = EdgeToolAPI.PostEdgeShift(selectedEdge.edgeName, shift.offset);
                    break;
                case EdgeAdjustmentsEnum.Rake:
                    const rake = cadState.activeEdgeAdjustment as IEdgeRake;
                    apiCall = EdgeToolAPI.PostEdgeRake(selectedEdge.edgeName, rake.offset, rake.rakeStart);
                    break;
                case EdgeAdjustmentsEnum.Elbow:
                    const elbow = cadState.activeEdgeAdjustment as IEdgeElbow;
                    apiCall = EdgeToolAPI.PostEdgeElbow(selectedEdge.edgeName, elbow.startFixed, elbow.breakDistance, elbow.breakOffset, elbow.overallOffset);
                    break;
            }

            if (apiCall) {
                result = await submitAPIAndHandleResult(apiCall, setErrorMessage, cadActions, setSubmitting, tm);
            }

        }

        if (result) {
            handleCancel();
        }

        return result;

    }, [cadActions, canApply, handleCancel, selectedEdge, tm, cadState.activeEdgeAdjustment]);


    if (adjustableEdges.length === 0) return null;

    const justifyButtons = isMobile ? "center" : "flex-end";
    const buttonSize = isMobile ? "large" : "small";

    return <>

        <Grid container width={"100%"} direction={"column"} padding={1} spacing={1} >

            {addingAdjustmentState === AddEdgeAdjustmentStateEnum.None ?
                <Grid item container justifyContent={justifyButtons} >
                    <Button variant="contained" color="secondary" onClick={handleAdd} size={buttonSize} >
                        {tm.Get("Add")}
                    </Button>
                </Grid>
                :
                <Grid item container justifyItems={justifyButtons} spacing={1} flexDirection={isMobile ? "column" : "row"} >

                    <Grid item  >
                        {selectedEdge && <Typography>{tm.GetWithParams("Edge {0}", selectedEdge.edgeName)}</Typography>}
                    </Grid>

                    <Grid container item justifyContent={justifyButtons} xs spacing={1}>

                        <Grid item>
                            <LoadingButton variant="contained"
                                size={buttonSize}
                                color="secondary"
                                onClick={handleApply} disabled={!canApply}
                                loading={submitting}
                            >
                                {tm.Get("Apply")}
                            </LoadingButton>
                        </Grid>

                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleCancel} size={buttonSize}>
                                {tm.Get("Cancel")}
                            </Button>
                        </Grid>

                    </Grid>

                </Grid>
            }

            <Grid container item justifyContent={"center"} >

                {addingAdjustmentState === AddEdgeAdjustmentStateEnum.SelectEdge &&
                    <AddEdgeAdjustmentSelectEdge adjustableEdges={adjustableEdges} handleEdgeSelect={handleEdgeSelect} />
                }

                {addingAdjustmentState === AddEdgeAdjustmentStateEnum.SelectType && selectedEdge &&
                    <AddEdgeAdjustmentSelectType layer={layer} cancelCallback={handleCancel} handleAdjustmentClick={handleAdjustmentClick} selectedEdge={selectedEdge} />
                }

                {addingAdjustmentState === AddEdgeAdjustmentStateEnum.EnterAdjustment && selectedEdge && selectedAdjustmentType &&
                    <EdgeAdjustmentEdgeComponent
                        layer={layer}
                        edge={selectedEdge}
                        adjustmentType={selectedAdjustmentType}
                        handleCanApply={handleCanApply}
                        errorMessage={errorMessage}
                    />
                }

            </Grid>

        </Grid>

    </>;
}

export default AddEdgeAdjustment;
