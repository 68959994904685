import APIHelper from "helpers/APIHelper";
import { IPasteSpecialLineAPIParams } from "helpers/interfaces";

export default class PasteSpecialAPI {
    
    public static async GetLineItemShapeParams(oKey: number, odKey: number): Promise<object> {
        const url: string = `api/quotes/${oKey}/lineitems/${odKey}/shapeparams`;
        const response = await APIHelper.GetAsync<object>(url);
        return response.Result;
    }

    public static async PasteLineItems(oKey: number, odKey: number, pasteSpecialLines: IPasteSpecialLineAPIParams[]): Promise<void> {
        const url: string = `api/quotes/${oKey}/lineitems/${odKey}/pastespecial`;
        const response = await APIHelper.PostAsync<void>(url, undefined, pasteSpecialLines);
        return response.Result;
    }
}