import { useTranslations } from "@fenetech/translations";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ICADEdge } from "../../interfaces";

interface IAddEdgeAdjustmentSelectEdgeProps {

    adjustableEdges: ICADEdge[];
    handleEdgeSelect: (edge: ICADEdge) => void;
}

const AddEdgeAdjustmentSelectEdge: React.FC<IAddEdgeAdjustmentSelectEdgeProps> = (props: IAddEdgeAdjustmentSelectEdgeProps) => {

    const tm = useTranslations();

    return <>
        <Grid item container alignItems={"center"} >

            <Grid item xs pr={1}>
                <FormControl fullWidth size="small">
                    <InputLabel id="edge-selection-label">{tm.Get("Edge")}</InputLabel>
                    <Select
                        labelId="edge-selection-label"
                        label={tm.Get("Edge")}
                        size="small"
                        fullWidth
                        onChange={(event) => { props.handleEdgeSelect(props.adjustableEdges.find((edge) => edge.edgeName === event.target.value)!) }}
                    >
                        {props.adjustableEdges.map((edge, index) => {
                            return <MenuItem key={index} value={edge.edgeName}>{tm.GetWithParams("Edge {0}", edge.edgeName)}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

        </Grid>
    </>;
}

export default AddEdgeAdjustmentSelectEdge;