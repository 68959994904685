export class Constants {

    public static Min = class {
        public static readonly MarkdownPercent: number = 0;
        public static readonly DiscountPercent: number = -5000;
        public static readonly DiscountMultiplier: number = -49;
        public static readonly MarkupPercent: number = -5000;
        public static readonly MarkupMultiplier: number = -49;
        public static readonly MarginPercent: number = -100;
        public static readonly UpchargePercent: number = -5000;
        public static readonly Quantity: number = 1;
        public static readonly Price: number = -1000000000;
        public static readonly TaxRate: number = 0;
        public static readonly UnitInches: number = 0;
        public static readonly PasteSpecialRows: number = 0;
    }

    public static Max = class {
        public static readonly MarkdownPercent: number = 100;
        public static readonly DiscountPercent: number = 5000;
        public static readonly DiscountMultiplier: number = 51;
        public static readonly MarkupPercent: number = 5000;
        public static readonly MarkupMultiplier: number = 51;
        public static readonly MarginPercent: number = 99.99;
        public static readonly UpchargePercent: number = 5000;
        public static readonly Quantity: number = 32767;
        public static readonly Price: number = 1000000000;
        public static readonly TaxRate: number = 100;
        public static readonly UnitInches: number = 9999999999;
        public static readonly PasteSpecialRows: number = 2500;
        public static readonly PasteSpecialRowsSoft: number = 25;
    }

    public static Default = class {
        public static readonly DiscountPercent: number = 0;
        public static readonly DiscountMultiplier: number = 1;
        public static readonly MarkupPercent: number = 0;
        public static readonly MarkupMultiplier: number = 1;
        public static readonly MarginPercent: number = 0;
    }

    public static MaxLength = class {
        public static readonly AddressName: number = 100;
        public static readonly Address1: number = 150;
        public static readonly Address2: number = 150;
        public static readonly City: number = 50;
        public static readonly State: number = 30;
        public static readonly ZipCode: number = 15;
        public static readonly Phone: number = 20;
        public static readonly Fax: number = 40;
        public static readonly Country: number = 20;

        public static readonly PONumber: number = 20;
        public static readonly CustomerRef: number = 50;
        public static readonly QuoteContact: number = 50;
        public static readonly QuoteComment: number = 255;
        public static readonly ShipComment: number = 255;
        public static readonly OptionValue: number = 50;

        public static readonly CustomerName: number = 100;
        public static readonly CompanyName: number = 50;
        public static readonly CustomerNotes: number = 255;
        public static readonly CustomerTaxTitles: number = 25;
        public static readonly EmailAddress: number = 100;
        public static readonly UserDefined: number = 8000;
        public static readonly WebURL: number = 50;

        public static readonly UserName: number = 128;
        public static readonly Password: number = 255;
        public static readonly FirstName: number = 20;
        public static readonly LastName: number = 20;
        public static readonly PreferenceValue: number = 255;

        public static readonly ItemCustomerRef: number = 255;
        public static readonly ItemGroup: number = 255;
    }

    public static Strings = class {
        public static readonly ZeroWidthSpace = "\u200B";
        public static readonly Classifications: string[] = ["Recent", "Uploaded", "Submitted", "Expired", "On Hold", "Available"]
    }

    public static TransitionDelays = class {
        public static readonly Collapse: number = 300;
    }

    public static ConversionFactors = class {
        public static readonly InchesToMillimeters: number = 25.4;
    }

    public static Dimensions = class {
        public static readonly CompanyLogoWidth: number = 125;
        public static readonly CompanyLogoHeight: number = 125;
    }

}

export default Constants;