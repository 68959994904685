import React from 'react';
import { ICADOperationEdgework, ICADOperationProperties, ICADSideOperationEdit } from "../../interfaces";
import EdgeworkSelection from "./EdgeworkSelection";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import EdgeworkPerSideSelection from "./EdgeworkPerSideSelection";
import { Add as AddIcon } from "@mui/icons-material";
import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";
import { useCADActions } from "../../Context/CADContext";

interface IOperationEdgeworkProps {
    edgework: ICADOperationEdgework | undefined;
    operation: ICADOperationProperties | undefined;
    loading: boolean;
}

const OperationEdgework: React.FC<IOperationEdgeworkProps> = (props: IOperationEdgeworkProps) => {

    const tm = useTranslations();
    const cadActions = useCADActions();

    const updateSideSelection = React.useCallback( async (index: number, edgeworkLabel: string, side: number) => {
        const newSideSelection: Partial<ICADSideOperationEdit> =  { uniqueID: index, label: edgeworkLabel, side: side };
        cadActions.setOperationEditorLoading();
        const newEditor = await CADOperationEditorAPI.PostEdgeworkAsync(index, newSideSelection);
        cadActions.setOperationEditor(newEditor);
    }, [cadActions]);

    const handleUpdateEdgework = React.useCallback(async (edgework: Partial<ICADSideOperationEdit>) => {
        cadActions.setOperationEditorLoading();
        const newEditor = await CADOperationEditorAPI.PostEdgeworkAsync(edgework.side ?? 0, edgework);
        cadActions.setOperationEditor(newEditor);
    }, [cadActions]);

    const addSideSelection = React.useCallback(async () => {
        cadActions.setOperationEditorLoading();
        const state = await CADOperationEditorAPI.PostEdgeworkAsync(-1, null);
        cadActions.setOperationEditor(state);
    }, [cadActions]);

    const removeSidSelection = React.useCallback(async (side: number) => {
        cadActions.setOperationEditorLoading();
        const state = await CADOperationEditorAPI.DeleteEdgeworkAsync(side);
        cadActions.setOperationEditor(state);
    }, [cadActions]);

    if (!props.operation || !props.edgework || !props.edgework.availableEdgework || props.edgework.availableEdgework.length === 0) return null;

    const edgework = props.edgework;

    const canAddSide = edgework.sideOperations.length < edgework.sideCount ;

    if (edgework.sideCount) {

        return <Grid container flexDirection={"column"} flexWrap={"nowrap"} spacing={1}>

            <Grid item my={1}>
                <Tooltip title={tm.GetWithParams("Add Edgework")} >
                    <IconButton onClick={addSideSelection} disabled={!canAddSide} >
                        <AddIcon fontSize="small" color={canAddSide?  "primary": "disabled"}  />
                    </IconButton>
                </Tooltip>
            </Grid>

            {edgework.sideOperations.map((sideSelection, index) => {
                return <>
                    <Grid item >
                        <EdgeworkPerSideSelection key={index} index={index} availableEdgework={props.edgework?.availableEdgework}
                            sideSelection={sideSelection}
                            sideCount={edgework.sideCount}
                            updateSideSelection={updateSideSelection}
                            removeSideSelection={removeSidSelection} 
                            loading={props.loading}
                            />
                    </Grid>
                </>
            })}

        </Grid>

    } else {

        return <>
            <EdgeworkSelection availableEdgework={props.edgework.availableEdgework}
                selectedEdgework={edgework.sideOperations[0]}
                onUpdateEdgework={handleUpdateEdgework}
                loading={props.loading}
            />
        </>;

    }

}

export default OperationEdgework;
