import WebResponse from "helpers/WebResponse";
import { ICADModel, IEdgeElbow, IEdgeRake, IEdgeShift } from "../../interfaces";
import APIHelper from "helpers/APIHelper";

export class EdgeToolAPI {

    public static PostEdgeShift(edgeName: string, offset: number): Promise<WebResponse<ICADModel>> {

        const shift: IEdgeShift = { edgeName, offset };
        return APIHelper.PostAsync<ICADModel>(`api/cad/item/shifts`, undefined, shift, false);

    }

    public static PostEdgeRake(edgeName: string, offset: number, rakeStart: boolean) {

        const rake: IEdgeRake = { edgeName, offset, rakeStart: rakeStart };
        return APIHelper.PostAsync<ICADModel>(`api/cad/item/rakes`, undefined, rake, false);

    }

    public static PostEdgeElbow(edgeName: string, startFixed: boolean, breakDistance: number, breakOffset: number, oppositeOffset: number) {

        const elbow: IEdgeElbow = { edgeName, startFixed, breakDistance, breakOffset, overallOffset: oppositeOffset };
        return APIHelper.PostAsync<ICADModel>(`api/cad/item/elbows`, undefined, elbow, false);

    }


}
