import { useContext } from "react";
import QuoteAttachmentsContext, { IQuoteAttachments } from "./QuoteAttachmentsContext";

export function useQuoteAttachments(): IQuoteAttachments {

    const quoteAttachments = useContext(QuoteAttachmentsContext);
    return quoteAttachments;
};

export default useQuoteAttachments;
