import * as React from "react";
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { ICADEdge, ICADOperationProperties, ICADOperationPlacement, ICADLayer } from "../../interfaces";
import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";
import { ICADState, useCADActions, useCADState } from "../../Context/CADContext";
import { ITranslationManager, useTranslations } from "@fenetech/translations";
import { CADOperationTypeEnum, CADPlacementReferenceTypeEnum } from "../../enums";

interface IOperationParametersProps {
    operationEdit?: ICADOperationProperties,
    operationPlacement?: ICADOperationPlacement,
    loading: boolean,
}


const ALL_EDGE_NAME = "0";

const ALL_EDGE: ICADEdge = {
    edgeName: ALL_EDGE_NAME,
    edgeSegments: [],
    endPoint: { x: 0, y: 0 },
    startPoint: { x: 0, y: 0 }
};

function getEdgeLabel(e: ICADEdge, tm: ITranslationManager): string {
    if (e.edgeName === ALL_EDGE_NAME) {
        return tm.Get("{All}");
    } else {
        return e.edgeName;
    }
}

function getSelectedEdge(cadState: ICADState, tm: ITranslationManager, edgeName?: string | null): ICADEdge | undefined {
    if (edgeName) {
        if (edgeName === ALL_EDGE_NAME) {
            return ALL_EDGE;
        } else {
            return cadState.model?.layers[0].edges.find(e => e.edgeName === edgeName);
        }
    }
    return undefined;
}

function getAvailableEdges(cadState: ICADState, layer: ICADLayer, operationType: CADOperationTypeEnum, currentEdgeName: string): ICADEdge[] {

    let localEdges = layer.edges ?? [];

    if (operationType === CADOperationTypeEnum.EdgeProcessing) {
        // An edge can only have a single edgework operation applied to it.  If an edge has an edgework operation applied to it, it should not be available for selection.
        const edgeNamesWithEdgework = new Set(cadState.model?.operations.filter(o => o.operationType === CADOperationTypeEnum.EdgeProcessing).map(o => o.edgeName));

        if (edgeNamesWithEdgework && edgeNamesWithEdgework.size > 0) {
            //There is already edgework applied to the glass.
            if (edgeNamesWithEdgework.has(ALL_EDGE_NAME)) {
                //If the edgework is applied to all edges, then no edges should be available for selection.
                localEdges = [];
            } else {
                //If the edgework is applied to specific edges, then only the edges without edgework should be available for selection.
                localEdges = localEdges.filter(e => !edgeNamesWithEdgework.has(e.edgeName) || e.edgeName === currentEdgeName);
            }
        } else {
            // If there isn't any other edgework operation applied to any edge, then all edges should be available for selection.
            localEdges = [ALL_EDGE].concat(localEdges);
        }

    }

    return localEdges;
}

const OperationPlacement: React.FC<IOperationParametersProps> = (props: IOperationParametersProps) => {

    const opPlacement = props.operationPlacement;
    const cadState = useCADState();
    const cadActions = useCADActions();
    const tm = useTranslations();

    const availableEdges = React.useMemo<ICADEdge[]>(() => {
        if (cadState.model && props.operationEdit) {
            return getAvailableEdges(cadState, cadState.model.layers[0], props.operationEdit.operationType, opPlacement?.edgeName ?? "");
        } else {
            return [];
        }
    }, [cadState.model?.layers, props.operationEdit]);

    const [selectedEdge, setSelectedEdge] = React.useState<ICADEdge | undefined>(getSelectedEdge(cadState, tm, opPlacement?.edgeName));
    const [measuredFrom, setMeasuredFrom] = React.useState<"Start" | "End">(opPlacement?.fromStart ?? true ? "Start" : "End");

    const onEdgeChange = React.useCallback(async (edgeName: string) => {

        let localEdgeName: string | null = edgeName;

        if (opPlacement) {
            if (localEdgeName !== opPlacement.edgeName) {
                if (localEdgeName === tm.Get("{All}")) {
                    localEdgeName = "0";
                }
                cadActions.setOperationEditorLoading();
                const newEditor = await CADOperationEditorAPI.PostPlacementAsync({ ...opPlacement, edgeName: localEdgeName });
                cadActions.setOperationEditor(newEditor);
                const newSelectedEdge = getSelectedEdge(cadState, tm, edgeName);
                setSelectedEdge(newSelectedEdge);
            }
        }

    }, [cadActions, cadState, opPlacement]);

    const onMeasuredFromChange = React.useCallback(async (newValue: "Start" | "End") => {

        const newFromStart = newValue === "Start";
        if (opPlacement) {
            if (newFromStart !== opPlacement.fromStart) {
                cadActions.setOperationEditorLoading();
                const newEditor = await CADOperationEditorAPI.PostPlacementAsync({ ...opPlacement, fromStart: newFromStart });
                cadActions.setOperationEditor(newEditor);
                setMeasuredFrom(newValue);
            }
        }

    }, [cadActions, opPlacement]);

    const measuredFromVisible = React.useMemo(() => {
        switch (props?.operationEdit?.placementReferenceType ?? CADPlacementReferenceTypeEnum.None) {
            case CADPlacementReferenceTypeEnum.Corner:
            case CADPlacementReferenceTypeEnum.CornerAndXY:
            case CADPlacementReferenceTypeEnum.EdgeAndDistanceFromCorner:
                return true;
            default:
                return false;
        }
    }, [props]);

    return <>
        {availableEdges &&
            <Grid container direction="row" spacing={1} >
                <Grid item sm={12} md={measuredFromVisible ? 4 : 12}>
                    <Autocomplete
                        disabled={props.loading}
                        fullWidth
                        autoComplete
                        disableClearable
                        autoSelect
                        autoHighlight
                        selectOnFocus
                        handleHomeEndKeys
                        options={availableEdges}
                        getOptionLabel={(s: ICADEdge) => getEdgeLabel(s, tm)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label={tm.Get("Edge")}
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                        onChange={(_, value) => onEdgeChange(value.edgeName)}
                        value={selectedEdge}
                    />
                </Grid>
                {measuredFromVisible &&
                    <Grid item sm={12} md={8}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="rake-position-label">{tm.Get("Measured from")}</InputLabel>
                            <Select
                                disabled={props.loading}
                                labelId="measured-from-position-label"
                                label={tm.Get("Measured from")}
                                size="small"
                                fullWidth
                                value={measuredFrom}
                                onChange={(event) => onMeasuredFromChange(event.target.value as "Start" | "End")}
                            >
                                <MenuItem value={"Start"}>{tm.Get("Start")}</MenuItem>
                                <MenuItem value={"End"}>{tm.Get("End")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
            </Grid>
        }
    </>

}

export default OperationPlacement;

