import { useEffect, useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { Explore } from "@mui/icons-material";

import SelectCategory from "components/Quotes/QuoteEntry/AddLineItem/SelectCategory";
import SelectPart from "components/Quotes/QuoteEntry/AddLineItem/SelectPart";

import { IPartListItem, ICategoryListItem } from "helpers/interfaces";
import { FindAllNestedProps, isNullOrWhiteSpace } from "helpers/objects";
import { useTranslations } from "@fenetech/translations";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useIsMobile from "helpers/hooks/useIsMobile";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useMeasurementTypes from "helpers/context/SelectionValues/useMeaurementTypes";
import { usePartDefaultsRepo } from "helpers/context/Parts/usePartDefaults";
import WizardAPI from "components/OptionsWizard/WizardAPI";
import useQuoteData from "components/Quotes/useQuoteData";
import useQuoteActions from "components/Quotes/useQuoteActions";
import useWait from "helpers/context/Page/useWait";
import { MeasurementSystemEnum } from "helpers/fv.format";
import Constants from "helpers/constants";
import { useNavigate } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import { OpeningShapeEnum, ShapeDirectionEnum, SizingModeEnum, ThemeColorEnum } from "helpers/enums";
import useItemPropertiesActions from "components/OptionsWizard/ItemProperties/useItemPropertiesActions";
import { usePartCallSizesRepo } from "helpers/context/Parts/usePartCallSizes";
import { IItemPropertiesData } from "components/OptionsWizard/ItemProperties/ItemPropertiesContext";


interface ISelections {
    category: ICategoryListItem | null,
    part: IPartListItem | null,
}

const DefaultSelections: ISelections = { category: null, part: null };

const AddLineItem: React.FC = () => {
    const tm = useTranslations();
    const messageBox = useMessageBox();
    const isMobile = useIsMobile();
    const applicationInfo = useApplicationInfo();
    const measurementTypes = useMeasurementTypes()
    const wait = useWait();
    const quoteActions = useQuoteActions();
    const navigate = useNavigate();

    const { quote, defaultCategoryID, defaultPart } = useQuoteData();

    const formMethods = useForm({ mode: "onChange", defaultValues: DefaultSelections });
    const { handleSubmit, setValue, reset, watch } = formMethods;

    const selectedCategory = watch("category");
    const selectedPart = watch("part")

    const itemPropertiesActions = useItemPropertiesActions();

    const callSizeRepo = usePartCallSizesRepo();
    const partDefaultsRepo = usePartDefaultsRepo();

    useEffect(() => {
        const defaults: ISelections = {
            category: (defaultCategoryID === undefined ? null : { categoryID: defaultCategoryID, description: "" }),
            part: (defaultPart === undefined ? null : { partKey: defaultPart, description: "" }),
        };
        reset(defaults);
    }, [defaultCategoryID, defaultPart, reset]);

    const onCategoryChange = useCallback((category: ICategoryListItem | null) => {
        if (!category || category.categoryID !== defaultCategoryID) {
            setValue("part", null);
        }

    }, [defaultCategoryID, setValue]);

    const quoteMeasurementType = useMemo(() => {
        if (quote && measurementTypes) {
            return measurementTypes.find((m) => m.setID === quote.engineeringUnitSetID);
        }
    }, [quote, measurementTypes]);

    const convertSystemMeasurementTypeToQuoteMeasurementType = useCallback((value: number) => {
        if (applicationInfo.engineeringUnits.systemUnitSet !== quote?.engineeringUnitSetID) {
            if (applicationInfo.engineeringUnits.systemUnitSet === MeasurementSystemEnum.Imperial) {
                return value * Constants.ConversionFactors.InchesToMillimeters;
            } else {
                return value / Constants.ConversionFactors.InchesToMillimeters;
            }
        }
        return value;
    }, [applicationInfo, quote]);

    const handleItemPropertiesSubmit = useCallback((oldState: IItemPropertiesData, newState: IItemPropertiesData) => {

        if (quote && selectedPart) {

            const submitParams = {
                OKey: quote?.oKey,
                PartNo: selectedPart?.partKey.masterPartNo,
                PartNoSuffix: selectedPart?.partKey.partNoSuffix,
                ShortcutName: selectedPart?.partKey.shortcutName,
                Quantity: newState?.qty,
                Width: newState?.width,
                Height: newState?.height,
                Thickness: newState?.thickness,
                CustomerRef: newState?.customerRef,
                ItemGroup: newState?.itemGroup,
                CallSize: newState?.callSize,
                Comment: newState?.comment,
            };

            wait.Show(true);

            WizardAPI.InitNewLineItemAsync(submitParams).then(() => {
                navigate(QuoteNavigation.OptionsWizardURL(quote?.oKey ?? 0, 0));
            }).finally(() => wait.Show(false));

        }

    }, [quote, selectedPart, wait, navigate]);

    const onSubmit = useCallback((formData: ISelections) => {
        if (formData?.part && quoteMeasurementType) {
            const partDefaultsPromise = partDefaultsRepo(formData.part.partKey.masterPartNo, formData.part.partKey.partNoSuffix, formData.part.partKey.shortcutName);
            const partCallSizesPromise = callSizeRepo(formData.part.partKey.masterPartNo, formData.part.partKey.partNoSuffix);

            Promise.all([partDefaultsPromise, partCallSizesPromise]).then(([partDefaults, partCallSizes]) => {
                let callSizeToUse = partDefaults?.callSize ?? "";
                const hasCallSizeList = partCallSizes.callSizes.length > 0;
                if (isNullOrWhiteSpace(callSizeToUse) || (hasCallSizeList && !partCallSizes.callSizes.find((cs) => cs.callSize === callSizeToUse))) {
                    callSizeToUse = partCallSizes.callSizes.find((cs) => cs.default)?.callSize ?? "";
                }
                if (isNullOrWhiteSpace(callSizeToUse) && partCallSizes.locked && hasCallSizeList) {
                    callSizeToUse = partCallSizes.callSizes[0].callSize;
                }
                const newLineItemProps: IItemPropertiesData = {
                    callSize: callSizeToUse,
                    comment: partDefaults?.comment ?? "",
                    height: convertSystemMeasurementTypeToQuoteMeasurementType(partDefaults?.height ?? 0),
                    qty: partDefaults?.quantity ?? 1,
                    thickness: convertSystemMeasurementTypeToQuoteMeasurementType(partDefaults?.thickness ?? 0),
                    width: convertSystemMeasurementTypeToQuoteMeasurementType(partDefaults?.width ?? 0),
                    direction: ShapeDirectionEnum.Left, 
                    legHeight: 0,
                    radius: 0,
                    shape: OpeningShapeEnum.Standard,
                    sizing: SizingModeEnum.Fixed,
                    customerRef: partDefaults?.customerRef ?? "",
                    itemGroup: partDefaults?.itemGroup ?? ""
                }
                itemPropertiesActions.SetState(newLineItemProps, quoteMeasurementType.setID);

                handleItemPropertiesSubmit(newLineItemProps, newLineItemProps);
            });
        }
    }, [callSizeRepo, convertSystemMeasurementTypeToQuoteMeasurementType, handleItemPropertiesSubmit, itemPropertiesActions, partDefaultsRepo, quoteMeasurementType]);

    const onError = (errors: { [x: string]: any }) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const handleProductNavClick = useCallback(() => {
        if (quote) {
            quoteActions.SetProductNavigatorState({ cameFromProductNavigator: false, callSize: "", finalNodeID: "" , isUsingProductFilter:false, productFilterHasPartsToAdd:false, productFilterPartsToAdd: []  });
            navigate(QuoteNavigation.ProductNavigatorURL(quote?.oKey ?? 0));
        }
    }, [quote, quoteActions, navigate]);

    return <>
        {quote &&
            <FormProvider {...formMethods}>
                <form id="addLineItem" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Grid container direction="row" spacing={1}>
                        {applicationInfo.productNavigatorEnabled &&
                            <Grid item xs="auto" display="flex" flexDirection="row" alignItems="center">
                                <Tooltip title={tm.Get("Product Navigator")}>
                                    <IconButton aria-label="productNav" sx={{ padding: 0 }} onClick={handleProductNavClick}>
                                        <Explore sx={{ width: "48px", height: "48px" }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item xs>
                            <Grid container direction={isMobile ? "column" : "row"} spacing={1}>
                                <Grid item xs>
                                    <SelectCategory
                                        mfgCustomerID={quote.mfgCustomerID}
                                        mfgSiteID={quote.mfgSiteID}
                                        onChange={onCategoryChange}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <SelectPart
                                        mfgCustomerID={quote.mfgCustomerID}
                                        mfgSiteID={quote.mfgSiteID}
                                        categoryID={selectedCategory?.categoryID}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" display="flex" flexDirection="row" alignItems="center">
                            <Button variant="contained" color={ThemeColorEnum.Secondary} type="submit">{tm.Get("Add")}</Button>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        }
    </>
}

export default AddLineItem;
