import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Stack, Grid, Link, IconButton } from "@mui/material";
import { Room, Phone, LocalShipping, SpeakerNotes, Edit } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";

import { useTranslations } from "@fenetech/translations";
import useQuoteData from "components/Quotes/useQuoteData";

import Format from "helpers/fv.format";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import { isNullOrWhiteSpace } from "helpers/objects";

function ShippingCard() {
    const tm = useTranslations();
    const { quote, permissions, lock } = useQuoteData();
    const navigate = useNavigate();

    const canEditShipping = useMemo(() => {
        if (permissions && lock) {
            return permissions.shippingAddress && !lock.isLockedByAnotherUser;
        }
    }, [permissions, lock]);

    return <>
        <Paper sx={{ display: "flex", width: "100%" }}>
            <Stack display="flex" width="100%" m={1}>
                <Grid container direction="row">
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="h6">
                            {tm.Get("Shipping")}
                        </Typography>
                    </Grid>
                    <Grid item display="flex" justifyContent="right">
                        {canEditShipping &&
                            <IconButton onClick={() => navigate(QuoteNavigation.QuoteEditURL(quote?.oKey ?? 0, "shipping"))}>
                                <Edit />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
                <Grid container mt={1} direction="row">
                    <Grid item mr={1}>
                        <Room color="primary" />
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {quote?.shipping?.name ?? ""}
                            <br />
                            {quote?.shipping?.address?.address1 ?? ""}
                            <br />
                            {quote?.shipping?.address?.address2 ?? ""}
                            <br />
                            {Format.FormatCityStateZip(quote?.shipping?.address?.city ?? "", quote?.shipping?.address?.state ?? "", quote?.shipping?.address?.zipCode ?? "")}
                            <br />
                            {quote?.shipping?.address?.country ?? ""}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Phone color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Phone")} text={
                            <Link href={`tel:${quote?.shipping?.phone ?? ""}`} underline="always" tabIndex={isNullOrWhiteSpace(quote?.shipping?.phone) ? -1 : undefined}>
                                {quote?.shipping?.phone ?? ""}
                            </Link>
                        } />
                    </Grid>
                </Grid>

                {quote?.shipping?.fax &&
                    <Grid container mt={1} direction="row">
                        <Grid item mr={1} display="flex" alignItems="center">
                            <Phone opacity={0} />
                        </Grid>
                        <Grid item xs display="flex" alignItems="center">
                            <LabeledText label={tm.Get("Fax")} text={quote.shipping.fax} />
                        </Grid>
                    </Grid>
                }

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <LocalShipping color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Ship Via")} text={quote?.shipping?.shipVia} />
                    </Grid>
                    {quote?.shipping?.dropShip &&
                        <Grid item xs display="flex" alignItems="center">
                            <Typography>{tm.Get("Drop Ship")}</Typography>
                        </Grid>
                    }
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <SpeakerNotes color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Comment")} text={quote?.shipping?.shippingComment} />
                    </Grid>
                </Grid>

            </Stack>
        </Paper>
    </>

}

export default ShippingCard;