import { LoadingButton } from "@mui/lab";
import { Grid, Button } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from 'react';
import CADOperationLibrary from "../CADOperationLibrary";
import OperationEditor from "./OperationEditor";
import { useTranslations } from "@fenetech/translations";
import CADItemAPI from "../../API/CADItemAPI";
import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";
import { useCADActions, useCADState } from "../../Context/CADContext";
import { ICADOperationLibraryItem } from "../../interfaces";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { CADOperationTypeEnum } from "../../enums";
import useIsMobile from "helpers/hooks/useIsMobile";

interface ICADOperationAddEditProps {
    operationType: CADOperationTypeEnum;
}

const CADOperationAddEdit: React.FC<ICADOperationAddEditProps> = (props: ICADOperationAddEditProps) => {

    const tm = useTranslations();
    const cadActions = useCADActions();
    const cadState = useCADState();
    const messageBox = useMessageBox();

    const [choosingOperation, setChoosingOperation] = React.useState(false);
    const [editingOperation, setEditingOperation] = React.useState(false);

    const [applying, setApplying] = React.useState(false);
    const isMobile = useIsMobile();


    useEffect(() => {
        if (cadState.operationEditor) {
            setEditingOperation(true);
        } else {
            setEditingOperation(false);
        }
    }, [cadState.operationEditor]);


    const onNewOperationChosen = useCallback(async (operation: ICADOperationLibraryItem) => {
        const editor = await CADOperationEditorAPI.InitNewAsync(operation.opType, operation.operationID);
        cadActions.setOperationEditor(editor);
        setChoosingOperation(false);
        setEditingOperation(true);
    }, [cadActions]);

    const onApplyOperation = useCallback(async (_: any) => {
        setEditingOperation(false);
        setChoosingOperation(false);
        setApplying(true);
        const [success, message] = await CADOperationEditorAPI.ApplyOperationAsync();

        if (success) {
            const cadModel = await CADItemAPI.GetCADItem();
            setApplying(false);
            cadActions.setOperationEditor(null);
            cadActions.setHoveredOperation(null);
            cadActions.setModel(cadModel);
        } else {
            setApplying(false);
            setEditingOperation(true);
            messageBox.Show({ message: message, title: tm.Get("Error") });
        }

    }, [cadActions, messageBox, tm]);

    const justifyButtons = isMobile ? "center" : "flex-end";
    const buttonSize = isMobile ? "large" : "small";
    
    const allowAdd = useMemo(() => {

        let allowAdd = true;

        if (props.operationType === CADOperationTypeEnum.EdgeProcessing) {
            const allEdgework = cadState.model?.operations.filter((o) => o.operationType === CADOperationTypeEnum.EdgeProcessing && o.edgeName === "0");
            if (allEdgework && allEdgework.length > 0) {
                allowAdd = false;
            }
        }
        return allowAdd;

    },[cadState.model?.operations, props.operationType]);

    return <Grid container direction={"column"} spacing={1} m={1} pr={1}  >

        {(!choosingOperation && !editingOperation && allowAdd) &&
            <Grid visibility={choosingOperation ? "collapse" : "visible"} container item xs mr={1} justifyContent={justifyButtons} >
                <Button
                    variant="contained"
                    color="secondary"
                    size={buttonSize}
                    onClick={() => { setChoosingOperation(true) }}>{tm.Get("Add")}</Button>
            </Grid>
        }

        {(choosingOperation || editingOperation) && <>
            <Grid container item xs direction={"row"} spacing={1} justifyContent={justifyButtons} >
                {editingOperation &&
                    <Grid item  >
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            size={buttonSize}
                            type="submit"
                            title={tm.Get("Apply")}
                            loading={applying}
                            onClick={onApplyOperation}>
                            {tm.Get("Apply")}
                        </LoadingButton>
                    </Grid>
                }
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        size={buttonSize}
                        onClick={() => {
                            setEditingOperation(false);
                            setChoosingOperation(false);
                            cadActions.setOperationEditor(null);
                        }}>
                        {tm.Get("Cancel")}
                    </Button>
                </Grid>
            </Grid>
        </>}

        {choosingOperation && <>
            <Grid container item xs justifyContent={"flex-start"} >
                <CADOperationLibrary
                    operationType={props.operationType}
                    addOperationCallback={onNewOperationChosen}
                />
            </Grid>
        </>}

        {editingOperation && <>
            <Grid container item xs justifyContent={"flex-start"} >
                <OperationEditor />
            </Grid>
        </>}

    </Grid>;
}

export default CADOperationAddEdit;