import React from 'react';
import Grid from "@mui/material/Grid";
import { ICADEdge, ICADLayer } from "../../interfaces";
import { EdgeAdjustmentsEnum } from "../../enums";
import { EdgeAdjustment } from "./AddEdgeAdjustment";
import EdgeShiftComponent from "./EdgeShiftComponent";
import EdgeRakeComponent from "./EdgeRakeComponent";
import EdgeElbowComponent from "./EdgeElbowComponent";

interface IEdgeAdjustmentEdgeComponentProps {
    layer: ICADLayer;
    edge: ICADEdge;
    adjustmentType: EdgeAdjustmentsEnum;
    errorMessage: string;
    handleCanApply: (canApply: boolean, item: EdgeAdjustment | null) => void;
}

const EdgeAdjustmentEdgeComponent: React.FC<IEdgeAdjustmentEdgeComponentProps> = (props: IEdgeAdjustmentEdgeComponentProps) => {

    const existingShift = props.layer.edgeShifts.find((shift) => shift.edgeName === props.edge.edgeName) ?? null;
    const existingRake = props.layer.edgeRakes.find((rake) => rake.edgeName === props.edge.edgeName) ?? null;
    const existingElbow = props.layer.edgeElbows.find((elbow) => elbow.edgeName === props.edge.edgeName) ?? null;

    const canEnterShift = props.adjustmentType === EdgeAdjustmentsEnum.Shift;
    const canEnterRake = props.adjustmentType === EdgeAdjustmentsEnum.Rake;
    const canEnterElbow = props.adjustmentType === EdgeAdjustmentsEnum.Elbow;

    return <Grid item container direction={"column"} justifyContent={"end"} spacing={1} alignItems={"center"}>

        {canEnterShift === true && <>
            <Grid item container pb={(canEnterElbow || canEnterRake) ? 2 : 0}>
                <EdgeShiftComponent
                    edgeName={props.edge.edgeName}
                    existingShift={existingShift}
                    handleCanApply={props.handleCanApply}
                    errorMessage={props.errorMessage}
                />
            </Grid>
        </>}

        {(canEnterRake === true) && <>
            <Grid item container pb={canEnterElbow ? 2 : 0}>
                <EdgeRakeComponent
                    edgeName={props.edge.edgeName}
                    existingRake={existingRake}
                    handleCanApply={props.handleCanApply}
                    errorMessage={props.errorMessage}
                />
            </Grid>
        </>}


        {(canEnterElbow === true) && <>
            <Grid item container >
                <EdgeElbowComponent
                    edgeName={props.edge.edgeName}
                    existingElbow={existingElbow}
                    handleCanApply={props.handleCanApply}
                    errorMessage={props.errorMessage}
                />
            </Grid>
        </>}

    </Grid >;

}

export default EdgeAdjustmentEdgeComponent;