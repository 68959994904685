import * as React from "react";
import { TextField } from "@mui/material";
import { ICADOperationEditor, ICADParameterEdit } from "../../interfaces";
import { useCADActions } from "../../Context/CADContext";
import ParameterTooltip from "./ParameterTooltip";

interface IOperationParameterTextProps {
    parameter: ICADParameterEdit;
    PostParameterAsync: (opParam: ICADParameterEdit) => Promise<ICADOperationEditor>;
    GetParameterFromLabel: (newEditor: ICADOperationEditor, label: string) => ICADParameterEdit;
    loading: boolean;
}

const OperationParameterText: React.FC<IOperationParameterTextProps> = ({parameter, PostParameterAsync, GetParameterFromLabel, loading}) => {

    const opParam = parameter;
    const cadActions = useCADActions();

    const [inputBuffer, setInputBuffer] = React.useState<string>(opParam.parameterTextValue);

    const handleValueChange = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;

        if (newValue !== opParam.parameterTextValue) {
            cadActions.setOperationEditorLoading();
            const newEditor = await PostParameterAsync({ ...opParam, parameterTextValue: newValue });
            cadActions.setOperationEditor(newEditor);
            const newParam = GetParameterFromLabel(newEditor, opParam.name);
            if (newParam) {
                setInputBuffer(newParam.parameterTextValue);
            }
        }

    }, [GetParameterFromLabel, PostParameterAsync, cadActions, opParam]);

    const paramLabel = React.useMemo(() => {
        if (opParam.description.length > 0) {
            return `${opParam.name} - ${opParam.description}`;
        }
        else {
            return opParam.name;
        }
    }, [opParam]);

    return <ParameterTooltip parameter={opParam}>
        <TextField
            disabled={loading}
            label={paramLabel}
            placeholder={paramLabel}
            variant="outlined"
            size="small"
            fullWidth
            value={inputBuffer}
            onChange={(e) => setInputBuffer(e.target.value)}
            onBlur={handleValueChange}
        />
    </ParameterTooltip>

}

export default OperationParameterText;