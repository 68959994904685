import { ITranslationManager } from "@fenetech/translations";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import { ILineItem } from "models/IQuote";

export const itemColumn = (field: string, title: string, tm: ITranslationManager, isMobile: boolean) => {

    const col = {
        field: field,
        headerName: tm.Get(title),
        mindWidth: isMobile ? 90 : 130,
        hideable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            if (!params || !params.row) return <></>;

            return <Typography variant="body2">
                {params.row.lineItem === 0 ? tm.Get("ORDER") : params.row.lineItem}
            </Typography>
        }
    }

    return col;
}

export const partColumn = (field: string, title: string, lineItems: ILineItem[], tm: ITranslationManager, isMobile: boolean) => {

    const col = {
        field: field,
        headerName: tm.Get(title),
        minWidth: isMobile ? 90 : 130,
        hideable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            if (!params || !params.row) return <></>;
            
            return <Typography variant="body2">
                {lineItems.find(x => x.lineItemNumber === params.row.lineItem)?.formattedPart ?? ""}
            </Typography>
        }
    }

    return col;
}