export default class QuoteNavigation {

    public static QuoteEntryURL(oKey: number) {
        return `/quotes?oKey=${oKey}`;
    }

    public static QuoteEditURL(oKey: number, section: string) {
        return `/quotes/edit?oKey=${oKey}&section=${section}`;
    }

    public static GlobalOptionsURL(oKey: number) {
        return `/quotes/globaloptions?oKey=${oKey}`;
    }

    public static ProductNavigatorURL(oKey: number) {
        return `/quotes/navigator?oKey=${oKey}`;
    }

    public static TransLogURL(oKey: number) {
        return `/quotes/translog?oKey=${oKey}`;
    }

    public static LineItemPriceBreakdownURL(oKey: number, odKey: number) {
        return `/quotes/pricebreakdown?oKey=${oKey}&odKey=${odKey}`;
    }

    public static OptionsWizardURL(oKey: number, odKey: number) {
        return `/quotes/lineitem?oKey=${oKey}&odKey=${odKey}`;
    }

    public static QuoteAttachmentsURL(oKey: number) {
        return `/quotes/attachments?oKey=${oKey}`;
    }
    
    public static PasteSpecialURL (oKey: number, odKey: number) {
        return `/quotes/pastespecial?oKey=${oKey}&odKey=${odKey}`;
    }

}