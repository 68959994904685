import * as React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { ICADOperationEditor, ICADParameterEdit, ICADParameterListItem } from "../../interfaces";
import { useCADActions } from "../../Context/CADContext";
import ParameterTooltip from "./ParameterTooltip";

interface IOperationParameterListProps {
    parameter: ICADParameterEdit;
    PostParameterAsync: (opParam: ICADParameterEdit) => Promise<ICADOperationEditor>;
    GetParameterFromLabel: (newEditor: ICADOperationEditor, label: string) => ICADParameterEdit;
    loading: boolean;
}

const OperationParameterList: React.FC<IOperationParameterListProps> = ({parameter, PostParameterAsync, GetParameterFromLabel, loading}) => {

    const opParam = parameter;
    const cadActions = useCADActions();

    const [selectedItemText, setSelectedItemText] = React.useState<string>(opParam.selectedListItem);
    const [selectedListItemValueText, setSelectedListItemValueText] = React.useState<string>(opParam.selectedListItemValue);

    const selectedItem = React.useMemo(() => {
        return opParam.listItems?.find((li) => li.value === selectedItemText)
    }, [opParam.listItems, selectedItemText]);

    const selectedItemValuesExist = React.useMemo(() => {
        if (opParam.selectedListItemValues?.length > 0) {
            return true;
        }
        return false;
    }, [opParam.selectedListItemValues]);

    const selectedListItemValue = React.useMemo(() => {
        return opParam.selectedListItemValues?.find((li) => li.value === selectedListItemValueText)
    }, [opParam.selectedListItemValues, selectedListItemValueText]);

    const paramLabel = React.useMemo(() => {
        if (opParam.description.length > 0) {
            return `${opParam.name} - ${opParam.description}`;
        }
        else {
            return opParam.name;
        }
    }, [opParam]);

    const handleValueChange = React.useCallback(async (newValue: string) => {

        if (newValue !== opParam.selectedListItem) {
            cadActions.setOperationEditorLoading();
            const newEditor = await PostParameterAsync({ ...opParam, selectedListItem: newValue });
            cadActions.setOperationEditor(newEditor);
            const newParam = GetParameterFromLabel(newEditor, opParam.name);
            if (newParam) {
                setSelectedItemText(newParam.selectedListItem);
            }
        }

    }, [GetParameterFromLabel, PostParameterAsync, cadActions, opParam]);

    const handleSelectedListItemValueChange = React.useCallback(async (newValue: string) => {

        if (newValue !== opParam.selectedListItem) {
            cadActions.setOperationEditorLoading();
            const newEditor = await PostParameterAsync({ ...opParam, selectedListItemValue: newValue });
            cadActions.setOperationEditor(newEditor);
            const newParam = GetParameterFromLabel(newEditor, opParam.name);
            if (newParam) {
                setSelectedListItemValueText(newParam.selectedListItemValue);
            }
        }

    }, [GetParameterFromLabel, PostParameterAsync, cadActions, opParam]);

    return <ParameterTooltip parameter={opParam}>
        <Grid container spacing={1}>
            <Grid item xs={selectedItemValuesExist ? 6 : 12}>
                <Autocomplete
                    disabled={loading}
                    fullWidth
                    autoComplete
                    disableClearable
                    autoSelect
                    autoHighlight
                    selectOnFocus
                    handleHomeEndKeys
                    options={opParam.listItems}
                    getOptionLabel={(li: ICADParameterListItem) => li.text}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            label={paramLabel}
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_, value) => handleValueChange(value.value)}
                    value={selectedItem}
                />
            </Grid>
            {selectedItemValuesExist &&
                <Grid item xs={6}>
                    <Autocomplete
                        disabled={loading}
                        fullWidth
                        autoComplete
                        disableClearable
                        autoSelect
                        autoHighlight
                        selectOnFocus
                        handleHomeEndKeys
                        options={opParam.selectedListItemValues}
                        getOptionLabel={(li: ICADParameterListItem) => li.text}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label={""}
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                        onChange={(_, value) => handleSelectedListItemValueChange(value.value)}
                        value={selectedListItemValue}
                    />
                </Grid>
            }
        </Grid>
    </ParameterTooltip>
}

export default OperationParameterList;