import AlternateWizardMain from "components/OptionsWizard/AlternateWizard/AlternateWizardMain";
import { IValidationInfo, ISubLineItemInfo, IOpeningDesignerOpeningSubLineItemInfo } from "components/OptionsWizard/interfaces";
import { IPartKey } from "helpers/interfaces";
import MultiSelectMain from "components/OptionsWizard/MultiSelect/MultiSelectMain";
import useWizardActionButtons from "components/OptionsWizard/useWizardActionButtons";
import useWizardHeaderText from "components/OptionsWizard/useWizardHeaderText";
import useWizardState, { useWizardStateActions } from "components/OptionsWizard/WebDesigner/useWizardState";
import WizardAPI, { ILineItemSimple } from "components/OptionsWizard/WizardAPI";
import { nullish, WizardModeEnum, WizardViewEnum } from "components/OptionsWizard/WizardContext";
import ProductNavigator from "components/ProductNavigator/ProductNavigator";
import useWait from "helpers/context/Page/useWait";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import React from 'react';
import StandardWizardMain from "./MainWizard/StandardWizardMain";
import ShapeLibrary from "./Configurators/ShapeLibrary/ShapeLibrary";
import ShapeEditor from "./Configurators/ShapeEditor/ShapeEditor";
import LoadingLinearProgress from "components/Common/LoadingLinearProgress";
import useWizardInteractions from "./useWizardInteractions";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";
import { useNavigate } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import useItemPropertiesActions from "components/OptionsWizard/ItemProperties/useItemPropertiesActions";
import { ShapeDirectionEnum, OpeningShapeEnum, SizingModeEnum } from "helpers/enums";
import CADMain from "./CAD/CADMain";

interface IProps {
    oKey: number;
    odKey: number;
}

export type PageButtonCallback = (validation: IValidationInfo | nullish) => void;

const Wizard: React.FC<IProps> = ({ oKey: okey, odKey: odkey }: IProps) => {

    const wizardState = useWizardState();
    const wizardActions = useWizardStateActions();

    const [simpleLineItem, setSimpleLineItem] = React.useState<ILineItemSimple | null>(null)
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const [partList, setPartList] = React.useState<IPartKey[]>([]);

    const wait = useWait();
    const wizardInteractions = useWizardInteractions();
    const itemPropertiesActions = useItemPropertiesActions();
    const navigate = useNavigate();

    const getLineItemInfo = React.useCallback(() => {
        WizardAPI.GetWizardLineItemInfoSimple().then((li) => {
            setSimpleLineItem(li);
        });
    }, []);

    React.useEffect(() => {
        if (wizardState.itemInfo) {

            const webDesigner = wizardState.webDesigner;
            const selectedSLI = webDesigner?.sectionList?.find(sli => sli.sli === webDesigner?.currentSection) ?? null;
            const designerProperties = selectedSLI?.designerProperties as IOpeningDesignerOpeningSubLineItemInfo | undefined;

            // ODSLIProperties does not use itemProperties context, so avoid updating it from ODSLI
            if (!selectedSLI || selectedSLI.sli === 0) {
                itemPropertiesActions.SetState({
                    qty: wizardState.itemInfo.qty,
                    callSize: wizardState.itemInfo.callSize,
                    width: wizardState.itemInfo.width,
                    height: wizardState.itemInfo.height,
                    thickness: wizardState.itemInfo.thickness,
                    comment: wizardState.itemInfo.comment,
                    direction: designerProperties?.direction ?? ShapeDirectionEnum.Left,
                    legHeight: designerProperties?.legHeight ?? 0,
                    radius: designerProperties?.radius ?? 0,
                    shape: designerProperties?.shape ?? OpeningShapeEnum.Standard,
                    sizing: designerProperties?.sizing ?? SizingModeEnum.Fixed,
                    customerRef: wizardState.itemInfo.customerRef,
                    itemGroup: wizardState.itemInfo.itemGroup
                }, wizardState.itemInfo.unitSet);
            }
        }
    }, [wizardState.itemInfo, itemPropertiesActions, wizardState.webDesigner]);

    React.useEffect(() => {
        if (initialized) {
            getLineItemInfo();
        }
    }, [initialized, getLineItemInfo]);

    const resetView = React.useCallback(() => {
        wait.Show(false);
        wizardActions?.SwitchToView(WizardViewEnum.Wizard);
    }, [wait, wizardActions]);

    const commitAction = React.useCallback(() => {
        wait.Show(true);
        wizardInteractions.CommitCurrentActionAsync().finally(() => {
            resetView();
            wizardActions?.SetCameFromMultiSelect(false);
        });
    }, [wizardInteractions, resetView, wizardActions, wait]);

    const pageHeader = useWizardHeaderText(odkey, simpleLineItem);
    useWindowTitle(pageHeader);

    useNavigationBlocker(true);

    useWizardActionButtons(okey, odkey, commitAction, getLineItemInfo, simpleLineItem);

    useEffectOnLoad(() => {

        WizardAPI.InitExistingLineItemAsync(okey, odkey).then((sessionInfo) => {

            if (sessionInfo) {

                if (sessionInfo.sequenced) {
                    wizardActions?.SwitchToSequencedWizard();
                }

                setInitialized(true);
                wizardInteractions.InitialLoad();
            } else {
                navigate(QuoteNavigation.QuoteEntryURL(okey));
            }

        });

    });

    React.useEffect(() => {
        let newPartList: IPartKey[] = [];
        if (wizardState.wizardView === WizardViewEnum.ProductNavigator && wizardState.webDesigner) {
            let currentInfo: ISubLineItemInfo | undefined;
            if (wizardState.wizardMode === WizardModeEnum.Standard) {
                currentInfo = wizardState.webDesigner.sectionList?.find((sli) => sli.sli === wizardState.webDesigner?.currentSection);
            } else if (wizardState.wizardMode === WizardModeEnum.SequencedWizard && wizardState.sequencedWizardInfo) {
                currentInfo = wizardState.webDesigner.sectionList?.find((sli) => sli.sli === wizardState.sequencedWizardInfo?.selectedItems[0]);
            }
            if (currentInfo) {
                newPartList = currentInfo.availableParts.map((x) => { return { masterPartNo: x.partNo, partNoSuffix: x.partNoSuffix, shortcutName: "" } as IPartKey });
            }
        }
        setPartList(newPartList);
    }, [wizardState]);

    if (initialized) {
        return <>
            {(wizardState.wizardMode === WizardModeEnum.Standard) && (wizardState.wizardView === WizardViewEnum.Wizard) &&
                <StandardWizardMain />
            }

            {(wizardState.wizardMode === WizardModeEnum.CAD) &&
                <CADMain owPreferences={wizardState.userPreferences} itemInfo={wizardState.itemInfo} />
            }

            {(wizardState.wizardMode === WizardModeEnum.MultiSelect) &&
                <MultiSelectMain />
            }

            {(wizardState.wizardMode === WizardModeEnum.SequencedWizard) &&
                <AlternateWizardMain />
            }

            {(wizardState.wizardView === WizardViewEnum.ProductNavigator) && partList.length > 0 &&
                <ProductNavigator
                    oKey={okey}
                    initialFinalNodeID=""
                    initialCallSize=""
                    partList={partList}
                    onCancel={() => {
                        wizardActions?.SwitchToView(WizardViewEnum.Wizard);
                    }}
                    onPartSelected={(part) => {
                        wait.Show(true);
                        wizardInteractions.ChangeDesignerPartAsync(part.masterPartNo, part.partNoSuffix).then(() => {
                            if (wizardState.wizardMode === WizardModeEnum.SequencedWizard) {
                                commitAction();
                            } else {
                                resetView();
                            }
                        });
                    }}
                />
            }

            {(wizardState.wizardView === WizardViewEnum.ShapeLibrary) &&
                <ShapeLibrary />
            }

            {(wizardState.wizardView === WizardViewEnum.ShapeEditor) &&
                <ShapeEditor />
            }
        </>
    } else {
        return <LoadingLinearProgress />;
    }

}

export default Wizard;
