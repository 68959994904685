import { useTranslations } from "@fenetech/translations";
import React, { useCallback } from 'react';
import { ICADOperationDimension } from "../../interfaces";
import { Checkbox, Grid } from "@mui/material";
import CADTable, { ITableColumn } from "../CADTable";
import CADOperationEditorAPI from "../../API/CADOperationEditorAPI";
import { useCADActions } from "../../Context/CADContext";

interface IOperationDimensionsProps {
    dimensions: ICADOperationDimension[] | undefined;
}

const OperationDimensions: React.FC<IOperationDimensionsProps> = (props: IOperationDimensionsProps) => {

    const tm = useTranslations();
    const cadActions = useCADActions();

    const handleDimensionClick = useCallback(async (dimension: ICADOperationDimension) => {

        let newDimension: ICADOperationDimension = { ...dimension, selected: !dimension.selected };

        const newState = await CADOperationEditorAPI.PostDimensionAsync(newDimension);
        cadActions.setOperationEditor(newState);

    }, [cadActions]);

    if (!props.dimensions || props.dimensions.length === 0) {
        return null;
    }

    const columns: Array<ITableColumn<ICADOperationDimension>> = [
        {
            fieldName: "name",
            headerText: tm.Get("Dimension"),
            width: "75%",
        },
        {
            fieldName: "selected",
            headerText: tm.Get("Add To Drawing"),
            width: "25%",
            render: (value, row) => {
                return <Grid container justifyContent={"center"}>
                    <Checkbox
                        sx={(theme) => {
                            return {
                                padding: theme.spacing(.375),
                                marginLeft: theme.spacing(.625)
                            };
                        }}
                        size="small"
                        checked={row.selected}
                        disabled={false}
                        onChange={() => handleDimensionClick(row)}
                    />
                </Grid>
            }
        }
    ]

    return <CADTable columns={columns} data={props.dimensions} />

}

export default OperationDimensions;